/* eslint-disable jsx-a11y/tabindex-no-positive */
/* eslint-disable import/order */
/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react'
import BaseRelatoryPage from '../../../components/hooks/BaseRelatoryPage'
import { Grid } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { fetchProductHistory, makeRelatory } from '../ProductHistoryActions'
import List from './List'
import Filter from './Filter'

const ProductHistory = props => {
  const dispatch = useDispatch()

  const { id } = props?.match?.params

  const [form, setForm] = useState({
    technical_id: '',
    product_id: '',
    date_start: '',
    date_end: '',
    user_id: '',
    moviment_type: '',
    per_page: 10,
    page: 1,
  })

  const handleRelatory = e => {
    e.preventDefault()
    dispatch(makeRelatory(form, id))
  }

  useEffect(() => {
    dispatch(fetchProductHistory(id, form))
  }, [form])

  useEffect(() => {
    if (id) {
      dispatch(fetchProductHistory(id))
    }
  }, [id])

  return (
    <BaseRelatoryPage
      title="Histórico de Produto"
      btnReturn
      btnReturnPath="/stock"
    >
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12}>
          <Filter form={form} setForm={setForm} handleRelatory={handleRelatory}/>
        </Grid>

        <Grid item xs={12}>
          <List form={form} setForm={setForm} />
        </Grid>
      </Grid>
    </BaseRelatoryPage>
  )
}

export default ProductHistory
