/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable import/order */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable eqeqeq */
/* eslint-disable no-return-assign */
/* eslint-disable object-shorthand */
/* eslint-disable camelcase */
import {
  ClickAwayListener,
  Grid,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Switch,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  makeStyles,
} from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import {
  StyledTableCellBody,
  StyledTableCellHeader,
} from '../../../../components/hooks/BaseTableComponents'
import {
  mdiAlert,
  mdiClipboardTextClockOutline,
  mdiDelete,
  mdiDotsVertical,
  mdiNoteEdit,
  mdiNoteEditOutline,
} from '@mdi/js'
import Icon from '@mdi/react'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import { useConfirm } from 'material-ui-confirm'
import { useDispatch, useSelector } from 'react-redux'
import colors from '../../../../assets/colors'
import Pagination from '../../../components/Pagination'
import { update } from '../TriggersActions'

const List = ({ items = [], form, setForm }) => {
  const confirm = useConfirm()
  const dispatch = useDispatch()
  const triggers = useSelector(state => state.triggers.items)
  const meta = useSelector(state => state.triggers.meta)

  const [paginate, setPaginate] = useState({
    page: 0,
    per_page: 10,
  })

  const handleChangeRowsPerPage = e => {
    const { value } = e.target

    setPaginate({
      ...paginate,
      per_page: value,
    })
  }

  const handleChangePage = (event, newPage) => {
    if (paginate.page > newPage) {
      return setPaginate({
        ...paginate,
        page: newPage - 1,
      })
    }

    setPaginate({
      ...paginate,
      page: newPage + 1,
    })
  }

  const handleChangeTriggerStatus = async (trigger, status) => {
    const data = {
      ...trigger,
      type: trigger?.type?.var_id,
      active: status,
    }

    dispatch(update(data, trigger?.id))
  }

  useEffect(() => {
    setForm({
      ...form,
      ...paginate,
    })
  }, [paginate])

  return (
    <>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <StyledTableCellHeader align="left" style={{ width: '20%' }}>
                Motivo
              </StyledTableCellHeader>
              <StyledTableCellHeader align="left">Dias</StyledTableCellHeader>
              <StyledTableCellHeader align="center">Tipo</StyledTableCellHeader>
              <StyledTableCellHeader align="right">
                Situação
              </StyledTableCellHeader>
              <StyledTableCellHeader align="right">Ações</StyledTableCellHeader>
            </TableRow>
          </TableHead>
          <TableBody>
            {triggers?.map(trigger => (
              <TableRow key={trigger?.id} hover>
                <StyledTableCellBody align="left">
                  {trigger?.reason}
                </StyledTableCellBody>
                <StyledTableCellBody align="left">
                  {trigger?.days}
                </StyledTableCellBody>
                <StyledTableCellBody align="center">
                  {trigger?.type?.value}
                </StyledTableCellBody>
                <StyledTableCellBody align="right">
                  <Switch
                    checked={trigger?.active}
                    onChange={e =>
                      handleChangeTriggerStatus(trigger, !trigger.active)
                    }
                  />
                </StyledTableCellBody>
                <StyledTableCellBody align="right">
                  <IconButton
                    component={Link}
                    to={`/crm/triggers/create-edit/${trigger.id}`}
                  >
                    <Icon path={mdiNoteEditOutline} size={1} />
                  </IconButton>
                </StyledTableCellBody>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        <Pagination
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          rowsPerPage={paginate?.per_page}
          rows={meta?.total}
          page={paginate?.page}
          handleChangePage={handleChangePage}
        />
      </TableContainer>
    </>
  )
}

export default List
