/* eslint-disable import/order */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable react/prop-types */
import Box from '@material-ui/core/Box'
import { MenuItem, Typography } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Popover from '@material-ui/core/Popover'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import FilterListIcon from '@material-ui/icons/FilterList'
import SearchIcon from '@material-ui/icons/Search'
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state'
import React, { useCallback, useState } from 'react'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { ValidatorForm } from 'react-material-ui-form-validator'
import { useDispatch, useSelector } from 'react-redux'

import {
  mdiFilterMultiple,
  mdiMagnify,
  mdiMicrosoftExcel,
  mdiTable,
} from '@mdi/js'
import BadgeGlobal from '../../components/Badge'
import {
  fetchSchedulesRelatoryInit,
  makeRelatory,
} from '../SchedulesRelatoryActions'
import SelectField from '../../components/SelectField'
import BaseFilter from '../../../components/hooks/BaseFilter'
import filterStylesComponents from '../../../assets/filterComponentsStyles'
import Icon from '@mdi/react'
import colors from '../../../assets/colors'
import BaseFilterModal from '../../../components/hooks/BaseFilterModal'

import { addDays, addHours, format, setHours } from 'date-fns'

export default function Filter(props) {
  const items = useSelector(state => state.schedules_relatory.items)
  const equips = useSelector(state => state.schedules_relatory.equips)
  const servicesTypes = useSelector(
    state => state.schedules_relatory.servicesTypes
  )
  const companies = useSelector(state => state.schedules_relatory.companies)
  const sizePerPage = useSelector(state => state.schedules_relatory.sizePerPage)

  const styles = filterStylesComponents()

  const dispatch = useDispatch()
  const { classes, setOpenColumns } = props
  const [form, setForm] = useState({
    id: '',
    name: '',
    equip: '',
    date_start: format(new Date(), 'yyyy-MM-dd 00:00:00'),
    date_end: format(new Date(), 'yyyy-MM-dd 23:59:59'),
    service_type: '',
  })

  const [open, setOpen] = useState(false)

  const handleClose = () => {
    setOpen(!open)
  }

  const onSubmit = e => {
    e.preventDefault()
    const data = { ...form, service_type: form.service_type?.id }
    dispatch(fetchSchedulesRelatoryInit(null, data, sizePerPage))
  }

  const updateField = useCallback(
    (name, value) => {
      setForm({ ...form, [name]: value })
    },
    [form]
  )

  const handleExportExcel = () => {
    dispatch(makeRelatory(form))
  }

  const handleChangePeriod = e => {
    e.preventDefault()

    if (form.date_end && form.date_start) {
      dispatch(fetchSchedulesRelatoryInit(null, form, sizePerPage))
    }
  }

  const handleChangeDate = (date, name) => {
    // if (date != 'Invalid Date') {
    //   const currentDate = new Date(date)
    //   const fixDateError = setHours(currentDate, 24)

    //   setForm({
    //     ...form,
    //     [name]: format(fixDateError, 'yyyy-MM-dd'),
    //   })
    // } else {
    let formattedDate = date

    try {
      formattedDate = format(date, 'yyyy-MM-dd 00:00:00')
      if (name === 'date_end') {
        formattedDate = format(date, 'yyyy-MM-dd 23:59:59')
      }
    } catch (error) {
      console.log('Invalid Date')
    }
    setForm({
      ...form,
      [name]: formattedDate,
    })
    // }
  }

  const handleSubmit = () => {
    const data = { ...form, service_type: form.service_type?.id }
    dispatch(fetchSchedulesRelatoryInit(null, data, sizePerPage))
  }

  return (
    <>
      <BaseFilter>
        <Grid container xs={12} spacing={1} alignItems="center">
          <Grid item xs={2}>
            <KeyboardDatePicker
              clearable
              value={form.date_start}
              label="Data Inicial"
              fullWidth
              views={['date']}
              clearLabel="Limpar"
              invalidDateMessage="Data inválida"
              autoOk
              // onBlur={handleChangePeriod}
              onChange={date => handleChangeDate(date, 'date_start')}
              format="dd/MM/yyyy"
            />
          </Grid>
          <Grid item xs={2}>
            <KeyboardDatePicker
              clearable
              value={form.date_end}
              label="Data Final"
              fullWidth
              views={['date']}
              clearLabel="Limpar"
              invalidDateMessage="Data inválida"
              autoOk
              onChange={date => handleChangeDate(date, 'date_end')}
              format="dd/MM/yyyy"
            />
          </Grid>

          <Grid item xs={2}>
            <IconButton className={styles.btnAdd} onClick={handleSubmit}>
              <Icon path={mdiMagnify} size={1} />
              Pesquisar
            </IconButton>
          </Grid>

          <Grid
            item
            container
            xs={6}
            justifyContent="flex-end"
            alignItems="flex-end"
            className={styles.areaButtons}
          >
            <Grid item>
              <IconButton
                className={styles.btnMoreFilters}
                onClick={() => setOpenColumns()}
              >
                <Icon path={mdiTable} size={1} color={colors.secondary} />
                Colunas da tabela
              </IconButton>
            </Grid>

            <Grid item>
              <IconButton
                className={styles.btnMoreFilters}
                onClick={handleClose}
              >
                <Icon
                  path={mdiFilterMultiple}
                  size={1}
                  color={colors.secondary}
                />
                Outros Filtros
              </IconButton>
            </Grid>

            <Grid item>
              <IconButton className={styles.btnAdd} onClick={handleExportExcel}>
                <Icon path={mdiMicrosoftExcel} size={1} />
                Download
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </BaseFilter>

      <BaseFilterModal title="FILTRO" open={open} handleOpen={handleClose}>
        <Grid
          container
          xs={12}
          item
          style={{ width: '400px' }}
          direction="row"
          spacing={1}
        >
          <Grid item xs={12}>
            <Typography
              style={{ fontSize: '16px', fontWeight: 'normal' }}
              color={colors.primary}
            >
              Utilize os campos abaixo para encontrar <br /> um item de
              agendamento
            </Typography>
          </Grid>

          <Grid item container xs={12}>
            <ValidatorForm onSubmit={onSubmit}>
              <Grid
                container
                spacing={1}
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={6}>
                  <TextField
                    onChange={e => setForm({ ...form, id: e.target.value })}
                    label="Numero da OS"
                    margin="dense"
                    className={classes.inputFilter}
                    fullWidth
                    value={form.id}
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    onChange={e => setForm({ ...form, name: e.target.value })}
                    label="Nome do Cliente"
                    margin="dense"
                    className={classes.inputFilter}
                    fullWidth
                    value={form.name}
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    onChange={e => setForm({ ...form, equip: e.target.value })}
                    select
                    label="Equipe"
                    className={classes.inputFilter}
                    fullWidth
                    value={form.equip}
                  >
                    <MenuItem key="" value="">
                      Selecione...
                    </MenuItem>
                    {equips?.map(eq => (
                      <MenuItem key={eq.id} value={eq.id}>
                        {eq.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item xs={6}>
                  <SelectField
                    options={servicesTypes}
                    setForm={updateField}
                    data={form.service_type || null}
                    name="service_type"
                    label="Tipo de Serviço"
                  />
                </Grid>

                <Grid item xs={6}>
                  <KeyboardDatePicker
                    clearable
                    value={form.date_start}
                    label="Data Inicial"
                    fullWidth
                    views={['date']}
                    clearLabel="Limpar"
                    invalidDateMessage="Data inválida"
                    autoOk
                    onChange={date => handleChangeDate(date, 'date_start')}
                    format="dd/MM/yyyy"
                  />
                </Grid>

                <Grid item xs={6}>
                  <KeyboardDatePicker
                    clearable
                    value={form.date_end}
                    label="Data Final"
                    fullWidth
                    views={['date']}
                    clearLabel="Limpar"
                    invalidDateMessage="Data inválida"
                    autoOk
                    onChange={date => handleChangeDate(date, 'date_end')}
                    format="dd/MM/yyyy"
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  container
                  alignItems="center"
                  justifyContent="center"
                  style={{ marginTop: '1.5em' }}
                >
                  <IconButton
                    type="submit"
                    fullWidth
                    className={styles.btnSalvar}
                    onClick={handleClose}
                  >
                    <SearchIcon />
                    {` Pesquisar`}
                  </IconButton>
                </Grid>
              </Grid>
            </ValidatorForm>
          </Grid>
        </Grid>
      </BaseFilterModal>
    </>

    // <Tooltip title="Filter list">
    //   <PopupState variant="popover" popupId="demo-popup-popover">
    //     {popupState => (
    //       <div>
    //         <IconButton aria-label="filter list" {...bindTrigger(popupState)}>
    //           <BadgeGlobal form={form} items={items}>
    //             <FilterListIcon />
    //           </BadgeGlobal>
    //         </IconButton>
    //         <Popover
    //           {...bindPopover(popupState)}
    //           anchorOrigin={{
    //             vertical: 'bottom',
    //             horizontal: 'center',
    //           }}
    //           transformOrigin={{
    //             vertical: 'top',
    //             horizontal: 'center',
    //           }}
    //         >
    //           <Box p={2} className={classes.popover}>
    //             <div className={classes.root}>
    //             </div>
    //           </Box>
    //         </Popover>
    //       </div>
    //     )}
    //   </PopupState>
    // </Tooltip>
  )
}
