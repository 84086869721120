/* eslint-disable import/order */
import React from 'react'
import BaseCreatePage from '../../../components/hooks/BaseCreatePage'
import Form from './Form'
import { Grid } from '@material-ui/core'
import colors from '../../../assets/colors'

const New = () => {
  return (
    <BaseCreatePage page="Assinatura" path="Nova" pageLink="/subscription">
      <Grid
        container
        xs={12}
        alignContent="center"
        justifyContent="center"
        style={{
          padding: '8px 0',
          borderTop: '1px dashed',
          borderColor: colors.textSecondary,
          borderBottom: '1px dashed',
          borderBottomColor: colors.textSecondary,
          color: colors.textPrimary,
          fontSize: '14px',
        }}
      >
        <Grid
          item
          xs={12}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            height: 'auto',
            fontWeight: 'normal',
          }}
        >
          Utilize os campos abaixo para fazer sua assinatura
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Form />
      </Grid>
    </BaseCreatePage>
  )
}

export default New
