/* eslint-disable eqeqeq */
/* eslint-disable import/order */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import {
  Button,
  Grid,
  InputAdornment,
  MenuItem,
  Switch,
  TextField,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import NumberFormat from 'react-number-format'
import { useSelector } from 'react-redux'
import filterStylesComponents from '../../../../assets/filterComponentsStyles'
import { exportFormatedMoney } from '../../../../util/utils'

const Form = ({ edit = false, product = {}, onSubmit }) => {
  const unitMeasures = useSelector(state => state.stock.unitMeasure)
  const classes = filterStylesComponents()

  const [form, setForm] = useState({
    name: '',
    unit_measure_id: '',
    minimun_stock: '',
    price: '',
    allow_fraction: false,
  })

  const handleChange = e => {
    e.preventDefault()
    const { name, value } = e.target

    setForm({
      ...form,
      [name]: value,
    })
  }

  const parseValue = value => {
    if (!value) return

    if (typeof value == 'number') return value

    return parseFloat(value?.replace(/\./g, '')?.replace(',', '.'))
  }

  const handleSubmit = e => {
    e.preventDefault()

    const values = {
      ...form,
      price: parseValue(form.price),
    }

    onSubmit(values)
  }

  useEffect(() => {
    if (edit && product) {
      setForm({
        ...form,
        ...product,
      })
    }
  }, [product, edit])

  return (
    <Grid container spacing={2}>
      <form onSubmit={handleSubmit} style={{ width: '100%' }}>
        <Grid item xs={12}>
          <TextField
            name="name"
            fullWidth
            label="Produto"
            required
            value={form.name}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            name="unit_measure_id"
            select
            fullWidth
            label="Medida"
            required
            value={form.unit_measure_id}
            onChange={handleChange}
          >
            <MenuItem value="">Selecione..</MenuItem>
            {unitMeasures?.map(unit => (
              <MenuItem value={unit?.var_id} key={unit?.var_id}>
                {unit?.value}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={12}>
          <TextField
            name="minimun_stock"
            fullWidth
            label="Quantidade mínima"
            required
            value={form.minimun_stock}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12}>
          <NumberFormat
            name="price"
            label="Valor"
            value={form.price}
            onChange={handleChange}
            customInput={TextField}
            decimalScale={2}
            decimalSeparator=","
            thousandSeparator="."
            displayType="input"
            startAdornment="R$"
            InputProps={{
              startAdornment: <InputAdornment>R$</InputAdornment>,
            }}
            fullWidth
            required
          />
        </Grid>

        <Grid item xs={12} style={{ margin: '.5em 0' }}>
          <Switch
            value={form.allow_fraction}
            onChange={() =>
              setForm({ ...form, allow_fraction: !form.allow_fraction })
            }
          />
          Movimentações fracionadas
        </Grid>

        <Grid
          item
          xs={12}
          style={{ marginTop: '2em' }}
          container
          alignItems="center"
          justifyContent="center"
        >
          <Button className={classes.btnSalvar} type="submit">
            {edit ? 'Salvar' : 'Cadastrar'}
          </Button>
        </Grid>
      </form>
    </Grid>
  )
}

export default Form
