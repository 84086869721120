import { Grid } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import NoteAddIcon from '@material-ui/icons/NoteAdd'
import Button from '@material-ui/core/Button'
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';



import styles from '../../../resources/theme/global'
import { fetchBanks, makeReport } from '../BankReportActions'
import { getBankReportListData } from '../BankReportReducer'

import BankCards from './Cards'
import BanksTable from './BanksTable'
import Filter from './Filter'

class BankReport extends Component {
  componentDidMount() {
    const { fetchBanks } = this.props
    fetchBanks()
  }

  render() {
    const { classes, items, graph, balance, allBillingTypes, makeReport, filters, bank } = this.props

    return (
      <>
        <Grid
          container
          className={classes.pageHead}
          spacing={1}
          alignItems="center"
        >
          <Grid item xs>
            <Typography variant="h4" color="secondary">
              Relatório bancário
            </Typography>
          </Grid>
          <Grid item>
            <Filter classes={classes} allBillingTypes={allBillingTypes}/>
          </Grid>

          <Grid item grid-xs-4>          
            <InputLabel id="bank-selector">Exportar Relatório</InputLabel>
            <Select
              style={{ width: '240px' }}
              labelId="bank-selector"
              id="bank-selector"
              value={bank}              
              onChange= {(e) => e.target.value?makeReport( filters, e.target.value):""}
              displayEmpty={false}
            >
              <MenuItem key="" value="">
                Selecione...
              </MenuItem>
              {this.props.items?.map(bank => (
                <MenuItem
                  key={bank.id}
                  value={bank.id}
                >
                  {bank.name}
                </MenuItem>
              ))}
            </Select>
            </Grid>
        </Grid>

        <BankCards balance={balance} />
        <BanksTable items={items} graph={graph} />
      </>
    )
  }
}

const mapStateToProps = state => ({
  ...getBankReportListData(state),
  permissions: state.auth.permissions || [],
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchBanks,
      makeReport
    },
    dispatch
  )
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(BankReport))
