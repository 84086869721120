// import { EDIT_CLIENT, FETCH_CLIENT } from './ClientPlanActions'

const INITIAL_STATE = {
  items: [],
  totalSize: 0,
  page: 1,
  sizePerPage: 10,
  filters: [],
  editingClient: null,
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_CLIENT_PLAN.SUCCESS:
      return {
        ...state,
        items: action.payload.data,
        filters: action.payload.data.filters,
        totalSize: action.payload.meta.total,
        page: action.payload.meta.current_page,
        sizePerPage: action.payload.meta.per_page,
      }
    case EDIT_CLIENT_PLAN.SUCCESS:
      return {
        ...state,
        editingClient: action.payload,
      }
    default:
      return state
  }
}

export const getClientPlanListData = state => {
  return {
    items: state.client.items,
    page: state.client.page,
    sizePerPage: state.client.sizePerPage,
    totalSize: state.client.totalSize,
  }
}

export const getEditingClientPlan = state => {
  return state.client.editingClient
}
