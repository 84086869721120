import { dateFormated } from '../../util/utils'

import { FETCH_BILLING, EDIT_BILLING, BANK_REPORT } from './BillingActions'

const INITIAL_STATE = {
  items: [],
  totalSize: 0,
  page: 1,
  sizePerPage: 100,
  filters: {},
  billingTypes: [],
  paymentMethods: [],
  editingBilling: null,
  statuses: [],
  companies: [],
  banks: [],
  bankBalance: null,
  bankReport: [],
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_BILLING.SUCCESS:
      return {
        ...state,
        items: action.payload.data.data,
        billingTypes: action.payload.data.billingTypes,
        totalSize: action.payload.data.meta.total,
        page: action.payload.data.meta.current_page,
        sizePerPage: action.payload.data.meta.per_page,
        statuses: action.payload.data.paymentStatuses,
        paymentMethods: action.payload.data.paymentMethods,
        companies: action.payload.data.companies,
        banks: action.payload.data.banks,
        filters: action.payload.filters,
      }
    case EDIT_BILLING.SUCCESS:
      return {
        ...state,
        editingBilling: {
          ...action.payload,
          date: dateFormated(action.payload.date),
          actual_payment_date: action.payload.actual_payment_date
            ? dateFormated(action.payload.actual_payment_date)
            : null,
        },
      }
    case BANK_REPORT.SUCCESS:
      return {
        ...state,
        bankBalance: action.payload.data.bankBalance,
        bankReport: action.payload.data.data,
        billingTypes: action.payload.data.billingTypes,
      }
    case 'CLEAR_BILLING_FILTERS':
      return {
        ...state,
        filters: {},
      }
    case 'CLEAR_BILLING':
      return {
        ...state,
        editingBilling: null,
      }
    default:
      return state
  }
}

export const getBillingListData = state => {
  return {
    items: state.billing.items,
    page: state.billing.page,
    sizePerPage: state.billing.sizePerPage,
    totalSize: state.billing.totalSize,
  }
}

export const getEditingBilling = state => {
  return state.billing.editingBilling
}
