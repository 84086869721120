import axios from 'axios/index'

import config from '../../config'
import action, { actionFailed } from '../../util/action'
import consts from '../../util/consts'
import createAsyncConst from '../../util/createAsyncConst'

export const SUBMIT_CLIENT_PLAN = createAsyncConst('SUBMIT_CLIENT')
export const FETCH_CLIENT_PLAN = createAsyncConst('FETCH_CLIENT')
export const EDIT_CLIENT_PLAN = createAsyncConst('EDIT_CLIENT')
export const FETCH_CEP = createAsyncConst('FETCH_CEP')

export function fetchClientPlan(page, filters = [], rows) {
  return dispatch => {
    dispatch(action(FETCH_CLIENT_PLAN.ACTION))
    return axios
      .get(`${config.API_URL}/client-plan`, {
        params: {
          filters: JSON.stringify(filters),
          rows: rows || consts.DEFAULT_PAGINATION,
          page: page || 1,
        },
      })
      .then(resp => {
        dispatch(action(FETCH_CLIENT_PLAN.SUCCESS, resp.data))
        return resp
      })
      .catch(e => dispatch(actionFailed(FETCH_CLIENT_PLAN, e)))
  }
}

export function edit(id) {
  return dispatch => {
    dispatch(action(EDIT_CLIENT_PLAN.ACTION, { id }))
    return axios
      .get(`${config.API_URL}/client-plan/${id}`)
      .then(resp => {
        return resp.data.data
      })
      .then(data => dispatch(action(EDIT_CLIENT_PLAN.SUCCESS, data)))
      .catch(e => dispatch(actionFailed(EDIT_CLIENT_PLAN, e)))
  }
}

function submit(values, method) {
  console.log("values",values)
  const id = values.id ? `/${values.id}` : ''
  return dispatch => {
    dispatch(action(SUBMIT_CLIENT_PLAN.ACTION))
    var formData = new FormData();
    values.is_active = values.is_active.toString();
    console.log("values str",values)
    Object.entries(values).forEach(entry => {
      
      entry[0] == 'logo' ? formData.append("logo", values.logo) : formData.append(entry[0], entry[1]) ;      
    });
    method == 'put' ? formData.append('_method', 'PUT'):''
    console.log(formData.entries) ;
    return axios['post'](`${config.API_URL}/client-plan${id}`, formData, {headers: {'Content-Type': 'multipart/form-data'}})
      .then(resp => resp.data)
      .then(data => {
        dispatch(action(SUBMIT_CLIENT_PLAN.SUCCESS, data))
        dispatch(fetchClientPlan())
        return data
      })
      .catch(e => dispatch(actionFailed(SUBMIT_CLIENT_PLAN, e)))
  }
}

export function post(values) {
  return submit(values, 'post')
}

export function update(values) {
  return submit(values, 'put')
}
