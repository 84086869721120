/* eslint-disable object-shorthand */
/* eslint-disable camelcase */
import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
  withStyles,
} from '@material-ui/core'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import ArrowBack from '@material-ui/icons/ArrowBackIos'
import classNames from 'classnames'
import NoteAddIcon from '@material-ui/icons/NoteAdd'

import styles from '../../../resources/theme/global'
import { dateFormatedFullDate, exportFormatedMoney } from '../../../util/utils'
import { details, late, makeReport } from '../HomeActions'

import Filter from './DetailsFilter'
import BaseRelatoryPage from '../../../components/hooks/BaseRelatoryPage'
import Icon from '@mdi/react'
import { mdiChevronLeft, mdiChevronRight } from '@mdi/js'
import colors from '../../../assets/colors'
import {
  StyledTableCellBody,
  StyledTableCellHeader,
} from '../../../components/hooks/BaseTableComponents'
import DetailsTable from './DetailsTable'
import queryString from 'query-string'

const useStyles = makeStyles({
  pageLink: {
    width: 'auto',
    '&:hover': {
      backgroundColor: 'inherit',
    },
  },
  page: {
    fontSize: '14px',
    color: colors.textGray,
    fontWeight: 'normal',
    transition: 'all .4s ease-in-out',
    '&:hover': {
      backgroundColor: 'inherit',
      color: colors.primary,
    },
  },
  path: {
    fontSize: '20px',
    color: colors.primary,
    fontWeight: '700',
    position: 'relative',
  },
  btnReturn: {
    width: 'auto',
    display: 'flex',
    alignItems: 'center',
    padding: '4px',
    fontSize: '14px',
    color: colors.gray,
    backgroundColor: 'transparent',
    transition: 'all .4s ease-in-out',
    '&:hover': {
      color: colors.primary,
      backgroundColor: 'inherit',
    },
  },
})

function Details(props) {
  const dispatch = useDispatch()
  const styles = useStyles()
  // const filters = useSelector(state => state.dashboard.filterDetails)
  // const isLate = useMemo(() => location.pathname.includes('late'))

  const { match, classes, location } = props

  const [modalOpen, setModalOpen] = useState(false)

  const handleModalOpen = () => {
    setModalOpen(!modalOpen)
  }

  const [filters, setFilters] = useState({
    date_start: '',
    date_end: '',
    status_id: '',
  })

  useEffect(() => {
    setFilters({
      ...filters,
      date_start: location?.state?.date_start,
      date_end: location?.state?.date_end,
      status_id: match.params.id,
    })

    dispatch(
      details(match.params.id, {
        date_start: location?.state?.date_start,
        date_end: location?.state?.date_end,
      })
    )
  }, [])

  useEffect(() => {
    dispatch(details(filters?.status_id || match.params.id, filters))
  }, [filters?.status_id])

  const items = useSelector(state => state.dashboard.details)

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item xs={8} container alignItems="center">
              <Typography variant="h6" color="inherit" className={styles.page}>
                Relátorios
              </Typography>

              <Icon path={mdiChevronRight} size={1} color={colors.graylight} />

              <Typography variant="h6" color="inherit" className={styles.path}>
                {`Itens ${items[0]?.status?.name || ''}` || ''}
              </Typography>
            </Grid>
            <Grid item xs={4} container justifyContent="flex-end">
              <IconButton className={styles.btnReturn} component={Link} to="/">
                <Icon
                  path={mdiChevronLeft}
                  size={1}
                  color={colors.textPrimary}
                />
                Voltar
              </IconButton>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Filter
            classes={classes}
            detailsId={match.params.id}
            filters={filters}
            setFilters={setFilters}
            handleModalOpen={handleModalOpen}
          />
        </Grid>

        <Grid item xs={12}>
          <DetailsTable
            data={items}
            open={modalOpen}
            handleModalOpen={handleModalOpen}
          />
        </Grid>
      </Grid>
      {/* <Grid
        container
        className={classes.pageHead}
        spacing={1}
        alignItems="center"
      >
        <Grid item xs>
          <Typography variant="h4" color="secondary">
            Itens {isLate ? 'Atrasados' : items[0]?.status?.name}
          </Typography>
        </Grid> */}
      {/* <Grid item><Filter classes={classes} /></Grid> */}
      {/* <Grid item>
          <Button
            color="primary"
            type="button"
            // onClick={() => dispatch(makeReport(match.params.id, filters))}
          >
            <NoteAddIcon className={classes.btnIcon} /> Relatório
          </Button>
        </Grid>
        <Grid item>
          <Button color="primary" variant="contained" component={Link} to="/">
            <ArrowBack className={classes.btnIcon} /> Voltar
          </Button>
        </Grid>
      </Grid> */}
      {/* <Grid container spacing={1}> */}
      {/* <Grid item xs>
          <Paper elevation={1}>
            <TableContainer className={classes.tableContainer}>
              <Table size="medium" className={classes.tableStyle}>
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Etiqueta</TableCell>
                    <TableCell>Numero da OS</TableCell>
                    <TableCell>Atendente</TableCell>
                    <TableCell>Tipo de serviço</TableCell>
                    <TableCell>Cliente</TableCell>
                    <TableCell>Lavanderia</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Data da Coleta</TableCell>
                    <TableCell>Status da Os</TableCell>
                    <TableCell>Valor do item</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {items.map(item => (
                    <TableRow
                      key={item.id}
                      className={classNames({
                        [classes.rowLate]: isLate || item.is_late,
                      })}
                    >
                      <TableCell>{item.id}</TableCell>
                      <TableCell>{item.bar_code || '-'}</TableCell>
                      {renderOsNumber(item)}
                      <TableCell>{item.service?.user?.name || '-'}</TableCell>
                      <TableCell>{item.service_type?.name}</TableCell>
                      <TableCell>
                        {item.service?.client?.name
                          ? item.service?.client?.name
                          : '-'}
                      </TableCell>
                      <TableCell>{item.status?.name}</TableCell>
                      <TableCell>
                        {dateFormatedFullDate(item.data_coleta) || '-'}
                      </TableCell>
                      <TableCell>{item.service?.status?.name}</TableCell>
                      <TableCell>
                        {exportFormatedMoney(item.final_value)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid> */}
      {/* </Grid> */}
    </>
  )
}

export default withStyles(styles)(Details)
