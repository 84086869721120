const statusColors = [
  {
    id: 1,
    status: 'Orçamento',
    color: '#242423',
  },
  {
    id: 2,
    status: 'Aguardando realização',
    color: '#1DBAEC',
  },
  {
    id: 3,
    status: 'Parcialmente concluido',
    color: '#ECA51D',
  },
  {
    id: 4,
    status: 'Concluido',
    color: '#449E68',
  },
  {
    id: 5,
    status: 'Cancelado',
    color: '#EC291D',
  },
  {
    id: 6,
    status: 'Orçamento Arquivado',
    color: '#EC291D',
  },
]

export default statusColors
