import { FETCH_STOCK, FETCH_PRODUCT } from './StockActions'

const INITIAL_STATE = {
  products: [],
  unitMeasure: [],
  technicals: [],
  product: {},
  meta: [],
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_STOCK.SUCCESS:
      return {
        ...state,
        products: action.payload.data.data,
        unitMeasure: action.payload.data.unit_measure,
        technicals: action.payload.data.technicals,
        meta: action.payload.data.meta,
      }
    case FETCH_PRODUCT.SUCCESS:
      return {
        ...state,
        product: action.payload.data,
      }
    default:
      return state
  }
}
