/* eslint-disable react/prop-types */
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Popover from '@material-ui/core/Popover'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import FilterListIcon from '@material-ui/icons/FilterList'
import SearchIcon from '@material-ui/icons/Search'
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import BadgeGlobal from '../../components/Badge'
import SelectField from '../../components/SelectField'
import { fetchUsers, makeReport } from '../UserActions'
import BaseFilter from '../../../components/hooks/BaseFilter'
import filterStylesComponents from '../../../assets/filterComponentsStyles'
import { renderRoute } from '../../../util/utils'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import Icon from '@mdi/react'
import {
  mdiFilterMultiple,
  mdiMagnifyExpand,
  mdiMicrosoftExcel,
  mdiNoteEditOutline,
} from '@mdi/js'
import { InputAdornment, Typography } from '@material-ui/core'
import { getUserListData } from '../UserReducer'
import colors from '../../../assets/colors'
import BaseFilterModal from '../../../components/hooks/BaseFilterModal'

export default function Filter(props) {
  const items = useSelector(state => state.user.items)
  const roles = useSelector(state => state.user.roles)
  const dispatch = useDispatch()
  const { classes, permissions } = props
  const [form, setForm] = useState({
    name: '',
    email: '',
    user_type: '',
    laundry_client: '',
  })
  const [open, setOpen] = useState(false)

  const styles = filterStylesComponents()

  const onSubmit = e => {
    e.preventDefault()
    dispatch(fetchUsers(null, form))
  }

  const updateUsers = React.useCallback(
    (name, value) => {
      setForm({
        ...form,
        [name]: value,
      })
    },
    [form]
  )

  useEffect(() => {
    if (!form.name) {
      dispatch(fetchUsers('', form))
    }
  }, [form?.name])

  useEffect(() => {
    dispatch(fetchUsers('', form))
  }, [form.user_type])

  const handleOpen = () => {
    setOpen(!open)
  }

  return (
    <>
      <BaseFilter>
        <Grid item container xs={12} alignItems="center">
          <Grid item container spacing={1} xs={6}>
            <Grid item xs={6}>
              <TextField
                onChange={e => setForm({ ...form, name: e.target.value })}
                label="Filtre por nome"
                className={classes.inputFilter}
                fullWidth
                value={form.name}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton>
                        <Icon
                          path={mdiMagnifyExpand}
                          size={1}
                          onClick={() => dispatch(fetchUsers('', form))}
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <SelectField
                options={roles}
                setForm={updateUsers}
                data={form.user_type}
                name="user_type"
                label="Filtre por função"
              />
            </Grid>
          </Grid>
          <Grid
            item
            className={styles.areaButtons}
            xs={6}
            container
            justifyContent="flex-end"
          >
            <IconButton className={styles.btnMoreFilters} onClick={handleOpen}>
              <Icon
                path={mdiFilterMultiple}
                size={1}
                color={colors.secondary}
              />{' '}
              Outros Filtros
            </IconButton>

            <IconButton
              className={styles.btnAdd}
              onClick={() => dispatch(makeReport(form))}
            >
              <Icon path={mdiMicrosoftExcel} size={1} /> Download
            </IconButton>

            {renderRoute(['create-users'], permissions) && (
              <IconButton
                color="primary"
                variant="contained"
                component={Link}
                to="/users/new"
                className={styles.btnAdd}
              >
                <Icon path={mdiNoteEditOutline} size={1} />
                Cadastrar
              </IconButton>
            )}
          </Grid>
        </Grid>
      </BaseFilter>

      <BaseFilterModal open={open} handleOpen={handleOpen} title="FILTRO">
        <Grid container xs={12} item style={{ width: '420px' }} spacing={1}>
          <Grid item xs={12}>
            <Typography
              style={{ fontSize: '16px', fontWeight: 'normal' }}
              color={colors.primary}
            >
              Utilize os campos abaixo para encontrar <br /> um usuário
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <TextField
              onChange={e => setForm({ ...form, name: e.target.value })}
              label="Nome"
              className={classes.inputFilter}
              fullWidth
              value={form.name}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              onChange={e => setForm({ ...form, email: e.target.value })}
              label="Email"
              className={classes.inputFilter}
              fullWidth
              value={form.email}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              onChange={e =>
                setForm({ ...form, laundry_client: e.target.value })
              }
              label="Cliente Lav."
              className={classes.inputFilter}
              fullWidth
              value={form.laundry_client}
            />
          </Grid>

          <Grid item xs={6}>
            <SelectField
              options={roles}
              setForm={updateUsers}
              data={form.user_type}
              name="user_type"
              label="Regra de usuário"
            />
          </Grid>

          <Grid
            item
            xs={12}
            container
            justifyContent="center"
            alignItems="center"
          >
            <Button
              type="submit"
              color="primary"
              variant="contained"
              className={styles.btnAdd}
              onClick={() => {
                dispatch(fetchUsers('', form))
                handleOpen()
              }}
              style={{ marginTop: '1.5em' }}
            >
              <SearchIcon />
              {` Filtrar`}
            </Button>
          </Grid>
        </Grid>
      </BaseFilterModal>
    </>
    // <Tooltip title="Filter list">
    //   <PopupState variant="popover" popupId="demo-popup-popover">
    //     {popupState => (
    //       <div>
    //         <IconButton aria-label="filter list" {...bindTrigger(popupState)}>
    //           <BadgeGlobal form={form} items={items}>
    //             <FilterListIcon />
    //           </BadgeGlobal>
    //         </IconButton>
    //         <Popover
    //           {...bindPopover(popupState)}
    //           anchorOrigin={{
    //             vertical: 'bottom',
    //             horizontal: 'center',
    //           }}
    //           transformOrigin={{
    //             vertical: 'top',
    //             horizontal: 'center',
    //           }}
    //         >
    //           <Box p={2} className={classes.popover}>
    //             <div className={classes.root}>
    //               <form className={classes.form} onSubmit={onSubmit}>
    //                 <Grid
    //                   container
    //                   className={classes.gridContainer}
    //                   spacing={1}
    //                   justify="center"
    //                   alignItems="center"
    //                 >
    //                   <Grid item xs={6}>
    //                     <TextField
    //                       onChange={e =>
    //                         setForm({ ...form, name: e.target.value })
    //                       }
    //                       label="Nome"
    //                       margin="dense"
    //                       className={classes.inputFilter}
    //                       variant="outlined"
    //                       fullWidth
    //                       value={form.name}
    //                     />
    //                   </Grid>

    //                   <Grid item xs={6}>
    //                     <TextField
    //                       onChange={e =>
    //                         setForm({ ...form, email: e.target.value })
    //                       }
    //                       label="Email"
    //                       margin="dense"
    //                       className={classes.inputFilter}
    //                       variant="outlined"
    //                       fullWidth
    //                       value={form.email}
    //                     />
    //                   </Grid>

    //                   <Grid item xs={6}>
    //                     <TextField
    //                       onChange={e =>
    //                         setForm({ ...form, laundry_client: e.target.value })
    //                       }
    //                       label="Cliente Lav."
    //                       margin="dense"
    //                       className={classes.inputFilter}
    //                       variant="outlined"
    //                       fullWidth
    //                       value={form.laundry_client}
    //                     />
    //                   </Grid>

    //                   <Grid item xs={6}>
    //                     <SelectField
    //                       options={roles}
    //                       setForm={updateUsers}
    //                       data={form.user_type}
    //                       margin="dense"
    //                       name="user_type"
    //                       label="Regra de usuário"
    //                       variant="outlined"
    //                     />
    //                   </Grid>

    //                   <Grid item xs>
    //                     <Button
    //                       type="submit"
    //                       color="primary"
    //                       fullWidth
    //                       variant="contained"
    //                       className={classes.submit}
    //                       onClick={popupState.close}
    //                     >
    //                       <SearchIcon />
    //                       {` Filtrar`}
    //                     </Button>
    //                   </Grid>
    //                 </Grid>
    //               </form>
    //             </div>
    //           </Box>
    //         </Popover>
    //       </div>
    //     )}
    //   </PopupState>
    // </Tooltip>
  )
}
