/* eslint-disable import/order */
import AppBar from '@material-ui/core/AppBar'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import withStyles from '@material-ui/core/styles/withStyles'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styles from '../../../resources/theme/users'
import Transiction from '../../components/Transition'
import { edit, update } from '../ClientActions'
import Form from './Form'
import { Grid, makeStyles } from '@material-ui/core'
import BaseCreatePage from '../../../components/hooks/BaseCreatePage'
import colors from '../../../assets/colors'

const Transition = React.forwardRef((props, ref) => {
  return <Transiction propis={props} refi={ref} />
})

const useStyles = makeStyles({
  rowMessage: {
    padding: '8px 0',
    borderTop: '1px dashed',
    borderColor: colors.textSecondary,
    borderBottom: '1px dashed',
    borderBottomColor: colors.textSecondary,
    color: colors.textPrimary,
    fontSize: '14px',
  },
  message: {
    display: 'flex',
    alignItems: 'center',
    height: 'auto',
    fontWeight: 'normal',
  },
  btnCalendar: {
    color: '#FFFF',
    fontSize: '14px',
    backgroundColor: colors.primary,
    borderRadius: '10px',
    padding: '4px 12px',
    transition: 'all .4s ease-in-out',
    '&:hover': {
      opacity: 0.6,
      backgroundColor: colors.primary,
    },
  },
})

function EditType(props) {
  const { classes } = props
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(edit(props.match.params.id))
  }, [])

  const styles = useStyles()

  const client = useSelector(state => state.client.editingClient)

  const onSubmit = values => {
    dispatch(update(values)).then(data => {
      if (data && data.code === 200) {
        props.history.push('/clients')
      }
    })
  }

  const onClose = () => {
    props.history.push('/clients')
  }

  return (
    <BaseCreatePage
      page="Clientes"
      path="Editar"
      onClose={onClose}
      pageLink="/clients"
    >
      <Grid
        container
        xs={12}
        alignContent="center"
        justifyContent="flex-start"
        className={styles.rowMessage}
      >
        <Grid item xs={8} className={styles.message}>
          Utilize os campos abaixo para editar um cliente
        </Grid>
      </Grid>
      <Form
        data={client}
        onClose={onClose}
        onSubmit={onSubmit}
        btnText="Salvar"
      />
    </BaseCreatePage>
  )
}

export default withStyles(styles)(EditType)
