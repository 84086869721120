/* eslint-disable import/order */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import React, { useMemo } from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Slide from '@material-ui/core/Slide'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import { Grid, Typography, makeStyles } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { format } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import Icon from '@mdi/react'
import { mdiHistory } from '@mdi/js'
import colors from '../../../assets/colors'
import BaseFilterModal from '../../../components/hooks/BaseFilterModal'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const useStyles = makeStyles({
  '@keyframes fadeIn': {
    '0%': {
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    },
  },
  box: {
    borderRadius: '16px',
    border: `2px solid ${colors.secondary}`,
    animation: '$fadeIn .4s ease-in-out',
    // position: 'absolute',
    // width: '226px',
    // // height: '120px',
    // borderRadius: '0 5px 5px 5px',
    // boxShadow: '0px 4px 5px 0px #00000080',
    // backgroundColor: '#FFF',
    // zIndex: 9999999999999,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '8px',
    padding: '8px 20px',
    position: 'relative',
    width: '100%',
    backgroundColor: colors.graylight,
    borderRadius: '5px 5px 0 0',
    '&:before': {
      content: "''",
      width: '12px',
      height: '12px',
      padding: '4px',
      transform: 'rotate(45deg)',
      borderRadius: '8px 0 0 0',
      backgroundColor: colors.graylight,
      position: 'absolute',
      left: '-2px',
      top: '-1px',
    },
  },
  headerTypography: {
    color: colors.gray,
    fontSize: '10px',
  },
  btnClose: {
    borderRadius: 'none',
    backgroundColor: 'inherit',
    position: 'absolute',
    padding: 0,
    margin: 0,
    top: '4px',
    right: '4px',
    '&:hover': {
      backgroundColor: 'inherit',
    },
  },
  body: {
    padding: '0 20px 8px 20px',
    color: colors.gray,
    fontSize: '10px',
    lineHeight: '12px',
  },
  btnDesmarcar: {
    color: colors.gray,
    fontSize: '10px',
    borderRadius: '5px',
    padding: '4px 8px',
    margin: 0,
    backgroundColor: colors.graylight,
    textAlign: 'center',
  },
  container: {
    width: '350px',
  },
  btn: {
    backgroundColor: colors.primary,
    color: '#FFF',
    width: '101px',
    height: '30px',
    borderRadius: '15px',
    fontSize: '14px',
    lineHeight: '14px',
    textAlign: 'center',
    textTransform: 'capitalize',
    transition: 'all .4s ease-in-out',
    '&:hover': {
      backgroundColor: colors.primary,
      opacity: '.7',
    },
  },
})

const ModalDay = ({
  open,
  handleClose,
  attendants,
  currentAttendant,
  currentEvent,
  changeAttendant,
  handleCancel,
  handleCancelTechnicalAbsence,
}) => {
  const classes = useStyles()

  const disabled = useMemo(() => {
    if (!currentEvent?.copy) return currentEvent?.lastStatus?.status_id > 3
    return currentEvent?.lastStatus?.status_id > 8
  }, currentEvent)

  const renderOsButton = () => {
    return (
      <IconButton
        component={Link}
        color="inherit"
        to={
          currentEvent.isLaundry
            ? `/laundry-os/${currentEvent.serviceId}/details`
            : `/attendance/${currentEvent.serviceId}/edit`
        }
        className={classes.btnDesmarcar}
      >
        OS: {currentEvent.service_id_laundry_client}
      </IconButton>
    )
  }

  const getEventDate = () => {
    if (currentEvent?.start) {
      const currentDate = new Date(currentEvent?.start)

      return format(currentDate, 'dd/MM/yyyy', { locale: ptBR })
    }

    return ''
  }

  const getEventTime = () => {
    if (currentEvent?.start && currentEvent?.end) {
      const eventStart = new Date(currentEvent?.start)
      const eventEnd = new Date(currentEvent?.end)

      const formattedTimeStart = format(eventStart, "HH'h'mm", { locale: ptBR })
      const formattedTimeEnd = format(eventEnd, "HH'h'mm", { locale: ptBR })

      return `De ${formattedTimeStart} às ${formattedTimeEnd}`
    }

    return ''
  }

  return (
    <BaseFilterModal
      open={open}
      handleOpen={handleClose}
      title="Editar agendamento"
      hiddenHelpButton
    >
      <Grid item xs={12} className={classes.container}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              style={{ fontSize: '14px', fontWeight: 'normal' }}
              color={colors.primary}
            >
              {getEventDate()} - {getEventTime()}
              <br />
              {currentEvent?.title}
              {currentEvent?.schedulingInformation && (
                <p>{`Inf. do agendamento: ${currentEvent?.schedulingInformation}`}</p>
              )}
            </Typography>
          </Grid>

          {!currentEvent?.isTechnicalAbsence && (
            <Grid item xs={12}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <Autocomplete
                    id="edit-responsable"
                    options={attendants}
                    disableClearable
                    disabled={disabled}
                    getOptionSelected={(option, value) =>
                      option.id === value.id
                    }
                    getOptionLabel={option => option.name}
                    value={currentAttendant || null}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Atendente"
                        variant="outlined"
                      />
                    )}
                    onChange={(event, newValue) => {
                      changeAttendant(currentEvent, newValue)
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Grid
                    container
                    justifyContent="flex-end"
                    alignItems="center"
                    spacing={1}
                  >
                    <Grid item>
                      {currentEvent?.service_id_laundry_client &&
                        renderOsButton()}
                    </Grid>
                    <Grid item>
                      <Tooltip
                        title="Restaurar"
                        aria-label="Restaurar"
                        placement="left"
                      >
                        <IconButton
                          color="inherit"
                          onClick={() =>
                            handleCancel(
                              currentEvent.id,
                              currentEvent.copy || null
                            )
                          }
                          aria-label="Close"
                          disabled={disabled}
                        >
                          <Icon path={mdiHistory} size={1} />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                    <Grid item>
                      <Button onClick={handleClose} className={classes.btn}>
                        Fechar
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}

          {currentEvent?.isTechnicalAbsence && (
            <Grid item xs={12}>
              <Grid
                container
                justifyContent="flex-end"
                alignItems="center"
                spacing={1}
              >
                <Grid item>
                  <Tooltip
                    title="Restaurar"
                    aria-label="Restaurar"
                    placement="left"
                  >
                    <IconButton
                      color="inherit"
                      onClick={() =>
                        handleCancelTechnicalAbsence(currentEvent.id)
                      }
                      aria-label="Close"
                      disabled={disabled}
                    >
                      <Icon path={mdiHistory} size={1} />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Button onClick={handleClose} className={classes.btn}>
                    Fechar
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </BaseFilterModal>
    // <div>
    //   <Dialog
    //     open={open}
    //     TransitionComponent={Transition}
    //     keepMounted
    //     maxWidth="sm"
    //     onClose={handleClose}
    //     aria-labelledby="modal-change-owner-title"
    //     aria-describedby="modal-change-owner-description"
    //   >
    //     <DialogTitle
    //       id="modal-change-owner-title"
    //       style={{ color: colors.primary }}
    //     >
    //       Editar agendamento
    //     </DialogTitle>
    //     <DialogContent>
    //       <DialogContentText>
    //         {getEventDate()} - {getEventTime()}
    //         <br />
    //         {currentEvent?.title}
    //         {currentEvent?.schedulingInformation && (
    //           <p>{`Inf. do agendamento: ${currentEvent?.schedulingInformation}`}</p>
    //         )}
    //       </DialogContentText>
    //       <Autocomplete
    //         id="edit-responsable"
    //         options={attendants}
    //         disableClearable
    //         disabled={disabled}
    //         getOptionSelected={(option, value) => option.id === value.id}
    //         getOptionLabel={option => option.name}
    //         value={currentAttendant || null}
    //         renderInput={params => (
    //           <TextField {...params} label="Atendente" variant="outlined" />
    //         )}
    //         onChange={(event, newValue) => {
    //           changeAttendant(currentEvent, newValue)
    //         }}
    //       />
    //     </DialogContent>
    //     <DialogActions>
    //       {currentEvent?.service_id_laundry_client && renderOsButton()}
    //       <Tooltip title="Restaurar" aria-label="Restaurar" placement="left">
    //         <IconButton
    //           color="inherit"
    //           onClick={() =>
    //             handleCancel(currentEvent.id, currentEvent.copy || null)
    //           }
    //           aria-label="Close"
    //           disabled={disabled}
    //         >
    //           <RestoreIcon />
    //         </IconButton>
    //       </Tooltip>
    //       <Button onClick={handleClose} color="primary">
    //         Fechar
    //       </Button>
    //     </DialogActions>
    //   </Dialog>
    // </div>
  )
}

export default React.memo(ModalDay, (prevProps, nextProps) => {
  return (
    prevProps.currentEvent === nextProps.currentEvent &&
    prevProps.open === nextProps.open
  )
})
