import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import colors from '../../../assets/colors'
import Icon from '@mdi/react'
import { mdiCurrencyUsd } from '@mdi/js'
import CountUp from 'react-countup'

const useStyles = makeStyles({
  root: {
    border: `1px solid ${colors.graylight}`,
    padding: '8px 16px',
    borderRadius: '10px',
  },
  paper: {
    color: colors.primary,
    fontSize: '12px',
  },
  number: {
    fontWeight: '700',
    fontSize: '20px',
  },
  value: {
    fontWeight: '700',
    fontSize: '12px',
  },
})

const CardKnow = ({ name, qtd, orcament }) => {
  const classes = useStyles()

  const contUp = money => {
    return (
      <CountUp
        end={money}
        duration={0.9}
        decimals={2}
        decimal=","
        prefix="R$"
        separator="."
      />
    )
  }

  return (
    <Grid item sm={4} xs={12}>
      <Grid item container xs={12} className={classes.root}>
        <Grid
          item
          container
          sm={6}
          xs={12}
          className={classes.paper}
          alignItems="center"
        >
          <Grid item xs={12}>
            {name}
          </Grid>
          <Grid item xs={12} className={classes.number}>
            {qtd}
          </Grid>
        </Grid>

        <Grid item sm={2} xs={4} className={classes.paper}>
          <Icon path={mdiCurrencyUsd} color={colors.graylight} size={2} />
        </Grid>

        <Grid
          item
          sm={4}
          xs={8}
          container
          className={classes.paper}
          alignItems="center"
        >
          <Grid item xs={12}>
            Valor orçamento
          </Grid>
          <Grid item xs={12} className={classes.value}>
            {contUp(orcament)}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default CardKnow
