import React from 'react'
import {
  Accordion,
  AccordionSummary,
  TablePagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box,
  makeStyles,
  Grid,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import classNames from 'classnames'

import { exportFormatedMoney } from '../../../util/utils'

import CardValue from './Card'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  tableStickHeader: {
    maxHeight: 'calc(100vh - 300px)',
  },
}))

function List(props) {
  const acordionClasses = useStyles()
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)

  const [pageExpenses, setPageExpenses] = React.useState(0)
  const [rowsPerPageExpenses, setRowsPerPageExpenses] = React.useState(10)

  const {
    classes,
    expenses,
    receipts,
    expense_subtotal,
    receipt_subtotal,
    total,
  } = props

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const handleChangePageExpenses = (event, newPage) => {
    setPageExpenses(newPage)
  }

  const handleChangeRowsPerPageExpenses = event => {
    setRowsPerPageExpenses(+event.target.value)
    setPageExpenses(0)
  }

  return (
    <>
      <Grid container spacing={1} style={{ marginBottom: '15px' }}>
        <Grid item xs={12} md={4} lg={4}>
          <CardValue
            name="Despesas"
            value={expense_subtotal || 0}
            colorClass="negative"
          />
        </Grid>
        <Grid item xs={12} md={4} lg={4}>
          <CardValue
            name="Receitas"
            value={receipt_subtotal || 0}
            colorClass="positive"
          />
        </Grid>
        <Grid item xs={12} md={4} lg={4}>
          <CardValue
            name="Total"
            value={total || 0}
            colorClass={total > 0 ? 'positive' : 'negative'}
          />
        </Grid>
      </Grid>
      <div className={acordionClasses.root}>
        {expenses.length > 0 && (
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="expenses-content"
              id="expenses-header"
            >
              <Typography className={acordionClasses.heading}>
                Despesas
              </Typography>
            </AccordionSummary>
            <div>
              <TableContainer
                className={classNames({
                  [classes.tableContainer]: true,
                  [acordionClasses.tableStickHeader]: true,
                })}
              >
                <Table
                  stickyHeader
                  size="medium"
                  className={classes.tableStyle}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell>Data</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Data da Baixa</TableCell>
                      <TableCell>Data do Pagamento</TableCell>
                      <TableCell>Tipo de Conta</TableCell>
                      <TableCell>Descrição</TableCell>
                      <TableCell>Forma de Pagamento</TableCell>
                      <TableCell>Cont. Financeiro</TableCell>
                      <TableCell>Banco</TableCell>
                      <TableCell>Valor</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {expenses
                      .slice(
                        pageExpenses * rowsPerPageExpenses,
                        pageExpenses * rowsPerPageExpenses + rowsPerPageExpenses
                      )
                      .map(expense => (
                        <TableRow key={expense.id}>
                          <TableCell>{expense.id}</TableCell>
                          <TableCell>{expense.date}</TableCell>
                          <TableCell>{expense.status_name}</TableCell>
                          <TableCell>
                            {expense.payment_date ? expense.payment_date : '-'}
                          </TableCell>
                          <TableCell>
                            {expense.actual_payment_date
                              ? expense.actual_payment_date
                              : '-'}
                          </TableCell>
                          <TableCell>{expense.title}</TableCell>
                          <TableCell>
                            {expense.description ? expense.description : '-'}
                          </TableCell>
                          <TableCell>
                            {expense.payment_name ? expense.payment_name : '-'}
                          </TableCell>
                          <TableCell>
                            {expense.supplier_name
                              ? expense.supplier_name
                              : expense.client_name
                              ? expense.client_name
                              : expense.laundry_name
                              ? expense.laundry_name
                              : '-'}
                          </TableCell>
                          <TableCell>
                            {expense.bank_name ? expense.bank_name : '-'}
                          </TableCell>
                          <TableCell>
                            <Box fontWeight="fontWeightBold" color="error.main">
                              {exportFormatedMoney(expense.value)}
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 50, 100]}
                  component="div"
                  count={expenses.length}
                  rowsPerPage={rowsPerPageExpenses}
                  page={pageExpenses}
                  labelRowsPerPage="Linhas por Página"
                  backIconButtonProps={{
                    'aria-label': 'previous page',
                  }}
                  labelDisplayedRows={({ from, to, count }) =>
                    `Mostrando linhas ${from}-${to} de ${count}`
                  }
                  nextIconButtonProps={{
                    'aria-label': 'next page',
                  }}
                  onChangePage={handleChangePageExpenses}
                  onChangeRowsPerPage={handleChangeRowsPerPageExpenses}
                />
              </TableContainer>
            </div>
          </Accordion>
        )}

        {receipts.length > 0 && (
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="receipts-content"
              id="receipts-header"
            >
              <Typography className={acordionClasses.heading}>
                Receitas
              </Typography>
            </AccordionSummary>
            <TableContainer
              className={classNames({
                [classes.tableContainer]: true,
                [acordionClasses.tableStickHeader]: true,
              })}
            >
              <Table stickyHeader size="medium" className={classes.tableStyle}>
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Data</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Data da Baixa</TableCell>
                    <TableCell>Data do Pagamento</TableCell>
                    <TableCell>Tipo de Conta</TableCell>
                    <TableCell>Descrição</TableCell>
                    <TableCell>Forma de Pagamento</TableCell>
                    <TableCell>Cont. Financeiro</TableCell>
                    <TableCell>Empresa</TableCell>
                    <TableCell>Banco</TableCell>
                    <TableCell>Valor</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {receipts
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map(receipt => (
                      <TableRow key={receipt.id}>
                        <TableCell>{receipt.id}</TableCell>
                        <TableCell>{receipt.date}</TableCell>
                        <TableCell>{receipt.status_name}</TableCell>
                        <TableCell>
                          {receipt.payment_date ? receipt.payment_date : '-'}
                        </TableCell>
                        <TableCell>
                          {receipt.actual_payment_date
                            ? receipt.actual_payment_date
                            : '-'}
                        </TableCell>
                        <TableCell>{receipt.title}</TableCell>
                        <TableCell>
                          {receipt.description ? receipt.description : '-'}
                        </TableCell>
                        <TableCell>
                          {receipt.payment_name ? receipt.payment_name : '-'}
                        </TableCell>
                        <TableCell>
                          {receipt.supplier_name
                            ? receipt.supplier_name
                            : receipt.client_name
                            ? receipt.client_name
                            : receipt.laundry_name
                            ? receipt.laundry_name
                            : '-'}
                        </TableCell>
                        <TableCell>
                          {receipt.company_name ? receipt.company_name : '-'}
                        </TableCell>
                        <TableCell>
                          {receipt.bank_name ? receipt.bank_name : '-'}
                        </TableCell>
                        <TableCell>
                          <Box fontWeight="fontWeightBold" color="success.main">
                            {exportFormatedMoney(receipt.value)}
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              component="div"
              count={receipts.length}
              rowsPerPage={rowsPerPage}
              page={page}
              labelRowsPerPage="Linhas por Página"
              backIconButtonProps={{
                'aria-label': 'previous page',
              }}
              labelDisplayedRows={({ from, to, count }) =>
                `Mostrando linhas ${from}-${to} de ${count}`
              }
              nextIconButtonProps={{
                'aria-label': 'next page',
              }}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Accordion>
        )}
      </div>
    </>
  )
}

export default List
