/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable react/self-closing-comp */
import {
  Backdrop,
  Box,
  Button,
  Fade,
  Grid,
  IconButton,
  Modal,
  makeStyles,
} from '@material-ui/core'
import React from 'react'
import filterStylesComponents from '../../../assets/filterComponentsStyles'
import Icon from '@mdi/react'
import {
  mdiAlert,
  mdiAlertCircleCheckOutline,
  mdiCheckCircle,
  mdiClose,
} from '@mdi/js'
import colors from '../../../assets/colors'

const useStyles = makeStyles({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    width: '470px',
    height: '220px',
    backgroundColor: '#FFF',
    border: '2px solid #1DBAEC',
    boxShadow: ' 10px 10px 20px 0px #00000033',
    padding: '16px',
    borderRadius: '16px',
    color: colors.primary,
    fontSize: '16px',
  },
  header: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    fontSize: '14px',
    lineHeight: '14px',
    fontWeight: '700',
  },
  content: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    gap: '12px',
  },
  closeButton: {
    borderRadius: '16px',
    color: colors.primary,
    fontSize: '16px',
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  title: {
    fontSize: '20px',
    lineHeight: '20px',
    fontWeight: '700',
    textAlign: 'center',
  },
  message: {
    fontSize: '16px',
    lineHeight: '20px',
    textAlign: 'center',
  },
  alignStart: {
    textAlign: 'start',
  },
  baseButton: {
    width: '79px',
    height: '30px',
    padding: '4px',
    borderRadius: '15px',
    backgroundColor: colors.graylight,
    color: colors.primary,
    transition: 'all .4s ease-in-out',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: colors.graylight,
      opacity: '.7',
    },
  },
  confirmButton: {
    backgroundColor: colors.primary,
    color: '#FFF',
    transition: 'all .4s ease-in-out',
    '&:hover': {
      backgroundColor: colors.primary,
      opacity: '.7',
    },
  },
})

const BasePopUp = ({
  open = false,
  handleOpen = () => {},
  type = '',
  title = '',
  message = '',
  onConfirm = null,
}) => {
  const classes = useStyles()
  const filterStyles = filterStylesComponents()

  const getPath = () => {
    if (type == 'error') return mdiAlertCircleCheckOutline
    if (type == 'info') return mdiAlert
    if (type == 'sucess') return mdiCheckCircle
  }

  const getColor = () => {
    if (type == 'error') return colors.error
    if (type == 'info') return colors.info
    if (type == 'sucess') return colors.sucess
  }

  return (
    <Modal
      open={open}
      onClose={handleOpen}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      className={classes.modal}
    >
      <Fade in={open}>
        <Box className={classes.paper}>
          <Grid
            container
            spacing={1}
            justifyContent="space-between"
            style={{ height: '100%' }}
          >
            <Grid item xs={12} className={classes.header}>
              <IconButton className={classes.closeButton} onClick={handleOpen}>
                Fechar
                <Icon path={mdiClose} size={1} />
              </IconButton>
            </Grid>
            <Grid item xs={12} className={classes.content}>
              {type && <Icon path={getPath()} size={5} color={getColor()} />}

              <Grid container spacing={1} style={{ flex: 1 }}>
                <Grid
                  item
                  xs={12}
                  className={[classes.title, type && classes.alignStart]}
                >
                  {title}
                </Grid>
                <Grid
                  item
                  xs={12}
                  className={[classes.message, type && classes.alignStart]}
                >
                  {message}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} container alignItems="flex-end">
              <Grid
                container
                alignItems="flex-end"
                justifyContent="center"
                spacing={3}
              >
                {onConfirm ? (
                  <>
                    <Grid item>
                      <Button
                        className={classes.baseButton}
                        onClick={handleOpen}
                      >
                        Não
                      </Button>
                    </Grid>

                    <Grid item>
                      <Button
                        className={[classes.baseButton, classes.confirmButton]}
                        onClick={onConfirm}
                      >
                        Sim
                      </Button>
                    </Grid>
                  </>
                ) : (
                  <>
                    <Button
                      className={[classes.baseButton, classes.confirmButton]}
                      onClick={handleOpen}
                    >
                      Ok
                    </Button>
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Fade>
    </Modal>
  )
}

export default BasePopUp
