import { Grid, IconButton, Tooltip } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link, Route } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import NoteAddIcon from '@material-ui/icons/NoteAdd'
import { FaFileCsv } from 'react-icons/fa'

import styles from '../../../resources/theme/global'
import { renderRoute } from '../../../util/utils'
import {
  downloadImportTemplate,
  fetchServiceType,
  makeRelatory,
} from '../ServiceTypeActions'
import { getServiceTypeListData } from '../ServiceTypeReducer'

import Edit from './Edit'
import Filter from './Filter'
import List from './List'
import New from './New'
import ImportServiceTypes from './ImportServiceTypes'
import { mdiMessageQuestion } from '@mdi/js'
import Icon from '@mdi/react'
import colors from '../../../assets/colors'

class ServiceCategory extends Component {
  componentDidMount() {
    const { fetchServiceType } = this.props
    fetchServiceType()
  }

  render() {
    const {
      classes,
      permissions,
      filters,
      makeRelatory,
      downloadImportTemplate,
    } = this.props

    return (
      <>
        <Grid
          container
          className={classes.pageHead}
          spacing={2}
          alignItems="center"
        >
          <Grid item xs={12}>
            <Typography
              variant="h4"
              style={{ fontSize: '20px' }}
              color={colors.primary}
            >
              Tipos de Serviço
              <IconButton className={classes.questionBox}>
                {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                <a href="" target="_blank" rel="noreferrer">
                  <Icon
                    path={mdiMessageQuestion}
                    size={1}
                    color={colors.secondary}
                    style={{
                      position: 'absolute',
                      top: '-10px',
                      right: '-10px',
                    }}
                  />
                </a>
              </IconButton>
            </Typography>
          </Grid>

          <Grid item xs={12} container alignItems="center">
            <Filter classes={classes} permissions={permissions} />
          </Grid>
        </Grid>

        {renderRoute(['create-service-types'], permissions) && (
          <>
            <Route path="/service-type/new" component={New} />
            <Route path="/service-type/import" component={ImportServiceTypes} />
          </>
        )}
        {renderRoute(['update-service-types'], permissions) && (
          <Route path="/service-type/:id/edit" component={Edit} />
        )}

        <List {...this.props} />
      </>
    )
  }
}

const mapStateToProps = state => ({
  ...getServiceTypeListData(state),
  filters: state.serviceType.filters,
  permissions: state.auth.permissions || [],
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchServiceType,
      makeRelatory,
      downloadImportTemplate,
    },
    dispatch
  )
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ServiceCategory))
