/* eslint-disable react/prop-types */
import { InputAdornment, MenuItem } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Popover from '@material-ui/core/Popover'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import FilterListIcon from '@material-ui/icons/FilterList'
import SearchIcon from '@material-ui/icons/Search'
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state'
import React, { useEffect, useState } from 'react'
import { ValidatorForm } from 'react-material-ui-form-validator'
import { useDispatch, useSelector } from 'react-redux'

import BadgeGlobal from '../../components/Badge'
import { fetchPaymentMethod, makeReport } from '../PaymentTypeActions'
import BaseFilter from '../../../components/hooks/BaseFilter'
import Icon from '@mdi/react'
import {
  mdiAccountSearch,
  mdiFileExcel,
  mdiMagnifyExpand,
  mdiMicrosoftExcel,
  mdiNoteEditOutline,
} from '@mdi/js'
import { renderRoute } from '../../../util/utils'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import filterStylesComponents from '../../../assets/filterComponentsStyles'

export default function Filter(props) {
  const items = useSelector(state => state.paymentMethod.items)

  const dispatch = useDispatch()
  const { classes, permissions } = props
  const [form, setForm] = useState({
    name: '',
    status: '',
  })

  const styles = filterStylesComponents()

  const onSubmit = e => {
    e.preventDefault()
    dispatch(fetchPaymentMethod(form))
  }

  const handleReportExport = () => {
    dispatch(makeReport(form))
  }

  useEffect(() => {
    dispatch(fetchPaymentMethod(form))
  }, [form.status])

  useEffect(() => {
    if (!form?.name) {
      dispatch(fetchPaymentMethod(form))
    }
  }, [form.name])

  return (
    <BaseFilter>
      <Grid item container xs={6}>
        <Grid item container xs={12} spacing={1} alignItems="center">
          <Grid item sm={5} xs={12}>
            <TextField
              onChange={e => setForm({ ...form, name: e.target.value })}
              label="Filtre por nome"
              className={classes.inputFilter}
              fullWidth
              value={form.name}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={onSubmit}>
                      <Icon path={mdiMagnifyExpand} size={1} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item sm={5} xs={12}>
            <TextField
              onChange={e => setForm({ ...form, status: e.target.value })}
              select
              label="Filtre por status"
              className={classes.inputFilter}
              fullWidth
              value={form.status}
            >
              <MenuItem key="" value="">
                Selecione...
              </MenuItem>
              <MenuItem key={0} value={0}>
                Inativo
              </MenuItem>
              <MenuItem key={1} value={1}>
                Ativo
              </MenuItem>
            </TextField>
          </Grid>
        </Grid>
      </Grid>

      <Grid item container xs={6} justifyContent="flex-end" alignItems="center">
        <Grid item xs={3} container justifyContent="flex-end">
          <IconButton className={styles.btnAdd} onClick={handleReportExport}>
            <Icon path={mdiMicrosoftExcel} size={1} />
            Donwload
          </IconButton>
        </Grid>

        <Grid item xs={3} container justifyContent="flex-end">
          {renderRoute(['create-payment-methods'], permissions) && (
            <IconButton
              color="primary"
              variant="contained"
              component={Link}
              to="/payment-method/new"
              className={styles.btnAdd}
            >
              <Icon path={mdiNoteEditOutline} size={1} />
              Cadastrar
            </IconButton>
          )}
        </Grid>
      </Grid>
    </BaseFilter>
  )
}
