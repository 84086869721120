import React, { useState } from 'react'
import {
  Button,
  Grid,
  IconButton,
  MenuItem,
  TextField,
} from '@material-ui/core'
import { Link } from 'react-router-dom/cjs/react-router-dom'
import Icon from '@mdi/react'
import { mdiFilterMultiple, mdiMagnify, mdiNoteEditOutline } from '@mdi/js'
import filterStylesComponents from '../../../../assets/filterComponentsStyles'
import BaseFilter from '../../../../components/hooks/BaseFilter'
import { renderRoute, renderRouteRole } from '../../../../util/utils'
import colors from '../../../../assets/colors'
import { useSelector } from 'react-redux'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { format, parse } from 'date-fns'

const Filter = ({ form = {}, setForm = () => {} }) => {
  const triggers = useSelector(state => state.crmPanel.triggers)

  const styles = filterStylesComponents()

  const [filter, setFilter] = useState({
    limit_date: new Date(),
  })

  const handleChangeDate = date => {
    setFilter({
      ...form,
      limit_date: date,
    })
  }

  const hanldeSubmit = e => {
    e.preventDefault()

    const formData = e.currentTarget
    const data = new FormData(formData)

    data.append('date_end', format(filter.limit_date, 'yyyy-MM-dd'))

    const entries = data.entries()
    const values = Object.fromEntries(entries)

    setForm({
      ...form,
      ...values,
    })
  }

  return (
    <BaseFilter>
      <Grid container spacing={3}>
        <Grid item xs={9}>
          <form
            style={{ width: '100%', height: '100%' }}
            onSubmit={hanldeSubmit}
          >
            <Grid container spacing={2}>
              <Grid item sm={4} md={2}>
                <TextField
                  type="text"
                  name="client"
                  variant="standard"
                  fullWidth
                  label="Filtre por Cliente:"
                />
              </Grid>
              <Grid item sm={4} md={2}>
                <TextField
                  type="number"
                  name="os"
                  variant="standard"
                  fullWidth
                  label="Filtre por Os:"
                />
              </Grid>
              <Grid item sm={4} md={3}>
                <TextField
                  select
                  name="trigger_id"
                  variant="standard"
                  fullWidth
                  label="Filtre por tipo"
                >
                  <MenuItem value="">Selecione...</MenuItem>

                  {triggers?.map((trigger, index) => (
                    <MenuItem value={trigger?.id} key={index}>
                      {trigger?.reason}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item sm={4} md={3}>
                <KeyboardDatePicker
                  clearable
                  label="Data Limite"
                  fullWidth
                  value={filter.limit_date}
                  onChange={handleChangeDate}
                  name="limit_date"
                  views={['date']}
                  clearLabel="Limpar"
                  invalidDateMessage="Data inválida"
                  autoOk
                  format="dd/MM/yyyy"
                />
              </Grid>

              <Grid item xs container alignItems="flex-end">
                <Button className={styles.btnMoreFilters} type="submit">
                  <Icon
                    path={mdiFilterMultiple}
                    size={1}
                    color={colors.secondary}
                  />
                  {` Filtrar`}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </BaseFilter>
  )
}

export default Filter
