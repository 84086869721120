/* eslint-disable react/prop-types */
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Popover from '@material-ui/core/Popover'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import FilterListIcon from '@material-ui/icons/FilterList'
import SearchIcon from '@material-ui/icons/Search'
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state'
import React, { useEffect, useState } from 'react'
import { ValidatorForm } from 'react-material-ui-form-validator'
import { useDispatch, useSelector } from 'react-redux'

import BadgeGlobal from '../../components/Badge'
import { fetchSupplier } from '../SupplierActions'
import BaseFilter from '../../../components/hooks/BaseFilter'
import filterStylesComponents from '../../../assets/filterComponentsStyles'
import { renderRoute } from '../../../util/utils'
import { Link } from 'react-router-dom/cjs/react-router-dom'
import Icon from '@mdi/react'
import { mdiMagnifyExpand, mdiNoteEditOutline } from '@mdi/js'
import { InputAdornment } from '@material-ui/core'
import colors from '../../../assets/colors'

export default function Filter(props) {
  const { permissions } = props
  const items = useSelector(state => state.supplier.items)

  const dispatch = useDispatch()
  const [form, setForm] = useState({
    name: '',
  })
  const classes = filterStylesComponents()

  const onSubmit = e => {
    e.preventDefault()
    dispatch(fetchSupplier(form))
  }

  useEffect(() => {
    if (!form?.name) {
      dispatch(fetchSupplier(form))
    }
  }, [form?.name])

  return (
    <BaseFilter>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Grid item xs={6}>
            <TextField
              onChange={e => setForm({ ...form, name: e.target.value })}
              label="Nome"
              className={classes.inputFilter}
              fullWidth
              value={form.name}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={onSubmit}>
                      <Icon
                        path={mdiMagnifyExpand}
                        size={1}
                        color={colors.graylight}
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        <Grid item xs={6} className={classes.areaButtons}>
          <Grid item>
            {renderRoute(['create-supplier'], permissions) && (
              <IconButton
                color="primary"
                variant="contained"
                component={Link}
                to="/supplier/new"
                className={classes.btnAdd}
              >
                <Icon path={mdiNoteEditOutline} size={1} />
                Cadastrar
              </IconButton>
            )}
          </Grid>
        </Grid>
      </Grid>
    </BaseFilter>
    // <Tooltip title="Filter list">
    //   <PopupState variant="popover" popupId="demo-popup-popover">
    //     {popupState => (
    //       <div>
    //         <IconButton aria-label="filter list" {...bindTrigger(popupState)}>
    //           <BadgeGlobal form={form} items={items}>
    //             <FilterListIcon />
    //           </BadgeGlobal>
    //         </IconButton>
    //         <Popover
    //           {...bindPopover(popupState)}
    //           anchorOrigin={{
    //             vertical: 'bottom',
    //             horizontal: 'center',
    //           }}
    //           transformOrigin={{
    //             vertical: 'top',
    //             horizontal: 'center',
    //           }}
    //         >
    //           <Box p={2} className={classes.popover}>
    //             <div className={classes.root}>
    //               <ValidatorForm className={classes.form} onSubmit={onSubmit}>
    //                 <Grid
    //                   container
    //                   className={classes.gridContainer}
    //                   spacing={1}
    //                   justify="center"
    //                   alignItems="center"
    //                 >

    //                   <Grid item xs>
    //                     <Button
    //                       type="submit"
    //                       color="primary"
    //                       fullWidth
    //                       variant="contained"
    //                       className={classes.submit}
    //                       onClick={popupState.close}
    //                     >
    //                       <SearchIcon />
    //                       {` Filtrar`}
    //                     </Button>
    //                   </Grid>
    //                 </Grid>
    //               </ValidatorForm>
    //             </div>
    //           </Box>
    //         </Popover>
    //       </div>
    //     )}
    //   </PopupState>
    // </Tooltip>
  )
}
