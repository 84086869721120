import React from 'react'
import { Forms } from '..'
import { Grid, MenuItem, TextField } from '@material-ui/core'
import ReactInputMask from 'react-input-mask'

const FormAddress = ({ form, validatedForm, handleChange = () => {} }) => {
  return (
    <Forms.root>
      <Grid item xs={12} sm={6} md={4}>
        <ReactInputMask
          mask="99.999-999"
          name="cep"
          maskChar=""
          value={form?.cep}
          required
          onChange={handleChange}
        >
          {inputProps => (
            <TextField
              {...inputProps}
              label="CEP"
              fullWidth
              error={validatedForm.cep}
              helperText={validatedForm.cep}
            />
          )}
        </ReactInputMask>
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <TextField
          label="Logradouro"
          name="logradouro"
          type="text"
          fullWidth
          required
          value={form.logradouro}
          onChange={handleChange}
          error={validatedForm.logradouro}
          helperText={validatedForm.logradouro}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <TextField
          label="Bairro"
          name="bairro"
          type="text"
          fullWidth
          required
          value={form.bairro}
          onChange={handleChange}
          error={validatedForm.bairro}
          helperText={validatedForm.bairro}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <TextField
          label="Número"
          name="numero"
          type="number"
          fullWidth
          required
          value={form.numero}
          onChange={handleChange}
          error={validatedForm.numero}
          helperText={validatedForm.numero}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <TextField
          label="Complemento"
          name="complemento"
          type="text"
          fullWidth
          value={form.complemento}
          onChange={handleChange}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <TextField
          label="Municipio"
          name="municipio"
          type="text"
          fullWidth
          value={form.municipio}
          onChange={handleChange}
          disabled
          required
        />
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <TextField
          label="UF"
          name="uf"
          type="text"
          fullWidth
          value={form.uf}
          onChange={handleChange}
          disabled
          required
        />
      </Grid>
    </Forms.root>
  )
}

export default FormAddress
