/* eslint-disable eqeqeq */
/* eslint-disable import/order */
/* eslint-disable camelcase */
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import {
  StyledTableCellBody,
  StyledTableCellHeader,
} from '../../../components/hooks/BaseTableComponents'
import { useSelector } from 'react-redux'
import { addDays, format } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { exportFormatedMoney } from '../../../util/utils'
import Pagination from '../../components/Pagination'

const List = ({ form, setForm, type }) => {
  const movementItems = useSelector(state => state.stockRelatory.movementItems)
  const meta = useSelector(state => state.stockRelatory.meta)

  const [paginate, setPaginate] = useState({
    page: 0,
    rows: 10,
  })

  const renderDate = date => {
    const currentDate = new Date(date)

    if (currentDate) {
      return format(addDays(currentDate, 1), 'dd/MM/yyyy', { locale: ptBR })
    }

    return date
  }

  const handleChangeRowsPerPage = e => {
    const { value } = e.target

    setPaginate({
      ...paginate,
      rows: value,
    })
  }

  const handleChangePage = (event, newPage) => {
    if (paginate.page > newPage) {
      return setPaginate({
        ...paginate,
        page: newPage - 1,
      })
    }

    setPaginate({
      ...paginate,
      page: newPage + 1,
    })
  }

  useEffect(() => {
    setForm({
      ...form,
      ...paginate,
    })
  }, [paginate])

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCellHeader>Data do lançamento</StyledTableCellHeader>
              <StyledTableCellHeader>
                {type == 1 ? 'Data da Entrada' : 'Data da Retirada'}
              </StyledTableCellHeader>
              <StyledTableCellHeader>Técnico</StyledTableCellHeader>
              <StyledTableCellHeader>Produto</StyledTableCellHeader>
              <StyledTableCellHeader>Medida</StyledTableCellHeader>
              <StyledTableCellHeader align="center">
                {type == 1 ? 'Quantidade Entrada' : 'Quantidade Retirada'}
              </StyledTableCellHeader>
              <StyledTableCellHeader>Valor Item</StyledTableCellHeader>
              <StyledTableCellHeader align="center">
                Valor Total
              </StyledTableCellHeader>
            </TableRow>
          </TableHead>
          <TableBody>
            {movementItems.map(item => (
              <TableRow key={item.id}>
                <StyledTableCellBody>{item?.created_at}</StyledTableCellBody>
                <StyledTableCellBody>
                  {renderDate(item?.date)}
                </StyledTableCellBody>
                <StyledTableCellBody>{item?.user?.name}</StyledTableCellBody>
                <StyledTableCellBody>{item?.product?.name}</StyledTableCellBody>
                <StyledTableCellBody>
                  {item?.product?.unit_measure?.value}
                </StyledTableCellBody>
                <StyledTableCellBody align="center">
                  {item?.quantity}
                </StyledTableCellBody>
                <StyledTableCellBody>
                  {exportFormatedMoney(item?.product?.price)}
                </StyledTableCellBody>
                <StyledTableCellBody align="center">
                  {exportFormatedMoney(item?.product?.price * item?.quantity)}
                </StyledTableCellBody>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        rowsPerPage={paginate?.rows}
        rows={meta?.total}
        page={paginate?.page}
        handleChangePage={handleChangePage}
      />
    </>
  )
}

export default List
