import React from 'react'
import filterStylesComponents from '../../../../../assets/filterComponentsStyles'
import { useDispatch, useSelector } from 'react-redux'
import BaseFilterModal from '../../../../../components/hooks/BaseFilterModal'
import {
  Button,
  Grid,
  InputBase,
  MenuItem,
  TextField,
  TextareaAutosize,
} from '@material-ui/core'
import { closeLead, createDescription, transferLead } from '../../PanelActions'
import colors from '../../../../../assets/colors'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'

const ModalCloseLead = ({
  open = false,
  handleOpen = () => {},
  leadId = '',
}) => {
  const filterStyles = filterStylesComponents()
  const dispatch = useDispatch()
  const history = useHistory()

  const handleSubmit = async e => {
    e.preventDefault()

    const formData = e.currentTarget
    const data = new FormData(formData)
    const entries = data.entries()
    const values = Object.fromEntries(entries)

    try {
      const resp = await dispatch(closeLead(leadId, values))

      if (resp) {
        history.push('/crm/panel')
      }
    } catch (e) {
      //
    }
  }

  return (
    <BaseFilterModal
      open={open}
      title="BLOQUEAR CLIENTE"
      hiddenHelpButton
      handleOpen={handleOpen}
      centerTitle
      paperColor={colors.error}
    >
      <Grid item xs={12} style={{ width: '220px' }}>
        <form style={{ width: '100%', height: '100%' }} onSubmit={handleSubmit}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <TextField
                name="close_reason"
                variant="standard"
                fullWidth
                label="Informe o motivo do bloqueio"
                type="text"
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                spacing={2}
                alignItems="center"
                justifyContent="center"
              >
                <Button
                  type="submit"
                  className={filterStyles.btnAdd}
                  style={{
                    textTransform: 'none',
                    backgroundColor: colors.error,
                  }}
                >
                  Bloquear
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </BaseFilterModal>
  )
}

export default ModalCloseLead
