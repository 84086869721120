/* eslint-disable import/order */
/* eslint-disable import/extensions */
import {
  Button,
  DialogActions,
  DialogContent,
  Grid,
  Slide,
} from '@material-ui/core'
import AppBar from '@material-ui/core/AppBar'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import withStyles from '@material-ui/core/styles/withStyles'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import React from 'react'
import { useDispatch } from 'react-redux'

import styles from '../../../resources/theme/global'
import GlobalDropzone from '../../components/Dropzone'
import { importServiceTypes } from '../ServiceTypeActions'

const Transition = React.forwardRef((props, ref) => {
  return <Slide direction="left" {...props} ref={ref} />
})

function ImportServiceTypes(props) {
  const { classes } = props
  const dispatch = useDispatch()
  const [file, setFile] = React.useState([])

  const onSubmit = () => {
    const formData = new FormData()
    formData.append('file', file)
    dispatch(importServiceTypes(formData)).then(data => {
      if (data && data.code === 200) {
        props.history.push('/service-type')
      }
    })
  }

  const onClose = () => {
    props.history.push('/service-type')
  }

  const handleChangeFiles = file => {
    setFile(file[0])
  }

  return (
    <Dialog
      open
      fullWidth
      maxWidth="sm"
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" color="inherit" className={classes.flex}>
            Importar tipos de servico
          </Typography>
          <IconButton color="inherit" onClick={onClose} aria-label="Close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <DialogContent>
        <Grid container justify="center" alignItems="center">
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <GlobalDropzone
              handleChangeFiles={handleChangeFiles}
              classes={classes}
              text="Clique aqui ou arraste documentos para selecionar"
              mimeTypes={['text/csv']}
              limit={1}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <Button color="primary" type="button" onClick={onSubmit}>
          Enviar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default withStyles(styles)(ImportServiceTypes)
