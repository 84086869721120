/* eslint-disable object-shorthand */
/* eslint-disable camelcase */
/* eslint-disable import/order */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import {
  Badge,
  FormControl,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  NativeSelect,
  Select,
  Typography,
  makeStyles,
} from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Popover from '@material-ui/core/Popover'
import TextField from '@material-ui/core/TextField'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'

import Tooltip from '@material-ui/core/Tooltip'
import FilterListIcon from '@material-ui/icons/FilterList'
import SearchIcon from '@material-ui/icons/Search'
import { KeyboardDatePicker } from '@material-ui/pickers'
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state'
import React, { useCallback, useEffect, useState } from 'react'
import InputMask from 'react-input-mask'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'
import { useDispatch, useSelector } from 'react-redux'

import { renderRoute, renderRouteRole } from '../../../util/utils'
import BadgeGlobal from '../../components/Badge'
import SelectField from '../../components/SelectField'
import { fetchAttendanceInit } from '../AttendanceActions'
import { Link, Route } from 'react-router-dom'
import {
  mdiAccountSearch,
  mdiFileEdit,
  mdiFileEditOutline,
  mdiFilterMultiple,
  mdiNoteEdit,
  mdiNoteEditOutline,
} from '@mdi/js'
import Icon from '@mdi/react'
import colors from '../../../assets/colors'
import BaseFilter from '../../../components/hooks/BaseFilter'
import filterStylesComponents from '../../../assets/filterComponentsStyles'
import { Visibility } from '@material-ui/icons'
import BaseFilterModal from '../../../components/hooks/BaseFilterModal'
import formComponentStyles from '../../../assets/formComponentsStyles'
import queryString from 'query-string'

const useStyles = makeStyles({
  container: {
    width: '370px',
  },
  form: {
    maxHeight: 'calc(330px + 50px)',
    overflowY: 'auto',
  },
})

export default function Filter(props) {
  const items = useSelector(state => state.attendance.items)
  const statuses = useSelector(state => state.attendance.status)
  const attendants = useSelector(state => state.attendance.attendants)
  const companies = useSelector(state => state.attendance.companies)
  const role = useSelector(state => state.auth.role)
  const sizePerPage = useSelector(state => state.attendance.sizePerPage)

  const dispatch = useDispatch()
  const { classes, permissions, match, location } = props

  const [form, setForm] = useState({
    id: '',
    name: '',
    phone: '',
    status: '',
    attendant: '',
    has_billing: '',
    company: '',
    status_id: '',
    date_end: null,
    date_start: null,
  })

  const modalClasses = useStyles()

  const filterClasses = filterStylesComponents()

  const formClasses = formComponentStyles()

  const [open, setOpen] = useState(false)

  const onSubmit = e => {
    e.preventDefault()
    dispatch(fetchAttendanceInit(form, sizePerPage))
  }

  const handleOpen = () => {
    setOpen(!open)
  }

  const updateField = useCallback(
    (name, value) => {
      setForm({ ...form, [name]: value })
    },
    [form]
  )

  const handleChange = e => {
    e.preventDefault()

    const { name, value } = e.target

    setForm({
      ...form,
      [name]: value,
    })
  }

  useEffect(() => {
    dispatch(fetchAttendanceInit(form, sizePerPage))
  }, [form.status])

  const handleSearchByName = e => {
    e.preventDefault()

    dispatch(fetchAttendanceInit(form, sizePerPage))
  }

  useEffect(() => {
    if (location?.state) {
      const filters = {
        ...form,
        status_id: location?.state?.status || '',
        date_end: location?.state?.date_end || '',
        date_start: location?.state?.date_start || '',
      }

      setForm({
        ...form,
        ...filters,
      })

      dispatch(fetchAttendanceInit(filters, sizePerPage))
    }
  }, [])

  return (
    <>
      <BaseFilter>
        <Grid
          item
          container
          xs={6}
          spacing={2}
          alignItems="center"
          justifyContent="flex-start"
        >
          <Grid item sm={4} xs={12}>
            <Input
              fullWidth
              style={{ marginTop: '16px' }}
              name="name"
              value={form?.name}
              onChange={handleChange}
              id="standard-adornment-person"
              placeholder="Pesquise por cliente"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="search person"
                    onClick={handleSearchByName}
                  >
                    <Icon path={mdiAccountSearch} size={1} />
                  </IconButton>
                </InputAdornment>
              }
            />
          </Grid>
          <Grid sm={4} xs={12}>
            <SelectField
              options={statuses}
              setForm={updateField}
              data={form.status || null}
              name="status"
              variant="standard"
              label="Filtre por status"
            />
          </Grid>
        </Grid>

        <Grid
          item
          container
          xs={6}
          alignItems="center"
          justifyContent="flex-end"
          spacing={2}
        >
          <Grid item>
            {renderRoute(['create-attendances'], permissions) && (
              <Button
                onClick={handleOpen}
                color={colors.textPrimary}
                className={filterClasses.btnMoreFilters}
              >
                <Icon
                  path={mdiFilterMultiple}
                  className={classes.btnIcon}
                  size={1}
                  color={colors.secondary}
                />
                Outros filtros
              </Button>
            )}
          </Grid>
          <Grid item>
            {renderRoute(['create-attendances'], permissions) && (
              <IconButton
                component={Link}
                className={filterClasses.btnAdd}
                to="/attendance/new"
              >
                <Icon
                  path={mdiNoteEditOutline}
                  size={1}
                  style={{ marginRight: '4px' }}
                />
                Cadastrar
              </IconButton>
            )}
          </Grid>
        </Grid>
      </BaseFilter>

      <BaseFilterModal open={open} handleOpen={handleOpen} title="FILTRO">
        <Grid
          container
          xs={12}
          item
          className={modalClasses.container}
          direction="row"
          spacing={4}
        >
          <Grid item xs={12}>
            <Typography
              style={{ fontSize: '16px', fontWeight: 'normal' }}
              color={colors.primary}
            >
              Utilize os campos abaixo para encontrar <br /> um item de
              atendimento
            </Typography>
          </Grid>

          <Grid item xs={12} container className={modalClasses.form}>
            <ValidatorForm className={classes.form} onSubmit={onSubmit}>
              <Grid
                container
                className={classes.gridContainer}
                spacing={1}
                justifyContent="center"
                alignItems="center"
              >
                <Grid item sm={6} xs={12} lg={6}>
                  <TextField
                    onChange={handleChange}
                    name="id"
                    label="Número da OS"
                    margin="dense"
                    className={formClasses.baseInput}
                    variant="standard"
                    fullWidth
                    value={form.id}
                  />
                </Grid>

                <Grid item sm={6} xs={12} lg={6}>
                  <TextField
                    onChange={handleChange}
                    name="name"
                    label="Nome"
                    margin="dense"
                    className={formClasses.baseInput}
                    variant="standard"
                    fullWidth
                    value={form.name}
                  />
                </Grid>

                <Grid item sm={6} xs={12} lg={6}>
                  <InputMask
                    mask={
                      form.phone?.replace(/[^\d]/g, '').length <= 10
                        ? '(99) 9999-9999?'
                        : '(99) 99999-9999'
                    }
                    formatChars={{ 9: '[0-9]', '?': '[0-9 ]' }}
                    maskChar=""
                    value={form.phone}
                    onChange={e => {
                      setForm({
                        ...form,
                        phone: e.target.value.replace(/[^\d]/g, ''),
                      })
                    }}
                  >
                    {inputProps => (
                      <TextValidator
                        {...inputProps}
                        label="Telefone"
                        fullWidth
                        variant="standard"
                        validators={[
                          'matchRegexp:^\\(?[0-9]{2,3}\\)? ?[0-9]{4,5}-?[0-9]{4}$',
                        ]}
                        errorMessages={['Telefone inválido']}
                      />
                    )}
                  </InputMask>
                </Grid>

                <Grid item sm={6} xs={12} lg={6}>
                  <SelectField
                    options={statuses}
                    setForm={updateField}
                    data={form.status || null}
                    name="status"
                    variant="standard"
                    label="Status"
                  />
                </Grid>

                <Grid item xs={12}>
                  <SelectField
                    options={attendants}
                    setForm={updateField}
                    data={form.attendant || null}
                    name="attendant"
                    variant="standard"
                    label="Atendente"
                  />
                </Grid>

                <Grid item sm={6} xs={12} lg={6}>
                  <KeyboardDatePicker
                    clearable
                    value={form.date_start}
                    label="Data Inicial"
                    fullWidth
                    clearLabel="Limpar"
                    invalidDateMessage="Data inválida"
                    autoOk
                    inputVariant="standard"
                    onChange={date =>
                      setForm({
                        ...form,
                        date_start: date,
                      })
                    }
                    format="dd/MM/yyyy"
                  />
                </Grid>

                <Grid item sm={6} xs={12} lg={6}>
                  <KeyboardDatePicker
                    clearable
                    value={form.date_end}
                    label="Data Final"
                    fullWidth
                    clearLabel="Limpar"
                    invalidDateMessage="Data inválida"
                    autoOk
                    inputVariant="standard"
                    onChange={date =>
                      setForm({
                        ...form,
                        date_end: date,
                      })
                    }
                    format="dd/MM/yyyy"
                  />
                </Grid>

                {renderRouteRole(['administrador'], role) && (
                  <Grid item sm={6} xs={12} lg={6}>
                    <SelectField
                      options={companies}
                      setForm={updateField}
                      data={form.company || null}
                      name="company"
                      variant="standard"
                      label="Empresa"
                    />
                  </Grid>
                )}

                <Grid item sm={6} xs={12} lg={6}>
                  <TextField
                    select
                    label="Possui pagamento?"
                    className={classes.inputFilter}
                    fullWidth
                    name="has_billing"
                    onChange={handleChange}
                    variant="standard"
                    value={form.has_billing}
                  >
                    <MenuItem key="" value="">
                      Selecione...
                    </MenuItem>
                    <MenuItem key={1} value={1}>
                      Com pagamento
                    </MenuItem>
                    <MenuItem key={2} value={2}>
                      Sem pagamento
                    </MenuItem>
                    <MenuItem key={3} value={3}>
                      Com pagamento parcial
                    </MenuItem>
                  </TextField>
                </Grid>

                <Grid item sm={6} xs={12} lg={6}>
                  <TextField
                    onChange={handleChange}
                    name="bar_code"
                    label="Etiqueta"
                    className={classes.inputFilter}
                    variant="standard"
                    fullWidth
                    value={form.bar_code}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  container
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Button
                    type="submit"
                    color={colors.primary}
                    variant="contained"
                    className={formClasses.btnSubmmit}
                    onClick={handleOpen}
                  >
                    <SearchIcon />
                    {` Pesquisar`}
                  </Button>
                </Grid>
              </Grid>
            </ValidatorForm>
          </Grid>
        </Grid>
      </BaseFilterModal>

      {/* <Popover
                {...bindPopover(popupState)}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <Box p={2} className={classes.popover}>
                  <div className={classes.root}>
                    
                  </div>
                </Box>
              </Popover>
            </div>
          )}
        </PopupState>
      </Tooltip> */}
    </>
  )
}
