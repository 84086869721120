/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable import/order */
import React, { useState } from 'react'
import {
  Box,
  Button,
  Grid,
  IconButton,
  MenuItem,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core'
import colors from '../../../assets/colors'
import BaseFilterModal from '../../../components/hooks/BaseFilterModal'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { useDispatch, useSelector } from 'react-redux'
import { format } from 'date-fns'
import {
  StyledTableCellBody,
  StyledTableCellHeader,
} from '../../../components/hooks/BaseTableComponents'
import Icon from '@mdi/react'
import { mdiDelete, mdiNoteEditOutline } from '@mdi/js'
import {
  findAttendantes,
  findTechnicalAbsence,
  getServicesBooked,
  technicalAbsence,
} from '../CalendarActions'
import { KeyboardDatePicker } from 'formik-material-ui-pickers'
import { KeyboardDateTimePicker } from '@material-ui/pickers'

const useStyles = makeStyles({
  container: {
    width: '550px',
  },
  form: {
    maxHeight: 'calc(330px + 50px)',
    overflowY: 'auto',
  },
  btn: {
    backgroundColor: colors.primary,
    color: '#FFF',
    width: '101px',
    height: '30px',
    borderRadius: '15px',
    fontSize: '14px',
    lineHeight: '14px',
    textAlign: 'center',
    textTransform: 'capitalize',
    transition: 'all .4s ease-in-out',
    '&:hover': {
      backgroundColor: colors.primary,
      opacity: '.7',
    },
  },
})

const TechnicalAbsencesModal = () => {
  const history = useHistory()
  const classes = useStyles()
  const dispatch = useDispatch()
  const technicals = useSelector(state => state.calendar.attendants)

  const today = () => {
    const date = new Date()
    return format(date, 'yyyy-MM-dd HH:mm')
  }

  const [form, setForm] = useState({
    technical_id: '',
    date_start: today(),
    date_end: today(),
    reason: '',
  })

  const handleOpen = () => {
    history.push('/calendar')
  }

  const handleCloseSuccess = () => {
    history.push('/calendar?reload=true')
  }

  const handleChange = e => {
    e.preventDefault()

    const { name, value } = e.target

    setForm({
      ...form,
      [name]: value,
    })
  }

  const handleChangeDate = (date, name) => {
    let formattedDate = date

    try {
      formattedDate = format(date, 'yyyy-MM-dd HH:mm')
      if (name === 'date_end') {
        formattedDate = format(date, 'yyyy-MM-dd HH:mm')
      }
    } catch (error) {
      console.log('Invalid Date')
    }

    setForm({
      ...form,
      [name]: formattedDate,
    })
  }

  const handleSubmit = async e => {
    e.preventDefault()

    const dateStart = new Date(form.date_start)
    const dateEnd = new Date(form.date_end)

    const formatedForm = {
      ...form,
      date_start: format(dateStart, 'yyyy-MM-dd HH:mm'),
      date_end: format(dateEnd, 'yyyy-MM-dd HH:mm'),
    }

    const resp = await dispatch(technicalAbsence(formatedForm))

    if (resp) {
      dispatch(findAttendantes())
      dispatch(findTechnicalAbsence())

      handleCloseSuccess()
    }
  }

  return (
    <BaseFilterModal open handleOpen={handleOpen} title="Ausência de Técnico">
      <Grid item xs={12} className={classes.container}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography
                style={{ fontSize: '16px', fontWeight: 'normal' }}
                color={colors.primary}
              >
                Utilize os campos abaixo para informar <br /> a ausência de um
                técnico.
              </Typography>
            </Grid>

            <Grid item xs={12} className={classes.form}>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <TextField
                    select
                    value={form.technical_id}
                    onChange={handleChange}
                    name="technical_id"
                    label="Selecione um Técnico"
                    fullWidth
                    required
                  >
                    <MenuItem value="">Selecione</MenuItem>
                    {technicals?.map(technical => (
                      <MenuItem value={technical?.id} key={technical?.id}>
                        {technical?.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={3}>
                  <KeyboardDateTimePicker
                    clearable
                    value={form.date_start}
                    label="Data Inicial"
                    fullWidth
                    views={['date']}
                    clearLabel="Limpar"
                    invalidDateMessage="Data inválida"
                    autoOk
                    required
                    onChange={date => handleChangeDate(date, 'date_start')}
                    format="dd/MM/yyyy HH:mm"
                  />
                </Grid>
                <Grid item xs={3}>
                  <KeyboardDateTimePicker
                    clearable
                    value={form.date_end}
                    label="Data Final"
                    fullWidth
                    views={['date']}
                    clearLabel="Limpar"
                    invalidDateMessage="Data inválida"
                    autoOk
                    required
                    onChange={date => handleChangeDate(date, 'date_end')}
                    format="dd/MM/yyyy HH:mm"
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    value={form.reason}
                    onChange={handleChange}
                    name="reason"
                    label="Motivo"
                    fullWidth
                    required
                    type="text"
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              container
              justifyContent="center"
              alignItems="center"
            >
              <Button className={classes.btn} type="submit">
                Salvar
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </BaseFilterModal>
  )
}

export default TechnicalAbsencesModal
