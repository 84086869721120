import { FETCH_STOCK_RELATORY_INIT } from './StockRelatoryActions'

const INITIAL_STATE = {
  movementItems: [],
  technicals: [],
  meta: [],
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_STOCK_RELATORY_INIT.SUCCESS:
      return {
        ...state,
        movementItems: action.payload.data.data,
        technicals: action.payload.data.technicals,
        meta: action.payload.data.meta,
      }
    default:
      return state
  }
}
