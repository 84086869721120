/* eslint-disable object-shorthand */
import axios from 'axios'

import config from '../../config'
import action, { actionFailed } from '../../util/action'
import createAsyncConst from '../../util/createAsyncConst'

export const FETCH_PRODUCT_HISTORY = createAsyncConst('FETCH_PRODUCT_HISTORY')
export const MAKE_PRODUCT_HISTORY_RELATORY = createAsyncConst(
  'MAKE_PRODUCT_HISTORY_RELATORY'
)

export function fetchProductHistory(id, filters = []) {
  return dispatch => {
    dispatch(action(FETCH_PRODUCT_HISTORY.ACTION))
    return axios
      .get(`${config.API_URL}/product-history/${id}`, {
        params: filters,
      })
      .then(resp => {
        dispatch(action(FETCH_PRODUCT_HISTORY.SUCCESS, resp))
        return resp
      })
      .catch(e => dispatch(actionFailed(FETCH_PRODUCT_HISTORY, e)))
  }
}

export function makeRelatory(data, id) {
  return dispatch => {
    return axios({
      url: `${config.API_URL}/make-product-history/${id}`,
      method: 'POST',
      responseType: 'blob',
      headers: { Accept: 'application/vnd.ms-excel' },
      params: data,
    })
      .then(resp => {
        const url = window.URL.createObjectURL(new Blob([resp.data]))
        const link = document.createElement('a')

        link.href = url
        link.setAttribute('download', 'product_history_relatory.xlsx')
        document.body.appendChild(link)
        link.click()
        return resp
      })
      .catch(e => dispatch(actionFailed(MAKE_PRODUCT_HISTORY_RELATORY, e)))
  }
}
