/* eslint-disable no-shadow */
import { Grid } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Route } from 'react-router-dom'
import NoteAddIcon from '@material-ui/icons/NoteAdd'

import styles from '../../../resources/theme/global'
import {
  fetchTeamProdutivityRelatoryInit,
  makeRelatory,
  clearFilter,
} from '../TeamProdutivityRelatoryActions'
import { getTeamProdutivityRelatoryListData } from '../TeamProdutivityRelatoryReducer'
import { renderRoute } from '../../../util/utils'

import Filter from './Filter'
import List from './List'
import Produtivity from './Produtivity'
import BaseRelatoryPage from '../../../components/hooks/BaseRelatoryPage'

class TeamProdutivityRelatory extends Component {
  componentWillUnmount() {
    const { clearFilter } = this.props
    clearFilter()
  }

  makeReport = filters => {
    makeRelatory(filters)
  }

  render() {
    const { classes, makeRelatory, filters, permissions } = this.props

    return (
      <BaseRelatoryPage title="Produtividade por Equipe">
        <Grid
          container
          className={classes.pageHead}
          spacing={1}
          alignItems="center"
        >
          <Grid item xs={12}>
            <Filter classes={classes} />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <List {...this.props} />
        </Grid>
      </BaseRelatoryPage>
      // <>
      //   <Grid
      //     container
      //     className={classes.pageHead}
      //     spacing={1}
      //     alignItems="center"
      //   >
      //     <Grid item xs>
      //       <Typography variant="h4" color="secondary">
      //         Relatório de Produtividade por Equipe
      //       </Typography>
      //     </Grid>
      //     <Grid item>
      //       <Filter classes={classes} />
      //     </Grid>
      //     <Grid item>
      //       <Button
      //         color="primary"
      //         type="button"
      //         onClick={() => makeRelatory(filters)}
      //       >
      //         <NoteAddIcon className={classes.btnIcon} /> Relatório
      //       </Button>
      //     </Grid>
      //   </Grid>

      //   <List {...this.props} />
      // </>
    )
  }
}

const mapStateToProps = state => ({
  ...getTeamProdutivityRelatoryListData(state),
  permissions: state.auth.permissions || [],
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchTeamProdutivityRelatoryInit,
      makeRelatory,
      clearFilter,
    },
    dispatch
  )
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(TeamProdutivityRelatory))
