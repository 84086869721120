import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import {
  Slide,
  TableHead,
  TableRow,
  TableBody,
  Table,
  TableCell,
  Button,
  TableContainer,
  Grid,
} from '@material-ui/core'

import { dateFormatedPT, exportFormatedMoney } from '../../../util/utils'
import {
  StyledTableCellBody,
  StyledTableCellHeader,
} from '../../../components/hooks/BaseTableComponents'
import { mdiChevronLeft, mdiChevronRight, mdiMicrosoftExcel } from '@mdi/js'
import Icon from '@mdi/react'
import colors from '../../../assets/colors'
import filterStylesComponents from '../../../assets/filterComponentsStyles'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  container: {
    width: '100%',
    margin: 0,
  },
  pageLink: {
    width: 'auto',
    '&:hover': {
      backgroundColor: 'inherit',
    },
  },
  page: {
    fontSize: '14px',
    color: colors.textGray,
    fontWeight: 'normal',
    transition: 'all .4s ease-in-out',
    '&:hover': {
      backgroundColor: 'inherit',
      color: colors.primary,
    },
  },
  path: {
    fontSize: '20px',
    color: colors.primary,
    fontWeight: '700',
    position: 'relative',
  },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function FullScreenDialog(props) {
  const { open, close, serviceId, details, filters, detailsReport } = props
  const classes = useStyles()

  const styles = filterStylesComponents()

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={close}
      TransitionComponent={Transition}
    >
      <Grid container spacing={4} className={classes.container}>
        <Grid item container xs={12}>
          <Grid item xs={6} container direction="row" alignItems="center">
            <IconButton className={classes.pageLink}>
              <Typography variant="h6" color="inherit" className={classes.page}>
                Relátorios
              </Typography>
            </IconButton>

            <Icon path={mdiChevronRight} size={1} color={colors.textPrimary} />

            <Typography variant="h6" color="inherit" className={classes.path}>
              Detalhado
            </Typography>
          </Grid>

          <Grid item xs={6} container className={styles.areaButtons}>
            <IconButton
              onClick={() => detailsReport(filters, serviceId)}
              className={styles.btnAdd}
            >
              <Icon path={mdiMicrosoftExcel} size={1} />
              Download
            </IconButton>

            <IconButton onClick={close} className={classes.pageLink}>
              <Icon path={mdiChevronLeft} size={1} color={colors.textPrimary} />

              <Typography variant="h6" color="inherit" className={classes.page}>
                Voltar
              </Typography>
            </IconButton>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <TableContainer className={classes.tableStickHeader}>
            <Table stickyHeader size="medium" className={classes.tableStyle}>
              <TableHead>
                <TableRow>
                  <StyledTableCellHeader />
                  <StyledTableCellHeader>Data</StyledTableCellHeader>
                  <StyledTableCellHeader>OS</StyledTableCellHeader>
                  <StyledTableCellHeader>Cliente</StyledTableCellHeader>
                  <StyledTableCellHeader>Tipo de Serviço</StyledTableCellHeader>
                  <StyledTableCellHeader>Valor</StyledTableCellHeader>
                  <StyledTableCellHeader>Técnico</StyledTableCellHeader>
                  <StyledTableCellHeader>Atendente</StyledTableCellHeader>
                </TableRow>
              </TableHead>

              <TableBody>
                {Array.isArray(details) &&
                  details.map(item => (
                    <React.Fragment key={item.service_items_id}>
                      <TableRow>
                        <StyledTableCellBody />
                        <StyledTableCellBody>
                          {dateFormatedPT(item.date)}
                        </StyledTableCellBody>
                        <StyledTableCellBody>
                          {item.attendance_id}
                        </StyledTableCellBody>
                        <StyledTableCellBody>
                          {item.client_name}
                        </StyledTableCellBody>
                        <StyledTableCellBody>
                          {item.service_type_name}
                        </StyledTableCellBody>
                        <StyledTableCellBody>
                          {item.final_value
                            ? exportFormatedMoney(item.final_value)
                            : '-'}
                        </StyledTableCellBody>
                        <StyledTableCellBody>
                          {item.tec_name}
                        </StyledTableCellBody>
                        <StyledTableCellBody>
                          {item.attendant_name}
                        </StyledTableCellBody>
                      </TableRow>
                    </React.Fragment>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      {/* <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={close}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Relatório detalhado
          </Typography>
          <Button
            autoFocus
            color="inherit"
            onClick={() => detailsReport(filters, serviceId)}
          >
            Relatório
          </Button>
        </Toolbar>
      </AppBar> */}
    </Dialog>
  )
}
