/* eslint-disable import/order */
import { Grid, IconButton, Typography, makeStyles } from '@material-ui/core'
import React from 'react'
import colors from '../../../assets/colors'
import { mdiChevronLeft, mdiChevronRight } from '@mdi/js'
import Icon from '@mdi/react'
import { Link } from 'react-router-dom/cjs/react-router-dom'

const useStyles = makeStyles({
  pageLink: {
    width: 'auto',
    '&:hover': {
      backgroundColor: 'inherit',
    },
  },
  page: {
    fontSize: '14px',
    color: colors.textGray,
    fontWeight: 'normal',
    transition: 'all .4s ease-in-out',
    '&:hover': {
      backgroundColor: 'inherit',
      color: colors.primary,
    },
  },
  path: {
    fontSize: '20px',
    color: colors.primary,
    fontWeight: '700',
    position: 'relative',
  },
  btnReturn: {
    width: 'auto',
    display: 'flex',
    alignItems: 'center',
    padding: '4px',
    fontSize: '14px',
    color: colors.gray,
    backgroundColor: 'transparent',
    transition: 'all .4s ease-in-out',
    '&:hover': {
      color: colors.primary,
      backgroundColor: 'inherit',
    },
  },
})

const BaseRelatoryPage = ({
  title,
  children,
  btnReturn = false,
  btnReturnPath = '/',
}) => {
  const classes = useStyles()

  return (
    <Grid container>
      <Grid
        item
        container
        xs={12}
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item xs={8} container alignItems="center">
          <Typography variant="h6" color="inherit" className={classes.page}>
            Relátorios
          </Typography>

          <Icon path={mdiChevronRight} size={1} color={colors.textPrimary} />

          <Typography variant="h6" color="inherit" className={classes.path}>
            {title}

            {/* <IconButton className={classes.questionBox}>
            <a href={helperLink} target="_blank" rel="noreferrer">
              <Icon
                path={mdiMessageQuestion}
                size={1}
                color={colors.secondary}
                style={{
                  position: 'absolute',
                  top: '-10px',
                  right: '-10px',
                }}
              />
            </a>
          </IconButton> */}
          </Typography>
        </Grid>

        {btnReturn && (
          <Grid item>
            <IconButton
              className={classes.btnReturn}
              component={Link}
              to={btnReturnPath}
              style={{ alignSelf: 'flex-end' }}
            >
              <Icon path={mdiChevronLeft} size={1} color={colors.textPrimary} />
              Voltar
            </IconButton>
          </Grid>
        )}
      </Grid>

      {children}
    </Grid>
  )
}

export default BaseRelatoryPage
