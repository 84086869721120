/* eslint-disable react/prop-types */
import Box from '@material-ui/core/Box'
import { MenuItem } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Popover from '@material-ui/core/Popover'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import FilterListIcon from '@material-ui/icons/FilterList'
import SearchIcon from '@material-ui/icons/Search'
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state'
import React, { useEffect, useState } from 'react'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { ValidatorForm } from 'react-material-ui-form-validator'
import { useDispatch, useSelector } from 'react-redux'
import { endOfMonth, format, startOfMonth } from 'date-fns'

import BadgeGlobal from '../../components/Badge'
import {
  fetchTeamProdutivityRelatoryInit,
  makeRelatory,
} from '../TeamProdutivityRelatoryActions'
import BaseFilter from '../../../components/hooks/BaseFilter'
import filterStylesComponents from '../../../assets/filterComponentsStyles'
import { mdiFilterMultiple, mdiMicrosoftExcel } from '@mdi/js'
import colors from '../../../assets/colors'
import Icon from '@mdi/react'
import BaseFilterModal from '../../../components/hooks/BaseFilterModal'

export default function Filter(props) {
  const items = useSelector(state => state.team_produtivity_relatory.items)

  const teams = useSelector(state => state.team_produtivity_relatory.teams)
  const serviceTypes = useSelector(
    state => state.team_produtivity_relatory.services_types
  )

  const dispatch = useDispatch()
  const { classes } = props
  const [form, setForm] = useState({
    team: '',
    service_type: '',
    date_start: startOfMonth(new Date()),
    date_end: endOfMonth(new Date()),
  })
  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(!open)
  }

  const onSubmit = () => {
    // e.preventDefault()
    const data = {
      ...form,
      date_start: form.date_start
        ? format(form.date_start, 'yyyy-MM-dd')
        : null,
      date_end: form.date_end ? format(form.date_end, 'yyyy-MM-dd') : null,
    }
    dispatch(fetchTeamProdutivityRelatoryInit(null, data, null))
  }

  const styles = filterStylesComponents()

  const handleRelatory = e => {
    e.preventDefault()
    dispatch(makeRelatory(form))
  }

  return (
    <BaseFilter>
      <Grid container spacing={2}>
        <Grid item container spacing={1} xs={12} sm={6}>
          <Grid item xs={12} sm={4}>
            <KeyboardDatePicker
              clearable
              value={form.date_start}
              label="Data Inicial"
              fullWidth
              views={['date']}
              clearLabel="Limpar"
              onBlur={onSubmit}
              onClose={onSubmit}
              invalidDateMessage="Data inválida"
              autoOk
              onChange={date => setForm({ ...form, date_start: date })}
              format="dd/MM/yyyy"
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <KeyboardDatePicker
              clearable
              value={form.date_end}
              label="Data Final"
              fullWidth
              views={['date']}
              onBlur={onSubmit}
              onClose={onSubmit}
              clearLabel="Limpar"
              invalidDateMessage="Data inválida"
              autoOk
              onChange={date => setForm({ ...form, date_end: date })}
              format="dd/MM/yyyy"
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} container className={styles.areaButtons}>
          <IconButton className={styles.btnMoreFilters} onClick={handleOpen}>
            <Icon path={mdiFilterMultiple} size={1} color={colors.secondary} />
            Filtros
          </IconButton>

          <IconButton className={styles.btnAdd} onClick={handleRelatory}>
            <Icon path={mdiMicrosoftExcel} size={1} />
            Download
          </IconButton>
        </Grid>
      </Grid>

      <BaseFilterModal open={open} handleOpen={handleOpen} title="FILTROS">
        <Grid
          xs={12}
          item
          container
          style={{ width: '420px' }}
          spacing={2}
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12} style={{ fontSize: '16px' }}>
            Utilize os campos abaixo para encontrar uma equipe
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              onChange={e => setForm({ ...form, team: e.target.value })}
              select
              label="Equipes"
              className={classes.inputFilter}
              fullWidth
              value={form.team}
            >
              <MenuItem key="" value="">
                Selecione...
              </MenuItem>
              {teams?.map(team => (
                <MenuItem key={team.id} value={team.id}>
                  {team.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              onChange={e => setForm({ ...form, service_type: e.target.value })}
              select
              label="Tipo de Serviço"
              className={classes.inputFilter}
              fullWidth
              value={form.service_type}
            >
              <MenuItem key="" value="">
                Selecione...
              </MenuItem>
              {serviceTypes?.map(serviceType => (
                <MenuItem key={serviceType.id} value={serviceType.id}>
                  {serviceType.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <KeyboardDatePicker
              clearable
              value={form.date_start}
              label="Data Inicial"
              fullWidth
              views={['date']}
              clearLabel="Limpar"
              invalidDateMessage="Data inválida"
              autoOk
              onChange={date => setForm({ ...form, date_start: date })}
              format="dd/MM/yyyy"
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <KeyboardDatePicker
              clearable
              value={form.date_end}
              label="Data Final"
              fullWidth
              views={['date']}
              clearLabel="Limpar"
              invalidDateMessage="Data inválida"
              autoOk
              onChange={date => setForm({ ...form, date_end: date })}
              format="dd/MM/yyyy"
            />
          </Grid>

          <Grid
            item
            xs={12}
            container
            justifyContent="center"
            alignItems="center"
            style={{ marginTop: '1em' }}
          >
            <Button
              className={styles.btnAdd}
              onClick={e => {
                onSubmit(e)
                handleOpen()
              }}
            >
              <SearchIcon />
              Pesquisar
            </Button>
          </Grid>
        </Grid>
      </BaseFilterModal>
    </BaseFilter>
  )
}
