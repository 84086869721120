/* eslint-disable consistent-return */
/* eslint-disable eqeqeq */
/* eslint-disable camelcase */
import React, { useEffect, useRef, useState } from 'react'

import { fetchServiceType, remove, update } from '../ServiceTypeActions'
import GlobalList from '../../components/GlobalList'
import { exportFormatedMoney, renderRoute } from '../../../util/utils'
import {
  Box,
  ClickAwayListener,
  Grid,
  Grow,
  IconButton,
  InputBase,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  TableRow,
  makeStyles,
} from '@material-ui/core'
import EnhancedTableHead from '../../components/TableHead'
import { getSorting, stableSort } from '../../../util/TableUtils'
import { StyledTableCellBody } from '../../../components/hooks/BaseTableComponents'
import { mdiDelete, mdiDotsVertical, mdiNoteEditOutline } from '@mdi/js'
import Icon from '@mdi/react'
import colors from '../../../assets/colors'
import fontSizes from '../../../assets/fontSizes'
import { useDispatch } from 'react-redux'
import { useConfirm } from 'material-ui-confirm'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import { number } from 'prop-types'
import NumberFormat from 'react-number-format'

function createData(
  id,
  name,
  value,
  technicialValue,
  technician_percent,
  time,
  category,
  status
) {
  return {
    id,
    name,
    value,
    technicialValue,
    technician_percent,
    time,
    category,
    status,
    update: 'update-service-types',
    delete: 'delete-service-types',
    link_update: `/service-type/${id}/edit`,
  }
}

const headCells = [
  {
    id: 'id',
    numeric: false,
    disablePadding: true,
    label: 'ID',
  },
  {
    id: 'name',
    numeric: true,
    disablePadding: false,
    label: 'Nome',
  },
  {
    id: 'value',
    numeric: true,
    disablePadding: false,
    label: 'Valor Padrão',
  },
  {
    id: 'technicialValue',
    numeric: true,
    disablePadding: false,
    label: 'Valor Técnico',
  },
  {
    id: 'time',
    numeric: true,
    disablePadding: false,
    label: 'Tempo padrão',
  },
  {
    id: 'category',
    numeric: true,
    disablePadding: false,
    label: 'Categoria',
  },
  {
    id: 'status',
    numeric: true,
    disablePadding: false,
    label: 'Status',
  },
  {
    id: 'actions',
    numeric: true,
    disablePadding: false,
    label: 'Ações',
    align: 'center',
  },
]

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(5),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  tableStickHeader: {
    maxHeight: 'calc(100vh - 252px)',
  },
  paper: {
    marginRight: 0,
    borderRadius: '10px',
    color: colors.textPrimary,
    fontSize: fontSizes.text,
    boxShadow: '0px 0px 7px 0px #00000040',
  },
  buttons: {
    color: colors.primary,
  },
  ref: {
    width: '34px',
    height: '34px',
    position: 'absolute',
    backgroundColor: '#FFFF',
    transform: 'rotate(45deg)',
    right: '7px',
    '&:hover': {
      backgroundColor: '#FFFF',
    },
  },
  menuList: {
    padding: '10px',
  },
  menuItem: {
    padding: '0 2px',
    height: 'auto',
    lineHeight: '1',
    transition: 'all .4s ease-in-out',
    '&:hover': {
      backgroundColor: 'inherit',
      color: colors.primary,
    },
  },
  menuItemLi: {
    padding: 0,
    margin: 0,
    borderRadius: 0,
    transition: 'all .4s ease-in-out',
    '&:hover': {
      backgroundColor: 'inherit',
      color: colors.primary,
    },
    fontSize: '14px',
  },
  menuItemLiIcon: {
    marginRight: '12px',
  },
  status: {
    textAlign: 'center',
    fontSize: '14p',
    borderRadius: '10px',
    padding: '4px 16px',
    maxWidth: '80px',
    backgroundColor: colors.graylight,
    color: '#000',
  },
  statusAtivo: {
    color: '#FFFF',
    backgroundColor: colors.secondary,
  },
  retangleColor: {
    width: '18px',
    height: '18px',
    borderRadius: '2px',
  },
}))

function List(props) {
  const { items, permissions } = props
  const confirm = useConfirm()
  const dispatch = useDispatch()
  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState('id')
  const classes = useStyles()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [edit, setEdit] = useState({
    id: null,
    technicialValue: null,
    defaultValue: null,
    technicianPercent: null,
  })

  const [open, setOpen] = useState({
    id: '',
  })

  const anchorRefs = useRef([])

  const handleToggle = id => {
    if (open?.id === id) {
      return setOpen({
        id: '',
      })
    }

    setOpen({
      ...open,
      id: id,
    })
  }

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === 'desc'
    setOrder(isDesc ? 'asc' : 'desc')
    setOrderBy(property)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const handleClose = (event, index) => {
    if (
      anchorRefs.current[index] &&
      anchorRefs.current[index].contains(event.target)
    ) {
      return
    }

    setOpen({
      id: '',
    })
  }

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen({
        id: '',
      })
    }
  }

  const confirmDelete = id => {
    confirm({
      description: 'Após ser apagado o conteúdo não poderá ser recuperado?',
      title: 'Tem certeza?',
      confirmationText: 'Sim',
      cancellationText: 'Cancelar',
      dialogProps: {
        fullWidth: true,
      },
    }).then(() => {
      dispatch(remove(id))
    })
  }

  const parseValue = value => {
    if (!value) return

    return parseFloat(value?.replace(/\./g, '')?.replace(',', '.'))
  }

  const handleChange = (rowId, e) => {
    e.preventDefault()

    const { name, value } = e.target

    const findItem = items?.filter(row => row.id === rowId)

    if (findItem[0]) {
      const item = findItem[0]

      if (name === 'defaultValue') {
        setEdit({
          ...edit,
          id: rowId,
          defaultValue: parseValue(value),
          technicianPercent: item.technician_percent,
          technicialValue: (item.technician_percent / 100) * parseValue(value),
        })
      }

      if (name === 'technicianPercent') {
        setEdit({
          ...edit,
          id: rowId,
          defaultValue: parseValue(item?.default_value_formated),
          technicianPercent: parseValue(value),
          technicialValue:
            (parseValue(value) / 100) *
            parseValue(item?.default_value_formated),
        })
      }

      if (name === 'technicialValue') {
        setEdit({
          ...edit,
          id: rowId,
          defaultValue: parseValue(item?.default_value_formated),
          technicianPercent:
            (parseValue(value) * 100) / parseValue(item.default_value_formated),
          technicialValue: parseValue(value),
        })
      }
    }
  }

  const handleEditValues = (id, e) => {
    e.preventDefault()

    const data = {
      id: id,
      default_value: edit.defaultValue,
      technician_percent: edit?.technicianPercent,
      technician_value: edit?.technicialValue,
    }

    dispatch(update(data))

    return setEdit({
      id: null,
      technicialValue: null,
      defaultValue: null,
      technicianPercent: null,
    })
  }

  return (
    <>
      <TableContainer className={classes.tableStickHeader}>
        <Table
          className={classes.table}
          aria-labelledby="Attendance table"
          size="medium"
          stickyHeader
        >
          <EnhancedTableHead
            classes={classes}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={items.length}
            headCells={headCells}
            hideID
            renderFirstTH={false}
          />
          <TableBody>
            {stableSort(items, getSorting(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map(row => {
                return (
                  <TableRow hover tabIndex={-1} key={row.id}>
                    <StyledTableCellBody>{row?.name}</StyledTableCellBody>
                    {edit?.id === row?.id ? (
                      <>
                        <StyledTableCellBody>
                          <NumberFormat
                            name="defaultValue"
                            value={edit.defaultValue}
                            onBlur={e => {
                              handleEditValues(row?.id, e)
                            }}
                            onChange={e => {
                              handleChange(row?.id, e)
                            }}
                            style={{
                              width: '83px',
                              marginRight: '4px',
                              borderBottom: `2px solid ${colors.graylight}`,
                              color: colors.gray,

                              fontSize: '14px',
                            }}
                            inputProps={{
                              style: {
                                fontSize: '14px',
                                textAlign: 'center',
                              },
                            }}
                            customInput={InputBase}
                            decimalScale={2}
                            decimalSeparator=","
                            thousandSeparator="."
                            displayType="input"
                            startAdornment="R$"
                          />
                        </StyledTableCellBody>
                        <StyledTableCellBody>
                          <NumberFormat
                            name="technicianPercent"
                            value={edit.technicianPercent}
                            onBlur={e => {
                              handleEditValues(row?.id, e)
                            }}
                            onChange={e => {
                              handleChange(row?.id, e)
                            }}
                            endAdornment="%"
                            style={{
                              width: '53px',
                              marginRight: '4px',
                              borderBottom: `2px solid ${colors.graylight}`,
                              color: colors.gray,

                              fontSize: '12px',
                            }}
                            inputProps={{
                              style: {
                                fontSize: '12px',
                                textAlign: 'end',
                              },
                            }}
                            customInput={InputBase}
                            decimalScale={2}
                            decimalSeparator=","
                            thousandSeparator="."
                            displayType="input"
                          />

                          <NumberFormat
                            name="technicialValue"
                            value={edit.technicialValue}
                            onBlur={e => {
                              handleEditValues(row?.id, e)
                            }}
                            onChange={e => {
                              handleChange(row?.id, e)
                            }}
                            style={{
                              width: '83px',
                              marginRight: '4px',
                              borderBottom: `2px solid ${colors.graylight}`,
                              color: colors.gray,

                              fontSize: '12px',
                            }}
                            inputProps={{
                              style: {
                                fontSize: '12px',
                                textAlign: 'center',
                              },
                            }}
                            startAdornment="R$"
                            customInput={InputBase}
                            decimalScale={2}
                            decimalSeparator=","
                            thousandSeparator="."
                            displayType="input"
                          />
                        </StyledTableCellBody>
                      </>
                    ) : (
                      <>
                        <StyledTableCellBody>
                          <NumberFormat
                            name="defaultValue"
                            value={row?.default_value_formated}
                            onChange={e => {
                              handleChange(row?.id, e)
                            }}
                            style={{
                              width: '83px',
                              marginRight: '4px',
                              borderBottom: `2px solid ${colors.graylight}`,
                              color: colors.gray,

                              fontSize: '14px',
                            }}
                            inputProps={{
                              style: {
                                fontSize: '14px',
                                textAlign: 'center',
                              },
                            }}
                            customInput={InputBase}
                            decimalScale={2}
                            decimalSeparator=","
                            thousandSeparator="."
                            displayType="input"
                            fixedDecimalScale
                            startAdornment="R$"
                          />
                        </StyledTableCellBody>
                        <StyledTableCellBody>
                          <NumberFormat
                            defaultValue={row?.technician_percent || 0}
                            name="technicianPercent"
                            value={row?.technician_percent}
                            onChange={e => {
                              handleChange(row?.id, e)
                            }}
                            endAdornment="%"
                            style={{
                              width: '53px',
                              marginRight: '4px',
                              borderBottom: `2px solid ${colors.graylight}`,
                              color: colors.gray,

                              fontSize: '12px',
                            }}
                            inputProps={{
                              style: {
                                fontSize: '12px',
                                textAlign: 'end',
                              },
                            }}
                            customInput={InputBase}
                            decimalScale={2}
                            decimalSeparator=","
                            thousandSeparator="."
                            displayType="input"
                          />

                          <NumberFormat
                            name="technicialValue"
                            value={row?.technician_value_formated}
                            onChange={e => {
                              handleChange(row?.id, e)
                            }}
                            style={{
                              width: '83px',
                              marginRight: '4px',
                              borderBottom: `2px solid ${colors.graylight}`,
                              color: colors.gray,

                              fontSize: '12px',
                            }}
                            inputProps={{
                              style: {
                                fontSize: '12px',
                                textAlign: 'center',
                              },
                            }}
                            startAdornment="R$"
                            customInput={InputBase}
                            decimalScale={2}
                            decimalSeparator=","
                            thousandSeparator="."
                            displayType="input"
                            fixedDecimalScale
                          />
                        </StyledTableCellBody>
                      </>
                    )}
                    <StyledTableCellBody>
                      {row?.execution_time_formated}
                    </StyledTableCellBody>
                    <StyledTableCellBody>
                      {row?.serviceCategory?.name}
                    </StyledTableCellBody>

                    <StyledTableCellBody>
                      {' '}
                      {row?.status == 1 ? (
                        <Box className={[classes.status, classes.statusAtivo]}>
                          Ativo
                        </Box>
                      ) : (
                        <Box className={classes.status}>Inativo</Box>
                      )}
                    </StyledTableCellBody>
                    <StyledTableCellBody>
                      <IconButton
                        ref={el => (anchorRefs.current[row?.id] = el)}
                        key={row.id}
                        id={row?.id}
                        aria-controls={
                          open?.id ? `menu-list-grow-${row.id}` : undefined
                        }
                        aria-haspopup="true"
                        onClick={() => {
                          handleToggle(row.id)
                        }}
                      >
                        <Icon path={mdiDotsVertical} size={1} />
                      </IconButton>

                      <Popper
                        open={open?.id === row?.id}
                        role={undefined}
                        anchorEl={anchorRefs.current[row?.id]}
                        transition
                        disablePortal
                        style={{
                          zIndex: 99999999,
                        }}
                        placement="bottom-end"
                      >
                        {({ TransitionProps, placement }) => (
                          <Grow
                            {...TransitionProps}
                            style={{
                              transformOrigin:
                                placement == 'bottom' ? 'left-end' : 'top-end',
                            }}
                          >
                            <Paper className={classes.paper}>
                              <ClickAwayListener onClickAway={handleClose}>
                                <MenuList
                                  autoFocusItem={open?.id}
                                  id={`menu-list-grow-${row.id}`}
                                  onKeyDown={handleListKeyDown}
                                  className={classes.menuList}
                                >
                                  <MenuItem
                                    className={classes.ref}
                                    style={
                                      placement === 'bottom-end'
                                        ? { top: '-2px' }
                                        : { bottom: '-2px' }
                                    }
                                  />
                                  {renderRoute(
                                    ['update-service-types'],
                                    permissions
                                  ) && (
                                    <MenuItem
                                      onClick={handleClose}
                                      className={classes.menuItem}
                                    >
                                      <IconButton
                                        aria-label="edit"
                                        component={Link}
                                        to={`/service-type/${row?.id}/edit`}
                                        className={classes.menuItemLi}
                                      >
                                        <Icon
                                          path={mdiNoteEditOutline}
                                          size={1}
                                          className={classes.menuItemLiIcon}
                                        />
                                        Editar
                                      </IconButton>
                                    </MenuItem>
                                  )}
                                  {renderRoute(
                                    ['delete-service-types'],
                                    permissions
                                  ) &&
                                    row.is_client_service != 1 && (
                                      <MenuItem
                                        onClick={handleClose}
                                        className={classes.menuItem}
                                      >
                                        <IconButton
                                          onClick={() => confirmDelete(row.id)}
                                          className={classes.menuItemLi}
                                        >
                                          <Icon
                                            path={mdiDelete}
                                            size={1}
                                            className={classes.menuItemLiIcon}
                                          />
                                          Deletar
                                        </IconButton>
                                      </MenuItem>
                                    )}
                                </MenuList>
                              </ClickAwayListener>
                            </Paper>
                          </Grow>
                        )}
                      </Popper>
                    </StyledTableCellBody>
                  </TableRow>
                )
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        component="div"
        count={items.length}
        rowsPerPage={rowsPerPage}
        page={page}
        labelRowsPerPage="Linhas por Página"
        backIconButtonProps={{
          'aria-label': 'previous page',
        }}
        labelDisplayedRows={({ from, to, count }) =>
          `Mostrando linhas ${from}-${to} de ${count}`
        }
        nextIconButtonProps={{
          'aria-label': 'next page',
        }}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  )
}

export default List
