export default {
  DEFAULT_PAGINATION: 10,

  ADMINISTRATOR_ROLE_NAME: 'administrator',

  ORCAMENTO: 1,
  AGUARDANDO_REALIZACAO: 2,
  PARCIALMENTE_CONCLUIDO: 3,
  CONCLUIDO: 4,
  CANCELADO: 5,
  ARQUIVADO: 6,

  NA_LAVANDERIA: 1,
  NO_CLIENTE: 2,
}
