import React, { useState } from 'react'
import {
  Button,
  Grid,
  IconButton,
  MenuItem,
  TextField,
} from '@material-ui/core'
import { Link } from 'react-router-dom/cjs/react-router-dom'
import Icon from '@mdi/react'
import {
  mdiFilterMultiple,
  mdiMagnify,
  mdiMicrosoftExcel,
  mdiNoteEditOutline,
} from '@mdi/js'
import filterStylesComponents from '../../../../../assets/filterComponentsStyles'
import BaseFilter from '../../../../../components/hooks/BaseFilter'
import colors from '../../../../../assets/colors'
import { useDispatch, useSelector } from 'react-redux'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { addDays, format, subMonths } from 'date-fns'
import { makeRelatory } from '../GerencyReportsActions'

const Filter = ({ form, setForm }) => {
  const attendants = useSelector(state => state.gerencyReport.attendants)

  const dispatch = useDispatch()

  const styles = filterStylesComponents()

  const [dates, setDates] = useState({
    date_start: format(subMonths(new Date(), 1), 'yyyy-MM-dd 00:00:00'),
    date_end: format(new Date(), 'yyyy-MM-dd 23:59:59'),
  })

  const handleChangeDateStart = date => {
    let formattedDate = date

    try {
      formattedDate = format(date, 'yyyy-MM-dd 00:00:00')
    } catch (error) {
      console.log('Invalid Date')
    }

    setDates({
      ...dates,
      date_start: formattedDate,
    })
  }

  const handleChangeDateEnd = date => {
    let formattedDate = date

    try {
      formattedDate = format(date, 'yyyy-MM-dd 23:59:59')
    } catch (error) {
      console.log('Invalid Date')
    }

    setDates({
      ...dates,
      date_end: formattedDate,
    })
  }

  const hanldeSubmit = e => {
    e.preventDefault()

    const formData = e.currentTarget
    const data = new FormData(formData)
    const entries = data.entries()
    const values = Object.fromEntries(entries)

    let filters = {
      ...values,
      ...dates,
    }

    setForm({
      ...form,
      ...filters,
    })
  }

  const handleExport = () => {
    dispatch(makeRelatory(form))
  }

  return (
    <BaseFilter>
      <Grid container spacing={3}>
        <Grid item xs={9}>
          <form onSubmit={hanldeSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <KeyboardDatePicker
                  clearable
                  label="Data Inicial"
                  fullWidth
                  views={['date']}
                  clearLabel="Limpar"
                  invalidDateMessage="Data inválida"
                  autoOk
                  value={dates.date_start}
                  onChange={handleChangeDateStart}
                  format="dd/MM/yyyy"
                />
              </Grid>
              <Grid item xs={2}>
                <KeyboardDatePicker
                  clearable
                  label="Data Final"
                  fullWidth
                  views={['date']}
                  clearLabel="Limpar"
                  invalidDateMessage="Data inválida"
                  autoOk
                  value={dates.date_end}
                  onChange={handleChangeDateEnd}
                  format="dd/MM/yyyy"
                />
              </Grid>

              <Grid item sm={4} md={2}>
                <TextField
                  select
                  name="attendant_id"
                  variant="standard"
                  fullWidth
                  label="Atendente"
                >
                  <MenuItem value="">Selecione...</MenuItem>

                  {attendants?.map((attendant, index) => (
                    <MenuItem value={attendant?.id} key={index}>
                      {attendant?.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs container alignItems="flex-end">
                <Button className={styles.btnMoreFilters} type="submit">
                  <Icon
                    path={mdiFilterMultiple}
                    size={1}
                    color={colors.secondary}
                  />
                  {` Filtrar`}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>

        <Grid item xs className={styles.areaButtons}>
          <IconButton className={styles.btnAdd} onClick={handleExport}>
            <Icon path={mdiMicrosoftExcel} size={1} />
            Download
          </IconButton>
        </Grid>
      </Grid>
    </BaseFilter>
  )
}

export default Filter
