import {
  Checkbox,
  DialogActions,
  FormControlLabel,
  Switch,
} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import DialogContent from '@material-ui/core/DialogContent'
import Grid from '@material-ui/core/Grid'
import ThumbDownAltOutlinedIcon from '@material-ui/icons/ThumbDownAltOutlined'
import ThumbUpAltOutlinedIcon from '@material-ui/icons/ThumbUp'
import React, { useEffect, useState } from 'react'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'
import filterStylesComponents from '../../../assets/filterComponentsStyles'

function Form(props) {
  const { data, onClose, onSubmit } = props

  const [form, updateForm] = useState({
    id: '',
    name: '',
    status: 1,
    days_to_deadline: '',
    quota: '',
  })

  const updateField = React.useCallback(name => e => {
    updateForm({
      ...form,
      [name]: e.target.value,
    })
  })

  const classes = filterStylesComponents()

  const handleClose = () => onClose && onClose()

  const onSubmitForm = e => {
    e.preventDefault()
    const send = { ...form, status: form.status || 0 }
    onSubmit && onSubmit(send)
  }

  useEffect(() => {
    updateForm({ ...form, ...data })
  }, [data])

  return (
    <ValidatorForm onSubmit={onSubmitForm}>
      <Grid container xs={12} spacing={2}>
        <Grid item xs={12}>
          <TextValidator
            validators={['required']}
            errorMessages={['Campo Obrigatório']}
            onChange={updateField('name')}
            label="Nome"
            fullWidth
            value={form.name}
          />
        </Grid>

        <Grid item xs={6}>
          <TextValidator
            validators={['required']}
            errorMessages={['Campo Obrigatório']}
            onChange={updateField('quota')}
            label="Parcelas"
            fullWidth
            value={form.quota}
          />
        </Grid>

        <Grid item xs={6}>
          <TextValidator
            validators={['required']}
            errorMessages={['Campo Obrigatório']}
            onChange={updateField('days_to_deadline')}
            label="Dias para o vencimento"
            fullWidth
            value={form.days_to_deadline}
            helperText="Em caso de vários dias utilize uma vírgula como separador"
          />
        </Grid>
      </Grid>

      <Grid container spacing={1}>
        <Grid item xs>
          <Switch
            checked={form.status || false}
            onChange={() => {
              updateForm({ ...form, status: !form.status })
            }}
          />{' '}
          Ativo?
        </Grid>
      </Grid>

      <Grid
        item
        container
        xs={12}
        style={{ marginTop: '1.5em' }}
        justifyContent="center"
        alignItems="center"
      >
        <Button type="submit" className={classes.btnAdd}>
          Salvar
        </Button>
      </Grid>
    </ValidatorForm>
  )
}

export default Form
