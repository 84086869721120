/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/no-unused-state */
import { Component } from 'react'

import { cpfMask } from '../../util/utils'

export default class EnhancedComponent extends Component {
  constructor(props) {
    super(props)

    this.state = {
      dirty: false,
      fields: {},
      notFindLogin: false,
      errorOnLogin: '',
      notFindPassword: false,
    }
  }

  onChange = name => event => {
    this.setState({
      dirty: true,
      fields: {
        ...this.state.fields,
        [name]: this.functionToCall(name, event.target.value),
      },
    })

    if (name === 'password' && !event.target?.value) {
      this.setState({
        dirty: true,
        fields: {
          ...this.state.fields,
          [name]: this.functionToCall(name, event.target.value),
        },
        notFindPassword: false,
        notFindLogin: this.state.notFindLogin,
        errorOnLogin: this.state.errorOnLogin,
      })
    }

    if (name === 'email' && !event.target?.value) {
      this.setState({
        dirty: true,
        fields: {
          ...this.state.fields,
          [name]: this.functionToCall(name, event.target.value),
        },
        notFindPassword: this.state.notFindPassword,
        notFindLogin: false,
        errorOnLogin: '',
      })
    }
  }

  functionToCall(name, value) {
    switch (name) {
      case 'cpf':
        return cpfMask(value)
      default:
        return value
    }
  }
}
