export default {
  title: 'Higsystem - Soluções em gestão',
  storagePrefix: '_higsystem',

  API_URL: process.env.RAZZLE_API_URL,
  API_CLIENT: process.env.RAZZLE_API_CLIENT,
  API_SECRET: process.env.RAZZLE_API_SECRET,
  API_GRANT: 'password',

  PUSHER_KEY: process.env.RAZZLE_PUSHER_KEY,
  CLUSTER: process.env.RAZZLE_PUSHER_CLUSTER,

  WS_HOST: process.env.RAZZLE_WS_HOST,
  WS_PORT: process.env.RAZZLE_WS_PORT,
  FORCE_TLS: process.env.RAZZLE_FORCE_TLS,

  GOOGLE_KEY: process.env.RAZZLE_GOOGLE_KEY,
}
