/* eslint-disable consistent-return */
/* eslint-disable eqeqeq */
/* eslint-disable import/order */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
import {
  Box,
  Card,
  CardContent,
  Container,
  Divider,
  Grid,
  Link,
  Paper,
  Typography,
  makeStyles,
} from '@material-ui/core'
import classnames from 'classnames'
import React from 'react'
import CountUp from 'react-countup'
import { Link as RouterLink } from 'react-router-dom'
import colors from '../../../assets/colors'
import Icon from '@mdi/react'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    borderRadius: '10px',
    border: `1px solid ${colors.graylight}`,
    height: 'auto',
  },
  aditionalBox: {
    padding: '8px 24px',
    color: colors.primary,
    fontSize: '12px',
    lineHeight: '12px',
  },
  rowTotal: {
    color: '#FFF',
    fontSize: '12px',
    backgroundColor: colors.primary,
    padding: '4px',
    borderRadius: '0px 0px 10px 10px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: '8px',
    paddingRight: '20px',
    width: '100%',
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: '.5rem',
  },
  cardTitle: {
    textAlign: 'start',
  },
  cardTitleLink: {
    cursor: 'pointer',
    textAlign: 'start',
    color: colors.primary,
    textTransform: 'captalize',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    fontSize: '12px',
    fontWeight: 'normal',
    lineHeight: '12px',
    margin: '.5em 0',
    textDecoration: 'underline',
    letterSpacing: '.09em',
  },
  value: {
    fontSize: '22px',
    lineHeight: '22px',
    fontWeight: '700',
    color: colors.graylight,
    marginRight: theme.spacing(1),
  },
  differenceValue: {
    color: '#3AA873',
    marginRight: theme.spacing(1),
  },
  differenceValueNegative: {
    color: '#C15858',
    marginRight: theme.spacing(1),
  },
  divider: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
}))

const CardSection = ({
  title,
  icon,
  data,
  isLinkCard = false,
  isMonetary = false,
  isLinkRelatory = false,
  finalDate = '',
  startDate = '',
}) => {
  const classes = useStyles()

  const contUp = money => {
    return (
      <CountUp
        end={money}
        duration={0.9}
        decimals={2}
        decimal=","
        // prefix="R$"
        separator="."
      />
    )
  }

  const attendancesPath = element => {
    return `/relatory-services?status=${element?.type}&finalDate=${finalDate}&startDate=${startDate}`
  }

  const relatoryPath = element => {
    return `/relatory-schedules?status=${element?.type}&finalDate=${finalDate}&startDate=${startDate}`
  }

  const cardLinkable = isLinkCard || isLinkRelatory

  return (
    <>
      <Grid item container xs={12}>
        <Grid item container className={classes.root}>
          <Grid
            item
            container
            md={4}
            sm={12}
            justifyContent="space-between"
            style={{ padding: '4px 12px' }}
          >
            <Grid item md={10} sm={12}>
              <Typography
                variant="h4"
                color="secondary"
                style={{
                  fontSize: '17px',
                  color: colors.primary,
                  lineHeight: '20px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  gap: '12px',
                  textAlign: 'start',
                }}
              >
                <Icon path={icon} size={3} color={colors.graylight} />
                {title}
              </Typography>
            </Grid>
            <Grid item md={1}>
              <Divider orientation="vertical" className={classes.divider} />
            </Grid>
          </Grid>

          <Grid item container md={8} alignItems="center">
            {data?.map((element, index) => {
              // no render total and ticket
              if (element?.type === 'total' || element?.type === 'ticket')
                return

              return (
                <Grid
                  item
                  key={index}
                  xs={12}
                  sm={4}
                  md={4}
                  style={{ padding: '8px' }}
                >
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography
                        style={{
                          fontSize: '12px',
                          color: colors.primary,
                          textAlign: 'start',
                        }}
                      >
                        R$
                      </Typography>
                    </Grid>
                    <Grid item xs={12} container justifyContent="flex-start">
                      {isMonetary ? (
                        <Typography
                          color="textPrimary"
                          variant="h3"
                          className={classnames(
                            {
                              [classes.differenceValue]: element.value > 0,
                              [classes.differenceValueNegative]:
                                element.value < 0,
                            },
                            classes.value
                          )}
                        >
                          {contUp(element.value)}
                        </Typography>
                      ) : (
                        <Typography color="textPrimary" variant="h3">
                          {element.value}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      {cardLinkable &&
                      element?.type != 'total' &&
                      element?.type != 'ticket' ? (
                        <Link
                          component={RouterLink}
                          to={
                            isLinkCard
                              ? attendancesPath(element)
                              : relatoryPath(element)
                          }
                          color="textSecondary"
                          gutterBottom
                          variant="h6"
                          className={classes.cardTitleLink}
                        >
                          {element.name}
                        </Link>
                      ) : (
                        <Typography
                          color="textSecondary"
                          gutterBottom
                          variant="h6"
                          className={classes.cardTitle}
                        >
                          {element.name}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              )
            })}
          </Grid>

          {data?.map((element, index) => {
            // render total
            if (element?.type === 'total') {
              return (
                <Box className={classes.rowTotal}>
                  <span>Total:</span>

                  <Box
                    style={{
                      width: 'auto',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <span>R$</span>
                    <Typography
                      color="textPrimary"
                      variant="h3"
                      className={classes.value}
                      style={{ color: '#FFFF', textAlign: 'start' }}
                    >
                      {contUp(element.value)}
                    </Typography>
                  </Box>
                </Box>
              )
            }
          })}
        </Grid>
      </Grid>
      {/* <Grid item container md={2} sm={4}>
        <Grid item container className={[classes.root, classes.aditionalBox]}>
          {data?.data?.map((element, index) => {
            // render ticket
            if (element?.type === 'ticket') {
              return (
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    R$
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    zeroMinWidth
                    container
                    justifyContent="flex-start"
                  >
                    <Typography
                      variant="h3"
                      style={{
                        color: colors.primary,
                        fontSize: '40px',
                        fontWeight: '700',
                      }}
                      color={colors.primary}
                    >
                      {contUp(element.value)}
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    {element.name}
                  </Grid>
                </Grid>
              )
            }
          })}
        </Grid>
      </Grid> */}
    </>
    // <Grid container xs={12} justifyContent="space-between">
    //   <Grid container xs={12} sm={6} md={9} className={styles.root}>
    //     <Grid
    //       item
    //       container
    //       md={4}
    //       sm={12}
    //       justifyContent="space-between"
    //       style={{ padding: '4px 12px' }}
    //     >
    //       <Grid item xs={10}>
    //         <Typography
    //           variant="h4"
    //           color="secondary"
    //           style={{
    //             fontSize: '17px',
    //             color: colors.primary,
    //             lineHeight: '20px',
    //             display: 'flex',
    //             alignItems: 'center',
    //             justifyContent: 'flex-start',
    //             gap: '12px',
    //             textAlign: 'start',
    //           }}
    //         >
    //           <Icon path={icon} size={3} color={colors.graylight} />
    //           {title}
    //         </Typography>
    //       </Grid>
    //       <Grid item xs={1}>
    //         <Divider orientation="vertical" />
    //       </Grid>
    //     </Grid>

    //     <Grid item container xs={12} md={8} style={{ padding: '4px 12px' }}>

    //     </Grid>

    //     {data?.data?.map((element, index) => {
    //       // render total
    //       if (element?.type === 'total') {
    //         return (
    //           <Grid
    //             xs={12}
    //             item
    //             container
    //             style={{
    //               background: colors.primary,
    //               borderRadius: '0 0 10px 10px',
    //               marginTop: '8px',
    //               padding: '5px',
    //               color: '#FFFF',
    //             }}
    //             justifyContent="flex-end"
    //             alignItems="center"
    //           >
    //             <Grid item md={1} xs={4} container alignItems="center">
    //               <Grid item md={8} xs={8}>
    //                 <Typography
    //                   style={{
    //                     fontSize: '12px',
    //                     textAlign: 'end',
    //                   }}
    //                 >
    //                   Total:
    //                 </Typography>
    //               </Grid>

    //               <Grid item md={3} xs={1}>
    //                 <Typography
    //                   style={{
    //                     fontSize: '12px',
    //                     textAlign: 'end',
    //                   }}
    //                 >
    //                   R$
    //                 </Typography>
    //               </Grid>
    //             </Grid>

    //             <Grid item md={2} xs={8} container justifyContent="flex-start">
    //               <Typography
    //                 color="textPrimary"
    //                 variant="h3"
    //                 className={styles.value}
    //                 style={{ color: '#FFFF', textAlign: 'start' }}
    //               >
    //                 {contUp(element.value)}
    //               </Typography>
    //             </Grid>
    //           </Grid>
    //         )
    //       }
    //     })}
    //   </Grid>

    //    <Grid item container xs={12} sm={5} md={2} className={styles.root}>
    //     {data?.data?.map((element, index) => {
    //       // render ticket
    //       if (element?.type === 'ticket') {
    //         return (
    //           <Grid style={{ padding: '10px 24px' }} container>
    //             <Grid container>
    //               <Grid item xs={12}>
    //                 <Typography
    //                   style={{
    //                     fontSize: '12px',
    //                     color: colors.primary,
    //                     textAlign: 'start',
    //                   }}
    //                 >
    //                   R$
    //                 </Typography>
    //               </Grid>
    //               <Grid item xs={12} container justifyContent="flex-start">
    //                 <Typography
    //                   variant="h3"
    //                   style={{
    //                     color: colors.primary,
    //                     fontSize: '40px',
    //                     fontWeight: '700',
    //                   }}
    //                   color={colors.primary}
    //                 >
    //                   {contUp(element.value)}
    //                 </Typography>
    //               </Grid>
    //               <Grid item xs={12}>
    //                 <Typography
    //                   color={colors.primary}
    //                   variant="h6"
    //                   style={{
    //                     fontSize: '12px',
    //                     color: colors.primary,
    //                     textAlign: 'start',
    //                   }}
    //                 >
    //                   {element.name}
    //                 </Typography>
    //               </Grid>
    //             </Grid>
    //           </Grid>
    //         )
    //       }
    //     })}
    //   </Grid>
    // </Grid>
  )
}

export default CardSection
