/* eslint-disable import/order */
import { makeStyles } from '@material-ui/core'
import colors from './colors'

const formComponentStyles = makeStyles(theme => ({
  btnSubmmit: {
    marginTop: '50px',
    borderRadius: '16px',
    backgroundColor: colors.primary,
    color: '#FFFF',
    padding: '4px 16px',
    margin: 0,
    transition: 'all .4s ease-in-out',
    '&:hover': {
      backgroundColor: colors.primary,
      opacity: 0.6,
    },
  },
  baseInput: {
    //
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    // marginTop: theme.spacing(2),
  },
}))

export default formComponentStyles
