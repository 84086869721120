/* eslint-disable import/order */
import { makeStyles } from '@material-ui/core'
import colors from './colors'

const filterStylesComponents = makeStyles(theme => ({
  btnAdd: {
    borderRadius: '16px',
    backgroundColor: colors.primary,
    color: '#FFFF',
    padding: '0 12px',
    height: '30px',
    margin: 0,
    fontSize: '14px',
    transition: 'all .4s ease-in-out',
    '&:hover': {
      backgroundColor: colors.primary,
      opacity: 0.6,
    },
    '&:disabled': {
      backgroundColor: colors.primary,
      color: '#FFFF',
      borderRadius: '16px',
      padding: '0 12px',
      height: '30px',
      margin: 0,
      fontSize: '14px',
      transition: 'all .4s ease-in-out',
      opacity: 0.5,
    },
  },
  btnMoreFilters: {
    textTransform: 'capitalize',
    height: '23px',
    padding: '10px 12px',
    color: colors.textPrimary,
    fontWeight: 'normal',
    fontSize: '14px',
    margin: 0,
    borderRadius: 0,
    transition: 'all .4s ease-in-out',
    '&:hover': {
      backgroundColor: '#f7f7f7F',
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  btnSalvar: {
    width: '101px',
    height: '30px',
    borderRadius: '16px',
    backgroundColor: colors.primary,
    color: '#FFFF',
    padding: '0 12px',
    height: '30px',
    margin: 0,
    fontSize: '14px',
    transition: 'all .4s ease-in-out',
    '&:hover': {
      backgroundColor: colors.primary,
      opacity: 0.6,
    },
  },
  baseInput: {
    //
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    // marginTop: theme.spacing(2),
  },
  areaButtons: {
    display: 'flex',
    alignItems: 'center',
    gap: '.4em',
    justifyContent: 'flex-end',
  },
  downloadTemplate: {
    textDecoration: 'underline',
    height: 'auto',
    width: 'auto',
    color: colors.secondary,
    textTransform: 'lowercase',
    fontSize: '14px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'inherit',
      textDecoration: 'underline',
    },
  },
}))

export default filterStylesComponents
