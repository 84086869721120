/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable no-shadow */
import { Grid } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import NoteAddIcon from '@material-ui/icons/NoteAdd'
import queryString from 'query-string'

import styles from '../../../resources/theme/global'
import {
  fetchSchedulesRelatoryInit,
  makeRelatory,
  clearFilter,
} from '../SchedulesRelatoryActions'
import { getSchedulesRelatoryListData } from '../SchedulesRelatoryReducer'

import Filter from './Filter'
import List from './List'
import BaseRelatoryPage from '../../../components/hooks/BaseRelatoryPage'

class ProspectsRelatory extends Component {
  componentWillUnmount() {
    const { clearFilter } = this.props
    clearFilter()
  }

  constructor(props) {
    super(props)
    this.state = {
      openSelectColumns: false,
    }
  }

  setOpen = () => {
    this.setState({ openSelectColumns: !this.state.openSelectColumns })
  }

  makeReport = filters => {
    const values = queryString.parse(props.location.search)

    const filtersLeads = {
      type: 'dashboardLeads',
      status: values?.status,
      finalDate: values?.finalDate,
      startDate: values?.startDate,
    }

    if (values?.status) {
      makeRelatory(filtersLeads)
    } else {
      makeRelatory(filters)
    }
  }

  render() {
    const { classes, makeRelatory, filters } = this.props

    return (
      <BaseRelatoryPage title="Agendamentos">
        <Grid
          item
          xs="12"
          container
          className={classes.pageHead}
          spacing={1}
          alignItems="center"
          style={{ marginTop: '1em' }}
        >
          <Grid item xs={12}>
            <Filter classes={classes} setOpenColumns={this.setOpen} />
          </Grid>
        </Grid>

        <Grid
          item
          container
          justifyContent="flex-end"
          alignItems="center"
          style={{ marginTop: '1em' }}
        >
          <List
            {...this.props}
            setOpenColumns={this.setOpen}
            openSelectColumns={this.state.openSelectColumns}
          />
        </Grid>
      </BaseRelatoryPage>
    )
  }
}

const mapStateToProps = state => ({
  ...getSchedulesRelatoryListData(state),
  permissions: state.auth.permissions || [],
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchSchedulesRelatoryInit,
      makeRelatory,
      clearFilter,
    },
    dispatch
  )
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ProspectsRelatory))
