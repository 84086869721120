/* eslint-disable react/sort-comp */
/* eslint-disable import/order */
import AppBar from '@material-ui/core/AppBar'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import withStyles from '@material-ui/core/styles/withStyles'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import React from 'react'
import { connect, useSelector } from 'react-redux'

import styles from '../../../resources/theme/users'
import { edit, getPermission, update } from '../AttendanceActions'
import Form from './Form'
import { bindActionCreators } from 'redux'
import { Grid, Slide } from '@material-ui/core'
import BaseCreatePage from '../../../components/hooks/BaseCreatePage'
import { mdiCalendarMultiple } from '@mdi/js'
import Icon from '@mdi/react'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import colors from '../../../assets/colors'
import { renderRoute } from '../../../util/utils'
import Calendar from './Calendar'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />
})

class EditAttendance extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = { attendance: null }
  }

  async refresh() {
    const { edit, match } = this.props
    const resp = await edit(match.params.id)
    if (resp?.code === 200) {
      const { data } = resp

      const editingAttendance = {
        ...data,
        final_value_discount: data.total_with_discount,
        services: data.services.map(_item => {
          let item = { ..._item }
          if (item?.measureType.id === 1)
            item = { ...item, quantity: parseInt(item.quantity, 10) }
          return item
        }),
      }

      this.setState({ attendance: editingAttendance })
    }
  }

  componentDidMount() {
    const { match } = this.props

    window.Echo.private(`Attendance.${match.params.id}`).listen(
      `AttendanceHasUpdate`,
      () => {
        document.location.reload()
      }
    )
    this.refresh()
  }

  componentWillUnmount() {
    const { match } = this.props
    window.Echo.leave(`Attendance.${match.params.id}`)
  }

  onSubmit = async (values, redirect) => {
    const { update, history } = this.props

    const data = await update(values)
    if (data?.code === 200) {
      if (redirect) {
        history.push(`/calendar/${data.data.id}`)
      } else {
        history.push('/attendance')
      }
    }
  }

  onClose = () => {
    const { history } = this.props
    history.push('/attendance')
  }

  render() {
    const { classes } = this.props
    const { attendance } = this.state

    return (
      <BaseCreatePage
        page="Atendimentos"
        path={`Editar - OS: ${attendance?.id_laundry_client}`}
        onClose={this.onClose}
        pageLink="/attendance"
      >
        <Grid
          container
          xs={12}
          alignContent="center"
          justifyContent="center"
          className={classes.rowMessage}
          style={{
            padding: '8px 0',
            borderTop: '1px dashed',
            borderColor: colors.textSecondary,
            borderBottom: '1px dashed',
            borderBottomColor: colors.textSecondary,
            color: colors.textPrimary,
            fontSize: '14px',
          }}
        >
          <Grid
            item
            xs={8}
            style={{
              display: 'flex',
              alignItems: 'center',
              height: 'auto',
              fontWeight: 'normal',
            }}
          >
            Utilize os campos abaixo para editar um atendimento
          </Grid>

          <Grid item xs={4} container justifyContent="flex-end">
            <Grid item xs={12} container justifyContent="flex-end">
              {renderRoute(['read-schedule'], getPermission()) && (
                <Grid item xs={12} container justifyContent="flex-end">
                  <Calendar classes={classes} />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>

        <Grid container item xs={12} style={{ marginTop: '1em' }}>
          <Form
            classes={classes}
            onClose={this.onClose}
            onSubmit={this.onSubmit}
            data={attendance}
          />
        </Grid>
      </BaseCreatePage>
    )
  }
}

// export default withStyles(styles)(
//   React.memo(EditType, (props, nextProps) => {
//     console.log(props)
//     return true
//   })
// )

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      edit,
      update,
    },
    dispatch
  )

export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(EditAttendance))
