/* eslint-disable react/prop-types */
import { MenuItem } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Popover from '@material-ui/core/Popover'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import FilterListIcon from '@material-ui/icons/FilterList'
import SearchIcon from '@material-ui/icons/Search'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { endOfDay, format, startOfDay } from 'date-fns'
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state'
import React, { useState } from 'react'
import { ValidatorForm } from 'react-material-ui-form-validator'
import { useDispatch, useSelector } from 'react-redux'

import BadgeGlobal from '../../components/Badge'
import { fetchBanks } from '../BankReportActions'

export default function Filter(props) {
  const items = useSelector(state => state.bankReport.items)

  const dispatch = useDispatch()
  const { classes, allBillingTypes } = props
  const [form, setForm] = useState({
    date: null,
    date_to: null,
    graph: 'months',
    billTypeFilter: null,
    paymentDateStart: null,
    paymentDateEnd: null,
  })

  const onSubmit = e => {
    e.preventDefault()
    const date = form.date
      ? format(startOfDay(new Date(form.date)), 'yyyy-MM-dd HH:mm:ss')
      : null
    const dateTo = form.date_to
      ? format(endOfDay(new Date(form.date_to)), 'yyyy-MM-dd HH:mm:ss')
      : null
    const paymentDateStart = form.paymentDateStart
      ? format(
          startOfDay(new Date(form.paymentDateStart)),
          'yyyy-MM-dd HH:mm:ss'
        )
      : null
    const paymentDateEnd = form.paymentDateEnd
      ? format(startOfDay(new Date(form.paymentDateEnd)), 'yyyy-MM-dd HH:mm:ss')
      : null
    const data = {
      ...form,
      date,
      date_to: dateTo,
      paymentDateStart,
      paymentDateEnd,
    }

    dispatch(fetchBanks(data))
  }
  const data = Array.from(allBillingTypes)

  return (
    <Tooltip title="Filter list">
      <PopupState variant="popover" popupId="demo-popup-popover">
        {popupState => (
          <div>
            <IconButton aria-label="filter list" {...bindTrigger(popupState)}>
              <BadgeGlobal form={form} items={items}>
                <FilterListIcon />
              </BadgeGlobal>
            </IconButton>
            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Box p={2} className={classes.popover}>
                <div className={classes.root}>
                  <ValidatorForm className={classes.form} onSubmit={onSubmit}>
                    <Grid
                      container
                      className={classes.gridContainer}
                      spacing={1}
                      justify="center"
                      alignItems="center"
                    ></Grid>
                  </ValidatorForm>
                </div>
              </Box>
            </Popover>
          </div>
        )}
      </PopupState>
    </Tooltip>
  )
}
