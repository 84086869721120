import axios from 'axios/index'

import config from '../../config'
import action, { actionFailed } from '../../util/action'
import createAsyncConst from '../../util/createAsyncConst'

export const FETCH_FINANCIAL_DASHBOARD = createAsyncConst(
  'FETCH_FINANCIAL_DASHBOARD'
)

export function fetchFinancialDashboard(filters = {}) {
  return dispatch => {
    dispatch(action(FETCH_FINANCIAL_DASHBOARD.ACTION, {}, false, false))
    return axios
      .get(`${config.API_URL}/financial-dashboard`, {
        params: {
          month: filters?.month,
          year: filters?.year,
        },
      })
      .then(resp => {
        dispatch(
          action(FETCH_FINANCIAL_DASHBOARD.SUCCESS, resp.data, false, false)
        )
        return resp
      })
      .catch(e =>
        dispatch(actionFailed(FETCH_FINANCIAL_DASHBOARD, e, false, false))
      )
  }
}
