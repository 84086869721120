/* eslint-disable camelcase */
/* eslint-disable prettier/prettier */
/* eslint-disable import/order */
import React, { useEffect, useState } from 'react'
import BaseFilter from '../../../components/hooks/BaseFilter'
import {
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core'
import filterStylesComponents from '../../../assets/filterComponentsStyles'
import { mdiFilterMultiple, mdiMagnify, mdiMicrosoftExcel } from '@mdi/js'
import Icon from '@mdi/react'
import colors from '../../../assets/colors'
import BaseFilterModal from '../../../components/hooks/BaseFilterModal'
import { format } from 'date-fns'
import { useSelector } from 'react-redux'

const Filter = ({ form, setForm, handleRelatory }) => {
  const classes = filterStylesComponents()
  const technicals = useSelector(state => state.productHistory.technicals)
  const users = useSelector(state => state.productHistory.users)

  const today = () => {
    const date = new Date()

    return format(date, 'yyyy-MM-dd')
  }

  const [open, setOpen] = useState(false)

  const [currentForm, setCurrentForm] = useState({
    technical_id: '',
    product_id: '',
    date_start: today(),
    date_end: today(),
    user_id: '',
    moviment_type: '',
  })

  const handleOpen = () => {
    setOpen(!open)
  }

  const handleChange = e => {
    e.preventDefault()

    const { name, value } = e.target

    setCurrentForm({
      ...form,
      [name]: value,
    })
  }

  const handleSubmit = () => {
    setForm({
      ...form,
      ...currentForm,
    })

    handleOpen()
  }

  useEffect(() => {
    setForm({
      ...form,
      ...currentForm,
    })
  }, [currentForm.moviment_type])

  return (
    <BaseFilter>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item sm={8} xs={6}>
            <Grid container spacing={2}>
              <Grid item xs={6} sm={4}>
                <TextField
                  label="Tipo de Movimentação"
                  name="moviment_type"
                  value={currentForm.moviment_type}
                  onChange={handleChange}
                  fullWidth
                  select
                >
                  <MenuItem value="">Selecione...</MenuItem>
                  <MenuItem value="1">Entrada</MenuItem>
                  <MenuItem value="2">Saída</MenuItem>
                </TextField>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={4} xs={6} className={classes.areaButtons}>
            <IconButton className={classes.btnMoreFilters} onClick={handleOpen}>
              <Icon
                path={mdiFilterMultiple}
                size={1}
                color={colors.secondary}
              />
              Outros Filtros
            </IconButton>

            <IconButton className={classes.btnAdd} onClick={handleRelatory}>
              <Icon path={mdiMicrosoftExcel} size={1} />
              Download
            </IconButton>
          </Grid>
        </Grid>
      </Grid>

      <BaseFilterModal open={open} handleOpen={handleOpen} title="FILTRO">
        <Grid item xs={12} style={{ width: '220px' }}>
          <Grid
            container
            alignItems="center"
            justifyContent="flex-start"
            spacing={1}
          >
            <Grid item xs={12}>
              <Typography
                style={{ fontSize: '16px', fontWeight: 'normal' }}
                color={colors.primary}
              >
                Utilize os campos para encontrar item de histórico
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Técnico"
                name="technical_id"
                value={currentForm.technical_id}
                onChange={handleChange}
                fullWidth
                select
              >
                <MenuItem value="">Selecione</MenuItem>
                {technicals?.map(technical => (
                  <MenuItem value={technical?.id} key={technical?.id}>
                    {technical?.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Tipo de Movimentação"
                name="moviment_type"
                value={currentForm.moviment_type}
                onChange={handleChange}
                fullWidth
                select
              >
                <MenuItem value="">Selecione...</MenuItem>
                <MenuItem value="1">Entrada</MenuItem>
                <MenuItem value="2">Saída</MenuItem>
              </TextField>
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Usuário"
                value={currentForm.user_id}
                onChange={handleChange}
                name="user_id"
                fullWidth
                select
              >
                <MenuItem value="">Selecione</MenuItem>
                {users?.map(user => (
                  <MenuItem value={user?.id} key={user?.id}>
                    {user?.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={12}>
              <TextField
                name="date_start"
                value={currentForm?.date_start}
                onChange={e => {
                  setCurrentForm({
                    ...currentForm,
                    date_start: e.target.value,
                  })
                }}
                type="date"
                fullWidth
                label="Data inicial"
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                name="date_end"
                value={currentForm?.date_end}
                onChange={handleChange}
                type="date"
                fullWidth
                label="Data final"
              />
            </Grid>

            <Grid
              item
              xs={12}
              container
              alignItems="center"
              justifyContent="center"
              style={{ marginTop: '1em' }}
            >
              <IconButton className={classes.btnAdd} onClick={handleSubmit}>
                <Icon path={mdiMagnify} size={1} />
                Pesquisar
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </BaseFilterModal>
    </BaseFilter>
  )
}

export default Filter
