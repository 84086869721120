import { Box, Grid } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link, Route } from 'react-router-dom'
import { bindActionCreators } from 'redux'

import styles from '../../../resources/theme/global'
import { renderRoute } from '../../../util/utils'
import { fetchBank } from '../BankActions'
import { getBankListData } from '../BankReducer'

import Edit from './Edit'
// import Filter from './Filter'
import List from './List'
import New from './New'
import BaseCreatePage from '../../../components/hooks/BaseCreatePage'
import Filter from './Filter'

class ServiceCategory extends Component {
  componentDidMount() {
    const { fetchBank } = this.props
    fetchBank()
  }

  render() {
    const { classes, permissions, filters } = this.props

    return (
      <>
        <BaseCreatePage
          page="Financeiro"
          path="Bancos"
          pageLink="/bank"
          hiddenReturn
        >
          <Grid container spacing="3" style={{ marginTop: '1em' }}>
            <Grid item xs={12}>
              <Filter permissions={permissions} />
            </Grid>
            <Grid item xs={12}>
              <List {...this.props} />
            </Grid>
          </Grid>
        </BaseCreatePage>

        {renderRoute(['create-bank'], permissions) && (
          <Route path="/bank/new" component={New} />
        )}
        {renderRoute(['update-bank'], permissions) && (
          <Route path="/bank/:id/edit" component={Edit} />
        )}
      </>
    )
  }
}

const mapStateToProps = state => ({
  ...getBankListData(state),
  filters: state.bank.filters,
  permissions: state.auth.permissions || [],
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchBank,
    },
    dispatch
  )
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ServiceCategory))
