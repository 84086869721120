/* eslint-disable react/prop-types */
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Popover from '@material-ui/core/Popover'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import FilterListIcon from '@material-ui/icons/FilterList'
import SearchIcon from '@material-ui/icons/Search'
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state'
import React, { useEffect, useState } from 'react'
import { ValidatorForm } from 'react-material-ui-form-validator'
import { useDispatch, useSelector } from 'react-redux'

import BadgeGlobal from '../../components/Badge'
import { fetchBillingType } from '../BillingTypeActions'
import BaseFilter from '../../../components/hooks/BaseFilter'
import filterStylesComponents from '../../../assets/filterComponentsStyles'
import Icon from '@mdi/react'
import { mdiFileEdit, mdiMagnifyExpand, mdiMicrosoftExcel, mdiNoteEditOutline } from '@mdi/js'
import { InputAdornment } from '@material-ui/core'
import colors from '../../../assets/colors'
import { renderRoute } from '../../../util/utils'
import { Link } from 'react-router-dom/cjs/react-router-dom'

export default function Filter(props) {
  const items = useSelector(state => state.billingType.items)
  const { permissions } = props

  const dispatch = useDispatch()
  const classes = filterStylesComponents()
  const [form, setForm] = useState({
    name: '',
  })

  const handleSubmit = e => {
    e.preventDefault()
    dispatch(fetchBillingType(form))
  }

  useEffect(() => {
    if (!form?.name) {
      dispatch(fetchBillingType(form))
    }
  }, [form?.name])

  return (
    <BaseFilter>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <TextField
            onChange={e => setForm({ ...form, name: e.target.value })}
            label="Filtre por nome"
            className={classes.inputFilter}
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleSubmit}>
                    <Icon
                      path={mdiMagnifyExpand}
                      size={1}
                      color={colors.graylight}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            value={form.name}
          />
        </Grid>

        <Grid item xs className={classes.areaButtons}>
          {renderRoute(['create-billing-type'], permissions) && (
            <IconButton
              className={classes.btnAdd}
              component={Link}
              to="/billing-type/new"
            >
              <Icon path={mdiNoteEditOutline} size={1} />
              Cadastar
            </IconButton>
          )}
        </Grid>
      </Grid>
    </BaseFilter>
  )
}
