import React from 'react'
import { Forms } from '..'
import { Grid, MenuItem, TextField } from '@material-ui/core'
import ReactInputMask from 'react-input-mask'
import colors from '../../../../../assets/colors'

const FormDocuments = ({ form, validatedForm, handleChange = () => {} }) => {
  return (
    <Forms.root>
      <Grid item xs={12} style={{ color: colors.textPrimary }}>
        <p>
          Algumas prefeituras utilizam usuário e senha para realizar a emissão
          de NFSe.
        </p>
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <TextField
          label="Login"
          name="login_responsavel"
          type="text"
          fullWidth
          required
          value={form.login_responsavel}
          onChange={handleChange}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <TextField
          label="Senha"
          name="senha_responsavel"
          type="password"
          fullWidth
          value={form.senha_responsavel}
          onChange={handleChange}
          required={!form.senha_responsavel_preenchida}
        />
      </Grid>
    </Forms.root>
  )
}

export default FormDocuments
