import React from 'react'
import BaseFilter from '../../../components/hooks/BaseFilter'
import filterStylesComponents from '../../../assets/filterComponentsStyles'
import { Grid, IconButton } from '@material-ui/core'
import { Link } from 'react-router-dom/cjs/react-router-dom'
import { renderRoute } from '../../../util/utils'
import Icon from '@mdi/react'
import { mdiNoteEditOutline } from '@mdi/js'

const Filter = ({ permissions }) => {
  const styles = filterStylesComponents()

  return (
    <BaseFilter>
      <Grid container spacing={3}>
        <Grid item xs={6}></Grid>
        <Grid item xs={6} className={styles.areaButtons}>
          <Grid item>
            {renderRoute(['create-bank'], permissions) && (
              <IconButton
                color="primary"
                variant="contained"
                component={Link}
                to="/bank/new"
                className={styles.btnAdd}
              >
                <Icon path={mdiNoteEditOutline} size={1} />
                Cadastrar
              </IconButton>
            )}
          </Grid>
        </Grid>
      </Grid>
    </BaseFilter>
  )
}

export default Filter
