/* eslint-disable object-shorthand */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/order */
/* eslint-disable eqeqeq */
import React, { useState } from 'react'
import { useEffect } from 'react'
import BaseFilterModal from '../../../../components/hooks/BaseFilterModal'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  Box,
  Button,
  Grid,
  IconButton,
  MenuItem,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  makeStyles,
} from '@material-ui/core'
import {
  StyledTableCellBody,
  StyledTableCellHeader,
} from '../../../../components/hooks/BaseTableComponents'
import Icon from '@mdi/react'
import {
  mdiAlert,
  mdiDelete,
  mdiPackageVariantMinus,
  mdiPackageVariantPlus,
} from '@mdi/js'
import filterStylesComponents from '../../../../assets/filterComponentsStyles'
import colors from '../../../../assets/colors'
import { addDays, format } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { SNACKBAR } from '../../../main/MainActions'
import { storeMovement } from '../../StockActions'
import { useConfirm } from 'material-ui-confirm'
import NumberFormat from '../../../components/NumberFormat'

const useStyles = makeStyles(theme => ({
  missingProduct: {
    color: colors.error,
    gap: '.4em',
  },
  tooltip: {
    backgroundColor: colors.error,
    fontSize: '14px',
    boxShadow: '0px 0px 7px 0px #00000040',
    '& .arrow': {
      color: colors.error,
    },
  },
}))

const Movements = props => {
  const { type } = props.match.params
  const history = useHistory()
  const dispatch = useDispatch()
  const filterStyles = filterStylesComponents()
  const confirm = useConfirm()

  const [movements, setMovements] = useState([])
  const [noHasStock, setNoHasStock] = useState(false)

  const handleOpen = () => {
    history.push('/stock')
  }

  const handleSave = async () => {
    if (!movements?.length) return handleOpen()

    confirm({
      description: 'Deseja concluir o lançamento?',
      title: 'Tem certeza?',
      confirmationText: 'Sim',
      cancellationText: 'Cancelar',
      dialogProps: {
        fullWidth: true,
      },
    }).then(async () => {
      const resp = await dispatch(storeMovement(movements, type))

      if (resp) {
        handleOpen()
      }
    })
  }

  useEffect(() => {
    movements?.map(movement => {
      if (type == 2 && movement?.actualStock < movement?.quantity) {
        setNoHasStock(true)
      } else {
        setNoHasStock(false)
      }
    })
  }, [movements])

  return (
    <BaseFilterModal
      open
      handleOpen={handleOpen}
      title={type == 1 ? 'Informar Entrada' : 'Informar Retirada'}
      hiddenHelpButton
    >
      <Grid item xs={12} style={{ width: '540px' }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <MovementsForm
              movements={movements}
              setMovements={setMovements}
              type={type}
            />
          </Grid>

          <Grid item xs={12}>
            {movements.length != 0 && (
              <MovementsTable
                type={type}
                movements={movements}
                setMovements={setMovements}
              />
            )}
          </Grid>

          {noHasStock && (
            <Grid
              item
              xs={12}
              container
              alignItems="center"
              justifyContent="center"
            >
              <Box
                style={{
                  width: '60%',
                  fontSize: '14px',
                  backgroundColor: colors.bgError,
                  display: 'flex',
                  gap: '4px',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '5px',
                }}
              >
                <Icon path={mdiAlert} color={colors.error} size={1} />
                <b>Produto em falta!</b>
                <p>Altere a quantidade em retirada</p>
              </Box>
            </Grid>
          )}

          <Grid
            item
            xs={12}
            container
            alignItems="center"
            justifyContent="center"
          >
            <Button className={filterStyles.btnSalvar} onClick={handleSave}>
              Salvar
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </BaseFilterModal>
  )
}

const MovementsForm = ({ movements, setMovements, type = 1 }) => {
  const products = useSelector(state => state.stock.products)
  const technicals = useSelector(state => state.stock.technicals)
  const dispatch = useDispatch()

  const today = () => {
    const date = new Date()

    return format(date, 'yyyy-MM-dd', { locale: ptBR })
  }

  const [form, setForm] = useState({
    id: '',
    product_id: '',
    productName: '',
    actualStock: '',
    quantity: '',
    date: today(),
  })

  const handleChange = e => {
    e.preventDefault()

    const { name, value } = e.target

    setForm({
      ...form,
      [name]: value,
    })
  }

  const handleAddMovement = e => {
    e.preventDefault()

    if (movements?.length > 4) {
      return dispatch({
        type: SNACKBAR.HARDFAIL,
        error: { message: 'Número máximo de 5 lançamentos.' },
      })
    }

    setMovements(prevState => [...prevState, form])
  }

  useEffect(() => {
    if (form.product_id) {
      const product = products?.find(product => product?.id == form?.product_id)

      if (product) {
        setForm({
          ...form,
          productName: product?.name,
          productAllowFraction: product?.allow_fraction,
          actualStock: product?.actual_stock,
        })
      }
    }
  }, [form.product_id])

  useEffect(() => {
    if (form.technical_id) {
      const technical = technicals?.find(
        techinical => techinical?.id == form?.technical_id
      )

      if (technical) {
        setForm({
          ...form,
          technicalName: technical?.name,
        })
      }
    }
  }, [form.technical_id])

  useEffect(() => {
    setForm({
      ...form,
      id: movements.length,
    })
  }, [movements])

  return (
    <form onSubmit={handleAddMovement} style={{ width: '100%' }}>
      <Grid container spacing={2}>
        {type == 2 && (
          <Grid item xs={4}>
            <TextField
              name="technical_id"
              value={form?.technical_id}
              onChange={handleChange}
              select
              required
              fullWidth
              label="Técnico"
            >
              <MenuItem value="">Selecione...</MenuItem>
              {technicals?.map(product => (
                <MenuItem value={product?.id}>{product?.name}</MenuItem>
              ))}
            </TextField>
          </Grid>
        )}

        <Grid item xs={type == 1 ? 5 : 3}>
          <TextField
            name="product_id"
            value={form.product_id}
            onChange={handleChange}
            select
            required
            fullWidth
            label="Produto"
          >
            <MenuItem value="">Selecione...</MenuItem>
            {products?.map(product => (
              <MenuItem value={product?.id}>{product?.name}</MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={type == 1 ? 2 : 2}>
          {form?.productAllowFraction ? (
            <NumberFormat
              label="Quantidade"
              value={form.quantity}
              onChange={e => {
                const { value } = e.target

                setForm({
                  ...form,
                  quantity: value,
                })
              }}
              decimalScale={2}
              decimalSeparator=","
              customInput={TextField}
              thousandSeparator="."
              displayType="input"
              required
              fullWidth
              type="text"
            />
          ) : (
            <TextField
              name="quantity"
              type="number"
              value={form.quantity}
              onChange={handleChange}
              fullWidth
              inputProps={{ min: '1' }}
              required
              label="Quantidade"
            />
          )}
        </Grid>

        <Grid item xs={type == 1 ? 3 : 2}>
          <TextField
            name="date"
            type="date"
            value={form.date}
            onChange={handleChange}
            required
            fullWidth
            label="Data"
          />
        </Grid>

        <Grid
          item
          xs={1}
          container
          justifyContent="flex-start"
          alignItems="flex-end"
        >
          <IconButton style={{ padding: 0, margin: 0 }} type="submit">
            <Icon
              path={type == 1 ? mdiPackageVariantPlus : mdiPackageVariantMinus}
              size={1}
              color={colors.primary}
            />
          </IconButton>
        </Grid>
      </Grid>
    </form>
  )
}

const MovementsTable = ({ movements = [], type = 1, setMovements }) => {
  const classes = useStyles()

  const renderDate = date => {
    const currentDate = new Date(date)

    if (currentDate) {
      return format(addDays(currentDate, 1), 'dd/MM/yyyy', { locale: ptBR })
    }

    return date
  }

  const handleRemove = id => {
    const movementsWithoutRemoved = movements?.filter(
      movement => movement?.id !== id
    )

    setMovements(movementsWithoutRemoved)
  }

  const handleChange = (e, id) => {
    const { value } = e.target

    const movement = movements?.find(movement => movement?.id == id)

    const movementsWithoutRemoved = movements?.filter(
      movement => movement?.id !== id
    )

    if (movement) {
      let quantity = value

      if (!movement?.productAllowFraction) {
        quantity = Math.round(quantity)
      }

      const newMovement = {
        ...movement,
        quantity: quantity || 1,
      }

      movementsWithoutRemoved.push(newMovement)

      setMovements(movementsWithoutRemoved || [])
    }
  }

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <StyledTableCellHeader align="left">Data</StyledTableCellHeader>
            {type == 2 && (
              <StyledTableCellHeader>Técnico</StyledTableCellHeader>
            )}
            <StyledTableCellHeader>Produto</StyledTableCellHeader>
            <StyledTableCellHeader>Em estoque</StyledTableCellHeader>
            <StyledTableCellHeader>
              {type == 1 ? 'Inserido' : 'Retirado'}
            </StyledTableCellHeader>
            <StyledTableCellHeader align="right">Ações</StyledTableCellHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {movements?.map(item => (
            <TableRow key={item?.id}>
              <StyledTableCellBody align="left">
                {renderDate(item?.date)}
              </StyledTableCellBody>
              {type == 2 && (
                <StyledTableCellBody>{item?.technicalName}</StyledTableCellBody>
              )}
              <StyledTableCellBody>{item?.productName}</StyledTableCellBody>
              <StyledTableCellBody>
                {item?.actualStock || 0}
              </StyledTableCellBody>
              <StyledTableCellBody>
                <Grid
                  container
                  alignItems="flex-end"
                  justifyContent="center"
                  className={
                    item?.actualStock < item?.quantity && classes.missingProduct
                  }
                  style={{ maxWidth: '100px' }}
                >
                  <Grid item xs={5}>
                    {item?.productAllowFraction ? (
                      <NumberFormat
                        value={item.quantity}
                        onChange={e => handleChange(e, item?.id)}
                        decimalScale={2}
                        decimalSeparator=","
                        customInput={TextField}
                        thousandSeparator="."
                        displayType="input"
                        required
                        fullWidth
                        type="text"
                        InputProps={{
                          style: {
                            color:
                              type == 2 &&
                              item?.actualStock < item?.quantity &&
                              colors.error,
                          },
                        }}
                      />
                    ) : (
                      <TextField
                        type="number"
                        inputProps={{ min: '1' }}
                        defaultValue={item?.quantity}
                        fullWidth
                        onChange={e => handleChange(e, item?.id)}
                        InputProps={{
                          style: {
                            color:
                              type == 2 &&
                              item?.actualStock < item?.quantity &&
                              colors.error,
                          },
                        }}
                      />
                    )}
                  </Grid>

                  <Grid item xs={3}>
                    {type == 2 && item?.actualStock < item?.quantity && (
                      <Tooltip
                        title="Sem estoque"
                        placement="right-start"
                        arrow
                        classes={{
                          tooltip: classes.tooltip,
                          arrow: 'arrow',
                        }}
                      >
                        <span>
                          <Grid item>
                            <Icon
                              path={mdiAlert}
                              size={1}
                              color={colors.info}
                            />
                          </Grid>
                        </span>
                      </Tooltip>
                    )}
                  </Grid>
                </Grid>
              </StyledTableCellBody>
              <StyledTableCellBody align="right">
                <IconButton onClick={() => handleRemove(item?.id)}>
                  <Icon path={mdiDelete} size={1} />
                </IconButton>
              </StyledTableCellBody>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default Movements
