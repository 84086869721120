import axios from 'axios/index'

import config from '../../config'
import action, { actionFailed } from '../../util/action'
import consts from '../../util/consts'
import createAsyncConst from '../../util/createAsyncConst'

export const SUBMIT_LAUNDRY_CLIENT = createAsyncConst('SUBMIT_LAUNDRY_CLIENT')
export const FETCH_LAUNDRY_CLIENT = createAsyncConst('FETCH_LAUNDRY_CLIENT')
export const EDIT_LAUNDRY_CLIENT = createAsyncConst('EDIT_LAUNDRY_CLIENT')
export const FETCH_CEP = createAsyncConst('FETCH_CEP')

export function fetchLaundryClients(page, filters = [], rows) {
  return dispatch => {
    dispatch(action(FETCH_LAUNDRY_CLIENT.ACTION))
    return axios
      .get(`${config.API_URL}/laundryClient`, {
        params: {
          filters: JSON.stringify(filters),
          rows: rows || consts.DEFAULT_PAGINATION,
          page: page || 1,
        },
      })
      .then(resp => {
        dispatch(action(FETCH_LAUNDRY_CLIENT.SUCCESS, resp.data))
        return resp
      })
      .catch(e => dispatch(actionFailed(FETCH_LAUNDRY_CLIENT, e)))
  }
}

export function edit(id) {
  return dispatch => {
    dispatch(action(EDIT_LAUNDRY_CLIENT.ACTION, { id }))
    return axios
      .get(`${config.API_URL}/laundryClient/${id}`)
      .then(resp => {
        return resp.data.data
      })
      .then(data => dispatch(action(EDIT_LAUNDRY_CLIENT.SUCCESS, data)))
      .catch(e => dispatch(actionFailed(EDIT_LAUNDRY_CLIENT, e)))
  }
}

function submit(values, method) {
  const id = values.id ? `/${values.id}` : ''
  return dispatch => {
    dispatch(action(SUBMIT_LAUNDRY_CLIENT.ACTION))
    var formData = new FormData()
    values.status = values.status.toString()
    Object.entries(values).forEach(entry => {
      entry[0] == 'logo'
        ? formData.append('logo', values.logo)
        : formData.append(entry[0], entry[1])
    })
    method == 'put' ? formData.append('_method', 'PUT') : ''
    return axios['post'](`${config.API_URL}/laundryClient${id}`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then(resp => resp.data)
      .then(data => {
        dispatch(action(SUBMIT_LAUNDRY_CLIENT.SUCCESS, data))
        dispatch(fetchLaundryClients())
        return data
      })
      .catch(e => dispatch(actionFailed(SUBMIT_LAUNDRY_CLIENT, e)))
  }
}

export function post(values) {
  return submit(values, 'post')
}

export function update(values) {
  return submit(values, 'put')
}

export function findCep(cep) {
  return dispatch => {
    dispatch(action(FETCH_CEP.ACTION))
    return axios
      .get(`${config.API_URL}/buscaCep/${cep}`)
      .then(resp => {
        dispatch(action(FETCH_CEP.SUCCESS, resp.data))
        return resp.data
      })
      .catch(e => dispatch(actionFailed(FETCH_CEP, e)))
  }
}
