import React from 'react'
import { Forms } from '..'
import { Grid, MenuItem, TextField } from '@material-ui/core'
import ReactInputMask from 'react-input-mask'

const FormIdentification = ({
  form,
  validatedForm,
  handleChange = () => {},
}) => {
  return (
    <Forms.root>
      <Grid item xs={12} sm={6} md={3}>
        <TextField
          label="Nome Fantasia"
          name="nome_fantasia"
          type="text"
          fullWidth
          required
          value={form?.nome_fantasia}
          onChange={handleChange}
          error={validatedForm?.nome_fantasia}
          helperText={validatedForm?.nome_fantasia}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <TextField
          label="Inscrição Estadual"
          name="inscricao_estadual"
          type="text"
          fullWidth
          value={form?.inscricao_estadual}
          onChange={handleChange}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <ReactInputMask
          mask="99999999999"
          name="inscricao_municipal"
          maskChar=""
          value={form?.inscricao_municipal}
          onChange={handleChange}
        >
          {inputProps => (
            <TextField
              {...inputProps}
              fullWidth
              required
              label="Inscrição Municipal"
              error={validatedForm?.inscricao_municipal}
              helperText={validatedForm?.inscricao_municipal}
            />
          )}
        </ReactInputMask>
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <TextField
          label="Regime Tributario"
          name="regime_tributario"
          type="text"
          fullWidth
          required
          select
          value={form?.regime_tributario}
          onChange={handleChange}
          error={validatedForm?.regime_tributario}
          helperText={validatedForm?.regime_tributario}
        >
          <MenuItem value="">Selecione...</MenuItem>
          <MenuItem value="1">Simples Nacional</MenuItem>
          <MenuItem value="2">
            Simples Nacional - Excesso de sublime de receita bruta
          </MenuItem>
          <MenuItem value="3">Regime Normal</MenuItem>
        </TextField>
      </Grid>
    </Forms.root>
  )
}

export default FormIdentification
