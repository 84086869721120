/* eslint-disable import/order */
// import 'isomorphic-fetch';
import React from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import CircularProgress from '@material-ui/core/CircularProgress'
import axios from 'axios'

import config from '../../config'
import { IconButton, InputAdornment, withStyles } from '@material-ui/core'
import colors from '../../assets/colors'
import { mdiMagnifyExpand } from '@mdi/js'
import Icon from '@mdi/react'

const CssTextField = withStyles({
  root: {
    color: colors.textGray,
    fontWeight: 700,
    borderColor: colors.graylight,
    '& .MuiInputBase-input': {
      padding: '14px 0 7px',
      borderColor: colors.graylight,
    },
    '& label.Mui-focused': {
      color: 'inherit',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: colors.graylight,
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: colors.graylight,
    },
    '& .MuiOutlinedInput-root': {
      borderColor: colors.graylight,
      '&.Mui-focused fieldset': {
        borderColor: colors.graylight,
      },
    },
    '& .MuiInputLabel-formControl': {
      top: '-4px',
    },
  },
})(TextField)

export default function Asynchronous(props) {
  const {
    label,
    link,
    setForm,
    name,
    data,
    uniqueState,
    disabled,
    variant,
    margin,
    onClickButton = '',
    disabledButton = '',
  } = props
  const [open, setOpen] = React.useState(false)
  const [options, setOptions] = React.useState([])
  const [typed, setTyped] = React.useState('')
  // const loading = open && options.length === 0
  const [loading, setLoading] = React.useState(false)
  let timeout = 0

  function setTypeFunction(e, value) {
    if (timeout) clearTimeout(timeout)
    timeout = setTimeout(() => {
      setTyped(value)
    }, 500)
  }

  React.useEffect(() => {
    let active = true

    // if (!loading) {
    //   return undefined
    // }

    if (typed.length >= 3) {
      ;(async () => {
        setLoading(true)
        axios
          .get(`${config.API_URL}/${link}`, {
            params: {
              filter: JSON.stringify(typed),
            },
          })
          .then(resp => {
            if (active) setOptions(resp.data.data)
            setLoading(false)
          })
          .catch(() => setLoading(false))
      })()
    }

    return () => {
      active = false
    }
  }, [typed])

  React.useEffect(() => {
    if (!open) {
      setOptions([])
    }
  }, [open])

  return (
    <Autocomplete
      id={label}
      open={open}
      onOpen={() => {
        setOpen(true)
      }}
      onClose={() => {
        setOpen(false)
      }}
      disabled={disabled}
      onInputChange={(e, value) => setTypeFunction(e, value)}
      getOptionSelected={(option, value) => option.name === value.name}
      getOptionLabel={option =>
        typeof option === 'string' ? option : option.name
      }
      onChange={(action, value) =>
        uniqueState ? setForm(value) : setForm(name, value)
      }
      loadingText="Digite o termo de busca"
      noOptionsText="Sem opções"
      options={options}
      loading={loading}
      value={data}
      renderInput={params => (
        <CssTextField
          {...params}
          label={label || ''}
          fullWidth
          variant={variant || 'standard'}
          margin={margin || 'none'}
          InputProps={{
            ...params.InputProps,
            style: {
              padding: 0,
            },
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={onClickButton} disabled={disabledButton}>
                  <Icon
                    path={mdiMagnifyExpand}
                    size={1}
                    color={colors.graylight}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  )
}
