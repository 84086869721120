import axios from 'axios/index'

import config from '../../config'
import action, { actionFailed } from '../../util/action'
import createAsyncConst from '../../util/createAsyncConst'

export const REMOVE_SERVICE_CATEGORY = createAsyncConst(
  'REMOVE_SERVICE_CATEGORY'
)
export const SUBMIT_SERVICE_CATEGORY = createAsyncConst(
  'SUBMIT_SERVICE_CATEGORY'
)
export const FETCH_SERVICE_CATEGORY = createAsyncConst('FETCH_SERVICE_CATEGORY')
export const EDIT_SERVICE_CATEGORY = createAsyncConst('EDIT_SERVICE_CATEGORY')

export function fetchServiceCategory(filters = []) {
  return dispatch => {
    dispatch(action(FETCH_SERVICE_CATEGORY.ACTION))
    return axios
      .get(`${config.API_URL}/service-category`, {
        params: {
          filters: JSON.stringify(filters),
        },
      })
      .then(resp => {
        dispatch(action(FETCH_SERVICE_CATEGORY.SUCCESS, resp.data))
        return resp
      })
      .catch(e => dispatch(actionFailed(FETCH_SERVICE_CATEGORY, e)))
  }
}

export async function show(id) {
  const resp = await axios.get(`${config.API_URL}/service-category/${id}`)

  return resp?.data
}

export function edit(id) {
  return dispatch => {
    dispatch(action(EDIT_SERVICE_CATEGORY.ACTION, { id }))
    return axios
      .get(`${config.API_URL}/service-category/${id}`)
      .then(resp => {
        return resp.data.data
      })
      .then(data => dispatch(action(EDIT_SERVICE_CATEGORY.SUCCESS, data)))
      .catch(e => dispatch(actionFailed(EDIT_SERVICE_CATEGORY, e)))
  }
}

function submit(values, method) {
  const id = values.id ? `/${values.id}` : ''

  return dispatch => {
    dispatch(action(SUBMIT_SERVICE_CATEGORY.ACTION))
    return axios[method](`${config.API_URL}/service-category${id}`, values)
      .then(resp => resp.data)
      .then(data => {
        dispatch(action(SUBMIT_SERVICE_CATEGORY.SUCCESS, data))
        dispatch(fetchServiceCategory())
        return data
      })
      .catch(e => dispatch(actionFailed(SUBMIT_SERVICE_CATEGORY, e)))
  }
}

export function post(values) {
  return submit(values, 'post')
}

export function update(values) {
  return submit(values, 'put')
}

export function remove(id) {
  return dispatch => {
    dispatch(action(REMOVE_SERVICE_CATEGORY.ACTION))
    return axios
      .delete(`${config.API_URL}/service-category/${id}`)
      .then(resp => resp.data)
      .then(data => {
        dispatch(action(REMOVE_SERVICE_CATEGORY.SUCCESS, data))
        dispatch(fetchServiceCategory())
      })
      .catch(e => dispatch(actionFailed(REMOVE_SERVICE_CATEGORY, e)))
  }
}
