import React from 'react'
import {
  usePopupState,
  bindTrigger,
  bindPopover,
} from 'material-ui-popup-state/hooks'
import { IconButton, Popover, withStyles } from '@material-ui/core'
import { GrDocumentPdf, GrDocumentWord } from 'react-icons/gr'
import { useConfirm } from 'material-ui-confirm'
import { useDispatch } from 'react-redux'
import { FaFileWord } from 'react-icons/fa'

import { downloadOrcamento } from '../AttendanceActions'

const styles = theme => ({
  container: {
    margin: theme.spacing.unit * 2,
  },
})

const PopoverPopupState = ({ classes, id }) => {
  const confirm = useConfirm()
  const dispatch = useDispatch()

  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'demoPopover',
  })


  return (
    <span>
      <IconButton {...bindTrigger(popupState)}>
        <FaFileWord />
      </IconButton>
      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className={classes.container}>
          <IconButton
            onClick={() => {
              confirmDownloadOrcamento('docx')
              popupState.close()
            }}
          >
            <GrDocumentWord />
          </IconButton>
          <IconButton
            onClick={() => {
              confirmDownloadOrcamento('pdf')
              popupState.close()
            }}
          >
            <GrDocumentPdf />
          </IconButton>
        </div>
      </Popover>
    </span>
  )
}

export default withStyles(styles)(PopoverPopupState)
