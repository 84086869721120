/* eslint-disable import/order */
import AppBar from '@material-ui/core/AppBar'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import withStyles from '@material-ui/core/styles/withStyles'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styles from '../../../resources/theme/users'
import Transiction from '../../components/Transition'
import { edit, update } from '../PaymentTypeActions'
import Form from './Form'
import BaseFilterModal from '../../../components/hooks/BaseFilterModal'
import { Grid } from '@material-ui/core'

const Transition = React.forwardRef((props, ref) => {
  return <Transiction propis={props} refi={ref} />
})

function EditPayment(props) {
  const { classes } = props
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(edit(props.match.params.id))
  }, [])

  const payment = useSelector(state => state.paymentMethod.editingPaymentMethod)

  const onSubmit = values => {
    dispatch(update(values)).then(data => {
      if (data && data.code === 200) {
        props.history.push('/payment-method')
      }
    })
  }

  const onClose = () => {
    props.history.push('/payment-method')
  }

  return (
    <BaseFilterModal
      open
      handleOpen={onClose}
      title="Editar método de pagamento"
      link=""
    >
      <Grid item container xs={12} style={{ width: '380px' }}>
        <Grid item xs={12} style={{ fontSize: '16px' }}>
          Utilize os campos abaixo para editar
          <br /> um método de pagamento
        </Grid>

        <Grid item xs={12} container style={{ marginTop: '1.5em' }}>
          <Form data={payment} onClose={onClose} onSubmit={onSubmit} />
        </Grid>
      </Grid>
    </BaseFilterModal>
  )
}

export default withStyles(styles)(EditPayment)
