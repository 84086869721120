/* eslint-disable import/order */
/* eslint-disable import/extensions */
import AppBar from '@material-ui/core/AppBar'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import withStyles from '@material-ui/core/styles/withStyles'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import React from 'react'
import { useDispatch } from 'react-redux'

import styles from '../../../resources/theme/global'
import Transiction from '../../components/Transition'
import { post } from '../ServiceTypeActions'
import Form from './Form'
import BaseFilterModal from '../../../components/hooks/BaseFilterModal'
import { Grid } from '@material-ui/core'

const Transition = React.forwardRef((props, ref) => {
  return <Transiction propis={props} refi={ref} />
})

function NewClient(props) {
  const { classes } = props
  const dispatch = useDispatch()

  const onSubmit = values => {
    dispatch(post(values)).then(data => {
      if (data && data.code === 200) {
        props.history.push('/service-type')
      }
    })
  }

  const onClose = () => {
    props.history.push('/service-type')
  }

  return (
    <BaseFilterModal
      open
      handleOpen={onClose}
      title="Novo tipo de serviço"
      hiddenHelpButton
    >
      <Grid item container xs={12} style={{ width: '600px' }}>
        <Grid item container xs={12} style={{ marginTop: '.5em' }}>
          <Form classes={classes} onClose={onClose} onSubmit={onSubmit} />
        </Grid>
      </Grid>
    </BaseFilterModal>
  )
}

export default withStyles(styles)(NewClient)
