/* eslint-disable camelcase */
import {
  Checkbox,
  DialogActions,
  FormControlLabel,
  Switch,
  TextField,
} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import DialogContent from '@material-ui/core/DialogContent'
import Grid from '@material-ui/core/Grid'
import InputMask from 'react-input-mask'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'
import { useDispatch } from 'react-redux'
import ThumbDownAltOutlinedIcon from '@material-ui/icons/ThumbDownAltOutlined'
import ThumbUpAltOutlinedIcon from '@material-ui/icons/ThumbUp'
import React, { useEffect, useState } from 'react'

import { findCep } from '../SupplierActions'
import filterStylesComponents from '../../../assets/filterComponentsStyles'
import NumberFormat from 'react-number-format'
import { parseValue } from '../../../util/utils'

function Form(props) {
  const { data, onClose, onSubmit, notShowPost, children } = props
  const dispatch = useDispatch()

  const [form, updateForm] = useState({
    id: '',
    name: '',
    description: '',
    status: 1,
    cep: '',
    street: '',
    complement: '',
    number: '',
    city: '',
    uf: '',
    neighborhood: '',
    phone: '',
    email: '',
    cpf_cnpj: '',
    commission_percent: null,
  })

  const [error, setError] = useState({
    commissionPercent: {
      error: false,
      message: '',
    },
  })

  const updateField = React.useCallback(name => e => {
    if (name === 'commission_percent') {
      return updateForm({
        ...form,
        [name]: parseValue(e.target.value),
      })
    }

    updateForm({
      ...form,
      [name]: e.target.value,
    })
  })

  const styles = filterStylesComponents()

  const handleClose = () => onClose && onClose()

  const onSubmitForm = e => {
    e.preventDefault()

    if (
      form?.commission_percent &&
      (form.commission_percent < 0.01 || form.commission_percent > 100)
    ) {
      return setError({
        ...error,
        commissionPercent: {
          error: true,
          message: 'O valor deve estar entre 0,01 até 100',
        },
      })
    }

    const send = { ...form, status: form.status || 0 }
    onSubmit && onSubmit(send)
  }

  useEffect(() => {
    updateForm({ ...form, ...data })
  }, [data])

  const onSubmitAndRedirect = e => {
    e.preventDefault()
    onSubmit && onSubmit(form, true)
  }

  const buscaCEP = () => {
    const cep = form.cep?.replace(/[^\d]/g, '')
    cep &&
      dispatch(findCep(cep)).then(resp => {
        if (resp && resp.code === 200) {
          const { items } = resp.data
          if (items) {
            updateForm({
              ...form,
              neighborhood: items?.bairro,
              city: items?.cidade,
              uf: items?.uf,
              street: items?.logradouro,
            })
          }
        }
      })
  }

  useEffect(() => {
    if (error.commissionPercent.error) {
      setError({
        ...error,
        commissionPercent: {
          error: false,
          message: '',
        },
      })
    }
  }, [form.commission_percent])

  return (
    <>
      <ValidatorForm>
        <Grid container spacing={2}>
          <Grid item sm={4} xs={12}>
            <TextValidator
              validators={['required']}
              errorMessages={['Campo Obrigatório']}
              onChange={updateField('name')}
              label="Nome"
              fullWidth
              value={form.name}
              required
            />
          </Grid>
          <Grid item sm={4} xs={12}>
            <TextValidator
              validators={['isEmail']}
              errorMessages={['O campo não representa um email válido']}
              onChange={updateField('email')}
              label="E-mail"
              fullWidth
              value={form.email}
            />
          </Grid>
          <Grid item sm={4} xs={12}>
            <InputMask
              mask={
                form.phone?.replace(/[^\d]/g, '').length <= 10
                  ? '(99) 9999-9999?'
                  : '(99) 99999-9999'
              }
              formatChars={{ 9: '[0-9]', '?': '[0-9 ]' }}
              maskChar=""
              value={form.phone}
              onChange={e => {
                updateForm({
                  ...form,
                  phone: e.target.value.replace(/[^\d]/g, ''),
                })
              }}
            >
              {inputProps => (
                <TextValidator
                  {...inputProps}
                  label="Telefone"
                  fullWidth
                  validators={[
                    'matchRegexp:^\\(?[0-9]{2,3}\\)? ?[0-9]{4,5}-?[0-9]{4}$',
                  ]}
                  errorMessages={['Telefone inválido']}
                />
              )}
            </InputMask>
          </Grid>
          <Grid item sm={3} xs={12}>
            <InputMask
              mask="99.999-999"
              maskChar=""
              value={form.cep}
              onChange={updateField('cep')}
              onBlur={() => buscaCEP()}
            >
              {inputProps => (
                <TextField {...inputProps} label="CEP" fullWidth />
              )}
            </InputMask>
          </Grid>
          <Grid item sm={6} xs={12}>
            <TextField
              onChange={updateField('street')}
              label="Endereço"
              fullWidth
              value={form.street}
            />
          </Grid>
          <Grid item sm={3} xs={12}>
            <TextField
              onChange={updateField('number')}
              label="Número"
              fullWidth
              value={form.number}
            />
          </Grid>
          <Grid item sm={3} xs={12}>
            <TextField
              onChange={updateField('neighborhood')}
              label="Bairro"
              fullWidth
              value={form.neighborhood}
            />
          </Grid>
          <Grid item sm={3} xs={12}>
            <TextField
              onChange={updateField('complement')}
              label="Complemento"
              fullWidth
              value={form.complement}
            />
          </Grid>
          <Grid item sm={3} xs={12}>
            <TextField
              onChange={updateField('city')}
              label="Cidade"
              fullWidth
              value={form.city}
            />
          </Grid>
          <Grid item sm={3} xs={12}>
            <TextField
              onChange={updateField('uf')}
              label="UF"
              fullWidth
              value={form.uf}
            />
          </Grid>

          <Grid item sm={3} xs={12}>
            <InputMask
              mask={
                form.cpf_cnpj?.replace(/[^\d]/g, '').length <= 11
                  ? '999.999.999-99?'
                  : '99.999.999/9999-99'
              }
              maskChar=""
              formatChars={{ 9: '[0-9]', '?': '[0-9 ]' }}
              value={form.cpf_cnpj}
              onChange={updateField('cpf_cnpj')}
            >
              {inputProps => (
                <TextValidator
                  {...inputProps}
                  validators={[
                    'matchRegexp:^([0-9]{2}[.]?[0-9]{3}[.]?[0-9]{3}[/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[.]?[0-9]{3}[.]?[0-9]{3}[-]?[0-9]{2})$',
                  ]}
                  errorMessages={['CPF/CNPJ Inválido']}
                  label="CPF/CNPJ"
                  fullWidth
                />
              )}
            </InputMask>
          </Grid>

          <Grid item sm={6} xs={12} justifyContent="center">
            <NumberFormat
              value={form.commission_percent}
              onChange={updateField('commission_percent')}
              endAdornment="%"
              decimalScale={2}
              label="Porcentagem de Comissão"
              decimalSeparator=","
              thousandSeparator="."
              customInput={TextField}
              displayType="input"
              InputProps={{
                endAdornment: '%',
              }}
              fullWidth
              error={error.commissionPercent.error}
              helperText={error.commissionPercent.message || ''}
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <Grid container justifyContent="flex-end" alignItems="center">
              <Switch
                checked={Boolean(form.status)}
                onChange={() =>
                  updateForm({
                    ...form,
                    status: form.status === 1 ? 0 : 1,
                  })
                }
              />
              Ativo
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} style={{ marginTop: '20px' }}>
          <TextField
            placeholder="Descrição"
            value={form.description}
            onChange={updateField('description')}
            variant="outlined"
            multiline
            fullWidth
            minRows={3}
            maxRows={5}
          />
        </Grid>

        <Grid
          item
          xs={12}
          container
          justifyContent="center"
          alignItems="center"
          style={{ marginTop: '1em' }}
        >
          <Button onClick={onSubmitForm} className={styles.btnSalvar}>
            Salvar
          </Button>

          {notShowPost && (
            <Button
              type="button"
              onClick={onSubmitAndRedirect}
              className={styles.btnSalvar}
            >
              Salvar e agendar
            </Button>
          )}
        </Grid>
      </ValidatorForm>
    </>
  )
}

export default Form
