import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import PencilIcon from '@material-ui/icons/Edit'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import consts from '../../../util/consts'
import Paginate from '../../components/Pagination'
import { fetchLaundryClients, getFile } from '../LaundryClientBillingsActions'
import { dateFormatedPT, exportFormatedMoney } from '../../../util/utils';
import ReceiptIcon from '@material-ui/icons/Receipt';
import { format } from 'date-fns';


function List(props) {
  const dispatch = useDispatch()
  const filters = useSelector(state => state.client.filters)
  const [rowsPerPage, setRowsPerPage] = useState(consts.DEFAULT_PAGINATION)
  const { classes, items, totalSize, page } = props

  const onMovePage = (event, newPage) => {
    dispatch(fetchLaundryClients(newPage + 1, filters, rowsPerPage))
  }

  useEffect(() => {
    onMovePage()
  }, [rowsPerPage])

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value)
  }

  const fetchFile = async (id) => {
    try {
      const resp = await getFile(id)
      console.log(resp.data)
      const url = window.URL.createObjectURL(new Blob([resp.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `Recibo_${id}.jpg`)
      document.body.appendChild(link)
      link.click();
    } catch (err) {
      toast.warning("Erro ao fazer download")
    }
  }

  return (
    <Paper>
      <TableContainer className={classes.tableContainer}>
        <Table size="small" className={classes.tableStyle}>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Cliente</TableCell>
              <TableCell>Referência</TableCell>
              <TableCell>Vencimento</TableCell>
              <TableCell>Valor</TableCell>
              <TableCell>Data Pgto. Pago</TableCell>
              <TableCell>Pago</TableCell>
              {/* <TableCell>Ações</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {items?.map(item => (
              <TableRow key={item.id}>
                <TableCell>{item.id}</TableCell>
                <TableCell>{item.laundry_client?.name}</TableCell>
                <TableCell>{dateFormatedPT(item.reference_date).slice(3)}</TableCell>
                <TableCell>{dateFormatedPT(item.due_date)}</TableCell>
                <TableCell>{exportFormatedMoney(item.value)}</TableCell>
                <TableCell>{item.payment_date ? dateFormatedPT(item.payment_date) : ""}</TableCell>
                <TableCell>{item.is_payed == 1? 'Sim': 'Não'}</TableCell>
                {/* <TableCell>
                  <IconButton component={Link} to={`/laundry-client-billings/${item.id}/edit`}>
                    <PencilIcon fontSize="small" />
                  </IconButton>
                {item.document_id && <IconButton onClick={() => fetchFile(item.id)}>
                    <ReceiptIcon fontSize="small" />
                  </IconButton>
                  }

                </TableCell> */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Paginate
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        rowsPerPage={rowsPerPage}
        rows={totalSize}
        page={page - 1}
        handleChangePage={onMovePage}
      />
    </Paper>
  )
}

export default List
