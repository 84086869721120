import React, { useState } from 'react'
import {
  Button,
  Grid,
  IconButton,
  MenuItem,
  TextField,
} from '@material-ui/core'
import { Link } from 'react-router-dom/cjs/react-router-dom'
import Icon from '@mdi/react'
import { mdiFilterMultiple, mdiMagnify, mdiNoteEditOutline } from '@mdi/js'
import filterStylesComponents from '../../../../assets/filterComponentsStyles'
import BaseFilter from '../../../../components/hooks/BaseFilter'
import { renderRoute, renderRouteRole } from '../../../../util/utils'
import colors from '../../../../assets/colors'
import { useSelector } from 'react-redux'

const Filter = ({ form, setForm }) => {
  const triggerTypes = useSelector(state => state.triggers.triggerTypes)
  const role = useSelector(state => state.auth.role)

  const styles = filterStylesComponents()

  const [currentFilters, setCurrentFilters] = useState({
    reason: '',
    type: '',
  })

  const handleChange = e => {
    e.preventDefault()

    const { name, value } = e.target

    setCurrentFilters({
      ...currentFilters,
      [name]: value,
    })
  }

  const hanldeSubmit = () => {
    setForm({
      ...form,
      ...currentFilters,
    })
  }

  return (
    <BaseFilter>
      <Grid container spacing={3}>
        <Grid item xs={9}>
          <Grid container spacing={2}>
            <Grid item sm={4} md={3}>
              <TextField
                type="text"
                name="reason"
                variant="standard"
                fullWidth
                onChange={handleChange}
                value={currentFilters.reason}
                label="Filtre por Motivo"
              />
            </Grid>
            <Grid item sm={4} md={2}>
              <TextField
                select
                name="type"
                onChange={handleChange}
                variant="standard"
                fullWidth
                label="Filtre por tipo"
                value={currentFilters.type}
              >
                <MenuItem value="">Selecione...</MenuItem>

                {triggerTypes?.map((trigger, index) => (
                  <MenuItem value={trigger?.var_id} key={index}>
                    {trigger?.value}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs container alignItems="flex-end">
              <Button className={styles.btnMoreFilters} onClick={hanldeSubmit}>
                <Icon
                  path={mdiFilterMultiple}
                  size={1}
                  color={colors.secondary}
                />
                {` Filtrar`}
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs className={styles.areaButtons}>
          {renderRouteRole(['gerente_lavanderia'], role) && (
            <Grid item>
              <IconButton
                color="primary"
                variant="contained"
                component={Link}
                to="/crm/triggers/create-edit/new"
                className={styles.btnAdd}
              >
                <Icon path={mdiNoteEditOutline} size={1} />
                Cadastrar
              </IconButton>
            </Grid>
          )}
        </Grid>
      </Grid>
    </BaseFilter>
  )
}

export default Filter
