import React from 'react'

import { remove } from '../BillingTypeActions'
import GlobalList from '../../components/GlobalList'

function createData(id, name, type, status, is_client_service) {
  return {
    id,
    name,
    type,
    status,
    update: 'update-billing-type',
    delete: 'delete-billing-type',
    link_update: `/billing-type/${id}/edit`,
    is_client_service: is_client_service,
  }
}

const headCells = [
  {
    id: 'id',
    numeric: false,
    disablePadding: true,
    label: 'ID',
  },
  {
    id: 'name',
    numeric: true,
    disablePadding: false,
    label: 'Nome',
  },
  {
    id: 'type',
    numeric: true,
    disablePadding: false,
    label: 'Tipo',
  },
  {
    id: 'status',
    numeric: true,
    disablePadding: false,
    label: 'Status',
  },
  {
    id: 'actions',
    numeric: true,
    disablePadding: false,
    label: 'Ações',
    align: 'center',
  },
]

function List(props) {
  const { items } = props
  const rows = []

  items.map(item => {
    return rows.push(
      createData(
        item.id,
        item.name,
        item.type_text,
        item.status_text,
        item.is_client_service
      )
    )
  })

  return (
    <GlobalList
      {...props}
      headCells={headCells}
      rows={rows}
      remove={remove}
      renderFirstTH
    />
  )
}

export default List
