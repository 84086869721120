/* eslint-disable jsx-a11y/anchor-is-valid */
import { Grid, IconButton } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link, Route } from 'react-router-dom'
import { bindActionCreators } from 'redux'

import styles from '../../../resources/theme/global'
import { renderRoute } from '../../../util/utils'
import { fetchServiceCategory } from '../ServiceCategoryActions'
import { getServiceCatListData } from '../ServiceCategoryReducer'
import colors from '../../../assets/colors'

import Edit from './Edit'
import Filter from './Filter'
import List from './List'
import New from './New'
import { mdiMessageQuestion } from '@mdi/js'
import Icon from '@mdi/react'

class ServiceCategory extends Component {
  componentDidMount() {
    this.props.fetchServiceCategory()
  }

  render() {
    const { classes, permissions } = this.props

    return (
      <>
        <Grid
          container
          className={classes.pageHead}
          spacing={2}
          alignItems="center"
        >
          <Grid item xs={12}>
            <Typography
              variant="h4"
              style={{ fontSize: '20px' }}
              color={colors.primary}
            >
              Categorias de Serviço

              <IconButton className={classes.questionBox}>
                {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                <a href="" target="_blank" rel="noreferrer">
                  <Icon
                    path={mdiMessageQuestion}
                    size={1}
                    color={colors.secondary}
                    style={{
                      position: 'absolute',
                      top: '-10px',
                      right: '-10px',
                    }}
                  />
                </a>
              </IconButton>
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Filter classes={classes} permissions={permissions} />
          </Grid>
        </Grid>

        {renderRoute(['update-service-categories'], permissions) && (
          <Route path="/service-category/:id/edit" component={Edit} />
        )}

        <List {...this.props} />
      </>
    )
  }
}

const mapStateToProps = state => ({
  ...getServiceCatListData(state),
  permissions: state.auth.permissions || [],
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchServiceCategory,
    },
    dispatch
  )
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ServiceCategory))
