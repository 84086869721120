import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import consts from '../../../util/consts'
import Paginate from '../../components/Pagination'
import { fetchProspectsRelatoryInit } from '../ProspectsRelatoryActions'
import {
  StyledTableCellBody,
  StyledTableCellHeader,
} from '../../../components/hooks/BaseTableComponents'
import classNames from 'classnames'

function List(props) {
  const dispatch = useDispatch()
  const filters = useSelector(state => state.prospects_relatory.filters)
  const [rowsPerPage, setRowsPerPage] = useState(consts.DEFAULT_PAGINATION)
  const { classes, items, totalSize, page } = props

  const onMovePage = (event, newPage) => {
    dispatch(fetchProspectsRelatoryInit(newPage + 1, filters, rowsPerPage))
  }

  useEffect(() => {
    onMovePage()
  }, [rowsPerPage])

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value)
  }

  return (
    <>
      <TableContainer style={{ maxHeight: 'calc(100vh - 270px)' }}>
        <Table>
          <TableHead>
            <TableRow>
              {/* <TableCell>ID</TableCell> */}
              <StyledTableCellHeader>Nome do Cliente</StyledTableCellHeader>
              <StyledTableCellHeader>Tipo do Serviço</StyledTableCellHeader>
              {/* <StyledTableCellHeader>Empresa</StyledTableCellHeader> */}
              <StyledTableCellHeader>Data</StyledTableCellHeader>
              <StyledTableCellHeader>Valor</StyledTableCellHeader>
              {/* <StyledTableCellHeader>Status</StyledTableCellHeader> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item, key) => (
              <TableRow key={key}>
                {/* <TableCell>{item.id}</TableCell> */}
                <StyledTableCellBody>{item.client_name}</StyledTableCellBody>
                <StyledTableCellBody>{item.service_type}</StyledTableCellBody>
                {/* <StyledTableCellBody>{item.company_name}</StyledTableCellBody> */}
                <StyledTableCellBody>{item.date}</StyledTableCellBody>
                <StyledTableCellBody>{item.final_value}</StyledTableCellBody>
                {/* <StyledTableCellBody>{item.status}</StyledTableCellBody> */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Paginate
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        rowsPerPage={rowsPerPage}
        rows={totalSize}
        page={page - 1}
        handleChangePage={onMovePage}
      />
    </>
  )
}

export default List
