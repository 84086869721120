/* eslint-disable import/order */

import React from 'react'
import { connect, useDispatch } from 'react-redux'

import styles from '../../../resources/theme/users'
import { post } from '../UserActions'

import Form from './Form'
import BaseCreatePage from '../../../components/hooks/BaseCreatePage'
import { Grid, makeStyles } from '@material-ui/core'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import colors from '../../../assets/colors'

const useStyles = makeStyles({
  rowMessage: {
    padding: '8px 0',
    borderTop: '1px dashed',
    borderColor: colors.textSecondary,
    borderBottom: '1px dashed',
    borderBottomColor: colors.textSecondary,
    color: colors.textPrimary,
    fontSize: '14px',
  },
  message: {
    display: 'flex',
    alignItems: 'center',
    height: 'auto',
    fontWeight: 'normal',
  },
  btnCalendar: {
    color: '#FFFF',
    fontSize: '14px',
    backgroundColor: colors.primary,
    borderRadius: '10px',
    padding: '4px 12px',
    transition: 'all .4s ease-in-out',
    '&:hover': {
      opacity: 0.6,
      backgroundColor: colors.primary,
    },
  },
})

const NewUser = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const classes = useStyles()

  const onSubmit = values => {
    dispatch(post(values)).then(data => {
      if (data && data.code === 200) {
        history.push('/users')
      }
    })
  }

  const onClose = () => {
    history.push('/users')
  }

  return (
    <BaseCreatePage
      page="Usuários"
      path="Cadastro"
      onClose={onClose}
      pageLink="/users"
    >
      <Grid
        container
        xs={12}
        alignContent="center"
        justifyContent="flex-start"
        className={classes.rowMessage}
      >
        <Grid item xs={12} className={classes.message}>
          Utilize os campos abaixo para cadastrar um novo usuário
        </Grid>
      </Grid>

      <Form
        classes={classes}
        onClose={onClose}
        onSubmit={onSubmit}
        btnText="Salvar"
      />
    </BaseCreatePage>
  )
}

export default NewUser
