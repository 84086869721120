/* eslint-disable import/order */
import { Grid, makeStyles } from '@material-ui/core'
import colors from '../../../assets/colors'
import React from 'react'

const useStyles = makeStyles({
  root: {
    padding: '8px 0',
    borderTop: '1px dashed',
    borderColor: colors.textSecondary,
    borderBottom: '1px dashed',
    borderBottomColor: colors.textSecondary,
    color: colors.textPrimary,
    fontSize: '14px',
  },
  disablePadding: {
    padding: 0,
  },
})

const BaseFilter = ({ children, disablePadding = false }) => {
  const classes = useStyles()

  return (
    <Grid
      container
      className={[classes.root, disablePadding ? classes.disablePadding : '']}
      xs={12}
      alignItems="center"
    >
      {children}
    </Grid>
  )
}

export default BaseFilter
