/* eslint-disable react/prop-types */
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Popover from '@material-ui/core/Popover'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import FilterListIcon from '@material-ui/icons/FilterList'
import SearchIcon from '@material-ui/icons/Search'
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state'
import React, { useState } from 'react'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'
import { useDispatch, useSelector } from 'react-redux'

import BadgeGlobal from '../../components/Badge'
import { fetchLaundryClients } from '../LaundryClientBillingsActions'
import { KeyboardDatePicker } from '@material-ui/pickers';
import SelectField from '../../components/SelectField'
import { format } from 'date-fns';

export default function Filter(props) {
  const items = useSelector(state => state.client.items)

  const dispatch = useDispatch()
  const { classes } = props
  const [form, setForm] = useState({
    name: '',
    due_date: null,
    due_date_end: null,
    is_payed: '',
  })
  const isPayed = ["Sim", "Não"]

  const onSubmit = e => {
    e.preventDefault()
    dispatch(fetchLaundryClients(null, form))
  }


  const updateFields = (name, value) => {
    setForm({
      ...form,
      [name]: value,
    })
  }

  const setDate = date => {
    if (date) {
      const formated = format(date, 'yyyy-MM-dd HH:mm:ss')
      setForm({ ...form, due_date: formated })
    } else {
      setForm({ ...form, due_date: null })
    }
  }

  const setDateEnd = date_end => {
    if (date_end) {
      const formated = format(date_end, 'yyyy-MM-dd HH:mm:ss')
      setForm({ ...form, due_date_end: formated })
    } else {
      setForm({ ...form, due_date_end: null })
    }
  }

  return (
    <Tooltip title="Filter list">
      <PopupState variant="popover" popupId="demo-popup-popover">
        {popupState => (
          <div>
            <IconButton aria-label="filter list" {...bindTrigger(popupState)}>
              <BadgeGlobal form={form} items={items}>
                <FilterListIcon />
              </BadgeGlobal>
            </IconButton>
            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Box p={2} className={classes.popover}>
                <div className={classes.root}>
                  <ValidatorForm className={classes.form} onSubmit={onSubmit}>
                    <Grid
                      container
                      className={classes.gridContainer}
                      spacing={1}
                      justify="center"
                      alignItems="center"
                    >

                      <Grid item sm={12} xs={12} lg={6}>
                        <KeyboardDatePicker
                          clearable
                          value={form.due_date}
                          label="Data Vencimento Inicial"
                          fullWidth
                          clearLabel="Limpar"
                          invalidDateMessage="Data inválida"
                          autoOk
                          margin="dense"
                          inputVariant="outlined"
                          onChange={date => setDate(date)}
                          format="dd/MM/yyyy"
                        />
                      </Grid>

                      <Grid item sm={12} xs={12} lg={6}>
                        <KeyboardDatePicker
                          clearable
                          value={form.due_date_end}
                          label="Data Vencimento Final"
                          fullWidth
                          clearLabel="Limpar"
                          invalidDateMessage="Data inválida"
                          autoOk
                          margin="dense"
                          inputVariant="outlined"
                          onChange={date => setDateEnd(date)}
                          format="dd/MM/yyyy"
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <TextField
                          onChange={e =>
                            setForm({ ...form, laundry_client_name: e.target.value })
                          }
                          label="Nome do Cliente"
                          margin="dense"
                          className={classes.inputFilter}
                          variant="outlined"
                          fullWidth
                          value={form.laundry_client_name}
                        />
                      </Grid>

                      <Grid item sm={12} xs={12} lg={6}>
                        <SelectField
                          options={isPayed}
                          setForm={updateFields}
                          data={form.is_payed}
                          name="is_payed"
                          variant="outlined"
                          margin="dense"
                          label="Pago"
                        />
                      </Grid>

                      <Grid item xs>
                        <Button
                          type="submit"
                          color="primary"
                          fullWidth
                          variant="contained"
                          className={classes.submit}
                          onClick={popupState.close}
                        >
                          <SearchIcon />
                          {` Filtrar`}
                        </Button>
                      </Grid>
                    </Grid>
                  </ValidatorForm>
                </div>
              </Box>
            </Popover>
          </div>
        )}
      </PopupState>
    </Tooltip>
  )
}
