import React, { useEffect } from 'react'
import BaseFilterModal from '../../../../../components/hooks/BaseFilterModal'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { Grid } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { edit } from '../../TriggersActions'
import Form from './Form'

const CreateEdit = props => {
  const editingTrigger = useSelector(state => state.triggers.editingTrigger)
  const history = useHistory()
  const dispatch = useDispatch()

  const { id } = props.match.params

  const handleClose = () => {
    history.push('/crm/triggers')
  }

  useEffect(() => {
    if (id !== 'new') {
      dispatch(edit(id))
    }
  }, [id])

  return (
    <BaseFilterModal
      open
      hiddenHelpButton
      handleOpen={handleClose}
      title={id !== 'new' ? 'Editar Gatilho' : 'Cadastrar Gatilho'}
    >
      <Grid item xs={12} style={{ width: '260px' }}>
        <Form
          edit={id !== 'new'}
          data={id !== 'new' ? editingTrigger : {}}
          id={id}
        />
      </Grid>
    </BaseFilterModal>
  )
}

export default CreateEdit
