import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import InputMask from 'react-input-mask'
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  TextField,
  makeStyles,
} from '@material-ui/core'

import Metro2 from './Metro2Modal'

const useStyles = makeStyles({
  input: {
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
})

function ModalGetInfos(props) {
  const styles = useStyles()

  const {
    open,
    handleClose,
    classes,
    updateChildren,
    currentIndex,
    services,
    changeOptionType,
  } = props

  const handleChangeMetrics = e => {
    e.preventDefault()
    const { name, value } = e.target

    const inputValue = value?.replace(',', '.')

    updateChildren(currentIndex, inputValue, name, true)
  }

  return (
    <div>
      <Dialog
        open={open}
        fullWidth
        maxWidth="md"
        onClose={handleClose}
        aria-labelledby="modal-get-infos-title"
      >
        <DialogTitle id="modal-get-infos-title">
          Informações do Tapete
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs>
              <FormControl className={classes.formControl}>
                <InputLabel>Tipo de tapete</InputLabel>
                <Select
                  native
                  value={services[currentIndex]?.options.type || 1}
                  fullWidth
                  onChange={e => {
                    changeOptionType(currentIndex, parseInt(e.target.value, 10))
                  }}
                >
                  <option value={1}>Quadrado</option>
                  <option value={2}>Redondo</option>
                </Select>
              </FormControl>
            </Grid>

            {services[currentIndex]?.options.type === 1 && (
              <Grid item xs>
                <Metro2
                  updateChildren={updateChildren}
                  chave={currentIndex}
                  item={services[currentIndex]}
                  styles={styles}
                />
              </Grid>
            )}

            {services[currentIndex]?.options.type === 2 && (
              <Grid item xs>
                <TextField
                  name="diametro"
                  type="number"
                  className={styles?.input}
                  onChange={handleChangeMetrics}
                  label="Diametro"
                  validators={['required']}
                  errorMessages={['Campo necessário']}
                  fullWidth
                  value={
                    services[currentIndex].options.diametro
                      ? services[currentIndex].options.diametro
                      : ''
                  }
                  onChangeCapture={handleChangeMetrics}
                  style={{ maxWidth: '100px' }}
                  inputProps={{ style: { textAlign: 'center' } }}
                />
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default React.memo(ModalGetInfos)
