import { FETCH_COMMISSION_RELATORY } from './RelatoryCommissionActions'

const INITIAL_STATE = {
  services: [],
  //   technicals: [],
  meta: [],
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_COMMISSION_RELATORY.SUCCESS:
      return {
        ...state,
        services: action.payload.data.data,
        // technicals: action.payload.data.technicals,
        meta: action.payload.data.meta,
      }
    default:
      return state
  }
}
