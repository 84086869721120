/* eslint-disable react/no-array-index-key */
/* eslint-disable import/order */
import AppBar from '@material-ui/core/AppBar'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import withStyles from '@material-ui/core/styles/withStyles'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import consts from '../../../util/consts'
import styles from '../../../resources/theme/users'
import Paginate from '../../components/Pagination'
import { team, makeRelatory } from '../TeamProdutivityRelatoryActions'
import { exportFormatedMoney } from '../../../util/utils'
import NoteAddIcon from '@material-ui/icons/NoteAdd'
import { Button, Grid, Slide } from '@material-ui/core'
import classNames from 'classnames'
import BaseRelatoryPage from '../../../components/hooks/BaseRelatoryPage'
import BaseFilter from '../../../components/hooks/BaseFilter'
import Icon from '@mdi/react'
import { mdiMicrosoftExcel } from '@mdi/js'
import filterStylesComponents from '../../../assets/filterComponentsStyles'
import {
  StyledTableCellBody,
  StyledTableCellHeader,
} from '../../../components/hooks/BaseTableComponents'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />
})

function Produtivity(props) {
  const classes = filterStylesComponents()

  const dispatch = useDispatch()

  const subItems = useSelector(
    state => state.team_produtivity_relatory.subItems
  )

  const subTotalSize = useSelector(
    state => state.team_produtivity_relatory.subTotalSize
  )
  const subPage = useSelector(state => state.team_produtivity_relatory.subPage)
  const subPerPage = useSelector(
    state => state.team_produtivity_relatory.subSizePerPage
  )

  const items = useSelector(state => state.team_produtivity_relatory.items)
  const filters = useSelector(state => state.team_produtivity_relatory.filters)

  const [subRowsPerPage, setSubRowsPerPage] = useState(subPerPage)

  const onMovePage = (event, newPage) => {
    dispatch(team(props.match.params.id, newPage + 1, subRowsPerPage, filters))
  }

  useEffect(() => {
    onMovePage()
  }, [subRowsPerPage])

  const handleChangeRowsPerPage = event => {
    setSubRowsPerPage(event.target.value)
  }

  // const onClose = () => {
  //   props.history.push('/relatory-team-produtivity')
  // }

  const makeReport = () => {
    const { id } = props.match.params
    const { team } = items.find(item => item.id == id)
    dispatch(
      makeRelatory(
        filters,
        `make-relatory-team-produtivity/${id}`,
        `${team.replace(' ', '_')}_productivity_relatory`
      )
    )
  }

  return (
    <BaseRelatoryPage
      btnReturn
      btnReturnPath="/relatory-team-produtivity"
      title="Equipe"
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <BaseFilter>
            <Grid item xs={12}>
              <Grid container justifyContent="flex-end" alignItems="center">
                <IconButton onClick={makeReport} className={classes.btnAdd}>
                  <Icon path={mdiMicrosoftExcel} size={1} />
                  Download
                </IconButton>
              </Grid>
            </Grid>
          </BaseFilter>
        </Grid>

        <Grid item xs={12}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCellHeader>ID</StyledTableCellHeader>
                  <StyledTableCellHeader align="center">
                    Data
                  </StyledTableCellHeader>
                  <StyledTableCellHeader>Tipo de Serviço</StyledTableCellHeader>
                  <StyledTableCellHeader>Nome do Cliente</StyledTableCellHeader>
                  <StyledTableCellHeader>Endereço</StyledTableCellHeader>
                  <StyledTableCellHeader align="center">
                    Valor
                  </StyledTableCellHeader>
                  <StyledTableCellHeader align="center">
                    Valor do Técnico
                  </StyledTableCellHeader>
                </TableRow>
              </TableHead>
              <TableBody>
                {subItems.map((item, index) => (
                  <TableRow key={index}>
                    <StyledTableCellBody>{item.id}</StyledTableCellBody>
                    <StyledTableCellBody align="center">
                      {item.date}
                    </StyledTableCellBody>
                    <StyledTableCellBody>{item.type}</StyledTableCellBody>
                    <StyledTableCellBody>
                      {item.client_name}
                    </StyledTableCellBody>
                    <StyledTableCellBody>{item.address}</StyledTableCellBody>
                    <StyledTableCellBody align="center">
                      {exportFormatedMoney(item.value)}
                    </StyledTableCellBody>
                    <StyledTableCellBody align="center">
                      {exportFormatedMoney(item.commission ?? 0)}
                    </StyledTableCellBody>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Paginate
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            rowsPerPage={subRowsPerPage}
            rows={subTotalSize}
            page={subPage - 1}
            handleChangePage={onMovePage}
          />
        </Grid>
      </Grid>
    </BaseRelatoryPage>
  )
}

export default withStyles(styles)(Produtivity)
