import './fonts/poppins.css'

import { createMuiTheme } from '@material-ui/core/styles'
import blueGrey from '@material-ui/core/colors/blueGrey'
import 'typeface-roboto'

export const themeDark = createMuiTheme({
  palette: {
    primary: {
      light: '#549fdd',
      main: '#549fdd',
      dark: '#19507e',
      contrastText: '#fff',
    },
    secondary: {
      light: '#549fdd',
      main: '#549fdd',
      dark: '#19507e',
      contrastText: '#fff',
    },
    danger: {
      light: '#ffebee',
      main: '#e84118',
    },
  },
  typography: {
    fontSize: 14,
    color: 'red',
    useNextVariants: true,
    fontFamily: 'Roboto',
    h4: {
      color: '#fff',
      fontWeight: 'bold',
    },
    body1: {
      fontSize: 14,
      //color: '#fff'
    },
  },
  icon: 'themeDark',
  gray1Color: blueGrey[700],
  gray2Color: blueGrey[800],
  blackColor: blueGrey[900],
  snackbarSuccess: '#BB86FC',
})
