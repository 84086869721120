import { Box, Card, Grid, makeStyles } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import BaseCreatePage from '../../../../components/hooks/BaseCreatePage'
import Filter from './Filter'
import Tabs from '../../../../components/hooks/BaseTabs/Tabs'
import Tab from '../../../../components/hooks/BaseTabs/Tab'
import colors from '../../../../assets/colors'
import CardLead from './CardLead'
import { useDispatch, useSelector } from 'react-redux'
import { fetchLeads } from '../PanelActions'
import { useConfirm } from 'material-ui-confirm'
import { renderRoute, renderRouteRole } from '../../../../util/utils'
import LeadForm from './lead'
import { Route } from 'react-router-dom/cjs/react-router-dom'

const useStyles = makeStyles({
  '@keyframes fadeIn': {
    '0%': {
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    },
  },
  cardBox: {
    height: '100%',
    padding: '20px 8px 8px 0',
    maxHeight: 'calc(100vh - 290px)',
    overflowY: 'auto',
    overflowX: 'hidden',
    animation: '$fadeIn .4s ease-in-out',
    '&::-webkit-scrollbar': {
      width: '3px',
      height: '8px',
      backgroundColor: colors.graylight,
    },
    '&::-webkit-scrollbar-thumb': {
      background: colors.secondary,
    },
  },
})

const CrmPanel = () => {
  const leads = useSelector(state => state.crmPanel.leads)
  const role = useSelector(state => state.auth.role)

  const dispatch = useDispatch()
  const classes = useStyles()

  const [value, setValue] = useState(0)

  const [form, setForm] = useState({
    client: '',
    os: '',
    trigger_id: '',
    state: '10',
  })

  useEffect(() => {
    dispatch(fetchLeads(form))
  }, [form])

  useEffect(() => {
    setForm({
      ...form,
      state: value == 0 ? 10 : 20,
    })
  }, [value])

  return (
    <Box
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
      }}
    >
      <Box>
        <BaseCreatePage
          page="CRM"
          path="Painel"
          pageLink="/crm/panel"
          hiddenReturn
        >
          <Grid container spacing="4" style={{ marginTop: '1em' }}>
            <Grid item xs={12}>
              <Filter form={form} setForm={setForm} />
            </Grid>
          </Grid>
        </BaseCreatePage>
      </Box>

      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '12px',
          flex: 1,
        }}
      >
        <Tabs>
          <Tab
            tabIndex={0}
            value={value}
            setValue={setValue}
            label="Entrada"
            removeNavigation={false}
          />
          <Tab
            tabIndex={1}
            value={value}
            setValue={setValue}
            label="Cadenciamento"
            removeNavigation={false}
            iconColor={colors.sucess}
          />
        </Tabs>

        {value == 0 && (
          <Grid container spacing={4} className={classes.cardBox}>
            {leads?.map((lead, index) => (
              <CardLead
                key={`crm-lead-${index}`}
                state={form.state}
                triggerType={lead?.trigger_type}
                clientName={lead?.client_name}
                schedulingDate={lead?.scheduling_date}
                os={lead?.os}
                reason={lead?.reason}
                leadId={lead?.id}
              />
            ))}
          </Grid>
        )}

        {value == 1 && (
          <Grid container spacing={4} className={classes.cardBox}>
            {leads?.map((lead, index) => (
              <CardLead
                key={`crm-lead-${index}`}
                state={form.state}
                triggerType={lead?.trigger_type}
                clientName={lead?.client_name}
                schedulingDate={lead?.scheduling_date}
                os={lead?.os}
                reason={lead?.reason}
                leadId={lead?.id}
              />
            ))}
          </Grid>
        )}
      </Box>

      {renderRouteRole(
        ['gerente_lavanderia', 'atendente_lavanderia', 'operador_lavanderia'],
        role
      ) && <Route path="/crm/panel/lead/:id" component={LeadForm} />}
    </Box>
  )
}

export default CrmPanel
