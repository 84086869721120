/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/label-has-associated-control */
import {
  Avatar,
  Box,
  Container,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  makeStyles,
} from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import ReactInputMask from 'react-input-mask'

import ufs from '../../util/uf'
import Icon from '@mdi/react'
import { mdiProgressUpload } from '@mdi/js'
import colors from '../../assets/colors'

const useStyles = makeStyles({
  uploadLogo: {
    width: '100%',
    height: '100%',
    border: `1px dashed ${colors.primary}`,
    padding: '4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '4px',
    fontSize: '14px',
    borderRadius: '10px',
    transition: 'all .4s ease-in-out',
    '&:hover': {
      backgroundColor: '#FFF',
      opacity: '.6',
    },
  },
  logo: {
    objectFit: 'cover',
    width: '169px',
    height: '32px',
    borderRadius: '10px',
  },
  logoValidate: {
    fontSize: '12px',
    color: colors.graylight,
  },
})
const Form = ({ data }) => {
  const classes = useStyles()
  const [dataForm, setDataForm] = useState(data)
  const [logoValidate, setLogoValidate] = useState({
    invalidType: '',
  })

  const inputRef = useRef()

  useEffect(() => {
    setDataForm(data)
  }, [data])

  const handleChange = e => {
    e.preventDefault()
    const { name, value } = e.target

    setDataForm({
      ...dataForm,
      [name]: value,
    })
  }

  const handleChangeLogo = event => {
    if (event.target.files[0] == null) {
      return
    }
    const image = event.target.files[0]
    const load = new FileReader()

    load.onload = event => {
      setDataForm({
        ...dataForm,
        logoPath: event.target.result,
      })
    }

    if (image) {
      load.readAsDataURL(image)
    }

    if (
      event.target.files[0].type != 'image/png' &&
      event.target.files[0].type != 'image/jpeg'
    ) {
      setDataForm({
        ...dataForm,
        logo: null,
      })
      setLogoValidate({
        ...logoValidate,
        invalidType: 'Tipo de arquivo inválido',
      })
    } else {
      setDataForm({
        ...dataForm,
        logo: event.target.files[0],
      })
      setLogoValidate({
        ...logoValidate,
        invalidType: '',
      })
    }
  }

  const buscaCep = async cep => {
    const currentCep = cep.replace(/\D/g, '')

    try {
      if (currentCep?.length === 8) {
        const resp = await axios
          .get(`https://viacep.com.br/ws/${currentCep}/json/`)
          .then(response => response?.data)

        setDataForm({
          ...dataForm,
          city: resp?.localidade,
          uf: resp?.uf,
          street: resp?.logradouro,
          neighborhood: resp?.bairro,
        })
      }
    } catch (e) {
      // console.log(e)
    }
  }

  const handleChooseFile = () => {
    inputRef.current.click()
  }

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '32px',
      }}
    >
      <Grid container spacing="4" direction="row">
        {dataForm?.logo && !dataForm?.logoPath && (
          <Grid item xs={12}>
            <Grid container justifyContent="flex-end">
              <Box className={classes.boxLogo}>
                <img
                  alt="Logo"
                  className={classes.logo}
                  src={`data:image/png;base64,${dataForm?.logo}`}
                />
              </Box>
            </Grid>
          </Grid>
        )}

        {dataForm?.logoPath && (
          <Grid item xs={12}>
            <Grid container justifyContent="flex-end">
              <Box className={classes.boxLogo}>
                <img
                  alt="Logo"
                  className={classes.logo}
                  src={dataForm?.logoPath}
                />
              </Box>
            </Grid>
          </Grid>
        )}
        <Grid item xs="12" sm={6} md={3}>
          <TextField
            label="Nome"
            name="name"
            required
            fullWidth
            value={dataForm.name}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs="12" sm={6} md={3}>
          <TextField
            label="Email"
            name="email"
            type="text"
            fullWidth
            required
            value={dataForm.email}
            onChange={handleChange}
          />
        </Grid>

        <Grid item sm={6} xs={12} md={3}>
          <ReactInputMask
            mask="(99) 9999-9999"
            placeholder="(__) ____-____"
            name="phone"
            maskChar=""
            value={dataForm?.phone}
            onChange={handleChange}
            required
          >
            {inputProps => (
              <TextField {...inputProps} fullWidth label="Telefone" />
            )}
          </ReactInputMask>
        </Grid>

        <Grid item sm={6} xs={12} md={3}>
          <ReactInputMask
            mask="(99) 99999-9999"
            placeholder="(__) _____-____"
            name="cell_phone"
            maskChar=""
            value={dataForm?.cell_phone || ' '}
            onChange={handleChange}
          >
            {inputProps => (
              <TextField {...inputProps} fullWidth label="Celular" />
            )}
          </ReactInputMask>
        </Grid>
      </Grid>

      <Grid container spacing="4" direction="row">
        <Grid item sm={3} xs={12} md={3}>
          <ReactInputMask
            mask="99.999-999"
            name="cep"
            maskChar=""
            value={dataForm?.cep}
            required
            onChange={handleChange}
            onBlur={() => buscaCep(dataForm?.cep)}
          >
            {inputProps => <TextField {...inputProps} label="CEP" fullWidth />}
          </ReactInputMask>
        </Grid>

        <Grid item sm={6} md={6} xs={12}>
          <TextField
            onChange={handleChange}
            label="Rua"
            name="street"
            fullWidth
            value={dataForm?.street}
            required
          />
        </Grid>

        <Grid item sm={3} md={3} xs={12}>
          <TextField
            label="Número"
            onChange={e => handleChange(e)}
            name="number"
            fullWidth
            value={dataForm?.number}
            required
            type="number"
          />
        </Grid>

        <Grid item sm={3} xs={12}>
          <TextField
            onChange={e => handleChange(e)}
            name="complement"
            label="Complemento"
            fullWidth
            value={dataForm?.complement == 'null' ? '' : dataForm?.complement}
          />
        </Grid>

        <Grid item sm={3} xs={12}>
          <TextField
            onChange={e => handleChange(e)}
            name="neighborhood"
            label="Bairro"
            fullWidth
            required
            value={dataForm?.neighborhood}
          />
        </Grid>

        <Grid item sm={6} md={3} xs={12}>
          <TextField
            onChange={e => handleChange(e)}
            name="city"
            label="Cidade"
            fullWidth
            required
            value={dataForm?.city}
          />
        </Grid>

        <Grid item sm={3} xs={12}>
          <TextField
            select
            labelId="demo-simple-select-required-label"
            id="demo-simple-select-required"
            value={dataForm?.uf}
            fullWidth
            onChange={e => handleChange(e)}
            name="uf"
            label="Estado"
            required
          >
            {ufs?.map((uf, index) => {
              return (
                // eslint-disable-next-line react/no-array-index-key
                <MenuItem key={index} value={uf}>
                  {uf}
                </MenuItem>
              )
            })}
          </TextField>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={9}>
              <TextField
                maxRows={10}
                placeholder="Descrição"
                defaultValue={dataForm?.description}
                name="description"
                onChange={handleChange}
                multiline
                fullWidth
                variant="outlined"
                sx={{ margin: '1em' }}
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <input
                ref={inputRef}
                name="logo"
                type="file"
                accept="image/*"
                onChange={handleChangeLogo}
                style={{ display: 'none' }}
              />
              <IconButton
                onClick={handleChooseFile}
                className={classes.uploadLogo}
              >
                <Grid container spacing={1}>
                  <Grid
                    item
                    xs={12}
                    container
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Icon path={mdiProgressUpload} size={1} />
                    Upload Logo
                  </Grid>
                  <Grid item xs={12}>
                    <Typography className={classes.logoValidate}>
                      {logoValidate?.invalidType || 'Logo (169 x 32)'}
                    </Typography>
                  </Grid>
                </Grid>
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Form
