/* eslint-disable camelcase */
import {
  Box,
  Grid,
  Snackbar,
  SnackbarContent,
  Typography,
} from '@material-ui/core'
import green from '@material-ui/core/colors/green'
import blue from '@material-ui/core/colors/blue'
import IconButton from '@material-ui/core/IconButton'
import Slide from '@material-ui/core/Slide'
import withStyles from '@material-ui/core/styles/withStyles'
import CloseIcon from '@material-ui/icons/Close'
import * as PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined'

import { clearSnackbar } from '../main/MainActions'
import { getSnackbar } from '../main/MainReducer'
import Icon from '@mdi/react'
import { mdiAlert } from '@mdi/js'
import colors from '../../assets/colors'

const AUTOCLOSE_DURATION = 1000 * 4

const styles = theme => ({
  error: {
    backgroundColor: colors.bgError,
  },
  success: {
    backgroundColor: colors.bgSucess,
  },
  info: {
    backgroundColor: colors.bgInfo,
  },
  snackBar: {
    maxHeight: '46px',
    boxShadow: 'none',
    padding: '0 8px',
    margin: '1em 1em 0 0',
    color: colors.primary,
  },
})

function Transition(props) {
  return <Slide {...props} direction="up" />
}

class Snackbars extends Component {
  constructor(props) {
    super(props)
    this.state = {
      message: null,
      open: false,
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.snackbar) {
      this.setState({
        open: true,
        message: nextProps.snackbar.message,
        success: nextProps.snackbar.success,
        info: nextProps.snackbar.info,
      })
    }
  }

  handleClose = () => {
    const { doClear } = this.props
    this.setState({
      open: false,
    })
    doClear()
  }

  rendedrMessageIcon = (message, type) => {
    return (
      <Grid container alignItems="center" style={{ gap: '4px' }}>
        <Grid item xs>
          {type === 'success' && (
            <Icon path={mdiAlert} color={colors.sucess} size={1} />
          )}

          {type === 'error' && (
            <Icon path={mdiAlert} color={colors.error} size={1} />
          )}

          {type === 'info' && (
            <Icon path={mdiAlert} color={colors.info} size={1} />
          )}
        </Grid>
        <Grid item style={{ display: 'flex', gap: '4px' }}>
          {type === 'success' ||
            (type === 'error' && (
              <Typography style={{ fontWeight: '700' }}>
                {type === 'success' ? 'Sucesso!' : 'Erro!'}
              </Typography>
            ))}
          {message}
        </Grid>
      </Grid>
    )
  }

  render() {
    const { classes } = this.props
    const { message, success, info, open } = this.state

    if (!message) {
      return null
    }

    const severity = () => {
      if (info) {
        return 'info'
      }

      if (success) {
        return 'success'
      }

      return 'error'
    }

    return (
      <Snackbar
        key={message}
        disableWindowBlurListener
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        autoHideDuration={AUTOCLOSE_DURATION}
        onClose={this.handleClose}
        TransitionComponent={Transition}
        component={<Box />}
      >
        <SnackbarContent
          className={[classes[severity()], classes.snackBar]}
          message={this.rendedrMessageIcon(message, severity())}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this.handleClose}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </Snackbar>
    )
  }
}

Snackbars.propTypes = {
  doClear: PropTypes.func.isRequired,
  snackbar: PropTypes.object,
  classes: PropTypes.object,
}

const mapStateToProps = store => ({
  snackbar: getSnackbar(store),
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doClear: clearSnackbar,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Snackbars))
