/* eslint-disable camelcase */
/* eslint-disable consistent-return */
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import {
  StyledTableCellBody,
  StyledTableCellHeader,
} from '../../../components/hooks/BaseTableComponents'
import { dateFormatedFullDate, exportFormatedMoney } from '../../../util/utils'
import { Link } from 'react-router-dom/cjs/react-router-dom'
import BaseFilterModal from '../../../components/hooks/BaseFilterModal'
import colors from '../../../assets/colors'
import StyledCheckBox from '../../../components/hooks/BaseCheckBox'

const useStyles = makeStyles({
  status: {
    width: '91px',
    height: '32px',
    padding: '4px',
    borderRadius: '5px',
    fontSize: '12px',
    color: '#FFF',
    lineHeight: '12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  statusService: {
    backgroundColor: '#ECA51D',
  },
  statusOs: {
    backgroundColor: '#539FDD',
  },
  table: {
    maxHeight: 'calc(100vh - 235px)',
  },
})

const DetailsTable = ({ data = [], handleModalOpen, open }) => {
  const classes = useStyles()

  const [columns, setColumns] = useState({
    todos: false,
    nOs: {
      name: 'nOs',
      label: 'Nº da OS',
      show: true,
    },
    attendance: {
      name: 'attendance',
      label: 'Atendente',
      show: true,
    },
    etiqueta: {
      name: 'etiqueta',
      label: 'Etiqueta',
      show: true,
    },
    client: {
      name: 'client',
      label: 'Cliente',
      show: true,
    },
    service_type: {
      name: 'service_type',
      label: 'Tipo de Serviço',
      show: true,
    },
    total: {
      name: 'value',
      label: 'Valor do Item',
      show: true,
    },
    dataColeta: {
      name: 'dataColeta',
      label: 'Data da Coleta',
      show: true,
    },
    status: {
      name: 'status',
      label: 'Status',
      show: true,
    },
    statusOs: {
      name: 'statusOs',
      label: 'Status da OS',
      show: true,
    },
  })

  const renderOsNumber = item => {
    return (
      <StyledTableCellBody
        align="center"
        component={Link}
        to={`/attendance/${item.service?.id}/edit`}
      >
        {item.service?.id_laundry_client}
      </StyledTableCellBody>
    )
  }

  useEffect(() => {
    if (columns.todos) {
      Object.entries(columns).map(([columnName, column]) => {
        if (!column?.name) return

        setColumns(prevState => ({
          ...prevState,
          [columnName]: {
            ...column,
            show: true,
          },
        }))
      })
    }
  }, [columns.todos])

  return (
    <TableContainer className={classes.table}>
      <Table size="medium">
        <TableHead>
          <TableRow>
            {/* <StyledTableCellHeader>ID</StyledTableCellHeader> */}
            {/* <StyledTableCellHeader>Lavanderia</StyledTableCellHeader> */}
            {columns?.nOs?.show && (
              <StyledTableCellHeader align="center">
                Nº da OS
              </StyledTableCellHeader>
            )}
            {columns?.client?.show && (
              <StyledTableCellHeader>Cliente</StyledTableCellHeader>
            )}
            {columns?.service_type?.show && (
              <StyledTableCellHeader>Tipo de serviço</StyledTableCellHeader>
            )}
            {columns?.etiqueta?.show && (
              <StyledTableCellHeader align="center">
                Etiqueta
              </StyledTableCellHeader>
            )}
            {columns?.total?.show && (
              <StyledTableCellHeader>Valor do item</StyledTableCellHeader>
            )}
            {columns?.dataColeta?.show && (
              <StyledTableCellHeader>Data da Coleta</StyledTableCellHeader>
            )}
            {columns?.attendance?.show && (
              <StyledTableCellHeader align="center">
                Atendente
              </StyledTableCellHeader>
            )}
            {columns?.status?.show && (
              <StyledTableCellHeader>Status</StyledTableCellHeader>
            )}
            {columns?.statusOs?.show && (
              <StyledTableCellHeader>Status da OS</StyledTableCellHeader>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map(item => (
            <TableRow key={item.id}>
              {/* <StyledTableCellBody>{item.id}</StyledTableCellBody> */}

              {columns?.nOs?.show && renderOsNumber(item)}
              {columns?.client?.show && (
                <StyledTableCellBody>
                  {item.service?.client?.name
                    ? item.service?.client?.name
                    : '-'}
                </StyledTableCellBody>
              )}
              {columns?.service_type?.show && (
                <StyledTableCellBody>
                  {item.service_type?.name}
                </StyledTableCellBody>
              )}
              {columns?.etiqueta?.show && (
                <StyledTableCellBody align="center">
                  {item.bar_code || '-'}
                </StyledTableCellBody>
              )}
              {columns?.total?.show && (
                <StyledTableCellBody>
                  {exportFormatedMoney(item.final_value)}
                </StyledTableCellBody>
              )}
              {columns?.dataColeta?.show && (
                <StyledTableCellBody>
                  {dateFormatedFullDate(item.data_coleta) || '/ /'}
                </StyledTableCellBody>
              )}
              {columns?.attendance?.show && (
                <StyledTableCellBody align="center">
                  {item.service?.user?.name || '/ /'}
                </StyledTableCellBody>
              )}
              {columns?.status?.show && (
                <StyledTableCellBody>
                  <Box className={[classes.status, classes.statusService]}>
                    {item.status?.name}
                  </Box>
                </StyledTableCellBody>
              )}
              {columns?.statusOs?.show && (
                <StyledTableCellBody>
                  <Box className={[classes.status, classes.statusOs]}>
                    {item.service?.status?.name}
                  </Box>
                </StyledTableCellBody>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <BaseFilterModal
        title="Colunas da tabela"
        open={open}
        handleOpen={handleModalOpen}
      >
        <Grid
          container
          xs={12}
          item
          style={{ width: '400px' }}
          direction="row"
          spacing={1}
        >
          <Grid item xs={12}>
            <Typography
              style={{ fontSize: '16px', fontWeight: 'normal' }}
              color={colors.primary}
            >
              Selecione os campos que devem aparecer na tabela
            </Typography>
          </Grid>

          <Grid container>
            <Grid item xs={12}>
              <StyledCheckBox
                checked={columns.todos}
                onChange={() =>
                  setColumns({
                    ...columns,
                    todos: !columns.todos,
                  })
                }
              />
              Todos
            </Grid>

            {Object.entries(columns).map(([columnName, column]) => {
              if (!column?.name) return

              return (
                <>
                  <Grid item xs={3}>
                    <StyledCheckBox
                      checked={column?.show}
                      onChange={() =>
                        setColumns({
                          ...columns,
                          [columnName]: {
                            ...column,
                            show: !column?.show,
                          },
                        })
                      }
                    />
                    {column?.label}
                  </Grid>
                </>
              )
            })}
          </Grid>
        </Grid>
      </BaseFilterModal>
    </TableContainer>
  )
}

export default DetailsTable
