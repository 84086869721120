import { Button, Grid, MenuItem, TextField } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import filterStylesComponents from '../../../../../assets/filterComponentsStyles'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { create, update } from '../../TriggersActions'

const Form = ({ edit = false, data = {}, id = null }) => {
  const triggerTypes = useSelector(state => state.triggers.triggerTypes)

  const history = useHistory()
  const styles = filterStylesComponents()
  const dispatch = useDispatch()

  const [form, setForm] = useState({
    reason: '',
    days: '',
    type: '',
  })

  const handleClose = () => {
    history.push('/crm/triggers')
  }

  const handleChangeType = e => {
    e.preventDefault()

    const { name, value } = e.target

    if (value == 20) {
      return setForm({
        ...e,
        [name]: value,
        reason: 'Recorrência',
      })
    }

    setForm({
      ...form,
      [name]: value,
    })
  }

  const handleSubmit = async e => {
    e.preventDefault()

    const formData = e.currentTarget
    const data = new FormData(formData)
    const entries = data.entries()
    const values = Object.fromEntries(entries)

    const trigger = {
      ...values,
      reason: values?.reason || form?.reason,
    }

    try {
      if (edit) {
        const resp = await dispatch(update(trigger, id))

        if (resp) {
          handleClose()
        }
      } else {
        const resp = await dispatch(create(trigger))

        if (resp) {
          handleClose()
        }
      }
    } catch (e) {
      //
    }
  }

  useEffect(() => {
    if (edit && data) {
      setForm({
        ...form,
        ...data,
      })
    }
  }, [edit, data])

  return (
    <form style={{ width: '100%', height: '100%' }} onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            select
            name="type"
            variant="standard"
            fullWidth
            label="Tipo"
            required
            value={form.type}
            onChange={handleChangeType}
            defaultValue={form.type}
            key={`input-type-${form.type}`}
          >
            <MenuItem value="">Selecione...</MenuItem>

            {triggerTypes?.map((trigger, index) => (
              <MenuItem value={trigger?.var_id} key={index}>
                {trigger?.value}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={12}>
          <TextField
            type="text"
            name="reason"
            variant="standard"
            label="Motivo"
            defaultValue={form.reason}
            key={`input-reason-${form.reason}`}
            disabled={form.type == 20}
            required
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            type="number"
            name="days"
            variant="standard"
            defaultValue={form.days}
            key={`input-days-${form.days}`}
            required
            label="Dias"
            fullWidth
          />
        </Grid>

        <Grid
          container
          alignItems="center"
          justifyContent="center"
          style={{ marginTop: '1em' }}
        >
          <Grid item>
            <Button type="submit" className={styles.btnSalvar}>
              Salvar
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  )
}

export default Form
