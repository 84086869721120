import axios from 'axios/index'

import config from '../../config'
import action, { actionFailed } from '../../util/action'
import createAsyncConst from '../../util/createAsyncConst'

export const GET_OPERADORES_RUA = createAsyncConst('GET_OPERADORES_RUA')
export const GET_SERVICES_UNBOOKED = createAsyncConst('GET_SERVICES_UNBOOKED')
export const GET_SERVICES_BOOKED = createAsyncConst('GET_SERVICES_BOOKED')
export const GET_SERVICE_UNBOOKED_BY_ID = createAsyncConst(
  'GET_SERVICE_UNBOOKED_BY_ID'
)
export const UPDATE_SERVICE_ITEM = createAsyncConst('UPDATE_SERVICE_ITEM')
export const CALENDAR_DETACHED = createAsyncConst('CALENDAR_DETACHED')
export const REORDER = createAsyncConst('REORDER')
export const TOOGLE_OPEN_NEXT_DAY = createAsyncConst('TOOGLE_OPEN_NEXT_DAY')
export const TECHNICAL_ABSENCE = createAsyncConst('TECHNICAL_ABSENCE')
export const FIND_TECHNICAL_ABSENCE = createAsyncConst('FIND_TECHNICAL_ABSENCE')
export const ENABLE_APP_DAYS = createAsyncConst('ENABLE_APP_DAYS')

export function findAttendantes() {
  return dispatch => {
    dispatch(action(GET_OPERADORES_RUA.ACTION, {}, false, false))
    return axios
      .get(`${config.API_URL}/get-operadores`)
      .then(resp => resp.data)
      .then(resp => {
        dispatch(action(GET_OPERADORES_RUA.SUCCESS, resp.data, false, false))
        return resp
      })
      .catch(e => dispatch(actionFailed(GET_OPERADORES_RUA, e, false, false)))
  }
}

export function findAppVisualizationPeriod() {
  return dispatch => {
    dispatch(action(ENABLE_APP_DAYS.ACTION, {}, false, false))
    return axios
      .get(`${config.API_URL}/calendar/enabled-app-days`)
      .then(resp => resp.data)
      .then(resp => {
        dispatch(action(ENABLE_APP_DAYS.SUCCESS, resp, false, false))
        return resp
      })
      .catch(e => dispatch(actionFailed(ENABLE_APP_DAYS, e, false, false)))
  }
}

export function technicalAbsence(form) {
  return dispatch => {
    dispatch(action(TECHNICAL_ABSENCE.ACTION, {}, false, false))
    return axios
      .post(`${config.API_URL}/technical-absence`, form)
      .then(resp => resp.data)
      .then(resp => {
        dispatch(action(TECHNICAL_ABSENCE.SUCCESS, resp.data, false, false))
        return true
      })
      .catch(e => {
        dispatch(actionFailed(TECHNICAL_ABSENCE, e, false, false))
        return false
      })
  }
}

export function findTechnicalAbsence() {
  return dispatch => {
    dispatch(action(FIND_TECHNICAL_ABSENCE.ACTION, {}, false, false))
    return axios
      .get(`${config.API_URL}/technical-absence`)
      .then(resp => resp.data)
      .then(resp => {
        dispatch(
          action(FIND_TECHNICAL_ABSENCE.SUCCESS, resp.data, false, false)
        )
      })
      .catch(e => {
        dispatch(actionFailed(FIND_TECHNICAL_ABSENCE, e, false, false))
      })
  }
}

export function getServicesUnbooked(serviceId, filter = '') {
  return dispatch => {
    const id = serviceId ? `/${serviceId}` : ''
    dispatch(action(GET_SERVICES_UNBOOKED.ACTION, {}, false, false))
    return axios
      .get(`${config.API_URL}/get-services-unbooked${id}`, {
        params: {
          filter,
        },
      })
      .then(resp => resp.data)
      .then(resp => {
        dispatch(action(GET_SERVICES_UNBOOKED.SUCCESS, resp.data, false, false))
        return resp
      })
      .catch(e =>
        dispatch(actionFailed(GET_SERVICES_UNBOOKED, e, false, false))
      )
  }
}

export function getServicesBooked(noEdit = false) {
  return dispatch => {
    dispatch(action(GET_SERVICES_BOOKED.ACTION, {}, false, false))
    return axios
      .get(`${config.API_URL}/get-services-booked`, {
        params: {
          noEdit,
        },
      })
      .then(resp => resp.data)
      .then(resp => {
        dispatch(action(GET_SERVICES_BOOKED.SUCCESS, resp.data, false, false))
        return resp
      })
      .catch(e => dispatch(actionFailed(GET_SERVICES_BOOKED, e, false, false)))
  }
}

export function getServiceUnbookedById(id, copy) {
  return dispatch => {
    dispatch(action(GET_SERVICE_UNBOOKED_BY_ID.ACTION, {}, false, false))
    return axios
      .get(`${config.API_URL}/get-service/${id}`, {
        params: {
          copy,
        },
      })
      .then(resp => resp.data)
      .then(resp => {
        dispatch(
          action(GET_SERVICE_UNBOOKED_BY_ID.SUCCESS, resp.data, false, false)
        )
        return resp
      })
      .catch(e =>
        dispatch(actionFailed(GET_SERVICE_UNBOOKED_BY_ID, e, false, false))
      )
  }
}

export function deleteTechnicalAbsence(id) {
  return dispatch => {
    dispatch(action(GET_SERVICE_UNBOOKED_BY_ID.ACTION, {}, false, false))
    return axios
      .delete(`${config.API_URL}/technical-absence/${id}`)
      .then(resp => resp.data)
      .then(resp => {
        dispatch(
          action(GET_SERVICE_UNBOOKED_BY_ID.SUCCESS, resp.data, false, false)
        )
        dispatch(findTechnicalAbsence())
        return resp
      })
      .catch(e =>
        dispatch(actionFailed(GET_SERVICE_UNBOOKED_BY_ID, e, false, false))
      )
  }
}

export function enableApp(params) {
  return dispatch => {
    dispatch(action(CALENDAR_DETACHED.ACTION))
    return axios
      .post(`${config.API_URL}/calendar/enable-app`, params)
      .then(resp => resp.data)
      .then(resp => {
        dispatch(action(CALENDAR_DETACHED.SUCCESS, resp))
        return true
      })
      .catch(e => {
        dispatch(actionFailed(CALENDAR_DETACHED, e))
        return false
      })
  }
}

export function updateServiceItem(id, body, isLast = false) {
  return dispatch => {
    dispatch(action(UPDATE_SERVICE_ITEM.ACTION, {}, false, false))
    return axios
      .post(`${config.API_URL}/service-item/${id}/update`, { ...body, isLast })
      .then(resp => resp.data)
      .then(resp => {
        dispatch(action(UPDATE_SERVICE_ITEM.SUCCESS, resp, false, false))
        return resp
      })
      .catch(e => dispatch(actionFailed(UPDATE_SERVICE_ITEM, e, false, false)))
  }
}

export function newDetachedCalendar(body) {
  return dispatch => {
    dispatch(action(CALENDAR_DETACHED.ACTION))
    return axios
      .post(`${config.API_URL}/service-item/calendar-detached`, body)
      .then(resp => resp.data)
      .then(resp => {
        dispatch(action(CALENDAR_DETACHED.SUCCESS, resp))
        return resp
      })
      .catch(e => dispatch(actionFailed(CALENDAR_DETACHED, e)))
  }
}

export function reorder(body) {
  return dispatch => {
    dispatch(action(CALENDAR_DETACHED.ACTION))
    return axios
      .post(`${config.API_URL}/service-item/reorder`, body)
      .then(resp => resp.data)
      .then(resp => {
        dispatch(action(CALENDAR_DETACHED.SUCCESS, resp))
        return resp
      })
      .catch(e => dispatch(actionFailed(CALENDAR_DETACHED, e)))
  }
}

export function toogleOpenNextDay() {
  return dispatch => {
    dispatch(action(TOOGLE_OPEN_NEXT_DAY.ACTION))
    return axios
      .post(`${config.API_URL}/calendar/toogle-open-next-day`)
      .then(resp => {
        dispatch(action(TOOGLE_OPEN_NEXT_DAY.SUCCESS, resp))
        return resp
      })
      .catch(e => dispatch(actionFailed(TOOGLE_OPEN_NEXT_DAY, e)))
  }
}
