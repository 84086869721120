/* eslint-disable camelcase */
/* eslint-disable object-shorthand */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable react/prop-types */
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Popover from '@material-ui/core/Popover'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import FilterListIcon from '@material-ui/icons/FilterList'
import SearchIcon from '@material-ui/icons/Search'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { format } from 'date-fns'
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state'
import React, { useEffect, useState } from 'react'
import { ValidatorForm } from 'react-material-ui-form-validator'
import { useDispatch, useSelector } from 'react-redux'

import BadgeGlobal from '../../components/Badge'
import SelectField from '../../components/SelectField'
import { fetchBilling } from '../BillingActions'
import filterStylesComponents from '../../../assets/filterComponentsStyles'
import Icon from '@mdi/react'
import {
  mdiFilterMultiple,
  mdiMagnify,
  mdiMicrosoftExcel,
  mdiNoteEditOutline,
  mdiSearchWeb,
} from '@mdi/js'
import colors from '../../../assets/colors'
import { MenuItem, Typography } from '@material-ui/core'
import BaseFilterModal from '../../../components/hooks/BaseFilterModal'
import { Link } from 'react-router-dom/cjs/react-router-dom'
import queryString from 'query-string'

export default function Filter(props) {
  const dispatch = useDispatch()
  const classes = filterStylesComponents()

  const items = useSelector(state => state.billing.items)
  const statuses = useSelector(state => state.billing.statuses)
  const billingTypes = useSelector(state => state.billing.billingTypes)
  const paymentMethods = useSelector(state => state.billing.paymentMethods)
  const banks = useSelector(state => state.billing.banks)
  const billingTypesGeneric = ['Despesa', 'Receita']
  const filters = useSelector(state => state.billing.filters)
  const [open, setOpen] = useState(false)

  const { typeGeneric, location, form, setForm } = props

  const [currentForm, setCurrentForm] = useState({
    person: '',
    date: null,
    date_end: null,
    date_payment: null,
    date_end_payment: null,
    status: '',
    billing_type: '',
    payment_method: '',
    service_id: '',
    company: '',
    bank: '',
    billing_type_generic: typeGeneric,
    type: typeGeneric,
    reference_month: '',
    period: '',
  })

  const handleOpen = () => {
    setOpen(!open)
  }

  const onSubmit = e => {
    e.preventDefault()
    setForm({ ...form, ...currentForm })
    handleOpen()
  }

  const handleChange = e => {
    e.preventDefault()

    const { name, value } = e.target

    setCurrentForm({
      ...currentForm,
      [name]: value,
    })
  }

  useEffect(() => {
    setForm({
      ...form,
      type: typeGeneric,
      billing_type_generic: typeGeneric,
    })
  }, [])

  useEffect(() => {
    setForm({
      ...form,
      ...currentForm,
    })
  }, [currentForm.status, currentForm.billing_type])

  return (
    <Grid container spacing={3}>
      <Grid item container spacing={3} xs={12} sm={6}>
        <Grid item xs={12} sm={6}>
          <TextField
            select
            value={currentForm.billing_type}
            name="billing_type"
            label="Filtre por centro de custo"
            fullWidth
            onChange={handleChange}
          >
            <MenuItem value="0">Selecione...</MenuItem>
            {billingTypes?.map(billingType => (
              <MenuItem value={billingType?.id} key={billingType?.id}>
                {billingType?.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            select
            value={currentForm.status}
            name="status"
            label="Filtre por status"
            fullWidth
            onChange={handleChange}
          >
            <MenuItem value="0">Selecione...</MenuItem>
            {statuses?.map(status => (
              <MenuItem value={status?.id} key={status?.id}>
                {status?.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
      <Grid item container xs={12} sm={6} className={classes.areaButtons}>
        <IconButton className={classes.btnMoreFilters} onClick={handleOpen}>
          <Icon path={mdiFilterMultiple} size={1} color={colors.secondary} />
          Filtros
        </IconButton>

        <IconButton
          className={classes.btnAdd}
          component={Link}
          to={{ pathname: '/billing/new', state: { typeGeneric: typeGeneric } }}
        >
          <Icon path={mdiNoteEditOutline} size={1} />
          Cadastrar
        </IconButton>
      </Grid>

      <BaseFilterModal
        title="FILTRO"
        hiddenHelpButton
        open={open}
        handleOpen={handleOpen}
      >
        <Grid container xs={12} item direction="row" style={{ width: '420px' }}>
          <Grid item xs={12}>
            <Typography
              style={{ fontSize: '16px', fontWeight: 'normal' }}
              color={colors.primary}
            >
              Utilize os campos abaixo para encontrar <br /> uma conta
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  onChange={e =>
                    setCurrentForm({ ...currentForm, person: e.target.value })
                  }
                  label="Contato"
                  fullWidth
                  value={currentForm.person}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  select
                  name="billing_type"
                  label="Centro de custo"
                  value={currentForm.billing_type}
                  fullWidth
                  onChange={handleChange}
                >
                  <MenuItem value="">Selecione...</MenuItem>
                  {billingTypes?.map(billingType => (
                    <MenuItem value={billingType?.id} key={billingType?.id}>
                      {billingType?.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  select
                  name="status"
                  label="Status"
                  fullWidth
                  value={currentForm.status}
                  onChange={handleChange}
                >
                  <MenuItem value="">Selecione...</MenuItem>
                  {statuses?.map(status => (
                    <MenuItem value={status?.id} key={status?.id}>
                      {status?.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  select
                  name="payment_method"
                  label="Método de pagamento"
                  fullWidth
                  value={currentForm.payment_method}
                  onChange={handleChange}
                >
                  <MenuItem value="">Selecione...</MenuItem>
                  {paymentMethods?.map(method => (
                    <MenuItem value={method?.id} key={method?.id}>
                      {method?.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  select
                  name="bank"
                  label="Banco"
                  fullWidth
                  value={currentForm.bank}
                  onChange={handleChange}
                >
                  <MenuItem value="">Selecione...</MenuItem>
                  {banks?.map(bank => (
                    <MenuItem value={bank?.id} key={bank?.id}>
                      {bank?.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item sm={6} xs={12}>
                <KeyboardDatePicker
                  clearable
                  value={currentForm.date}
                  label="Data de Vencimento Inicial"
                  fullWidth
                  clearLabel="Limpar"
                  invalidDateMessage="Data inválida"
                  autoOk
                  onChange={date =>
                    setCurrentForm({ ...currentForm, date: date })
                  }
                  format="dd/MM/yyyy"
                />
              </Grid>

              <Grid item sm={6} xs={12}>
                <KeyboardDatePicker
                  clearable
                  value={currentForm.date_end}
                  label="Data de Vencimento Final"
                  fullWidth
                  clearLabel="Limpar"
                  invalidDateMessage="Data inválida"
                  autoOk
                  onChange={date =>
                    setCurrentForm({ ...currentForm, date_end: date })
                  }
                  format="dd/MM/yyyy"
                />
              </Grid>

              <Grid item sm={6} xs={12}>
                <KeyboardDatePicker
                  clearable
                  value={currentForm.date_payment}
                  label="Data de Pagamento Inicial"
                  fullWidth
                  clearLabel="Limpar"
                  invalidDateMessage="Data inválida"
                  autoOk
                  onChange={date =>
                    setCurrentForm({ ...currentForm, date_payment: date })
                  }
                  format="dd/MM/yyyy"
                />
              </Grid>

              <Grid item sm={6} xs={12}>
                <KeyboardDatePicker
                  clearable
                  value={currentForm.date_end_payment}
                  label="Data de Pagamento Final"
                  fullWidth
                  clearLabel="Limpar"
                  invalidDateMessage="Data inválida"
                  autoOk
                  onChange={date =>
                    setCurrentForm({ ...currentForm, date_end_payment: date })
                  }
                  format="dd/MM/yyyy"
                />
              </Grid>

              <Grid
                item
                xs={12}
                style={{ marginTop: '1em' }}
                container
                alignItems="center"
                justifyContent="center"
              >
                <IconButton onClick={onSubmit} className={classes.btnSalvar}>
                  <Icon path={mdiMagnify} size={1} />
                  Pesquisar
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </BaseFilterModal>
    </Grid>
  )
}
