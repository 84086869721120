/* eslint-disable react/prop-types */
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Popover from '@material-ui/core/Popover'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import FilterListIcon from '@material-ui/icons/FilterList'
import SearchIcon from '@material-ui/icons/Search'
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state'
import React, { useEffect, useState } from 'react'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { ValidatorForm } from 'react-material-ui-form-validator'
import { useDispatch, useSelector } from 'react-redux'

import BadgeGlobal from '../../components/Badge'
import {
  fetchClientsRelatoryInit,
  makeRelatory,
} from '../ClientsRelatoryActions'
import filterStylesComponents from '../../../assets/filterComponentsStyles'
import BaseFilter from '../../../components/hooks/BaseFilter'
import Icon from '@mdi/react'
import { mdiMagnifyExpand, mdiMicrosoftExcel } from '@mdi/js'
import { InputAdornment } from '@material-ui/core'
import colors from '../../../assets/colors'

export default function Filter(props) {
  const items = useSelector(state => state.clients_relatory.items)

  const dispatch = useDispatch()
  const { classes } = props
  const [form, setForm] = useState({
    company: '',
    name: '',
    last_service_start: null,
    last_service_end: null,
  })

  const styles = filterStylesComponents()

  const onSubmit = e => {
    e.preventDefault()
    dispatch(fetchClientsRelatoryInit(null, form, null))
  }

  const handleSearch = e => {
    e.preventDefault()
    dispatch(fetchClientsRelatoryInit(null, form, null))
  }

  const handleSearchDate = e => {
    e.preventDefault()
    dispatch(fetchClientsRelatoryInit(null, form, null))
  }

  const handleRelatory = e => {
    e.preventDefault()
    dispatch(makeRelatory(form))
  }

  useEffect(() => {
    if (!form?.name) {
      dispatch(fetchClientsRelatoryInit(null, form, null))
    }
  }, [form?.name])

  return (
    <BaseFilter>
      <Grid container spacing={2}>
        <Grid item container spacing={1} xs={8}>
          <Grid item sm={4} xs={12}>
            <TextField
              onChange={e => setForm({ ...form, name: e.target.value })}
              label="Nome"
              className={classes.inputFilter}
              fullWidth
              value={form.name}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleSearch}>
                      <Icon
                        path={mdiMagnifyExpand}
                        size={1}
                        color={colors.graylight}
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item sm={4} xs={12}>
            <KeyboardDatePicker
              clearable
              value={form.last_service_start}
              label="Último Serviço Inicial"
              fullWidth
              views={['date']}
              clearLabel="Limpar"
              onBlur={handleSearchDate}
              invalidDateMessage="Data inválida"
              autoOk
              onChange={date => setForm({ ...form, last_service_start: date })}
              format="dd/MM/yyyy"
            />
          </Grid>

          <Grid item sm={4} xs={12}>
            <KeyboardDatePicker
              clearable
              value={form.last_service_end}
              label="Último Serviço Final"
              fullWidth
              views={['date']}
              clearLabel="Limpar"
              onBlur={handleSearchDate}
              invalidDateMessage="Data inválida"
              autoOk
              onChange={date => setForm({ ...form, last_service_end: date })}
              format="dd/MM/yyyy"
            />
          </Grid>
        </Grid>

        <Grid item sm={4} xs={12} className={styles.areaButtons}>
          <IconButton className={styles.btnAdd} onClick={handleRelatory}>
            <Icon path={mdiMicrosoftExcel} size={1} />
            Download
          </IconButton>
        </Grid>
      </Grid>
    </BaseFilter>
    // <Tooltip title="Filter list">
    //   <PopupState variant="popover" popupId="demo-popup-popover">
    //     {popupState => (
    //       <div>
    //         <IconButton aria-label="filter list" {...bindTrigger(popupState)}>
    //           <BadgeGlobal form={form} items={items}>
    //             <FilterListIcon />
    //           </BadgeGlobal>
    //         </IconButton>
    //         <Popover
    //           {...bindPopover(popupState)}
    //           anchorOrigin={{
    //             vertical: 'bottom',
    //             horizontal: 'center',
    //           }}
    //           transformOrigin={{
    //             vertical: 'top',
    //             horizontal: 'center',
    //           }}
    //         >
    //           <Box p={2} className={classes.popover}>
    //             <div className={classes.root}>
    //               <ValidatorForm className={classes.form} onSubmit={onSubmit}>
    //                 <Grid
    //                   container
    //                   className={classes.gridContainer}
    //                   spacing={1}
    //                   justify="center"
    //                   alignItems="center"
    //                 >
    //                   <Grid item xs={12}>
    //                     <TextField
    //                       onChange={e =>
    //                         setForm({ ...form, name: e.target.value })
    //                       }
    //                       margin="dense"
    //                       variant="outlined"
    //                       label="Nome"
    //                       className={classes.inputFilter}
    //                       fullWidth
    //                       value={form.name}
    //                     />
    //                   </Grid>

    //                   {/* <Grid item xs={6}>
    //                     <TextField
    //                       onChange={e =>
    //                         setForm({ ...form, company: e.target.value })
    //                       }
    //                       select
    //                       label="Empresa"
    //                       margin="dense"
    //                       variant="outlined"
    //                       className={classes.inputFilter}
    //                       fullWidth
    //                       value={form.company}
    //                     >
    //                       <MenuItem key="" value="">
    //                         Selecione...
    //                       </MenuItem>
    //                       {companies?.map(company => (
    //                         <MenuItem key={company.id} value={company.id}>
    //                           {company.name}
    //                         </MenuItem>
    //                       ))}
    //                     </TextField>
    //                   </Grid> */}

    //                   <Grid item xs={6}>
    //                     <KeyboardDatePicker
    //                       clearable
    //                       value={form.last_service_start}
    //                       label="Último Serviço Inicial"
    //                       fullWidth
    //                       margin="dense"
    //                       inputVariant="outlined"
    //                       views={['date']}
    //                       clearLabel="Limpar"
    //                       invalidDateMessage="Data inválida"
    //                       autoOk
    //                       onChange={date =>
    //                         setForm({ ...form, last_service_start: date })
    //                       }
    //                       format="dd/MM/yyyy"
    //                     />
    //                   </Grid>

    //                   <Grid item xs={6}>
    //                     <KeyboardDatePicker
    //                       clearable
    //                       value={form.last_service_end}
    //                       label="Último Serviço Final"
    //                       fullWidth
    //                       margin="dense"
    //                       inputVariant="outlined"
    //                       views={['date']}
    //                       clearLabel="Limpar"
    //                       invalidDateMessage="Data inválida"
    //                       autoOk
    //                       onChange={date =>
    //                         setForm({ ...form, last_service_end: date })
    //                       }
    //                       format="dd/MM/yyyy"
    //                     />
    //                   </Grid>

    //                   <Grid item xs>
    //                     <Button
    //                       type="submit"
    //                       color="primary"
    //                       fullWidth
    //                       variant="contained"
    //                       className={classes.submit}
    //                       onClick={popupState.close}
    //                     >
    //                       <SearchIcon />
    //                       {` Filtrar`}
    //                     </Button>
    //                   </Grid>
    //                 </Grid>
    //               </ValidatorForm>
    //             </div>
    //           </Box>
    //         </Popover>
    //       </div>
    //     )}
    //   </PopupState>
    // </Tooltip>
  )
}
