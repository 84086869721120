/* eslint-disable import/order */
/* eslint-disable camelcase */
import {
  DialogActions,
  FormControlLabel,
  TextField,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  FormHelperText,
  makeStyles,
  Box,
  Typography,
} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import DialogContent from '@material-ui/core/DialogContent'
import Grid from '@material-ui/core/Grid'
import withStyles from '@material-ui/core/styles/withStyles'
import ThumbDownAltOutlinedIcon from '@material-ui/icons/ThumbDownAltOutlined'
import ThumbUpAltOutlinedIcon from '@material-ui/icons/ThumbUp'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'
import { useDispatch, useSelector } from 'react-redux'
import Checkbox from '@material-ui/core/Checkbox'
import IconButton from '@material-ui/core/IconButton'
import PhotoCamera from '@material-ui/icons/PhotoCamera'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { format, setHours } from 'date-fns'
import axios from 'axios'
import ReactInputMask from 'react-input-mask'

import config from '../../../config'
import { SNACKBAR } from '../../main/MainActions'
import SelectField from '../../components/SelectField'
import styles from '../../../resources/theme/users'
import { findCep } from '../LaundryClientActions'
import { mdiProgressUpload } from '@mdi/js'
import Icon from '@mdi/react'
import colors from '../../../assets/colors'
import ufs from '../../../util/uf'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  formControl: {
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  control: {
    padding: theme.spacing(2),
  },
  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '20px',
  },
  footer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '1em',
  },
  uploadLogo: {
    width: '100%',
    height: '100%',
    border: `1px dashed ${colors.primary}`,
    padding: '4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '4px',
    fontSize: '14px',
    borderRadius: '10px',
    transition: 'all .4s ease-in-out',
    '&:hover': {
      backgroundColor: '#FFF',
      opacity: '.6',
    },
  },
  logo: {
    objectFit: 'cover',
    width: '169px',
    height: '32px',
    borderRadius: '10px',
  },
  logoValidate: {
    fontSize: '12px',
    color: colors.graylight,
  },
  btnSalvar: {
    width: '101px',
    height: '30px',
    borderRadius: '16px',
    backgroundColor: colors.primary,
    color: '#FFFF',
    padding: '0 12px',
    margin: 0,
    fontSize: '14px',
    transition: 'all .4s ease-in-out',
    '&:hover': {
      backgroundColor: colors.primary,
      opacity: 0.6,
    },
  },
}))

function Form(props) {
  const classes = useStyles()
  // const plans = useSelector(state => state.laundryClient.plans)
  const { data, onClose, onSubmit, notShowPost, children, formEdit } = props
  const dispatch = useDispatch()

  const [status, setStatus] = useState()

  const inputRef = useRef()

  const [form, updateForm] = useState({
    id: '',
    name: '',
    // max_clients: '',
    // bank_info: '',
    logo: null,
    status: 1,
    statusString: 1,
    // price: '',
    // client_plan: null,
    // plan_date_start: format(Date.now(), 'yyyy-MM-dd'),
    // plan_date_end: format(Date.now() + 1000 * 60 * 60 * 24 * 365, 'yyyy-MM-dd'),
    // due_day: '',
    theme_color: '#93c3ea',
    cep: '',
    street: '',
    number: '',
    complement: '',
    neighborhood: '',
    city: '',
    uf: '',
    phone: '',
    cpf_cnpj: '',
    email: '',
    isFormEdit: formEdit || false,
    // selected_payment_method_ID: '',
    // selected_client_ID: '',
    // selectedPaymentMethod: '',
    // selectedClient: null,
  })

  const [logoValidate, setLogoValidate] = useState({
    invalidType: '',
  })

  const handleChangeLogo = event => {
    if (event.target.files[0] == null) {
      return
    }
    const image = event.target.files[0]
    const load = new FileReader()

    load.onload = event => {
      updateForm({
        ...form,
        logoPath: event.target.result,
      })
    }

    if (image) {
      load.readAsDataURL(image)
    }

    if (
      event.target.files[0].type != 'image/png' &&
      event.target.files[0].type != 'image/jpeg'
    ) {
      updateForm({
        ...form,
        logo: null,
      })
      setLogoValidate({
        ...logoValidate,
        invalidType: 'Tipo de arquivo inválido',
      })
    } else {
      updateForm({
        ...form,
        logo: event.target.files[0],
      })
      setLogoValidate({
        ...logoValidate,
        invalidType: '',
      })
    }
  }

  const handleChooseFile = () => {
    inputRef.current.click()
  }

  useEffect(() => {
    if (!status) {
      const allStatus = async () => {
        const response = await axios.get(
          `${config.API_URL}/string-values?path=laundry_client`
        )

        if (response) {
          setStatus(response.data?.data)
        }
      }

      allStatus()
    }
  }, [form, formEdit])

  const updateField = React.useCallback(name => e => {
    const { value } = e.target

    updateForm({
      ...form,
      [name]: value,
    })
  })

  const statusChange = e => {
    const { name, value } = e.target
    const status = value?.split('.')

    updateForm({
      ...form,
      [name]: status[0],
      statusString: status[1],
    })
  }

  /**
   * @deprecated
   */
  // const onFileChange = event => {
  //   if (event.target.files[0] == null) {
  //     return
  //   }
  //   if (
  //     event.target.files[0].type != 'image/png' &&
  //     event.target.files[0].type != 'image/jpeg'
  //   ) {
  //     updateForm({
  //       ...form,
  //       logo: null,
  //     })
  //     dispatch({
  //       type: SNACKBAR.HARDFAIL,
  //       error: {
  //         message: 'Somente arquivos *.png e *.jpg. Escolha outro arquivo',
  //       },
  //     })
  //   } else {
  //     updateForm({
  //       ...form,
  //       logo: event.target.files[0],
  //     })
  //   }
  // }

  const handleClose = () => onClose && onClose()

  const onSubmitAndRedirect = e => {
    e.preventDefault()
    onSubmit && onSubmit(form, true)
  }

  useEffect(() => {
    if (formEdit) {
      const formatedData = {
        ...data,
        // plan_date_start: data?.plan_date_start
        //   ? `${data.plan_date_start} 12:00:00`
        //   : null,
        // plan_date_end: data?.plan_date_end
        //   ? `${data.plan_date_end} 12:00:00`
        //   : null,
        // selectedPaymentMethod: data.payment_method ? data.payment_method : null,
        // selectedClient: data.client ? data.client : null,
        // selected_payment_method_ID: data.payment_method_id
        //   ? data.payment_method_id
        //   : null,
        // selected_client_ID: data.client_id ? data.client_id : null,
      }
      updateForm({
        ...form,
        ...formatedData,
      })

      // const currentDate = new Date()
      // const monthNumber = currentDate.getMonth() + 1

      // if (data.plan_date_start) {
      //   const dateStartMonth = Number(data.plan_date_start.split('-')[1])
      //   if (dateStartMonth !== monthNumber) {
      //     setIsDatePickerStartDateDisabled(true)
      //   } else {
      //     setIsDatePickerStartDateDisabled(false)
      //   }
      // }

      // if (data.plan_date_end) {
      //   const dateEndMonth = Number(data.plan_date_end.split('-')[1])
      //   if (dateEndMonth !== monthNumber) {
      //     setIsDatePickerEndDateDisabled(true)
      //   } else {
      //     setIsDatePickerStartDateDisabled(false)
      //   }
      // }
    } else {
      const formatedData = {
        ...data,
        // plan_date_start: data?.plan_date_start
        //   ? `${data.plan_date_start} 12:00:00`
        //   : null,
        // plan_date_end: data?.plan_date_end
        //   ? `${data.plan_date_end} 12:00:00`
        //   : null,
      }
      updateForm({
        ...form,
        status: data?.status,
        statusString: data?.statusString,
      })
      updateForm({
        ...form,
        ...formatedData,
      })
    }
  }, [data, formEdit])

  const updateFields = React.useCallback(
    (name, value) => {
      updateForm({
        ...form,
        [name]: value,
      })
    },
    [form]
  )

  // const setDate = (date, formValue) => {
  //   if (date) {
  //     const formated = format(setHours(date, 12), 'yyyy-MM-dd HH:mm:ss')
  //     updateForm({ ...form, [formValue]: formated })
  //   } else {
  //     updateForm({ ...form, [formValue]: null })
  //   }

  //   if (formErrors.plan_date_start) {
  //     setFormErrors({ ...formErrors, plan_date_start: false })
  //   }
  // }

  // const updatePlan = e => {
  //   const plan = plans.find(plan => plan.id == e.target.value)
  //   if (plan) {
  //     updateForm({
  //       ...form,
  //       price: plan.price,
  //       max_clients: plan.max_users,
  //       client_plan: e.target.value,
  //     })
  //   }
  // }

  const buscaCEP = () => {
    const cep = form.cep?.replace(/[^\d]/g, '')
    cep &&
      dispatch(findCep(cep)).then(resp => {
        if (resp && resp.code === 200) {
          const { items } = resp.data
          if (items) {
            updateForm({
              ...form,
              neighborhood: items?.bairro,
              city: items?.cidade,
              uf: items?.uf,
              street: items?.logradouro,
            })
          }
        }
      })
  }

  const handleSubmit = e => {
    e.preventDefault()

    onSubmit && onSubmit(form)
  }

  // const  = async () => {
  //   onSubmitForm()
  // }

  // const date = new Date()
  // const firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
  // const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0)

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              {form?.logo && !form?.logoPath && (
                <Grid item xs={12}>
                  <Grid container justifyContent="flex-end">
                    <Box className={classes.boxLogo}>
                      <img
                        alt="Logo"
                        className={classes.logo}
                        src={`data:image/png;base64,${form?.logo}`}
                      />
                    </Box>
                  </Grid>
                </Grid>
              )}

              {form?.logoPath && (
                <Grid item xs={12}>
                  <Grid container justifyContent="flex-end">
                    <Box className={classes.boxLogo}>
                      <img
                        alt="Logo"
                        className={classes.logo}
                        src={form?.logoPath}
                      />
                    </Box>
                  </Grid>
                </Grid>
              )}

              <Grid item sm={4} xs={12} lg={3}>
                <TextField
                  onChange={updateField('name')}
                  label="Nome"
                  fullWidth
                  required
                  value={form.name}
                />
              </Grid>

              <Grid item sm={4} xs={12} lg={3}>
                <TextField
                  onChange={updateField('email')}
                  label="E-mail"
                  fullWidth
                  required
                  value={form.email}
                />
              </Grid>

              <Grid item sm={4} xs={12} lg={3}>
                <ReactInputMask
                  mask={
                    form.phone?.replace(/[^\d]/g, '').length <= 10
                      ? '(99) 9999-9999?'
                      : '(99) 99999-9999'
                  }
                  formatChars={{ 9: '[0-9]', '?': '[0-9 ]' }}
                  maskChar=""
                  value={form.phone}
                  onChange={e => {
                    updateForm({
                      ...form,
                      phone: e.target.value.replace(/[^\d]/g, ''),
                    })
                  }}
                >
                  {inputProps => (
                    <TextField {...inputProps} label="Telefone" fullWidth />
                  )}
                </ReactInputMask>
              </Grid>

              <Grid item sm={4} xs={12} lg={3}>
                <ReactInputMask
                  mask="(99) 99999-9999"
                  placeholder="(__) _____-____"
                  maskChar=""
                  required
                  value={form?.cell_phone || ' '}
                  onChange={e => {
                    updateForm({
                      ...form,
                      cell_phone: e.target.value.replace(/[^\d]/g, ''),
                    })
                  }}
                >
                  {inputProps => (
                    <TextField {...inputProps} fullWidth label="Celular" />
                  )}
                </ReactInputMask>
              </Grid>

              {/* <Grid item sm={12} xs={12} lg={12}>
            <Grid>
              <label>Logo (169 x 32):</label>
            </Grid>
            <input
              name="logo"
              type="file"
              accept="image/*"
              onChange={onFileChange}
            />
            <label htmlFor="icon-button-photo">
              <IconButton color="primary" component="span">
                <PhotoCamera />
              </IconButton>
            </label>
          </Grid>
          {data?.logo ? (
            <img
              height="50"
              src={`${config.API_URL.slice(0, -3)}/images/logo/${
                data.logo
              }?${Math.random()}`}
              alt="Logo"
            />
          ) : (
            ''
          )} */}
            </Grid>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6} xl={6} container spacing={2}>
            {/* <Grid item sm={12} xs={12} lg={12}>
            <FormControl className={classes.formControl} fullWidth>
              <InputLabel id="demo-simple-select-outlined-label">
                Plano
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={form.client_plan}
                onChange={updatePlan}
                label="Plano"
                error={formErrors.client_plan}
              >
                {plans.length > 1 &&
                  plans?.map(plan => (
                    <MenuItem key={plan.id} value={plan.id}>
                      {plan.name}
                    </MenuItem>
                  ))}
              </Select>
              {formErrors.client_plan && (
                <FormHelperText error>Campo Obrigatório</FormHelperText>
              )}
            </FormControl>
          </Grid>

          <Grid item sm={12} xs={12} lg={12} container spacing={4}>
            <Grid item xs={4}>
              <TextField
                label="Máximo de Clientes"
                type="number"
                onChange={updateField('max_clients')}
                fullWidth
                value={form.max_clients}
                error={formErrors.max_clients}
                helperText={formErrors.max_clients && 'Campo Obrigatório'}
              />
            </Grid>

            <Grid item xs={4}>
              <TextField
                type="number"
                onChange={updateField('due_day')}
                label="Dia do Vencimento"
                fullWidth
                value={form.due_day}
                maxChars={2}
                error={formErrors.due_day}
                helperText={formErrors.due_day && 'Campo Obrigatório'}
                inputProps={{ min: 1, max: 31 }}
              />
            </Grid>

            <Grid item xs={4}>
              <TextField
                type="text"
                isReadOnly
                label="Valor Mensal"
                fullWidth
                value={form.price}
              />
            </Grid>
          </Grid>

          <Grid item sm={12} xs={12} lg={12}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                {formEdit ? (
                  <KeyboardDatePicker
                    clearable
                    value={form.plan_date_start}
                    label="Data Início"
                    fullWidth
                    views={['date']}
                    clearLabel="Limpar"
                    autoOk
                    onChange={date => setDate(date, 'plan_date_start')}
                    format="dd/MM/yyyy"
                    error={formErrors.plan_date_start}
                    helperText={
                      formErrors.plan_date_start && 'Campo Obrigatório'
                    }
                    disabled={isDatePickerStartDateDisabled || false}
                  />
                ) : (
                  <KeyboardDatePicker
                    clearable
                    value={form.plan_date_start}
                    label="Data Início"
                    fullWidth
                    views={['date']}
                    clearLabel="Limpar"
                    invalidDateMessage="Data inválida"
                    autoOk
                    onChange={date => setDate(date, 'plan_date_start')}
                    minDate={firstDay}
                    maxDate={lastDay}
                    format="dd/MM/yyyy"
                    error={formErrors.plan_date_start}
                    helperText={
                      formErrors.plan_date_start && 'Campo Obrigatório'
                    }
                  />
                )}
              </Grid>
              <Grid item xs={6}>
                {formEdit ? (
                  <KeyboardDatePicker
                    clearable
                    value={form.plan_date_end}
                    label="Data Encerramento"
                    fullWidth
                    views={['date']}
                    clearLabel="Limpar"
                    autoOk
                    margin="dense"
                    onChange={date => setDate(date, 'plan_date_end')}
                    format="dd/MM/yyyy"
                    minDate={form.plan_date_start}
                    minDateMessage="Data de encerramento não pode ser antes da data inicial"
                    disabled={isDatePickerEndDateDisabled || false}
                  />
                ) : (
                  <KeyboardDatePicker
                    clearable
                    value={form.plan_date_end}
                    label="Data Encerramento"
                    fullWidth
                    views={['date']}
                    clearLabel="Limpar"
                    invalidDateMessage="Data inválida"
                    autoOk
                    margin="dense"
                    onChange={date => setDate(date, 'plan_date_end')}
                    format="dd/MM/yyyy"
                    minDate={form.plan_date_start}
                    minDateMessage="Data de encerramento não pode ser antes da data inicial"
                  />
                )}
              </Grid>
            </Grid>
          </Grid> */}
          </Grid>
        </Grid>

        <Grid container spacing={3} style={{ marginTop: '12px' }}>
          <Grid item xs={12} container spacing={2}>
            <Grid item sm={3} xs={12}>
              <ReactInputMask
                mask="99.999-999"
                maskChar=""
                value={form.cep}
                onChange={updateField('cep')}
                onBlur={() => buscaCEP()}
              >
                {inputProps => (
                  <TextField {...inputProps} label="CEP" fullWidth required />
                )}
              </ReactInputMask>
            </Grid>

            <Grid item sm={6} xs={12}>
              <TextField
                onChange={updateField('street')}
                label="Rua"
                required
                fullWidth
                value={form.street}
              />
            </Grid>

            <Grid item sm={3} xs={12}>
              <TextField
                onChange={updateField('number')}
                label="Número"
                fullWidth
                required
                value={form.number}
              />
            </Grid>
          </Grid>

          <Grid item xs={12} container spacing={2}>
            <Grid item sm={3} xs={12}>
              <TextField
                onChange={updateField('complement')}
                label="Complemento"
                fullWidth
                value={form.complement}
              />
            </Grid>

            <Grid item sm={3} xs={12}>
              <TextField
                onChange={updateField('neighborhood')}
                label="Bairro"
                fullWidth
                required
                value={form.neighborhood}
              />
            </Grid>

            <Grid item sm={3} xs={12}>
              <TextField
                onChange={updateField('city')}
                label="Cidade"
                fullWidth
                required
                value={form.city}
              />
            </Grid>

            <Grid item sm={3} xs={12}>
              <SelectField
                options={ufs}
                setForm={updateFields}
                data={form.uf}
                name="uf"
                required
                label="UF"
              />
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            container
            spacing={2}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item sm={6} xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <ReactInputMask
                    mask={
                      form.cpf_cnpj?.replace(/[^\d]/g, '').length <= 11
                        ? '999.999.999-99?'
                        : '99.999.999/9999-99'
                    }
                    maskChar=""
                    formatChars={{ 9: '[0-9]', '?': '[0-9 ]' }}
                    value={form.cpf_cnpj}
                    onChange={updateField('cpf_cnpj')}
                  >
                    {inputProps => (
                      <TextField
                        {...inputProps}
                        validators={[
                          'matchRegexp:^([0-9]{2}[.]?[0-9]{3}[.]?[0-9]{3}[/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[.]?[0-9]{3}[.]?[0-9]{3}[-]?[0-9]{2})$',
                        ]}
                        errorMessages={['CPF/CNPJ Inválido']}
                        label="CPF/CNPJ"
                        required
                        fullWidth
                      />
                    )}
                  </ReactInputMask>
                </Grid>

                <Grid item xs={12} sm={6}>
                  {formEdit && (
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-outlined-label">
                        Status
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={form.statusString}
                        onChange={statusChange}
                        label="status"
                        name="status"
                        required
                        renderValue={value => `${value}`}
                      >
                        {status &&
                          status?.map(item => {
                            return (
                              <MenuItem
                                value={`${item?.var_id}.${item?.value}`}
                                key={item?.id}
                              >
                                <em>{item?.value}</em>
                              </MenuItem>
                            )
                          })}
                      </Select>
                    </FormControl>
                  )}
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={3}>
              <input
                ref={inputRef}
                name="logo"
                type="file"
                accept="image/*"
                onChange={handleChangeLogo}
                style={{ display: 'none' }}
              />
              <IconButton
                onClick={handleChooseFile}
                className={classes.uploadLogo}
              >
                <Grid container spacing={1}>
                  <Grid
                    item
                    xs={12}
                    container
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Icon path={mdiProgressUpload} size={1} />
                    Upload Logo
                  </Grid>
                  <Grid item xs={12}>
                    <Typography className={classes.logoValidate}>
                      {logoValidate?.invalidType || 'Logo (169 x 32)'}
                    </Typography>
                  </Grid>
                </Grid>
              </IconButton>
            </Grid>

            <Grid item xs={12} style={{ marginTop: '20px' }}>
              <TextField
                maxRows={10}
                placeholder="Descrição"
                name="description"
                value={form.description == 'null' ? '' : form.description}
                onChange={updateField('description')}
                multiline
                fullWidth
                variant="outlined"
                sx={{ margin: '1em' }}
              />
            </Grid>
          </Grid>
        </Grid>

        <>{children}</>

        <Box className={classes.footer}>
          {notShowPost && (
            <Button color="primary" type="button" onClick={onSubmitAndRedirect}>
              Salvar e agendar
            </Button>
          )}
          <Button color="primary" type="submit" className={classes.btnSalvar}>
            Salvar
          </Button>
          {/* {!notShowPost && <Button onClick={handleClose}>Cancelar</Button>} */}
        </Box>
      </form>
    </>
  )
}

export default withStyles(styles)(Form)
