import colors from '../../assets/colors'

export default theme => ({
  main: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    position: 'fixed',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  animationNono: {
    animation: '$nono',
    animationIterationCount: '2',
    animationDuration: '300ms',
    animationTimingFunction: 'linear',
  },
  animationFade: {
    animation: '$fade',
    animationDuration: '1000ms',
    animationDelay: '0.2s',
    animationFillMode: 'backwards',
  },
  container: {
    width: '100%',
    height: '100%',
    maxWidth: '700px',
    maxHeight: '360px',
    display: 'flex',
    flexDirection: 'row',
    borderRadius: '20px',
    padding: 0,
    boxShadow: '0px 10px 15px 0px #00000040',
    margin: '10px 0',
  },
  wrraperLogo: {
    width: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '20px 0 0 20px',
    background: 'linear-gradient(180deg, #FFFFFF 0%, #D9D9D9 100%)',
  },
  paper: {
    width: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: `${theme.spacing(4)}px`,
    backgroundColor: '#FFFF',
    borderRadius: '0 20px 20px 0',
  },
  logo: {
    width: 468,
  },
  field: {
    width: '100%',
    // marginBottom: theme.spacing(2),
  },
  fieldMargin: {
    width: '100%',
    // marginBottom: theme.spacing(3),
  },
  wrraperButtons: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '1.2em',
    padding: '.8em',
  },
  submit: {
    margin: '10px 0 0 0',
    borderRadius: '15px',
    width: 'auto',
    maxWidth: '84px',
    padding: '10px 30px',
    backgroundColor: colors.primary,
    color: '#FFFF',
    transition: 'all .4s',
    '&:hover': {
      backgroundColor: colors.primary,
      opacity: 0.6,
    },
    fontSize: '10px',
    fontWeight: '400px',
    lineHeight: '10px',
  },
  circular: {
    color: '#fff',
  },
  link: {
    color: theme.palette.primary.main,
  },
  wrraperFooter: {
    display: 'flex',
    flexDirection: 'row',
    gap: '4px',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  '@keyframes nono': {
    '0%, 100%': {
      transform: 'translateX(0%)',
    },
    '35%': {
      transform: 'translateX(-2%)',
    },
    '70%': {
      transform: 'translateX(2%)',
    },
  },
  '@keyframes fade': {
    from: {
      opacity: 0,
      transform: 'scale(0.5)',
    },
    to: {
      opacity: 1,
      transform: 'scale(1)',
    },
  },
})
