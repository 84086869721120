import React from 'react'
import filterStylesComponents from '../../../../../assets/filterComponentsStyles'
import { useDispatch, useSelector } from 'react-redux'
import BaseFilterModal from '../../../../../components/hooks/BaseFilterModal'
import {
  Button,
  Grid,
  InputBase,
  MenuItem,
  TextField,
  TextareaAutosize,
} from '@material-ui/core'
import { createDescription, transferLead } from '../../PanelActions'
import colors from '../../../../../assets/colors'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'

const ModalTransferOs = ({
  open = false,
  handleOpen = () => {},
  leadId = '',
}) => {
  const attendants = useSelector(state => state.crmPanel.attendants)

  const filterStyles = filterStylesComponents()
  const dispatch = useDispatch()
  const history = useHistory()

  const handleSubmit = async e => {
    e.preventDefault()

    const formData = e.currentTarget
    const data = new FormData(formData)
    const entries = data.entries()
    const values = Object.fromEntries(entries)

    try {
      const resp = await dispatch(transferLead(leadId, values))

      if (resp) {
        history.push('/crm/panel')
      }
    } catch (e) {
      //
    }
  }

  return (
    <BaseFilterModal
      open={open}
      title="TRANSFERIR OS"
      hiddenHelpButton
      handleOpen={handleOpen}
      centerTitle
    >
      <Grid item xs={12} style={{ width: '220px' }}>
        <form style={{ width: '100%', height: '100%' }} onSubmit={handleSubmit}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <TextField
                select
                name="attendant_id"
                variant="standard"
                fullWidth
                label="Selecione um atendente"
              >
                <MenuItem value="">Selecione...</MenuItem>

                {attendants?.map((user, index) => (
                  <MenuItem value={user?.id} key={index}>
                    {user?.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                spacing={2}
                alignItems="center"
                justifyContent="center"
              >
                <Button
                  type="submit"
                  className={filterStyles.btnAdd}
                  style={{ textTransform: 'none' }}
                >
                  Salvar
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </BaseFilterModal>
  )
}

export default ModalTransferOs
