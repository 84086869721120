/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable import/extensions */
import AppBar from '@material-ui/core/AppBar'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import withStyles from '@material-ui/core/styles/withStyles'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styles from '../../../resources/theme/global'
import { post } from '../AttendanceActions'
import Form from './Form'
import { Box, Fade, Grid, makeStyles, Slide } from '@material-ui/core'
import BaseCreatePage from '../../../components/hooks/BaseCreatePage'
import colors from '../../../assets/colors'
import {
  mdiAccountEdit,
  mdiCalendarMultiple,
  mdiCommentTextMultiple,
  mdiWater,
} from '@mdi/js'
import Icon from '@mdi/react'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import { renderRoute } from '../../../util/utils'
import Calendar from './Calendar'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const useStyles = makeStyles({
  rowMessage: {
    padding: '8px 0',
    borderTop: '1px dashed',
    borderColor: colors.textSecondary,
    borderBottom: '1px dashed',
    borderBottomColor: colors.textSecondary,
    color: colors.textPrimary,
    fontSize: '14px',
  },
  message: {
    display: 'flex',
    alignItems: 'center',
    height: 'auto',
    fontWeight: 'normal',
  },
  btnCalendar: {
    color: '#FFFF',
    fontSize: '14px',
    backgroundColor: colors.primary,
    borderRadius: '10px',
    padding: '4px 12px',
    transition: 'all .4s ease-in-out',
    '&:hover': {
      opacity: 0.6,
      backgroundColor: colors.primary,
    },
  },
})

function NewPaymentType(props) {
  const { history } = props
  const classes = useStyles()

  const permissions = useSelector(state => state.auth.permissions)

  const dispatch = useDispatch()

  const onSubmit = async (values, redirect) => {
    const data = await dispatch(post(values))
    if (data?.code === 200) {
      if (redirect) {
        history.push(`/calendar/${data.data.id}`)
      } else {
        history.push('/attendance')
      }
    }
  }

  const onClose = () => {
    history.push('/attendance')
  }

  return (
    <BaseCreatePage
      page="Atendimentos"
      path="Cadastro"
      onClose={onClose}
      pageLink="/attendance"
    >
      <Grid
        container
        xs={12}
        alignContent="center"
        justifyContent="center"
        className={classes.rowMessage}
      >
        <Grid item xs={8} className={classes.message}>
          Utilize os campos abaixo para cadastrar um novo atendimento
        </Grid>

        <Grid item xs={4} container justifyContent="flex-end">
          {renderRoute(['read-schedule'], permissions) && (
            <Grid item container justifyContent="flex-end">
              <Calendar classes={classes} />
            </Grid>
          )}
        </Grid>
      </Grid>

      <Grid container item xs={12} style={{ marginTop: '1em' }}>
        <Form
          {...props}
          path="new"
          classes={classes}
          onClose={onClose}
          onSubmit={onSubmit}
        />
      </Grid>
    </BaseCreatePage>
  )
}

export default withStyles(styles)(NewPaymentType)
