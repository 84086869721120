import React, { useEffect, useState } from 'react'
import BaseCreatePage from '../../../../components/hooks/BaseCreatePage'
import { Grid } from '@material-ui/core'
import Filter from './Filter'
import List from './List'
import { useDispatch, useSelector } from 'react-redux'
import { fetchTriggers } from '../TriggersActions'
import { renderRouteRole } from '../../../../util/utils'
import { Route } from 'react-router-dom/cjs/react-router-dom'
import CreateEdit from './create-edit'

const Triggers = () => {
  const role = useSelector(state => state.auth.role)
  const dispatch = useDispatch()

  const [form, setForm] = useState({
    per_page: 10,
    page: 1,
    reason: '',
    type: '',
  })

  useEffect(() => {
    dispatch(fetchTriggers(form))
  }, [form])

  return (
    <>
      <BaseCreatePage
        page="CRM"
        path="Gatilhos"
        pageLink="/crm/triggers"
        hiddenReturn
      >
        <Grid container spacing="3" style={{ marginTop: '1em' }}>
          <Grid item xs={12}>
            <Filter form={form} setForm={setForm} />
          </Grid>
          <Grid item xs={12}>
            <List setForm={setForm} form={form} />
          </Grid>
        </Grid>
      </BaseCreatePage>

      {renderRouteRole(['gerente_lavanderia'], role) && (
        <Route path="/crm/triggers/create-edit/:id" component={CreateEdit} />
      )}
    </>
  )
}

export default Triggers
