import {
  Button,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  withStyles,
} from '@material-ui/core'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import ArrowBack from '@material-ui/icons/ArrowBackIos'
import EventIcon from '@material-ui/icons/Event'

import styles from '../../../resources/theme/global'
import { ready } from '../HomeActions'

import ReadyItemsFilter from './ReadyItemsFilter'
import BaseRelatoryPage from '../../../components/hooks/BaseRelatoryPage'
import {
  StyledTableCellBody,
  StyledTableCellHeader,
} from '../../../components/hooks/BaseTableComponents'

function ReadyItems(props) {
  const dispatch = useDispatch()
  const { classes } = props

  const items = useSelector(state => state.dashboard.readyItems)

  useEffect(() => {
    dispatch(ready())
  }, [])

  return (
    <>
      <BaseRelatoryPage
        title="OSs prontas para entrega"
        btnReturn
        btnReturnPath="/"
      >
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <ReadyItemsFilter classes={classes} />
          </Grid>

          <Grid item xs={12}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCellHeader>Número da OS</StyledTableCellHeader>
                    <StyledTableCellHeader>Cliente</StyledTableCellHeader>
                    <StyledTableCellHeader>Atendente</StyledTableCellHeader>
                    <StyledTableCellHeader align="center">
                      Itens na OS
                    </StyledTableCellHeader>
                    <StyledTableCellHeader align="center">
                      Itens Entregues
                    </StyledTableCellHeader>
                    <StyledTableCellHeader align="center">
                      Itens prontos para entrega
                    </StyledTableCellHeader>
                    <StyledTableCellHeader align="center">
                      Data da Coleta
                    </StyledTableCellHeader>
                    <StyledTableCellHeader align="center">
                      Data de entrega
                    </StyledTableCellHeader>
                    <StyledTableCellHeader />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {items?.map(item => (
                    <TableRow key={item.id}>
                      <TableCell
                        component={Link}
                        to={`/attendance/${item.id}/edit`}
                      >
                        {item.id_laundry_client}
                      </TableCell>
                      <StyledTableCellBody>
                        {item.client_name}
                      </StyledTableCellBody>
                      <StyledTableCellBody>
                        {item.attendant_name}
                      </StyledTableCellBody>
                      <StyledTableCellBody align="center">
                        {item.total_items}
                      </StyledTableCellBody>
                      <StyledTableCellBody align="center">
                        {item.finished_items}
                      </StyledTableCellBody>
                      <StyledTableCellBody align="center">
                        {item.delivering_items}
                      </StyledTableCellBody>
                      <StyledTableCellBody align="center">
                        {item.collected_at}
                      </StyledTableCellBody>
                      <StyledTableCellBody align="center">
                        {item.deadline_date}
                      </StyledTableCellBody>
                      <StyledTableCellBody>
                        <IconButton
                          aria-label="calendar"
                          color="primary"
                          component={Link}
                          to={`/calendar/${item.id}`}
                        >
                          <EventIcon />
                        </IconButton>
                      </StyledTableCellBody>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </BaseRelatoryPage>
      {/* <Grid
        container
        className={classes.pageHead}
        spacing={1}
        alignItems="center"
      >
        <Grid item xs>
          <Typography variant="h4" color="secondary">
            OSs prontas para entrega
          </Typography>
        </Grid>
        <Grid item>
          <ReadyItemsFilter classes={classes} />
        </Grid>
        <Grid item>
          <Button color="primary" variant="contained" component={Link} to="/">
            <ArrowBack className={classes.btnIcon} /> Voltar
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs>
          <Paper elevation={1}>
            <TableContainer className={classes.tableContainer}>
              <Table size="medium" className={classes.tableStyle}>
                <TableHead>
                  <TableRow>
                    <TableCell>Número da OS</TableCell>
                    <TableCell>Cliente</TableCell>
                    <TableCell>Atendente</TableCell>
                    <TableCell>Itens na OS</TableCell>
                    <TableCell>Itens Entregues</TableCell>
                    <TableCell>Itens prontos para entrega</TableCell>
                    <TableCell>Data da Coleta</TableCell>
                    <TableCell>Data de entrega</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {items?.map(item => (
                    <TableRow key={item.id}>
                      {renderOsNumber(item)}
                      <TableCell>{item.client_name}</TableCell>
                      <TableCell>{item.attendant_name}</TableCell>
                      <TableCell>{item.total_items}</TableCell>
                      <TableCell>{item.finished_items}</TableCell>
                      <TableCell>{item.delivering_items}</TableCell>
                      <TableCell>{item.collected_at}</TableCell>
                      <TableCell>{item.deadline_date}</TableCell>
                      <TableCell>
                        <IconButton
                          aria-label="calendar"
                          color="primary"
                          component={Link}
                          to={`/calendar/${item.id}`}
                        >
                          <EventIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      </Grid> */}
    </>
  )
}

export default withStyles(styles)(ReadyItems)
