import React from 'react'
import { Box, makeStyles } from '@material-ui/core'

import colors from '../../../assets/colors'

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: 'auto',
    position: 'relative',
    zIndex: 0,
    padding: '0 4px',
    borderBottom: `1px solid ${colors.graylight}`,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
})

const Tabs = ({ children }) => {
  const classes = useStyles()

  return <Box className={classes.root}>{children}</Box>
}

export default Tabs
