import {
  DialogActions,
  FormControlLabel,
  TextField,
  Select,
  FormControl,
  InputLabel,
  MenuItem
} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import DialogContent from '@material-ui/core/DialogContent'
import Grid from '@material-ui/core/Grid'
import withStyles from '@material-ui/core/styles/withStyles'
import ThumbDownAltOutlinedIcon from '@material-ui/icons/ThumbDownAltOutlined'
import ThumbUpAltOutlinedIcon from '@material-ui/icons/ThumbUp'
import React, { useEffect, useState } from 'react'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'
import { useDispatch } from 'react-redux'
import { SNACKBAR } from '../../main/MainActions'
import config from '../../../config'
import Checkbox from '@material-ui/core/Checkbox'
import SelectField from '../../components/SelectField'
import NumberFormat from '../../components/NumberFormat'


import styles from '../../../resources/theme/users'
import IconButton from '@material-ui/core/IconButton';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { useSelector } from 'react-redux'
import { format, parse, setHours } from 'date-fns'
import { array } from 'prop-types'

function Form(props) {
  const plans = useSelector(
    state => state.laundryClient.plans
  )
  const states = useSelector(
    state => state
  )
  const { data, onClose, onSubmit, notShowPost, children } = props
  const dispatch = useDispatch()

  const [form, updateForm] = useState({
    id: '',
    due_date: format(Date.now(), 'yyyy-MM-dd'),
    is_payed: '',
    laundry_client_id: '',
    obs: "",
    payment_date: format(Date.now(), 'yyyy-MM-dd'),
    reference_date: format(Date.now(), 'yyyy-MM-dd'),
    register_date: format(Date.now(), 'yyyy-MM-dd'),
    user_id : "",
  })

  const updateField = React.useCallback(name => e => {
    updateForm({
      ...form,
      [name]: e.target.value,
    })
  })

  const statusChange = (() => {
    form.is_payed == 1 ? 
    updateForm({
      ...form,
      is_payed: 0,
    })
    :
    updateForm({
      ...form,
      is_payed: 1,
    })
  })

  const onFileChange = event => {
    if (event.target.files[0] == null){return}
    if (event.target.files[0].type != 'image/png' && event.target.files[0].type != 'image/jpeg'){
      updateForm({
        ...form,
        file: null,
      })
      dispatch({
        type: SNACKBAR.HARDFAIL,
        error: { message: 'Somente arquivos *.png e *.jpg. Escolha outro arquivo' },
      })
    }else{
      updateForm({
        ...form,
        file: event.target.files[0],
      })
    }
  
  };

  const handleClose = () => onClose && onClose()

  const onSubmitForm = e => {
    e.preventDefault()
    onSubmit && onSubmit(form)
  }

  const onSubmitAndRedirect = e => {
    e.preventDefault()
    onSubmit && onSubmit(form, true)
  }


  useEffect(() => {    
    let formatedData = {...data, 
      payment_date : data?.payment_date ? data.payment_date + " 12:00:00" : null,
      due_date: data?.due_date ? data.due_date + " 12:00:00" : null,
      reference_date: data?.reference_date ? data.reference_date + " 12:00:00" : null,
      register_date: data?.register_date ? data.register_date + " 12:00:00" : null,
    }
    updateForm({
      ...form, ...formatedData, 
    })
  }, [data])

  const setDate = (date, formValue) => {
    if (date) {
      const formated = format(setHours(date,12), 'yyyy-MM-dd HH:mm:ss')
      updateForm({ ...form, [formValue]: formated })
    }
  }

  return (
    <ValidatorForm>
      <DialogContent>
        <Grid container spacing={2}>
            <Grid item  lg={6}>
              <TextValidator
                label="Nome"
                fullWidth
                disabled
                value={form.laundry_client?.name}
              />
            </Grid>
            
            <Grid item lg={2}>
              <KeyboardDatePicker
                clearable
                value={form.reference_date}
                label="Data de Referência"
                fullWidth
                views={['date']}
                clearLabel="Limpar"
                invalidDateMessage="Data inválida"
                autoOk
                margin="dense"
                inputVariant="outlined"
                disabled
                format="dd/MM/yyyy"
              />
            </Grid>

            <Grid item xs={2}>
              <KeyboardDatePicker
                clearable
                value={form.register_date}
                label="Data de Registro"
                fullWidth
                views={['date']}
                clearLabel="Limpar"
                invalidDateMessage="Data inválida"
                autoOk
                margin="dense"
                inputVariant="outlined"
                disabled
                format="dd/MM/yyyy"
              />
            </Grid>

            <Grid item xs={2}>
              <KeyboardDatePicker
                clearable
                value={form.due_date}
                label="Data de Vencimento"
                fullWidth
                views={['date']}
                clearLabel="Limpar"
                invalidDateMessage="Data inválida"
                autoOk
                margin="dense"
                inputVariant="outlined"
                disabled
                format="dd/MM/yyyy"
              />
            </Grid>

            <Grid item xs={3}>
              <KeyboardDatePicker
                clearable
                value={form.payment_date}
                label="Data de Pagamento"
                fullWidth
                views={['date']}
                clearLabel="Limpar"
                invalidDateMessage="Data inválida"
                autoOk
                margin="dense"
                inputVariant="outlined"
                onChange={date => setDate( date, "payment_date")}
                format="dd/MM/yyyy"
              />
            </Grid>

            <Grid item lg={8}>
              <TextValidator
                validators={['required']}
                errorMessages={['Campo Obrigatório']}
                onChange={updateField('obs')}
                label="Observações"
                fullWidth
                value={form.obs}
              />
            </Grid>

            <Grid item lg={1} >
              <FormControlLabel
                control={
                  <Checkbox
                    icon={<ThumbDownAltOutlinedIcon />}
                    checkedIcon={<ThumbUpAltOutlinedIcon color="secondary" />}
                    checked={form.is_payed}
                    onChange={statusChange}
                    value={form.is_payed}
                  />
                }
                label="Pago?"
              />
            </Grid>

            <Grid style={{ marginTop: 20 }}>
              <Grid>
                <label>Recibo:</label>
              </Grid>
              <input
                name="file"
                type="file"
                accept="image/*"
                onChange={onFileChange}
              />
              <label htmlFor="icon-button-photo">
                <IconButton color="primary" component="span">
                  <PhotoCamera />
                </IconButton>
              </label>
            </Grid>
          
        </Grid>

        <>{children}</>
      </DialogContent>

      <DialogActions>
        {notShowPost && (
          <Button color="primary" type="button" onClick={onSubmitAndRedirect}>
            Salvar e agendar
          </Button>
        )}
        <Button color="primary" onClick={onSubmitForm}>
          Salvar
        </Button>
        {!notShowPost && <Button onClick={handleClose}>Cancelar</Button>}
      </DialogActions>
    </ValidatorForm>
  )
}

export default withStyles(styles)(Form)
