import AppBar from '@material-ui/core/AppBar'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import Slide from '@material-ui/core/Slide'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import CloseIcon from '@material-ui/icons/Close'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { format } from 'date-fns'

import styles from '../../../resources/theme/users'
import { billPaid, edit } from '../BillingActions'

import Form from './FormClose'
import BaseFilterModal from '../../../components/hooks/BaseFilterModal'
import { Grid } from '@material-ui/core'
import colors from '../../../assets/colors'

function Transition(props) {
  return <Slide direction="up" {...props} />
}

function CloseDetails(props) {
  const { classes, match, history, location } = props
  const dispatch = useDispatch()
  const [typeGeneric, setTypeGeneric] = useState('')

  const onSubmit = values => {
    const { id } = match.params
    const data = {
      ...values,
      actual_payment_date: values.actual_payment_date
        ? format(new Date(values.actual_payment_date), 'yyyy-MM-dd')
        : '',
    }
    dispatch(billPaid(id, data, typeGeneric)).then(data => {
      if (data && data.code === 200) {
        history.push('/billing')
      }
    })
  }

  const onClose = () => {
    history.push('/billing')
  }

  useEffect(() => {
    dispatch(edit(match.params.id))
  }, [])

  useEffect(() => {
    const { state } = location

    if (state?.typeGeneric) {
      setTypeGeneric(state?.typeGeneric)
    }
  }, [])

  const item = useSelector(state => state.billing.editingBilling)

  return (
    <BaseFilterModal open handleOpen={onClose} title="Detalhes do pagamento">
      <Grid container xs={12} item direction="row" style={{ width: '380px' }}>
        <Grid item xs={12}>
          <Typography
            style={{ fontSize: '16px', fontWeight: 'normal' }}
            color={colors.primary}
          >
            Utilize os campos abaixo para adicionar o pagamento da conta
          </Typography>
        </Grid>

        <Grid item xs={12} style={{ marginTop: '1em' }}>
          <Form onClose={onClose} onSubmit={onSubmit} item={item} />
        </Grid>
      </Grid>
    </BaseFilterModal>
  )
}

export default withStyles(styles)(CloseDetails)
