/* eslint-disable no-shadow */
/* eslint-disable simple-import-sort/sort */
import { Grid } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link, Route } from 'react-router-dom'
import { bindActionCreators } from 'redux'

import styles from '../../../resources/theme/global'
import { renderRoute } from '../../../util/utils'
import { fetchLaundryClients } from '../LaundryClientBillingsActions'
import { getLaundryClientListData } from '../LaundryClientBillingsReducer'
import { useSelector } from 'react-redux'


import Filter from './Filter'
import List from './List'
import NewClient from './New'
import EditClient from './Edit'

class laundryClientBillings extends Component {
  componentDidMount() {
    // const { laundryClients } = this.props
    // fetchLaundryClients()
  }
  
  

  render() {
    const { classes, permissions } = this.props
    return (
      <>
        <Grid
          container
          className={classes.pageHead}
          spacing={1}
          alignItems="center"
        >
          <Grid item xs>
            <Typography variant="h4" color="secondary">
              Faturas de Cliente Lavanderia
            </Typography>
          </Grid>
          <Grid item>
            <Filter classes={classes} />
          </Grid>
          <Grid item>
            {/* {renderRoute(['create-client'], permissions) && (
              <Button
                color="primary"
                variant="contained"
                component={Link}
                to="/laundry-client-billings/new"
              >
                <AddCircleOutlineIcon className={classes.btnIcon} /> Cadastrar
              </Button>
            )} */}
          </Grid>
        </Grid>

        {/* {renderRoute(['laundry-client-billing-create'], permissions) && (
          <Route path="/laundry-client-billings/new" component={NewClient} />
        )}
        {renderRoute(['laundry-client-billing-edit'], permissions) && (
          <Route path="/laundry-client-billings/:id/edit" component={EditClient} />
        )} */}

        <List {...this.props} classes={classes} />
      </>
    )
  }
}

const mapStateToProps = state => ({
  ...getLaundryClientListData(state),
  permissions: state.auth.permissions || [],
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchLaundryClients,
    },
    dispatch
  )
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(laundryClientBillings))
