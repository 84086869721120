/* eslint-disable jsx-a11y/tabindex-no-positive */
/* eslint-disable import/order */
/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react'
import BaseRelatoryPage from '../../../components/hooks/BaseRelatoryPage'
import { Grid } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import Tabs from '../../../components/hooks/BaseTabs/Tabs'
import Tab from '../../../components/hooks/BaseTabs/Tab'
import colors from '../../../assets/colors'
import { mdiCurrencyUsdOff } from '@mdi/js'
import Filter from './Filter'
import { fetchStockRelatoryInit, makeRelatory } from '../StockRelatoryActions'
import { fetchStock } from '../../stock/StockActions'
import List from './List'

const StockRelatory = () => {
  const dispatch = useDispatch()

  const [value, setValue] = useState(2)

  const [form, setForm] = useState({
    technical_id: '',
    product_id: '',
    date_start: '',
    date_end: '',
    rows: 10,
    page: 1,
  })

  useEffect(() => {
    dispatch(fetchStockRelatoryInit(value))
    dispatch(fetchStock())
  }, [])

  useEffect(() => {
    dispatch(fetchStockRelatoryInit(value, form))
  }, [value, form])

  const handleRelatory = e => {
    e.preventDefault()
    dispatch(makeRelatory(form, value))
  }

  return (
    <BaseRelatoryPage title="Estoque">
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} style={{ marginTop: '1em' }}>
          <Tabs>
            <Tab
              tabIndex={2}
              value={value}
              setValue={setValue}
              removeNavigation={false}
              iconColor={colors.error}
              label="Saída"
              icon={mdiCurrencyUsdOff}
            />
            <Tab
              tabIndex={1}
              value={value}
              setValue={setValue}
              removeNavigation={false}
              iconColor={colors.sucess}
              label="Entrada"
              icon={mdiCurrencyUsdOff}
            />
          </Tabs>
        </Grid>

        <Grid item xs={12}>
          <Filter
            form={form}
            setForm={setForm}
            movementType={value}
            handleReport={handleRelatory}
          />
        </Grid>

        <Grid item xs={12}>
          <List form={form} setForm={setForm} type={value} />
        </Grid>
      </Grid>
    </BaseRelatoryPage>
  )
}

export default StockRelatory
