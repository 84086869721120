/* eslint-disable prefer-template */
import React from 'react'
import {
  Card,
  CardContent,
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core'
import classnames from 'classnames'
import CountUp from 'react-countup'
import colors from '../../../assets/colors'
import { getDecimalPart } from '../../../util/utils'

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    borderRadius: '10px',
    border: `1px solid ${colors.graylight}`,
    color: colors.primary,
  },
  differenceValue: {
    color: colors.sucess,
    marginRight: theme.spacing(1),
  },
  differenceValueNegative: {
    color: colors.error,
    marginRight: theme.spacing(1),
  },
  noDifference: {
    color: colors.graylight,
    marginRight: theme.spacing(1),
  },
  value: {
    fontWeight: '700',
    fontSize: '20px',
    lineHeight: '20px',
  },
  title: {
    color: colors.primary,
    fontSize: '20px',
    lineHeight: '20px',
  },
  cents: {
    fontSize: '13px',
    fontWeight: '400',
  },
}))

const BankCard = ({ total, tipo, positive, justRight }) => {
  const classes = useStyles()

  const contUp = money => {
    return (
      <CountUp
        end={money}
        duration={0.7}
        // decimals={2}
        decimal=","
        // prefix="R$"
        separator="."
      />
    )
  }

  const contUpDecimals = money => {
    if (money === 0) return ',00'
    const decimalPart = getDecimalPart(money)
    return <>{',' + decimalPart}</>
  }

  return (
    <Card className={classes.root} elevation="0">
      <CardContent>
        <Grid container justifyContent="space-between" spacing={3}>
          <Grid item>
            <Typography className={classes.title}>{tipo}</Typography>
            <Typography className={classes.cents}>R$</Typography>
            <Typography
              // color="textPrimary"
              // variant="h5"
              // className={classnames({
              //   [classes.differenceValue]: positive,
              //   [classes.differenceValueNegative]: !positive,
              // })}
              className={classes.value}
            >
              {contUp(total)}
              <span className={classes.cents}>{contUpDecimals(total)}</span>
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default BankCard
