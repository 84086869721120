import {
  Checkbox,
  DialogActions,
  FormControlLabel,
  Switch,
} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import DialogContent from '@material-ui/core/DialogContent'
import Grid from '@material-ui/core/Grid'
import ThumbDownAltOutlinedIcon from '@material-ui/icons/ThumbDownAltOutlined'
import ThumbUpAltOutlinedIcon from '@material-ui/icons/ThumbUp'
import React, { useEffect, useState } from 'react'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'
import filterStylesComponents from '../../../assets/filterComponentsStyles'

function Form(props) {
  const { data, onClose, onSubmit } = props

  const [form, updateForm] = useState({
    id: '',
    name: '',
    status: 1,
  })

  const styles = filterStylesComponents()

  const updateField = React.useCallback(name => e => {
    updateForm({
      ...form,
      [name]: e.target.value,
    })
  })

  const handleClose = () => onClose && onClose()

  const onSubmitForm = e => {
    e.preventDefault()
    const send = { ...form, status: form.status || 0 }
    onSubmit && onSubmit(send)
  }

  useEffect(() => {
    updateForm({ ...form, ...data })
  }, [data])

  return (
    <ValidatorForm onSubmit={onSubmitForm}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextValidator
            validators={['required']}
            errorMessages={['Campo Obrigatório']}
            onChange={updateField('name')}
            label="Nome"
            fullWidth
            value={form.name}
          />
        </Grid>

        <Grid item xs={12}>
          <Grid
            item
            xs={12}
            container
            justifyContent="space-between"
            alignItems="flex-end"
          >
            <Grid item>
              <Switch
                checked={Boolean(form.status)}
                onChange={e => {
                  updateForm({ ...form, status: !form.status })
                }}
              />
              Ativar como soube
            </Grid>
            <Grid item>
              <Button
                className={styles.btnSalvar}
                color="primary"
                type="submit"
              >
                Salvar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ValidatorForm>
  )
}

export default Form
