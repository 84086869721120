import React from 'react'
import { Grid, TextField } from '@material-ui/core'
import InputMask from 'react-input-mask'

function Metro2({ updateChildren, chave, item, styles }) {
  const handleChangeMetrics = e => {
    e.preventDefault()
    const { name, value } = e.target

    const inputValue = value?.replace(',', '.')

    updateChildren(chave, inputValue, name, true)
  }

  return (
    <>
      <Grid
        container
        direction="row"
        justify="space-around"
        alignItems="center"
        spacing={1}
      >
        <Grid item xs={4}>
          <TextField
            name="base"
            type="number"
            className={styles?.input}
            onChange={handleChangeMetrics}
            label="Base"
            validators={['required']}
            errorMessages={['Campo necessário']}
            fullWidth
            value={item.options.base ? item.options.base : ''}
            style={{ maxWidth: '100px' }}
          />
        </Grid>
        {/* X */}
        <Grid item xs={4}>
          <TextField
            name="altura"
            type="number"
            className={styles?.input}
            onChange={handleChangeMetrics}
            label="Altura"
            validators={['required']}
            errorMessages={['Campo necessário']}
            fullWidth
            value={item.options.altura ? item.options.altura : ''}
            style={{ maxWidth: '100px' }}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default Metro2
