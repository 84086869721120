/* eslint-disable import/order */
/* eslint-disable camelcase */
import React from 'react'
import BaseFilterModal from '../../../../components/hooks/BaseFilterModal'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { Grid } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import Form from './Form'
import { storeProduct } from '../../StockActions'

const NewProduct = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const handleOpen = () => {
    history.push('/stock')
  }

  const onSubmit = async form => {
    const resp = await dispatch(storeProduct(form))

    if (resp) {
      handleOpen()
    }
  }

  return (
    <BaseFilterModal
      open
      handleOpen={handleOpen}
      title="Cadastrar Produto"
      hiddenHelpButton
    >
      <Grid item xs={12} style={{ width: '220px' }}>
        <Form onSubmit={onSubmit} />
      </Grid>
    </BaseFilterModal>
  )
}

export default NewProduct
