/* eslint-disable import/order */
import React, { useState } from 'react'
import BaseFilter from '../../../components/hooks/BaseFilter'
import filterStylesComponents from '../../../assets/filterComponentsStyles'
import { Box, Grid, IconButton, Switch } from '@material-ui/core'
import Icon from '@mdi/react'
import { mdiCalendarAccount, mdiCalendarClock } from '@mdi/js'
import colors from '../../../assets/colors'
import { renderRoute } from '../../../util/utils'
import TechnicalAbsencesModal from './TechnicalAusencyModal'
import { useSelector } from 'react-redux'
import { Link, Route } from 'react-router-dom'
import EnableAppModal from './EnableAppModal'

const Header = ({
  showServices = false,
  setShowServices = useState(false),
}) => {
  const permissions = useSelector(state => state.auth.permissions)
  const classes = filterStylesComponents()

  return (
    <BaseFilter>
      <Grid item xs={12} className={classes.areaButtons}>
        <Box className={classes.btnMoreFilters}>
          <Switch
            checked={showServices}
            onClick={() => setShowServices(!showServices)}
          />
          Mostrar Serviços
        </Box>

        {renderRoute(['update-users'], permissions) && (
          <IconButton
            className={classes.btnMoreFilters}
            component={Link}
            to="/calendar/technical-absences"
          >
            <Icon path={mdiCalendarAccount} size={1} color={colors.secondary} />
            Ausentar técnico
          </IconButton>
        )}

        {renderRoute(['update-users'], permissions) && (
          <IconButton
            className={classes.btnMoreFilters}
            component={Link}
            to="/calendar/enable-app"
          >
            <Icon path={mdiCalendarClock} size={1} color={colors.secondary} />
            Habilitar visualização no APP
          </IconButton>
        )}
      </Grid>

      {renderRoute(['update-users'], permissions) && (
        <Route
          path="/calendar/technical-absences"
          component={TechnicalAbsencesModal}
        />
      )}

      {renderRoute(['update-users'], permissions) && (
        <Route path="/calendar/enable-app" component={EnableAppModal} />
      )}
    </BaseFilter>
  )
}

export default Header
