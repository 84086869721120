/* eslint-disable no-shadow */
/* eslint-disable simple-import-sort/sort */
import { Grid } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import FileCopy from '@material-ui/icons/FileCopy'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link, Route } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import axios from 'axios'

import styles from '../../../resources/theme/global'
import { renderRoute } from '../../../util/utils'
import { fetchLaundryClients } from '../LaundryClientActions'
import { getLaundryClientListData } from '../LaundryClientReducer'
import config from '../../../config'

import Filter from './Filter'
import List from './List'
import NewClient from './New'
import EditClient from './Edit'
import colors from '../../../assets/colors'

class LaundryClients extends Component {
  componentDidMount() {
    // const { laundryClients } = this.props
    // fetchLaundryClients()
  }

  render() {
    const { classes, permissions, plans, items } = this.props

    return (
      <>
        <Grid
          container
          className={classes.pageHead}
          spacing={1}
          alignItems="center"
        >
          <Grid item xs={12}>
            <Typography
              variant="h4"
              style={{ fontSize: '20px' }}
              color={colors.primary}
            >
              Cliente Lavanderia
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Filter classes={classes} plans={plans} items={items} />
          </Grid>

          <Grid item xs={12}>
            <List {...this.props} classes={classes} />
          </Grid>
        </Grid>

        {renderRoute(['create-laundry-client'], permissions) && (
          <Route path="/laundry-clients/new" component={NewClient} />
        )}
        {renderRoute(['update-laundry-client'], permissions) && (
          <Route path="/laundry-clients/:id/edit" component={EditClient} />
        )}
      </>
    )
  }
}

const mapStateToProps = state => ({
  ...getLaundryClientListData(state),
  permissions: state.auth.permissions || [],
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchLaundryClients,
    },
    dispatch
  )
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(LaundryClients))
