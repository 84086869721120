/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from 'react'
import {
  DialogActions,
  FormControlLabel,
  Switch,
  TextField as TextFieldOr,
} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import DialogContent from '@material-ui/core/DialogContent'
import Grid from '@material-ui/core/Grid'
import ThumbDownAltOutlinedIcon from '@material-ui/icons/ThumbDownAltOutlined'
import ThumbUpAltOutlinedIcon from '@material-ui/icons/ThumbUp'
import { Field, Formik, Form } from 'formik'
import { TextField, Checkbox } from 'formik-material-ui'
import * as yup from 'yup'

import NumberFormatCustom from '../../components/NumberFormat'
import filterStylesComponents from '../../../assets/filterComponentsStyles'

function BankForm(props) {
  const { data, onClose, onSubmit } = props

  const classes = filterStylesComponents()

  const handleClose = () => onClose && onClose()

  const [initialValues, setInitialValues] = useState({
    id: '',
    name: '',
    status: 1,
    current_balance: 0,
    agency: '',
    account: '',
  })

  useEffect(() => {
    if (data) {
      setInitialValues(data)
    }
  }, [data])

  const schema = yup.object().shape({
    name: yup.string().required('Obrigatório'),
  })

  return (
    <Formik
      key={initialValues.id}
      initialValues={initialValues}
      enableReinitialize
      validationSchema={schema}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false)
        onSubmit && onSubmit(values)
      }}
    >
      {({
        submitForm,
        isSubmitting,
        handleChange,
        touched,
        errors,
        values,
      }) => (
        <Form>
          <Grid container spacing={1}>
            <Grid item xs>
              <Field
                component={TextField}
                name="name"
                label="Nome"
                fullWidth
                error={touched.name && !!errors.name}
                helperText={touched.name && errors.name}
              />
            </Grid>
            {!values.id && (
              <Grid item xs>
                <Field name="current_balance">
                  {({ field }) => (
                    <TextFieldOr
                      {...field}
                      label="Valor inicial"
                      onChange={handleChange('current_balance')}
                      fullWidth
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                      }}
                    />
                  )}
                </Field>
              </Grid>
            )}
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs>
              <Field
                component={TextField}
                name="agency"
                label="Agência"
                fullWidth
                error={touched.agency && !!errors.agency}
                helperText={touched.agency && errors.agency}
              />
            </Grid>

            <Grid item xs>
              <Field
                component={TextField}
                name="account"
                label="Conta"
                fullWidth
                error={touched.account && !!errors.account}
                helperText={touched.account && errors.account}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs>
              {/* <FormControlLabel
                control={
                  <Field
                    icon={<ThumbDownAltOutlinedIcon />}
                    checkedIcon={<ThumbUpAltOutlinedIcon color="secondary" />}
                    component={Checkbox}
                    type="checkbox"
                    name="status"
                  />
                }
                label="Ativo?"
              /> */}
              <Switch
                checked={initialValues.status}
                onChange={() =>
                  setInitialValues({
                    ...initialValues,
                    status: initialValues.status === 1 ? 0 : 1,
                  })
                }
              />
              Ativo
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            container
            alignItems="center"
            justifyContent="center"
          >
            <Button
              disabled={isSubmitting}
              onClick={submitForm}
              className={classes.btnSalvar}
            >
              Salvar
            </Button>
            {/* <Button onClick={handleClose}>Cancelar</Button> */}
          </Grid>
        </Form>
      )}
    </Formik>
  )
}

export default BankForm
