/* eslint-disable consistent-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable prefer-template */
/* eslint-disable import/order */
/* eslint-disable camelcase */
import {
  Button,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import filterStylesComponents from '../../../assets/filterComponentsStyles'
import {
  mdiBank,
  mdiCurrencyUsd,
  mdiFilterMultiple,
  mdiMagnify,
  mdiMicrosoftExcel,
} from '@mdi/js'
import Icon from '@mdi/react'
import colors from '../../../assets/colors'
import { useDispatch, useSelector } from 'react-redux'
import CountUp from 'react-countup'
import { getDecimalPart } from '../../../util/utils'
import { bankReport, makeReport } from '../BillingActions'
import BaseFilterModal from '../../../components/hooks/BaseFilterModal'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { SNACKBAR } from '../../main/MainActions'

const useStyles = makeStyles({
  filterButton: {
    fontSize: '13px',
    lineHeight: '10px',
    color: colors.primary,
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
  filterButtonIcon: {
    width: '30px',
    height: '30px',
  },
  total: {
    flex: 1,
    color: colors.graylight,
    fontSize: '24px',
    lineHeight: '24px',
  },
  positive: {
    color: colors.sucess,
  },
  negative: {
    color: colors.error,
  },
  cents: {
    fontSize: '16px',
    fontWeight: '400',
  },
})

const BankReportFilter = props => {
  const styles = filterStylesComponents()
  const classes = useStyles()
  const dispatch = useDispatch()
  const banks = useSelector(state => state.billing?.banks)
  const bankBalance = useSelector(state => state.billing?.bankBalance)
  const billingTypes = useSelector(state => state.billing.billingTypes)

  const [open, setOpen] = useState(false)

  const [form, setForm] = useState({
    bank_id: '',
    date: null,
    date_to: null,
    bill_type_id: null,
    payment_date_start: null,
    payment_date_end: null,
  })

  const handleOpen = () => {
    setOpen(!open)
  }

  const handleChange = e => {
    e.preventDefault()
    const { name, value } = e.target

    setForm({ ...form, [name]: value })
  }

  const contUp = money => {
    if (!money) {
      return (
        <CountUp end={0} duration={0.8} prefix="R$" decimal="," separator="." />
      )
    }

    // only integer part
    const integerPart = money?.split('.')

    return (
      <CountUp
        end={integerPart[0] || 0}
        duration={0.8}
        prefix="R$"
        decimal=","
        separator="."
      />
    )
  }

  const contUpDecimals = money => {
    if (money === 0) return ',00'
    const decimalPart = getDecimalPart(money)
    return <>{',' + decimalPart}</>
  }

  useEffect(() => {
    dispatch(bankReport(form))
  }, [form.bank_id])

  const handleSubmit = () => {
    dispatch(bankReport(form))
    handleOpen()
  }

  const handleRelatoryExport = () => {
    if (!form.bank_id) {
      return dispatch({
        type: SNACKBAR.INFO,
        success: { message: 'Selecione um banco.' },
      })
    }

    dispatch(makeReport(form, form.bank_id))
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={8}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} className={classes.filterButton}>
            <Icon path={mdiBank} className={classes.filterButtonIcon} />
            <Typography>
              Selecione
              <br />
              um banco:
            </Typography>

            <TextField
              select
              name="bank_id"
              onChange={handleChange}
              value={form?.bank_id}
              style={{ flex: 1 }}
            >
              <MenuItem value="">Selecione</MenuItem>
              {banks?.map(bank => (
                <MenuItem value={bank?.id} key={bank?.id}>
                  {bank?.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6} className={classes.filterButton}>
            <Icon path={mdiCurrencyUsd} className={classes.filterButtonIcon} />
            <Typography>
              Saldo
              <br />
              atual:
            </Typography>
            <Typography
              className={[
                classes.total,
                bankBalance?.balance > 0 ? classes.positive : '',
                bankBalance?.balance < 0 ? classes.negative : '',
              ]}
            >
              {contUp(bankBalance?.balance || 0)}
              {/* {bankBalance?.balance || 0} */}
              <span className={classes.cents}>
                {contUpDecimals(bankBalance?.balance || 0)}
              </span>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={4} container className={styles.areaButtons}>
        <IconButton className={styles.btnMoreFilters} onClick={handleOpen}>
          <Icon path={mdiFilterMultiple} size={1} color={colors.secondary} />
          Outros Filtros
        </IconButton>

        <IconButton className={styles.btnAdd} onClick={handleRelatoryExport}>
          <Icon path={mdiMicrosoftExcel} size={1} />
          Exportar
        </IconButton>
      </Grid>

      <BaseFilterModal open={open} handleOpen={handleOpen} title="FILTRO">
        <Grid
          container
          alignItems="center"
          item
          xs={12}
          justifyContent="flex-start"
          style={{ width: '420px' }}
          spacing={1}
        >
          <Grid item xs={12}>
            <Typography
              style={{ fontSize: '16px', fontWeight: 'normal' }}
              color={colors.primary}
            >
              Utilize os campos para encontrar uma conta
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item md={6} sm={6} xs={12}>
                <KeyboardDatePicker
                  clearable
                  value={form.date}
                  label="Data do vencimento inicial"
                  fullWidth
                  clearLabel="Limpar"
                  invalidDateMessage="Data inválida"
                  autoOk
                  onChange={date => setForm({ ...form, date })}
                  format="dd/MM/yyyy"
                />
              </Grid>

              <Grid item md={6} sm={6} xs={12}>
                <KeyboardDatePicker
                  clearable
                  value={form.date_to}
                  label="Data do vencimento final"
                  fullWidth
                  clearLabel="Limpar"
                  invalidDateMessage="Data inválida"
                  autoOk
                  onChange={date_to => setForm({ ...form, date_to })}
                  format="dd/MM/yyyy"
                />
              </Grid>

              <Grid item md={6} sm={6} xs={12}>
                <KeyboardDatePicker
                  clearable
                  value={form.payment_date_start}
                  label="Data de pagamento inicial"
                  fullWidth
                  clearLabel="Limpar"
                  invalidDateMessage="Data inválida"
                  autoOk
                  onChange={payment_date_start =>
                    setForm({
                      ...form,
                      payment_date_start,
                    })
                  }
                  format="dd/MM/yyyy"
                />
              </Grid>

              <Grid item md={6} sm={6} xs={12}>
                <KeyboardDatePicker
                  clearable
                  value={form.payment_date_end}
                  label="Data de pagamento final"
                  fullWidth
                  clearLabel="Limpar"
                  invalidDateMessage="Data inválida"
                  autoOk
                  onChange={payment_date_end =>
                    setForm({
                      ...form,
                      payment_date_end,
                    })
                  }
                  format="dd/MM/yyyy"
                />
              </Grid>

              <Grid item sm={12}>
                <TextField
                  onChange={e =>
                    setForm({ ...form, bill_type_id: e.target.value })
                  }
                  select
                  label="Tipo de Conta"
                  className={classes.inputFilter}
                  fullWidth
                  value={form.bill_type_id}
                >
                  <MenuItem key="" value="">
                    Selecione...
                  </MenuItem>
                  {billingTypes?.map(billingType => (
                    <MenuItem key={billingType.id} value={billingType.id}>
                      {billingType.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid
                item
                xs
                container
                justifyContent="center"
                alignITems="center"
              >
                <Button className={styles.btnSalvar} onClick={handleSubmit}>
                  <Icon path={mdiMagnify} size={1} />
                  {` Filtrar`}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </BaseFilterModal>
    </Grid>
  )
}

export default BankReportFilter
