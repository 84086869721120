import {
  GET_OPERADORES_RUA,
  GET_SERVICES_UNBOOKED,
  TECHNICAL_ABSENCE,
  FIND_TECHNICAL_ABSENCE,
  ENABLE_APP_DAYS,
} from './CalendarActions'

const INITIAL_STATE = {
  attendants: [],
  unbookedServices: [],
  technicalAbsences: [],
  enableAppDates: [],
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_OPERADORES_RUA.SUCCESS:
      return {
        ...state,
        attendants: action.payload,
      }
    case GET_SERVICES_UNBOOKED.SUCCESS:
      return {
        ...state,
        unbookedServices: action.payload,
      }
    case TECHNICAL_ABSENCE.SUCCESS:
      return state
    case FIND_TECHNICAL_ABSENCE.SUCCESS:
      return {
        ...state,
        technicalAbsences: action.payload,
      }
    case ENABLE_APP_DAYS.SUCCESS:
      return {
        ...state,
        enableAppDates: action.payload.data,
      }
    default:
      return state
  }
}
