/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import {
  TextField as MuiTextField,
  Box,
  Button,
  Grid,
  IconButton,
  Popover,
  Tooltip,
  TextField,
  MenuItem,
  InputAdornment,
} from '@material-ui/core'
import FilterListIcon from '@material-ui/icons/FilterList'
import SearchIcon from '@material-ui/icons/Search'
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state'
import React from 'react'
import { Formik, Form, Field } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { KeyboardDatePicker } from 'formik-material-ui-pickers'
import { useLocation, useParams } from 'react-router'
import { Autocomplete } from 'formik-material-ui-lab'

import { details, late, makeReport } from '../HomeActions'
import BaseFilter from '../../../components/hooks/BaseFilter'
import filterStylesComponents from '../../../assets/filterComponentsStyles'
import {
  mdiFilterMultiple,
  mdiMagnifyExpand,
  mdiMicrosoftExcel,
  mdiTable,
} from '@mdi/js'
import colors from '../../../assets/colors'
import Icon from '@mdi/react'

export default function Filter(props) {
  const dispatch = useDispatch()
  const { detailsId, filters = {}, setFilters, handleModalOpen } = props
  // const { id } = useParams()
  // const location = useLocation()
  const statuses = useSelector(state => state.dashboard.statuses)
  const styles = filterStylesComponents()

  const handleExportExcel = e => {
    e.preventDefault()
    dispatch(makeReport(detailsId, filters))
  }

  const handleChange = e => {
    e.preventDefault()

    const { name, value } = e.target

    setFilters({
      ...filters,
      [name]: value,
    })
  }

  return (
    <BaseFilter>
      <Grid container>
        <Grid item xs={6}>
          <Grid container spacing={2}>
            {/* <Grid item xs={12} md={6} sm={6}>
              <TextField
                label="Filtre por cliente"
                InputLabelProps={{
                  style: {
                    fontSize: '12px',
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton>
                        <Icon
                          path={mdiMagnifyExpand}
                          size={1}
                          color={colors.graylight}
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />
            </Grid> */}
            <Grid item xs={12} md={6} sm={6}>
              <TextField
                label="Filtre por status"
                InputLabelProps={{
                  style: {
                    fontSize: '12px',
                  },
                }}
                fullWidth
                name="status_id"
                onChange={handleChange}
                select
                value={filters?.status_id}
              >
                <MenuItem value="">Selecione..</MenuItem>
                {statuses?.map(status => (
                  <MenuItem key={status?.id} value={status?.id}>
                    {status?.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} container className={styles.areaButtons}>
          <Grid item>
            <IconButton
              className={styles.btnMoreFilters}
              onClick={() => handleModalOpen()}
            >
              <Icon path={mdiTable} size={1} color={colors.secondary} />
              Colunas da tabela
            </IconButton>
          </Grid>

          <Grid item>
            <IconButton className={styles.btnMoreFilters} disabled>
              <Icon
                path={mdiFilterMultiple}
                size={1}
                color={colors.secondary}
              />
              Filtros
            </IconButton>
          </Grid>

          <Grid item>
            <IconButton className={styles.btnAdd} onClick={handleExportExcel}>
              <Icon path={mdiMicrosoftExcel} size={1} />
              Download
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    </BaseFilter>
    // <Tooltip title="Filter list">
    //   <PopupState variant="popover" popupId="demo-popup-popover">
    //     {popupState => (
    //       <div>
    //         <IconButton aria-label="filter list" {...bindTrigger(popupState)}>
    //           <FilterListIcon />
    //         </IconButton>
    //         <Popover
    //           {...bindPopover(popupState)}
    //           anchorOrigin={{
    //             vertical: 'bottom',
    //             horizontal: 'center',
    //           }}
    //           transformOrigin={{
    //             vertical: 'top',
    //             horizontal: 'center',
    //           }}
    //         >
    //           <Box p={2} className={classes.popover}>
    //             <div className={classes.root}>
    //               <Formik
    //                 initialValues={{
    //                   status: null,
    //                   date: null,
    //                 }}
    //                 onSubmit={(values, { setSubmitting }) => {
    //                   setSubmitting(false)
    //                   if (location.pathname.includes('late')) {
    //                     dispatch(late(values))
    //                   } else {
    //                     dispatch(details(id, values))
    //                   }
    //                 }}
    //               >
    //                 {({ submitForm, isSubmitting }) => (
    //                   <Form className={classes.form}>
    //                     <Grid
    //                       container
    //                       className={classes.gridContainer}
    //                       spacing={1}
    //                       justify="center"
    //                       alignItems="center"
    //                     >
    //                       <Grid item xs={6}>
    //                         <Field
    //                           name="status"
    //                           component={Autocomplete}
    //                           options={statuses}
    //                           getOptionSelected={(option, value) =>
    //                             option.id === value.id
    //                           }
    //                           getOptionLabel={option =>
    //                             typeof option === 'string'
    //                               ? option
    //                               : option.name
    //                           }
    //                           renderInput={params => (
    //                             <MuiTextField
    //                               {...params}
    //                               label="Status"
    //                               margin="dense"
    //                               variant="outlined"
    //                             />
    //                           )}
    //                         />
    //                       </Grid>

    //                       <Grid item xs={6}>
    //                         <Field
    //                           component={KeyboardDatePicker}
    //                           name="date"
    //                           clearLabel="Limpar"
    //                           label="Data da Coleta"
    //                           margin="dense"
    //                           openTo="year"
    //                           fullWidth
    //                           clearable
    //                           orientation="landscape"
    //                           format="dd/MM/yyyy"
    //                           inputVariant="outlined"
    //                           autoOk
    //                         />
    //                       </Grid>

    //                       <Grid item xs>
    //                         <Button
    //                           color="primary"
    //                           fullWidth
    //                           type="submit"
    //                           variant="contained"
    //                           className={classes.submit}
    //                           onClick={popupState.close}
    //                         >
    //                           <SearchIcon />
    //                           {` Filtrar`}
    //                         </Button>
    //                       </Grid>
    //                     </Grid>
    //                   </Form>
    //                 )}
    //               </Formik>
    //             </div>
    //           </Box>
    //         </Popover>
    //       </div>
    //     )}
    //   </PopupState>
    // </Tooltip>
  )
}
