import axios from 'axios/index'

import config from '../../config'
import action, { actionFailed } from '../../util/action'
import createAsyncConst from '../../util/createAsyncConst'

export const FETCH_STATUS = createAsyncConst('FETCH_STATUS')
export const CHANGE_VALUE = createAsyncConst('CHANGE_VALUE')
export const UPLOAD_DOC_MODEL = createAsyncConst('UPLOAD_DOC_MODEL')
export const DOWNLOAD_APK = createAsyncConst('DOWNLOAD_APK')
export const DOWNLOAD_FILE = createAsyncConst('DOWNLOAD_FILE')

export function fetchStatuses() {
  return dispatch => {
    dispatch(action(FETCH_STATUS.ACTION))
    return axios
      .get(`${config.API_URL}/change-status-settings`)
      .then(resp => {
        dispatch(action(FETCH_STATUS.SUCCESS, resp.data))
        return resp
      })
      .catch(e => dispatch(actionFailed(FETCH_STATUS, e)))
  }
}

export function changeValueStatus(slug, value) {
  return dispatch => {
    dispatch(action(CHANGE_VALUE.ACTION))
    return axios
      .put(`${config.API_URL}/change-status-settings/${slug}`, { value })
      .then(resp => {
        dispatch(action(CHANGE_VALUE.SUCCESS, resp.data))
        return resp
      })
      .catch(e => dispatch(actionFailed(CHANGE_VALUE, e)))
  }
}

export function uploadDocModel(file) {
  return dispatch => {
    dispatch(action(UPLOAD_DOC_MODEL.ACTION))
    return axios
      .post(`${config.API_URL}/upload-doc-model`, file)
      .then(resp => {
        dispatch(action(UPLOAD_DOC_MODEL.SUCCESS, resp.data))
        return resp
      })
      .catch(e => dispatch(actionFailed(UPLOAD_DOC_MODEL, e)))
  }
}

export function getCurrentFileUrl() {
  return dispatch => {
    dispatch(action(DOWNLOAD_FILE.ACTION))
    return axios
      .get(`${config.API_URL}/get-current-file-url`)
      .then(async response => {
        dispatch(action(DOWNLOAD_FILE.SUCCESS, {}))
        return response
      })
      .catch(e => dispatch(actionFailed(DOWNLOAD_FILE, e)))
  }
}

export function downloadAndroidApk() {
  return dispatch => {
    dispatch(action(DOWNLOAD_APK.ACTION))
    return axios
      .get(`${config.API_URL}/download-app/android`, {
        responseType: 'blob',
        validateStatus: status => status <= 500,
      })
      .then(async response => {
        if (response.status !== 200) {
          const error = JSON.parse(await response.data.text())
          dispatch(
            actionFailed(DOWNLOAD_APK, {
              response: {
                data: error,
              },
            })
          )
        } else {
          dispatch(action(DOWNLOAD_APK.SUCCESS, {}))
          const blob = new Blob([response.data], { type: response.data.type })
          const url = window.URL.createObjectURL(blob)
          const link = document.createElement('a')
          link.href = url
          const contentDisposition = response.headers['content-disposition']
          let fileName = `unknown.${response.data.type}`
          if (contentDisposition) {
            const fileNameMatch = contentDisposition.match(/filename=(.+)/)
            if (fileNameMatch.length === 2) {
              fileName = fileNameMatch[1].replaceAll('"', '')
            }
          }
          link.setAttribute('download', fileName)
          document.body.appendChild(link)
          link.click()
          link.remove()
          window.URL.revokeObjectURL(url)
        }
      })
  }
}
