import { FETCH_PRODUCT_HISTORY } from './ProductHistoryActions'

const INITIAL_STATE = {
  history: [],
  technicals: [],
  users: [],
  meta: [],
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_PRODUCT_HISTORY.SUCCESS:
      return {
        ...state,
        history: action.payload.data.data,
        technicals: action.payload.data.technicals,
        users: action.payload.data.users,
        meta: action.payload.data.meta,
      }
    default:
      return state
  }
}
