/* eslint-disable no-unused-vars */
import axios from 'axios/index'

import config from '../../config'
import action, { actionFailed } from '../../util/action'
import consts from '../../util/consts'
import createAsyncConst from '../../util/createAsyncConst'

export const FETCH_SUBSCRIPTION = createAsyncConst('FETCH_SUBSCRIPTION')
export const SUBMIT_SUBSCRIPTION = createAsyncConst('SUBMIT_SUBSCRIPTION')
export const FETCH_PAYMENT_HISTORY = createAsyncConst('FETCH_PAYMENT_HISTORY')
export const STORE_CREDIT_CARD = createAsyncConst('STORE_CREDIT_CARD')
export const SHOW_CREDIT_CARD = createAsyncConst('SHOW_CREDIT_CARD')
export const DESTROY_CREDIT_CARD = createAsyncConst('DESTROY_CREDIT_CARD')

export function fetchPlanDetails() {
  return dispatch => {
    dispatch(action(FETCH_SUBSCRIPTION.ACTION))
    return axios
      .get(`${config.API_URL}/subscription`, {
        // params: {
        //   filters: ,
        // },
      })
      .then(resp => {
        dispatch(
          action(FETCH_SUBSCRIPTION.SUCCESS, {
            data: resp.data,
          })
        )
        return resp
      })
      .catch(e => dispatch(actionFailed(FETCH_SUBSCRIPTION, e)))
  }
}

export function submitSubscription(form) {
  return dispatch => {
    dispatch(action(SUBMIT_SUBSCRIPTION.ACTION))
    return axios
      .post(`${config.API_URL}/subscription`, form)
      .then(resp => resp.data)
      .then(data => {
        dispatch(action(SUBMIT_SUBSCRIPTION.SUCCESS, data))
        dispatch(fetchPlanDetails())
        return true
      })
      .catch(e => {
        dispatch(actionFailed(SUBMIT_SUBSCRIPTION, e))
        return false
      })
  }
}

export function updateSubscriptionPlan(form, id) {
  return dispatch => {
    dispatch(action(SUBMIT_SUBSCRIPTION.ACTION))
    return axios
      .put(`${config.API_URL}/subscription/${id}`, form)
      .then(resp => resp.data)
      .then(data => {
        dispatch(action(SUBMIT_SUBSCRIPTION.SUCCESS, data))
        dispatch(fetchPlanDetails())
        return true
      })
      .catch(e => {
        dispatch(actionFailed(SUBMIT_SUBSCRIPTION, e))
        return false
      })
  }
}

export function updateSubscriptionPaymentMethod(form, id) {
  return dispatch => {
    dispatch(action(SUBMIT_SUBSCRIPTION.ACTION))
    return axios
      .put(`${config.API_URL}/subscription/payment-method/${id}`, form)
      .then(resp => resp.data)
      .then(data => {
        dispatch(action(SUBMIT_SUBSCRIPTION.SUCCESS, data))
        dispatch(fetchPlanDetails())
        return true
      })
      .catch(e => {
        dispatch(actionFailed(SUBMIT_SUBSCRIPTION, e))
        return false
      })
  }
}

export function storeCard(form) {
  return dispatch => {
    dispatch(action(STORE_CREDIT_CARD.ACTION))
    return axios
      .post(`${config.API_URL}/credit-card`, form)
      .then(resp => resp.data)
      .then(data => {
        dispatch(action(STORE_CREDIT_CARD.SUCCESS, data))
        dispatch(fetchPlanDetails())
        return true
      })
      .catch(e => {
        dispatch(actionFailed(STORE_CREDIT_CARD, e))
        return false
      })
  }
}

export function updateCard(form, id) {
  return dispatch => {
    dispatch(action(STORE_CREDIT_CARD.ACTION))
    return axios
      .put(`${config.API_URL}/credit-card/${id}`, form)
      .then(resp => resp.data)
      .then(data => {
        dispatch(action(STORE_CREDIT_CARD.SUCCESS, data))
        dispatch(fetchPlanDetails())
        return true
      })
      .catch(e => {
        dispatch(actionFailed(STORE_CREDIT_CARD, e))
        return false
      })
  }
}

export function destroyCard(id) {
  return dispatch => {
    dispatch(action(DESTROY_CREDIT_CARD.ACTION))
    return axios
      .delete(`${config.API_URL}/credit-card/${id}`)
      .then(resp => resp.data)
      .then(data => {
        dispatch(action(DESTROY_CREDIT_CARD.SUCCESS, data))
        dispatch(fetchPlanDetails())
        return true
      })
      .catch(e => {
        dispatch(actionFailed(DESTROY_CREDIT_CARD, e))
        return false
      })
  }
}

export function showCreditCard(id) {
  return dispatch => {
    dispatch(action(SHOW_CREDIT_CARD.ACTION))
    return axios
      .get(`${config.API_URL}/credit-card/${id}`)
      .then(data => {
        dispatch(action(SHOW_CREDIT_CARD.SUCCESS, data))
      })
      .catch(e => {
        dispatch(actionFailed(SHOW_CREDIT_CARD, e))
      })
  }
}
