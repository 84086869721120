/* eslint-disable import/order */
import { MenuItem, Typography } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Popover from '@material-ui/core/Popover'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import FilterListIcon from '@material-ui/icons/FilterList'
import SearchIcon from '@material-ui/icons/Search'
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state'
import React, { useState } from 'react'
import { ValidatorForm } from 'react-material-ui-form-validator'
import { useDispatch, useSelector } from 'react-redux'

import BadgeGlobal from '../../components/Badge'
import {
  downloadImportTemplate,
  fetchServiceType,
  makeRelatory,
} from '../ServiceTypeActions'
import SelectField from '../../components/SelectField'
import BaseFilter from '../../../components/hooks/BaseFilter'
import Icon from '@mdi/react'
import {
  mdiFilterMultiple,
  mdiMicrosoftExcel,
  mdiNoteEditOutline,
  mdiSwapVertical,
} from '@mdi/js'
import filterStylesComponents from '../../../assets/filterComponentsStyles'
import colors from '../../../assets/colors'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import { renderRoute } from '../../../util/utils'
import BaseFilterModal from '../../../components/hooks/BaseFilterModal'

function Filter(props) {
  const items = useSelector(state => state.serviceType.items)
  const measureType = useSelector(state => state.serviceType.measureType)
  const attendanceType = useSelector(state => state.serviceType.attendanceType)
  const serviceCategory = useSelector(
    state => state.serviceType.serviceCategory
  )

  const dispatch = useDispatch()
  const { classes, permissions } = props

  const styles = filterStylesComponents()

  const [open, setOpen] = useState(false)

  const [form, setForm] = useState({
    name: '',
    status: '',
    measureType: '',
    serviceCategory: '',
    attendanceTypeId: '',
  })

  const updateFun = React.useCallback(
    (name, value) => {
      setForm({
        ...form,
        [name]: value,
      })
    },
    [form]
  )

  const handleOpen = () => {
    setOpen(!open)
  }

  const onSubmit = e => {
    e.preventDefault()
    dispatch(fetchServiceType(form))
    handleOpen()
  }
  const handleDownloadTemplate = () => {
    dispatch(downloadImportTemplate())
  }

  const handleRelatoryClick = () => {
    dispatch(makeRelatory(form))
  }

  return (
    <>
      <BaseFilter>
        <Grid
          item
          container
          xs={12}
          justifyContent="flex-end"
          className={styles.areaButtons}
        >
          <Grid item>
            <IconButton className={styles.btnMoreFilters} onClick={handleOpen}>
              <Icon
                path={mdiFilterMultiple}
                className={styles.btnIcon}
                size={1}
                color={colors.secondary}
              />
              Filtrar
            </IconButton>
          </Grid>

          <Grid item className={styles.areaButtons}>
            <IconButton
              component={Link}
              to="/service-type/import"
              className={styles.btnMoreFilters}
            >
              <Icon path={mdiSwapVertical} size={1} color={colors.secondary} />
              Importar Tipo de Serviço
            </IconButton>

            <Button
              className={styles.downloadTemplate}
              onClick={handleDownloadTemplate}
            >
              (baixar modelo)
            </Button>
          </Grid>

          <Grid item>
            <IconButton className={styles.btnAdd}>
              <Icon
                path={mdiMicrosoftExcel}
                size={1}
                onClick={handleRelatoryClick}
              />
              Download
            </IconButton>
          </Grid>
          <Grid item>
            {renderRoute(['create-service-types'], permissions) && (
              <IconButton
                className={styles.btnAdd}
                component={Link}
                to="/service-type/new"
              >
                <Icon path={mdiNoteEditOutline} size={1} />
                Cadastrar
              </IconButton>
            )}
          </Grid>
        </Grid>
      </BaseFilter>

      <BaseFilterModal title="FILTRO" open={open} handleOpen={handleOpen}>
        <Grid
          container
          xs={12}
          item
          direction="column"
          style={{ width: '367px' }}
        >
          <Grid item xs={12}>
            <Typography
              style={{ fontSize: '16px', fontWeight: 'normal' }}
              color={colors.primary}
            >
              Utilize os campos abaixo para encontrar <br /> um tipo de serviço
            </Typography>
          </Grid>

          <Grid item container xs={12}>
            <ValidatorForm className={classes.form} onSubmit={onSubmit}>
              <Grid
                container
                className={classes.gridContainer}
                spacing={1}
                justify="center"
                alignItems="center"
              >
                <Grid item xs={6}>
                  <TextField
                    onChange={e => setForm({ ...form, name: e.target.value })}
                    label="Nome"
                    className={classes.inputFilter}
                    fullWidth
                    value={form.name}
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    onChange={e => setForm({ ...form, status: e.target.value })}
                    select
                    label="Status"
                    className={classes.inputFilter}
                    fullWidth
                    value={form.status}
                  >
                    <MenuItem key="" value="">
                      Selecione...
                    </MenuItem>
                    <MenuItem key={0} value={0}>
                      Inativo
                    </MenuItem>
                    <MenuItem key={1} value={1}>
                      Ativo
                    </MenuItem>
                  </TextField>
                </Grid>

                <Grid item xs={12} md={6}>
                  <SelectField
                    options={measureType}
                    setForm={updateFun}
                    data={form.measureType}
                    name="measureType"
                    label="Tipo de Cobrança"
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    onChange={e =>
                      setForm({ ...form, attendanceTypeId: e.target.value })
                    }
                    select
                    label="Tipo de serviço"
                    className={classes.inputFilter}
                    fullWidth
                    value={form.attendanceTypeId}
                  >
                    <MenuItem key="" value="">
                      Selecione...
                    </MenuItem>
                    {attendanceType?.map(type => (
                      <MenuItem key={type?.id} value={type?.id}>
                        {type?.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item xs={12}>
                  <SelectField
                    options={serviceCategory}
                    setForm={updateFun}
                    data={form.serviceCategory}
                    name="serviceCategory"
                    label="Categoria"
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  style={{ marginTop: '1.5em' }}
                  container
                  justifyContent="center"
                  alignItems="center"
                >
                  <Button type="submit" className={styles.btnAdd}>
                    <SearchIcon />
                    {` Pesquisar`}
                  </Button>
                </Grid>
              </Grid>
            </ValidatorForm>
          </Grid>
        </Grid>
      </BaseFilterModal>
    </>
    // <Tooltip title="Filter list">
    //   <PopupState variant="popover" popupId="demo-popup-popover">
    //     {popupState => (
    //       <div>
    //         <IconButton aria-label="filter list" {...bindTrigger(popupState)}>
    //           <BadgeGlobal form={form} items={items}>
    //             <FilterListIcon />
    //           </BadgeGlobal>
    //         </IconButton>
    //         <Popover
    //           {...bindPopover(popupState)}
    //           anchorOrigin={{
    //             vertical: 'bottom',
    //             horizontal: 'center',
    //           }}
    //           transformOrigin={{
    //             vertical: 'top',
    //             horizontal: 'center',
    //           }}
    //         >
    //           <Box p={2} className={classes.popover}>
    //             <div className={classes.root}>

    //             </div>
    //           </Box>
    //         </Popover>
    //       </div>
    //     )}
    //   </PopupState>
    // </Tooltip>
  )
}

export default Filter
