import React from 'react'
import { useSelector } from 'react-redux'

import { renderRoute } from '../../../util/utils'

// import ChangeStatus from './changeStatus'
import DownloadAPK from './DownloadAPK'
import UploadDocModel from './UploadDocModel'

function Settings() {
  const permissions = useSelector(state => state.auth.permissions)
  return (
    <>
      {/* {renderRoute(['change-status-settings'], permissions) && <ChangeStatus />} */}
      {renderRoute(['update-doc-model'], permissions) && <UploadDocModel />}
      {renderRoute(['download-apk'], permissions) && <DownloadAPK />}
    </>
  )
}

export default React.memo(Settings)
