/* eslint-disable import/no-extraneous-dependencies */
import './fonts/poppins.css'
import 'typeface-roboto'

import { createMuiTheme } from '@material-ui/core/styles'

export const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#93c3ea',
      main: '#539fdd',
      dark: '#2679be',
      contrastText: '#fff',
    },
    secondary: {
      light: '#93c3ea',
      main: '#539fdd',
      dark: '#2679be',
      contrastText: '#fff',
    },
    danger: {
      light: '#ffebee',
      main: '#e84118',
    },
  },
  typography: {
    fontSize: 14,
    color: '#2d2f33',
    useNextVariants: true,
    fontFamily: 'Roboto',
    h4: {
      fontWeight: 'bold',
    },
    body1: {
      fontSize: 14,
    },
  },
  icon: 'theme',
})
