/* eslint-disable jsx-a11y/tabindex-no-positive */
/* eslint-disable import/order */
/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react'
import BaseRelatoryPage from '../../../components/hooks/BaseRelatoryPage'
import { Grid } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import Filter from './Filter'
import List from './List'
import {
  fetchCommissionRelatory,
  makeRelatory,
} from '../RelatoryCommissionActions'

const RelatoryCommission = () => {
  const dispatch = useDispatch()

  const [form, setForm] = useState({
    technical_id: '',
    product_id: '',
    date_start: '',
    date_end: '',
    rows: 10,
    page: 1,
  })

  useEffect(() => {
    dispatch(fetchCommissionRelatory(form))
  }, [])

  useEffect(() => {
    dispatch(fetchCommissionRelatory(form))
  }, [form])

  const handleRelatory = e => {
    e.preventDefault()
    dispatch(makeRelatory(form))
  }

  return (
    <BaseRelatoryPage title="Comissões">
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12}>
          <Filter form={form} setForm={setForm} handleReport={handleRelatory} />
        </Grid>

        <Grid item xs={12}>
          <List form={form} setForm={setForm} />
        </Grid>
      </Grid>
    </BaseRelatoryPage>
  )
}

export default RelatoryCommission
