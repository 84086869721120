import { Grid } from "@material-ui/core"
import React from "react"

const PaymentHistory = () => {

    return(
        <Grid>
            Payment History
        </Grid>
    )
}

export default PaymentHistory