/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable import/order */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable eqeqeq */
/* eslint-disable no-return-assign */
/* eslint-disable object-shorthand */
/* eslint-disable camelcase */
import {
  ClickAwayListener,
  Grid,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  makeStyles,
} from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import {
  StyledTableCellBody,
  StyledTableCellHeader,
} from '../../../../../components/hooks/BaseTableComponents'
import { exportFormatedMoney } from '../../../../../util/utils'

const ServiceItemsTable = ({ items = [] }) => {
  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <StyledTableCellHeader align="left">
              Tipo de serviço
            </StyledTableCellHeader>

            <StyledTableCellHeader align="right">
              Valor do item
            </StyledTableCellHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {items?.map((item, index) => (
            <TableRow key={index}>
              <StyledTableCellBody align="left">
                {item?.name}
              </StyledTableCellBody>
              <StyledTableCellBody align="right">
                {exportFormatedMoney(item?.value || 0)}
              </StyledTableCellBody>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default ServiceItemsTable
