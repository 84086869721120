/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable eqeqeq */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
import React, { useEffect, useState } from 'react'
import Tabs from '../../../components/hooks/BaseTabs/Tabs'
import Tab from '../../../components/hooks/BaseTabs/Tab'
import {
  mdiAccountCard,
  mdiAccountCreditCard,
  mdiAccountFile,
  mdiCreditCard,
  mdiInvoiceTextEditOutline,
} from '@mdi/js'
import {
  Box,
  Button,
  Divider,
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
  makeStyles,
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import CountUp from 'react-countup'
import colors from '../../../assets/colors'
import Cards from 'react-credit-cards-2'
import 'react-credit-cards-2/dist/es/styles-compiled.css'
import { exportFormatedMoney } from '../../../util/utils'
import InputMask from 'react-input-mask'
import cardValidator from 'card-validator'
import '../../../resources/css/credit-card.css'
import brandVisaPath from '../../../../public/card_brands/visa_brand.png'
import brandMastercardPath from '../../../../public/card_brands/mastercard_brand.png'
import { format, parse } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import Icon from '@mdi/react'
import {
  submitSubscription,
  updateSubscriptionPaymentMethod,
  updateSubscriptionPlan,
} from '../SubscriptionActions'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const useStyles = makeStyles({
  btnSalvar: {
    padding: '4px 12px',
    fontSize: '17px',
    lineHeight: '17px',
    fontWeight: 400,
    color: '#FFF',
    backgroundColor: colors.primary,
    textTransform: 'capitalize',
    borderRadius: '15px',
    transition: 'all .4s ease-in-out',
    '&:hover': {
      backgroundColor: colors.primary,
      opacity: '.7',
    },
  },
  btnVoltar: {
    marginRight: '.5em',
    backgroundColor: colors.graylight,
    color: colors.primary,
    '&:hover': {
      backgroundColor: colors.graylight,
    },
  },
  creditCard: {
    border: `1px solid ${colors.graylight}`,
    borderRadius: '10px',
    display: 'flex',
    padding: '8px',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    transition: 'all .4s ease-in-out',
    '&:hover': {
      opacity: '1 !important',
    },
    cursor: 'pointer',
  },
  detailsBox: {
    marginTop: '2em',
    border: `1px solid ${colors.graylight}`,
    borderRadius: '10px',
    padding: '4px',
  },
})

const Form = ({ planDetails = null, edit = false }) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const history = useHistory()

  const paymentMethods = useSelector(state => state.subscription.paymentMethods)
  const allPlans = useSelector(state => state.subscription.allPlans)
  const creditCards = useSelector(state => state.subscription.creditCards)
  const [value, setValue] = useState(0)

  const [form, setForm] = useState({
    plan_id: '',
    name: '',
    price: 0,
    maxClients: '',
    due_day: '',
    payment_method_id: '',
    card_id: '',
  })

  const handleChange = e => {
    e.preventDefault()

    const { name, value } = e.target

    setForm({
      ...form,
      [name]: value,
    })
  }

  const handleSelectCreditCard = id => {
    setForm({
      ...form,
      card_id: id,
    })
  }

  const handleSubmit = async e => {
    e.preventDefault()

    if (edit) {
      if (value == 0) {
        const resp = await dispatch(
          updateSubscriptionPlan(form, planDetails?.id)
        )

        if (resp) {
          history.push('/subscription')
        }
      }

      if (value == 1) {
        const resp = await dispatch(
          updateSubscriptionPaymentMethod(form, planDetails?.id)
        )

        if (resp) {
          history.push('/subscription')
        }
      }
    } else {
      const resp = await dispatch(submitSubscription(form))

      if (resp) {
        history.push('/subscription')
      }
    }
  }

  useEffect(() => {
    if (planDetails) {
      setForm({
        ...form,
        plan_id: planDetails?.plan_id,
        due_day: planDetails?.due_date,
        payment_method_id: planDetails?.payment_method?.var_id,
        card_id: planDetails?.credit_card_active,
      })
    }
  }, [planDetails])

  useEffect(() => {
    if (form?.plan_id) {
      const filteredPlan = allPlans?.filter(plan => plan?.id == form?.plan_id)

      if (filteredPlan[0]) {
        setForm({
          ...form,
          name: filteredPlan[0]?.name,
          maxClients: filteredPlan[0]?.max_users,
          price: filteredPlan[0]?.price,
        })
      }
    }
  }, [form?.plan_id])

  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center">
      {edit && (
        <Grid item xs={12} style={{ marginTop: '1em' }}>
          <Tabs>
            <Tab
              label="Plano"
              value={value}
              setValue={setValue}
              icon={mdiAccountFile}
              tabIndex={0}
              removeNavigation={false}
            />
            <Tab
              label="Método de pagamento"
              value={value}
              setValue={setValue}
              icon={mdiAccountCreditCard}
              tabIndex={1}
              removeNavigation={false}
            />
          </Tabs>
        </Grid>
      )}

      <Grid item sm={10} md={6}>
        <form onSubmit={handleSubmit}>
          <Grid
            container
            spacing={2}
            className={classes.detailsBox}
            alignItems="flex-end"
          >
            {value == 0 && (
              <>
                <Grid item xs={12}>
                  <TextField
                    label="Plano"
                    name="plan_id"
                    required
                    onChange={handleChange}
                    select
                    fullWidth
                    value={form.plan_id}
                  >
                    <MenuItem value="">Selecione...</MenuItem>
                    {allPlans?.map(plan => (
                      <MenuItem value={plan?.id} key={plan?.id}>
                        {plan?.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item sm={4} xs={12}>
                  <TextField
                    fullWidth
                    value={exportFormatedMoney(form.price)}
                    label="Preço"
                    name="price"
                    InputLabelProps={{
                      style: {
                        color: colors.graylight,
                      },
                    }}
                    disabled
                  />
                </Grid>

                <Grid item sm={4} xs={12}>
                  <TextField
                    fullWidth
                    name="maxClients"
                    label="Máximo de clientes"
                    value={form.maxClients}
                    disabled
                  />
                </Grid>

                <Grid item sm={4} xs={12}>
                  <TextField
                    fullWidth
                    name="due_day"
                    onChange={handleChange}
                    label="Dia de vencimento"
                    required
                    value={form.due_day}
                    type="number"
                    inputProps={{ min: 1, max: 27 }}
                  />
                </Grid>
                {!edit && (
                  <>
                    <Grid item md={12} sm={12} xs={12}>
                      <TextField
                        fullWidth
                        label="Método de pagamento"
                        select
                        name="payment_method_id"
                        required
                        value={form.payment_method_id}
                        onChange={handleChange}
                      >
                        <MenuItem value="">Selecione...</MenuItem>
                        {paymentMethods?.map(paymentMethod => (
                          <MenuItem
                            value={paymentMethod?.var_id}
                            key={paymentMethod?.var_id}
                          >
                            {paymentMethod?.value}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>

                    {/* quando o método de pagamento for cartão de crédito */}
                    {form?.payment_method_id == 10 && (
                      <>
                        {creditCards?.map(card => (
                          <Grid item md={6} sm={12} key={card?.id}>
                            <RenderCreditCard
                              card={card}
                              form={form}
                              handleSelectCreditCard={handleSelectCreditCard}
                            />
                          </Grid>
                        ))}
                      </>
                    )}
                  </>
                )}
              </>
            )}

            {value == 1 && (
              <>
                <Grid item md={12} sm={12} xs={12}>
                  <TextField
                    fullWidth
                    label="Método de pagamento"
                    select
                    name="payment_method_id"
                    required
                    value={form.payment_method_id}
                    onChange={handleChange}
                  >
                    <MenuItem value="">Selecione...</MenuItem>
                    {paymentMethods?.map(paymentMethod => (
                      <MenuItem
                        value={paymentMethod?.var_id}
                        key={paymentMethod?.var_id}
                      >
                        {paymentMethod?.value}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                {/* quando o método de pagamento for cartão de crédito */}
                {form?.payment_method_id == 10 && (
                  <>
                    {creditCards?.map(card => (
                      <Grid item md={6} sm={12} key={card?.id}>
                        <RenderCreditCard
                          card={card}
                          form={form}
                          handleSelectCreditCard={handleSelectCreditCard}
                        />
                      </Grid>
                    ))}
                  </>
                )}
              </>
            )}

            <Grid
              item
              xs={12}
              container
              alignItems="center"
              justifyContent="flex-end"
            >
              <Button className={classes.btnSalvar} type="submit">
                {edit ? 'Atualizar' : 'Salvar'}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  )
}

export default Form

const RenderCreditCard = ({
  card,
  form,
  handleSelectCreditCard = () => {},
}) => {
  const classes = useStyles()

  const cardBrandPath = brand => {
    if (brand == 'visa') {
      return brandVisaPath
    }

    if (brand == 'mastercard') {
      return brandMastercardPath
    }

    return ''
  }

  const cardExpiryDate = date => {
    const parsedDate = parse(`01/${date}`, 'dd/MM/yy', new Date())

    return format(parsedDate, "'Expira em' MMMM 'de' yyyy", { locale: ptBR })
  }

  return (
    <Box
      className={classes.creditCard}
      style={{
        opacity: form?.card_id == card?.id ? 1 : 0.3,
      }}
      onClick={() => handleSelectCreditCard(card?.id)}
    >
      <Box>
        <Grid container justifyContent="space-between">
          <Grid
            item
            style={{
              width: '40px',
              height: '40px',
              borderRadius: '4px',
              border: `1px solid ${colors.graylight}`,
              fontSize: '40px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {cardBrandPath(card?.brand) ? (
              <img
                src={cardBrandPath(card?.brand)}
                alt="Card Brand"
                width="24px"
                height="12px"
                style={{ objectFit: 'contain' }}
              />
            ) : (
              <Icon path={mdiCreditCard} size={1} color={colors.primary} />
            )}
          </Grid>
          <Grid item xs>
            <Grid
              container
              alignItems="flex-start"
              justifyContent="flex-start"
              style={{ padding: '0 8px' }}
            >
              <Grid
                item
                xs={12}
                style={{
                  fontSize: '14px',
                  fontWeight: '700',
                  textTransform: 'capitalize',
                }}
              >
                {`${card?.brand} ... ${card?.last_four_digits}`}
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  fontSize: '11px',
                  lineHeight: '14px',
                }}
              >
                {cardExpiryDate(card?.expiry)}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}
