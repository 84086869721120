/* eslint-disable object-shorthand */
import axios from 'axios'

import config from '../../config'
import action, { actionFailed } from '../../util/action'
import createAsyncConst from '../../util/createAsyncConst'

export const FETCH_COMMISSION_RELATORY = createAsyncConst(
  'FETCH_COMMISSION_RELATORY'
)
export const MAKE_COMMISSION_RELATORY = createAsyncConst(
  'MAKE_COMMISSION_RELATORY'
)

export function fetchCommissionRelatory(filters = []) {
  return dispatch => {
    dispatch(action(FETCH_COMMISSION_RELATORY.ACTION))
    return axios
      .get(`${config.API_URL}/commission-relatory`, {
        params: filters,
      })
      .then(resp => {
        dispatch(action(FETCH_COMMISSION_RELATORY.SUCCESS, { data: resp.data }))
        return resp
      })
      .catch(e => dispatch(actionFailed(FETCH_COMMISSION_RELATORY, e)))
  }
}

export function makeRelatory(data) {
  return dispatch => {
    return axios({
      url: `${config.API_URL}/commission-relatory/export`,
      method: 'POST',
      responseType: 'blob',
      headers: { Accept: 'application/vnd.ms-excel' },
      params: data,
    })
      .then(resp => {
        const url = window.URL.createObjectURL(new Blob([resp.data]))
        const link = document.createElement('a')

        link.href = url
        link.setAttribute('download', 'commission_relatory.xlsx')
        document.body.appendChild(link)
        link.click()
        return resp
      })
      .catch(e => dispatch(actionFailed(MAKE_COMMISSION_RELATORY, e)))
  }
}
