/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable camelcase */
/* eslint-disable import/order */
/* eslint-disable prettier/prettier */
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from '@material-ui/core'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import colors from '../../../assets/colors'
import CountUp from 'react-countup'
import Icon from '@mdi/react'
import { mdiCreditCard, mdiInvoiceEdit, mdiPlus } from '@mdi/js'
import { format, parse } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import {
  SiVisa,
  SiMastercard,
  SiAmericanexpress,
  SiDiscover,
  SiJcb,
} from 'react-icons/si'
import brandVisaPath from '../../../../public/card_brands/visa_brand.png'
import brandMastercardPath from '../../../../public/card_brands/mastercard_brand.png'
import { renderRoute } from '../../../util/utils'
import { Link } from 'react-router-dom'
import { useConfirm } from 'material-ui-confirm'
import { destroyCard } from '../SubscriptionActions'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: '8px',
  },
  planRow: {
    fontSize: '14px',
    lineHeight: '14px',
    color: colors.gray,
    flexDirection: 'row',
    gap: '8px',
    padding: '8px',
  },
  planDetail: {
    fontWeight: '600',
    color: colors.primary,
  },
  divider: {
    borderLeft: `1px solid ${colors.graylight}`,

    [theme.breakpoints.down('xs')]: {
      borderLeft: `0`,
      borderBottom: `1px solid ${colors.graylight}`,
    },
  },
  btnEditPlan: {
    backgroundColor: colors.primary,
    borderRadius: '15px',
    fontSize: '14px',
    lineHeight: '14px',
    color: '#FFF',
    padding: '4px 8px',
    margin: 0,
    width: '120px',
    height: '29px',
    transition: 'all .4s ease-in-out',
    '&:hover': {
      opacity: '.7',
      backgroundColor: colors.primary,
    },
  },
  creditCard: {
    border: `1px solid ${colors.graylight}`,
    width: '170px',
    borderRadius: '10px',
    display: 'flex',
    minHeight: '97px',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    transition: 'all .4s ease-in-out',
    '&:hover': {
      opacity: '1 !important',
    },
  },
  creditCardInfo: {
    width: '100%',
    height: '80%',
    padding: '12px',
    color: colors.gray,
  },
  creditCardActions: {
    backgroundColor: colors.graylight,
    color: colors.primary,
    fontSize: '12px',
    lineHeight: '12px',
    margin: '0',
    borderRadius: '0 0 10px 10px',
    flex: 1,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '4px',
  },
  newCreditCard: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.graylight,
  },
  btnNewCreditCard: {
    borderRadius: 0,
    backgroundColor: 'inherit',
    width: '100%',
    height: '90px',
    fontSize: '11px',
    color: colors.gray,
    lineHeight: '14px',
    transition: 'all .4s ease-in-out',
    '&:hover': {
      backgroundColor: 'inherit',
      opacity: '.6',
    },
  },
  btnCreditCardActions: {
    width: 'auto',
    padding: 0,
    margin: 0,
    fontSize: '12px',
    lineHeight: '12px',
    fontWeight: 400,
    textTransform: 'capitalize',
    transition: 'all .4s ease-in-out',
    '&:hover': {
      opacity: '.7',
      backgroundColor: colors.graylight,
    },
  },
}))

const PlanDetails = () => {
  const planDetails = useSelector(state => state.subscription.planDetails)
  const creditCards = useSelector(state => state.subscription.creditCards)
  const permissions = useSelector(state => state.auth.permissions)
  const confirm = useConfirm()
  const dispatch = useDispatch()
  const classes = useStyles()

  const contUpMoney = money => {
    return (
      <CountUp
        end={money}
        duration={0.7}
        decimal=","
        decimals={2}
        prefix="R$"
        separator="."
      />
    )
  }

  const cardBrandPath = brand => {
    if (brand == 'visa') {
      return brandVisaPath
    }

    if (brand == 'mastercard') {
      return brandMastercardPath
    }

    return ''
  }

  const cardExpiryDate = date => {
    const parsedDate = parse(`01/${date}`, 'dd/MM/yy', new Date())

    return format(parsedDate, "'Expira em' MMMM 'de' yyyy", { locale: ptBR })
  }

  const handleDeleteCard = id => {
    confirm({
      description: 'Deseja deletar esse cartão?',
      title: 'Tem certeza?',
      confirmationText: 'Sim',
      cancellationText: 'Cancelar',
      dialogProps: {
        fullWidth: true,
      },
    }).then(async () => {
      const resp = await dispatch(destroyCard(id))
    })
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={4}>
        <Grid container spacing={1} className={classes.container}>
          {planDetails && (
            <>
              <Grid item className={classes.planRow} xs={12}>
                <span className={classes.planDetail}>Plano: </span>
                {planDetails?.name}
              </Grid>

              <Grid item className={classes.planRow} xs={12}>
                <span className={classes.planDetail}>Preço: </span>
                {contUpMoney(planDetails?.price)}
              </Grid>

              <Grid item className={classes.planRow} xs={12}>
                <span className={classes.planDetail}>Máximo de clientes: </span>
                {planDetails?.max_clients}
              </Grid>

              <Grid item className={classes.planRow} xs={12}>
                <span className={classes.planDetail}>Data de vencimento: </span>
                {planDetails?.due_date}
              </Grid>

              <Grid item className={classes.planRow} xs={12}>
                <span className={classes.planDetail}>Método de pagamento:</span>
                {planDetails?.payment_method?.value}
              </Grid>
            </>
          )}

          <Grid
            item
            className={classes.planRow}
            xs={12}
            style={{ marginTop: '12px' }}
          >
            {renderRoute(['gerency-update-laundry-client'], permissions) && (
              <IconButton
                className={classes.btnEditPlan}
                component={Link}
                to={planDetails ? `/subscription/edit/${planDetails?.id}` : 'subscription/new'}
              >
                <Icon path={mdiInvoiceEdit} size={1} />
                {planDetails ? 'Editar' : 'Cadastrar'}
              </IconButton>
            )}
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} sm={1} className={classes.divider} />

      <Grid item xs={12} sm>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            style={{
              color: colors.primary,
              fontSize: '17px',
              lineHeight: '17px',
              fontWeight: 700,
            }}
          >
            Seus cartões:
          </Grid>

          <Grid item container spacing={4}>
            {creditCards?.map(card => (
              <Grid item>
                <Box
                  className={classes.creditCard}
                  style={{
                    opacity: card?.active ? 1 : 0.5,
                  }}
                >
                  <Box className={classes.creditCardInfo}>
                    <Grid container justifyContent="space-between">
                      <Grid
                        item
                        style={{
                          width: '40px',
                          height: '40px',
                          borderRadius: '4px',
                          border: `1px solid ${colors.graylight}`,
                          fontSize: '40px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        {cardBrandPath(card?.brand) ? (
                          <img
                            src={cardBrandPath(card?.brand)}
                            alt="Card Brand"
                            width="24px"
                            height="12px"
                            style={{ objectFit: 'contain' }}
                          />
                        ) : (
                          <Icon
                            path={mdiCreditCard}
                            size={1}
                            color={colors.primary}
                          />
                        )}
                      </Grid>
                      <Grid item xs>
                        <Grid
                          container
                          alignItems="flex-start"
                          justifyContent="flex-start"
                          style={{ padding: '0 8px' }}
                        >
                          <Grid
                            item
                            xs={12}
                            style={{
                              fontSize: '14px',
                              fontWeight: '700',
                              textTransform: 'capitalize',
                            }}
                          >
                            {`${card?.brand} ... ${card?.last_four_digits}`}
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            style={{
                              fontSize: '11px',
                              lineHeight: '14px',
                            }}
                          >
                            {cardExpiryDate(card?.expiry)}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box className={classes.creditCardActions}>
                    {renderRoute(
                      ['gerency-update-laundry-client'],
                      permissions
                    ) && (
                      <Button
                        className={classes.btnCreditCardActions}
                        component={Link}
                        to={`/subscription/credit-card/${card?.id}`}
                      >
                        Editar
                      </Button>
                    )}
                    <Box
                      style={{
                        borderLeft: `1px solid ${colors.primary}`,
                        width: '2px',
                        height: '100%',
                      }}
                    />
                    <Button
                      className={classes.btnCreditCardActions}
                      onClick={() => handleDeleteCard(card?.id)}
                    >
                      Excluir
                    </Button>
                  </Box>
                </Box>
              </Grid>
            ))}

            <Grid item>
              <Box className={[classes.creditCard, classes.newCreditCard]}>
                {renderRoute(
                  ['gerency-update-laundry-client'],
                  permissions
                ) && (
                  <IconButton
                    className={classes.btnNewCreditCard}
                    component={Link}
                    to="/subscription/credit-card/new"
                  >
                    <Grid container alignItems="center" justifyContent="center">
                      <Grid item xs={12}>
                        <Icon path={mdiPlus} size={1} color={colors.primary} />
                      </Grid>
                      <Grid item xs={12}>
                        Adicionar novo cartão
                      </Grid>
                    </Grid>
                  </IconButton>
                )}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default PlanDetails
