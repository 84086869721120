/* eslint-disable import/order */
/* eslint-disable react/react-in-jsx-scope */
import { Checkbox, makeStyles } from '@material-ui/core'
import { mdiCheck } from '@mdi/js'
import Icon from '@mdi/react'
import React from 'react'
import colors from '../../../assets/colors'

const useStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: 2,
    width: 20,
    height: 20,
    border: `1px solid ${colors.grayMedium}`,
    backgroundColor: 'transparent',
    transition: 'all .4s ease-in-out',
    // '$root.Mui-focusVisible &': {
    //   outline: '2px auto rgba(19,124,189,.6)',
    //   outlineOffset: 2,
    // },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: '#FFF',
    },
  },
  checkedIcon: {},
})

const StyledCheckBox = props => {
  const classes = useStyles()

  return (
    <Checkbox
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={
        <Icon
          path={mdiCheck}
          size={1}
          color={colors.secondary}
          className={classes.icon}
        />
      }
      icon={<span className={classes.icon} />}
      inputProps={{ 'aria-label': 'decorative checkbox' }}
      {...props}
    />
  )
}

export default StyledCheckBox
