/* eslint-disable camelcase */
import React from 'react'
import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  makeStyles,
  IconButton,
} from '@material-ui/core'
import ArrowUpward from '@material-ui/icons/ArrowUpward'
import NullIcon from '@material-ui/icons/Remove'
import VisibilityIcon from '@material-ui/icons/Visibility'
import { Link } from 'react-router-dom'
import colors from '../../../assets/colors'
import Icon from '@mdi/react'
import { mdiMagnifyExpand } from '@mdi/js'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    border: `1px solid ${colors.graylight}`,
    // maxHeight: '73px',
    color: colors.primary,
    fontSize: '13px',
    padding: '4px 12px',
    borderRadius: '10px',
  },
  cardContent: {
    padding: 0,
    margin: 0,
    display: 'flex',
    paddingBottom: '0 !important',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    textAlign: 'start',
    gap: '12px',
  },
  qtd: {
    fontWeight: 700,
    fontSize: '30px',
  },
  avatar: {
    backgroundColor: colors.error,
    height: 56,
    width: 56,
  },
  differenceIcon: {
    color: colors.sucess,
  },
  nullIcon: {
    color: colors.gray,
  },
  differenceValue: {
    color: colors.sucess,
    marginRight: theme.spacing(1),
  },
  noDifference: {
    color: colors.graylight,
    marginRight: theme.spacing(1),
    fontSize: '19px',
    lineHeight: '19px',
    fontWeight: 700,
  },
  today: {
    fontWeight: 400,
    fontSize: '14px',
  },
  footer: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
    textAlign: 'start',
    gap: '4px',
  },
}))

const DashboardCard = ({
  item,
  item2,
  title,
  adminCard,
  redirectLink,
  filters,
  noDetails = false,
  os = false,
}) => {
  const classes = useStyles()

  return (
    <Grid>
      {!adminCard ? (
        <Card className={classes.root} elevation={0}>
          <CardContent className={classes.cardContent}>
            <Grid container>
              <Grid item xs={12}>
                <Box className={classes.header}>
                  <Typography className={classes.qtd}>{item?.qtd}</Typography>
                  <Typography>{item?.name}</Typography>
                  <Box
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      width: 'auto',
                      flex: 1,
                    }}
                  >
                    <IconButton
                      variant="contained"
                      component={Link}
                      to={{
                        pathname: os
                          ? `/attendance`
                          : `details/${item?.status_id}`,
                        state: {
                          status: item?.status_id,
                          date_start: filters?.date_start,
                          date_end: filters?.date_end,
                        },
                      }}
                      style={{
                        padding: 0,
                      }}
                    >
                      <Icon
                        path={mdiMagnifyExpand}
                        size={1}
                        color={colors.graylight}
                      />
                    </IconButton>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box className={classes.footer}>
                  <Typography className={classes.noDifference}>
                    {item.today || 0}
                  </Typography>
                  <Typography className={[classes.today, classes.noDifference]}>
                    Hoje
                  </Typography>
                </Box>
              </Grid>
              {/* <Grid item>
                <IconButton
                  color="primary"
                  variant="contained"
                  component={Link}
                  to={`/details/${item.status_id}`}
                >
                  <VisibilityIcon />
                </IconButton>
              </Grid> */}
            </Grid>
          </CardContent>
        </Card>
      ) : (
        <Card className={classes.root}>
          <CardContent>
            <Grid container justify="space-between" spacing={3}>
              <Grid item>
                <Typography color="textSecondary" gutterBottom variant="h6">
                  {title}
                </Typography>
                <Typography color="textPrimary" variant="h3">
                  {item} {item2 && `/ ${item2}`}
                </Typography>
              </Grid>
              <Grid item>
                {!noDetails && (
                  <IconButton
                    color="primary"
                    variant="contained"
                    component={Link}
                    to={redirectLink}
                  >
                    <VisibilityIcon />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )}
    </Grid>
  )
}

export default DashboardCard
