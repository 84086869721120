import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import consts from '../../../util/consts'
import Paginate from '../../components/Pagination'
import { fetchBirthRelatoryInit } from '../BirthRelatoryActions'
import {
  StyledTableCellBody,
  StyledTableCellHeader,
} from '../../../components/hooks/BaseTableComponents'

function List(props) {
  const dispatch = useDispatch()
  const filters = useSelector(state => state.birth_relatory.filters)
  const [rowsPerPage, setRowsPerPage] = useState(consts.DEFAULT_PAGINATION)
  const { classes, items, totalSize, page } = props

  const onMovePage = (event, newPage) => {
    dispatch(fetchBirthRelatoryInit(newPage + 1, filters, rowsPerPage))
  }

  useEffect(() => {
    onMovePage()
  }, [rowsPerPage])

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value)
  }

  return (
    <>
      <TableContainer>
        <Table stickyHeader size="medium">
          <TableHead>
            <TableRow>
              <StyledTableCellHeader>Nome</StyledTableCellHeader>
              <StyledTableCellHeader>Aniversário</StyledTableCellHeader>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map(item => (
              <TableRow key={item.id}>
                <StyledTableCellBody style={{ padding: '8px 0' }}>
                  {item.name}
                </StyledTableCellBody>
                <StyledTableCellBody style={{ padding: '8px 0' }}>
                  {item.birthday}
                </StyledTableCellBody>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Paginate
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        rowsPerPage={rowsPerPage}
        rows={totalSize}
        page={page - 1}
        handleChangePage={onMovePage}
      />
    </>
  )
}

export default List
