import React from 'react'
import filterStylesComponents from '../../../../../assets/filterComponentsStyles'
import { useDispatch } from 'react-redux'
import BaseFilterModal from '../../../../../components/hooks/BaseFilterModal'
import {
  Button,
  Grid,
  InputBase,
  TextField,
  TextareaAutosize,
} from '@material-ui/core'
import { createDescription } from '../../PanelActions'
import colors from '../../../../../assets/colors'

const ModalCreateDescription = ({
  open = false,
  handleOpen = () => {},
  leadId = '',
}) => {
  const filterStyles = filterStylesComponents()
  const dispatch = useDispatch()

  const handleSubmit = async e => {
    e.preventDefault()

    const formData = e.currentTarget
    const data = new FormData(formData)
    const entries = data.entries()
    const values = Object.fromEntries(entries)

    try {
      const resp = await dispatch(createDescription(leadId, values))

      if (resp) {
        handleOpen()
      }
    } catch (e) {
      //
    }
  }

  return (
    <BaseFilterModal
      open={open}
      title="Adicionar Observação"
      hiddenHelpButton
      handleOpen={handleOpen}
      centerTitle
    >
      <Grid item xs={12} style={{ width: '220px' }}>
        <form style={{ width: '100%', height: '100%' }} onSubmit={handleSubmit}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <InputBase
                name="description"
                label="Descrição"
                fullWidth
                required
                multiline
                inputProps={{
                  style: {
                    minHeight: '150px',
                    maxHeight: '200px',
                    padding: '12px',
                  },
                }}
                style={{
                  borderRadius: '15px',
                  border: `1px solid ${colors.graylight}`,
                  minHeight: '150px',
                  padding: '8px',
                  maxHeight: '200px',
                  padding: '12px',
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                spacing={2}
                alignItems="center"
                justifyContent="center"
              >
                <Button
                  type="submit"
                  className={filterStyles.btnAdd}
                  style={{ textTransform: 'none' }}
                >
                  Salvar
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </BaseFilterModal>
  )
}

export default ModalCreateDescription
