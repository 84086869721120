/* eslint-disable camelcase */
/* eslint-disable eqeqeq */
/* eslint-disable import/order */
import React, { useEffect, useState } from 'react'
import BaseFilterModal from '../../../components/hooks/BaseFilterModal'
import { Box, Button, Grid, TextField } from '@material-ui/core'
import Cards from 'react-credit-cards-2'

import cardValidator from 'card-validator'
import 'react-credit-cards-2/dist/es/styles-compiled.css'
import '../../../resources/css/credit-card.css'
import InputMask from 'react-input-mask'
import filterStylesComponents from '../../../assets/filterComponentsStyles'
import { useDispatch, useSelector } from 'react-redux'
import { showCreditCard, storeCard, updateCard } from '../SubscriptionActions'

const CreditCardForm = props => {
  const { history, match } = props
  const classes = filterStylesComponents()
  const creditCard = useSelector(state => state.subscription.creditCard)
  const dispatch = useDispatch()

  const [card, setCard] = useState({
    number: '',
    expiry: '',
    cvc: '',
    name: '',
    focus: '',
    type: '',
  })

  const [cardValid, setCardValid] = useState({
    isValid: true,
    type: '',
    expiryDateValid: true,
  })

  const [edit, setEdit] = useState(false)

  const handleClose = () => {
    history.push('/subscription')
  }

  const handleInputFocus = e => {
    setCard(prev => ({ ...prev, focus: e.target.name }))
  }

  const handleChange = e => {
    e.preventDefault()

    const { name, value } = e.target

    if (name == 'number' || name == 'expiry') {
      const formatedValue = value.replace(/\s/g, '').trim()

      if (name == 'number' && formatedValue.length >= 16) {
        const validatedCard = cardValidator.number(formatedValue)

        setCardValid({
          ...cardValid,
          isValid: validatedCard?.isValid,
          type: validatedCard?.card?.type || '',
        })
      }

      if (name == 'expiry' && formatedValue.length >= 4) {
        const validatedCard = cardValidator.expirationDate(formatedValue)

        setCardValid({
          ...cardValid,
          expiryDateValid: validatedCard.isPotentiallyValid,
        })
      }
    }

    setCard({
      ...card,
      [name]: value,
    })
  }

  const handleSubmit = async e => {
    e.preventDefault()

    if (edit) {
      const resp = await dispatch(updateCard(card, match?.params?.id))

      if (resp) {
        handleClose()
      }
    } else {
      const resp = await dispatch(storeCard(card))

      if (resp) {
        handleClose()
      }
    }
  }

  useEffect(() => {
    if (cardValid.type) {
      setCard({
        ...card,
        type: cardValid.type,
      })
    }
  }, [cardValid])

  useEffect(() => {
    if (match?.params?.id !== 'new') {
      if (match?.params?.id) {
        dispatch(showCreditCard(match?.params?.id))
        setEdit(true)
      }
    }
  }, [match])

  useEffect(() => {
    if (creditCard) {
      if (match?.params?.id !== 'new') {
        if (match?.params?.id) {
          setCard({
            ...card,
            expiry: creditCard?.expiry?.replace('/', ''),
            name: creditCard?.name,
            focus: '',
            last_four_digits: creditCard?.last_four_digits,
            brand: creditCard?.brand,
          })
        }
      }
    }
  }, [creditCard, match])

  return (
    <BaseFilterModal
      open
      handleOpen={handleClose}
      title={edit ? 'Editar Cartão de Cŕedito' : 'Novo Cartão de Crédito'}
      hiddenHelpButton
    >
      <Grid item xs={12} style={{ width: '300px', margin: '1em 0' }}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              {edit ? (
                <Cards
                  number={`**** **** **** ${card.last_four_digits}`}
                  expiry={card?.expiry || ''}
                  cvc="***"
                  name={card.name}
                  focused={card.focus}
                  placeholders={{ name: 'NOME NO CARTÃO' }}
                  locale={{ valid: 'Validade' }}
                  preview
                  issuer={card?.brand}
                />
              ) : (
                <Cards
                  number={card.number}
                  expiry={card.expiry}
                  cvc={card.cvc}
                  name={card.name}
                  focused={card.focus}
                  placeholders={{ name: 'NOME NO CARTÃO' }}
                  locale={{ valid: 'Validade' }}
                />
              )}
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={2}>
                {!edit && (
                  <Grid item md={6} xs={6}>
                    <InputMask
                      mask="9999 9999 9999 9999"
                      maskChar={null}
                      min={16}
                      required
                      value={card.number}
                      onChange={handleChange}
                      onFocus={handleInputFocus}
                      name="number"
                    >
                      {inputProps => (
                        <TextField
                          {...inputProps}
                          label="Número"
                          fullWidth
                          error={!cardValid.isValid}
                          helperText={!cardValid.isValid && 'Cartão inválido'}
                        />
                      )}
                    </InputMask>
                  </Grid>
                )}

                <Grid item md={6} xs={6}>
                  <TextField
                    label="Nome"
                    name="name"
                    value={card.name || ''}
                    defaultValue={card?.name}
                    fullWidth
                    required
                    onChange={handleChange}
                    onFocus={handleInputFocus}
                  />
                </Grid>

                <Grid item md={6} xs={6}>
                  <InputMask
                    mask="99/99"
                    maskChar={null}
                    value={card.expiry || ''}
                    defaultValue={card?.expiry}
                    name="expiry"
                    required
                    onChange={handleChange}
                    onFocus={handleInputFocus}
                  >
                    {inputProps => (
                      <TextField
                        {...inputProps}
                        label="Válidade"
                        fullWidth
                        error={!cardValid.expiryDateValid}
                        helperText={
                          !cardValid.expiryDateValid && 'Data inválida'
                        }
                      />
                    )}
                  </InputMask>
                </Grid>

                {!edit && (
                  <Grid item md={6} xs={6}>
                    <InputMask
                      mask="999"
                      maskChar={null}
                      value={card.cvc}
                      name="cvc"
                      required
                      onChange={handleChange}
                      onFocus={handleInputFocus}
                    >
                      {inputProps => (
                        <TextField {...inputProps} label="CVV" fullWidth />
                      )}
                    </InputMask>
                  </Grid>
                )}
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              container
              justifyContent="flex-end"
              alignItems="flex-end"
            >
              <Button className={classes.btnSalvar} type="submit">
                Salvar
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </BaseFilterModal>
  )
}

export default CreditCardForm
