import React from 'react'
import { Forms } from '..'
import { Grid, MenuItem, TextField } from '@material-ui/core'
import ReactInputMask from 'react-input-mask'

const FormResponsible = ({ form, validatedForm, handleChange = () => {} }) => {
  return (
    <Forms.root>
      <Grid item xs={12} sm={6} md={3}>
        <TextField
          label="Nome responsável"
          name="nome_responsavel"
          type="text"
          fullWidth
          value={form.nome_responsavel}
          onChange={handleChange}
          error={validatedForm.nome_responsavel}
          helperText={validatedForm.nome_responsavel}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <ReactInputMask
          mask="999.999.999-99"
          name="cpf_responsavel"
          maskChar=""
          value={form?.cpf_responsavel}
          required
          onChange={handleChange}
        >
          {inputProps => (
            <TextField
              {...inputProps}
              label="CPF responsável"
              fullWidth
              error={validatedForm.cpf_responsavel}
              helperText={validatedForm.cpf_responsavel}
            />
          )}
        </ReactInputMask>
      </Grid>
    </Forms.root>
  )
}

export default FormResponsible
