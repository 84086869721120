/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable react/react-in-jsx-scope */
import React from 'react'
import {
  Backdrop,
  Box,
  Fade,
  Grid,
  IconButton,
  Modal,
  Typography,
  makeStyles,
} from '@material-ui/core'
import Icon from '@mdi/react'
import {
  mdiClose,
  mdiCommentQuestionOutline,
  mdiMessageQuestion,
} from '@mdi/js'
import colors from '../../../assets/colors'
import fontSizes from '../../../assets/fontSizes'
import { Link } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    // marginTop: '8em',
    justifyContent: 'center',
  },
  paper: {
    width: 'auto',
    maxHeight: 'max-content',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #1DBAEC',
    boxShadow: theme.shadows[1],
    padding: theme.spacing(2, 4, 3),
    borderRadius: '16px',
  },
  btnClose: {
    borderRadius: '5px',
    padding: '5px',
    color: colors.primary,
    fontSize: fontSizes.text,
    fontWeight: '700',
    transition: 'all .4s ease-in-out',
    '&:hover': {
      backgroundColor: '#ececec',
    },
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    gap: '2px',
  },
  description: {
    fontWeight: 400,
  },
  questionBox: {
    position: 'relative',
  },
}))

const BaseFilterModal = ({
  open,
  handleOpen,
  title,
  link = null,
  children,
  hiddenHelpButton = false,
  centerTitle = false,
  paperColor = colors.secondary,
}) => {
  const classes = useStyles()

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleOpen}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box
          className={classes.paper}
          style={{ border: `2px solid ${paperColor}` }}
        >
          <Grid container xs={12} justifyContent="flex-end">
            <Grid item>
              <IconButton onClick={handleOpen} className={classes.btnClose}>
                Fechar
                <Icon path={mdiClose} size={1} />
              </IconButton>
            </Grid>
          </Grid>

          <Grid xs={12} container>
            <Grid
              container
              xs={12}
              item
              spacing={2}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Grid item xs={12} className={classes.header}>
                <Typography
                  variant="h4"
                  style={{
                    fontSize: '20px',
                    width: centerTitle ? '100%' : 'auto',
                    textAlign: centerTitle ? 'center' : 'start',
                    alignSelf: 'flex-start',
                  }}
                  color={colors.primary}
                >
                  {title}
                </Typography>

                {!hiddenHelpButton && (
                  <IconButton className={classes.questionBox}>
                    {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                    <a href={link} target="_blank" rel="noreferrer">
                      <Icon
                        path={mdiMessageQuestion}
                        size={0.9}
                        color="#1DBAEC"
                        style={{
                          position: 'absolute',
                          top: '-10px',
                          right: '0',
                        }}
                      />
                    </a>
                  </IconButton>
                )}
              </Grid>

              {children}
            </Grid>
          </Grid>
        </Box>
      </Fade>
    </Modal>
  )
}

export default BaseFilterModal
