import {
  Box,
  Collapse,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'
import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import Slide from '@material-ui/core/Slide'
import HistoryIcon from '@material-ui/icons/History'
import { useDispatch, useSelector } from 'react-redux'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import { useConfirm } from 'material-ui-confirm'

import { SNACKBAR } from '../../main/MainActions'
import { edit, getHistory } from '../AttendanceActions'
import { dateFormatedFullDate, exportFormatedMoney } from '../../../util/utils'
import filterStylesComponents from '../../../assets/filterComponentsStyles'
import { mdiContentCopy, mdiHistory } from '@mdi/js'
import Icon from '@mdi/react'
import {
  StyledTableCellBody,
  StyledTableCellHeader,
} from '../../../components/hooks/BaseTableComponents'
import colors from '../../../assets/colors'

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
    backgroundColor: colors.primary,
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  table: {
    padding: theme.spacing(2),
  },
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: '100%',
    padding: '8px 0px 0px 10px',
  },
}))

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
})

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />
})

function Historic({ disabled, clientId, canClick, fillNewServices }) {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const historic = useSelector(state => state.attendance.historic)
  const confirm = useConfirm()

  const filterClasses = filterStylesComponents()

  const dispatch = useDispatch()

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  function createData(
    id,
    id_laundry_client,
    createdAt,
    totalWithDiscount,
    totalWithoutDiscount,
    totalHours,
    status,
    generalDiscount,
    sallesMan,
    services
  ) {
    return {
      id,
      id_laundry_client,
      createdAt,
      totalWithDiscount,
      totalWithoutDiscount,
      totalHours,
      status,
      generalDiscount,
      sallesMan,
      services,
    }
  }

  const rows = []

  historic?.map(row => {
    rows.push(
      createData(
        row.id,
        row.id_laundry_client,
        row.created_at,
        row.total_with_discount,
        row.total_without_discount,
        row.total_hours,
        row.status,
        row.general_discount,
        row.salles_man,
        row.services
      )
    )
  })

  const confirmCopy = id => {
    confirm({
      description: `Deseja realmente copiar os itens da OS ${id} para a nova OS?`,
      title: 'Tem certeza?',
      confirmationText: 'Sim',
      cancellationText: 'Cancelar',
      dialogProps: {
        fullWidth: true,
      },
    }).then(async () => {
      const item = historic.find(x => x.id === id)

      const { data } = await dispatch(edit(item.id))

      fillNewServices(data)
      setOpen(false)

      dispatch({
        type: SNACKBAR.SOFTSUCCESS,
        success: { message: 'A Nova OS foi populada com os itens' },
      })
    })
  }

  function Row(props) {
    const { row } = props
    const [open, setOpen] = React.useState(false)
    const classes = useRowStyles()

    return (
      <>
        <TableRow className={classes.root}>
          <StyledTableCellBody>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </StyledTableCellBody>
          <StyledTableCellBody component="th" scope="row">
            {row.id_laundry_client}
          </StyledTableCellBody>
          <StyledTableCellBody align="right">
            {dateFormatedFullDate(row.createdAt)}
          </StyledTableCellBody>
          <StyledTableCellBody align="right">
            {exportFormatedMoney(row.totalWithDiscount)}
          </StyledTableCellBody>
          <StyledTableCellBody align="right">
            {exportFormatedMoney(row.totalWithoutDiscount)}
          </StyledTableCellBody>
          <StyledTableCellBody align="right">{`${
            row.generalDiscount || 0
          }%`}</StyledTableCellBody>
          <StyledTableCellBody align="right">
            {row.totalHours}
          </StyledTableCellBody>
          <StyledTableCellBody align="right">{row.status}</StyledTableCellBody>
          <StyledTableCellBody align="right">
            {row.sallesMan}
          </StyledTableCellBody>
          <StyledTableCellBody align="center">
            <IconButton
              onClick={() => confirmCopy(row.id)}
              disabled={canClick}
              className={filterClasses.btnSalvar}
            >
              <Icon path={mdiContentCopy} size={1} />
            </IconButton>
          </StyledTableCellBody>
        </TableRow>
        <TableRow>
          <StyledTableCellBody
            style={{ paddingBottom: 0, paddingTop: 0 }}
            colSpan={10}
          >
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Typography variant="h6" gutterBottom component="div">
                  Itens
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <StyledTableCellHeader>ID</StyledTableCellHeader>
                      <StyledTableCellHeader align="right">
                        Valor
                      </StyledTableCellHeader>
                      <StyledTableCellHeader align="right">
                        Desconto
                      </StyledTableCellHeader>
                      <StyledTableCellHeader align="right">
                        Tempo de Ex
                      </StyledTableCellHeader>
                      <StyledTableCellHeader>Serviço</StyledTableCellHeader>
                      <StyledTableCellHeader>
                        Quantidade/Tamanho
                      </StyledTableCellHeader>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.services.map(service => (
                      <TableRow key={service.id}>
                        <StyledTableCellBody component="th" scope="row">
                          {service.id}
                        </StyledTableCellBody>
                        <StyledTableCellBody align="right">
                          {exportFormatedMoney(service.final_value)}
                        </StyledTableCellBody>
                        <StyledTableCellBody align="right">
                          {service.discount}
                        </StyledTableCellBody>
                        <StyledTableCellBody align="right">
                          {service.execution_time}
                        </StyledTableCellBody>
                        <StyledTableCellBody>
                          {service.serviceType}
                        </StyledTableCellBody>
                        <StyledTableCellBody>
                          {service.quantity}
                        </StyledTableCellBody>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </StyledTableCellBody>
        </TableRow>
      </>
    )
  }

  useEffect(() => {
    if (open && clientId) dispatch(getHistory(clientId))
  }, [open])

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleClickOpen}
        disabled={disabled}
        className={filterClasses.btnAdd}
      >
        <Icon path={mdiHistory} size={1} style={{ marginRight: '4px' }} />
        {!disabled && 'Histórico de atendimentos'}
      </IconButton>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Histórico do cliente
            </Typography>
          </Toolbar>
        </AppBar>
        <Paper elevation={0}>
          <TableContainer className={classes.table}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <StyledTableCellHeader />
                  <StyledTableCellHeader>ID</StyledTableCellHeader>
                  <StyledTableCellHeader align="right">
                    Data do serviço
                  </StyledTableCellHeader>
                  <StyledTableCellHeader align="right">
                    Total c/ desc
                  </StyledTableCellHeader>
                  <StyledTableCellHeader align="right">
                    Total s/ desc
                  </StyledTableCellHeader>
                  <StyledTableCellHeader align="right">
                    Desc
                  </StyledTableCellHeader>
                  <StyledTableCellHeader align="right">
                    Tempo gasto
                  </StyledTableCellHeader>
                  <StyledTableCellHeader align="right">
                    Status
                  </StyledTableCellHeader>
                  <StyledTableCellHeader align="right">
                    Vendedor
                  </StyledTableCellHeader>
                  <StyledTableCellHeader align="center">
                    Ações
                  </StyledTableCellHeader>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map(row => (
                  <Row key={row.id} row={row} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Dialog>
    </div>
  )
}

export default React.memo(Historic)
