import { TableCell, withStyles } from '@material-ui/core'
import colors from '../../../assets/colors'

export const StyledTableCellBody = withStyles(theme => ({
  head: {
    backgroundColor: '#FFFF',
    color: colors.textPrimary,
  },
  body: {
    fontSize: 14,
    padding: '4px 12px',
    color: colors.textPrimary,
  },
}))(TableCell)

export const StyledTableCellHeader = withStyles(theme => ({
  head: {
    backgroundColor: '#FFFF',
    color: colors.primary,
    padding: '8px 12px',
    // border: '2px',
    borderColor: '#1DBAEC',
  },
  body: {
    fontWeight: 700,
    fontSize: 14,
  },
}))(TableCell)
