import { FETCH_FINANCIAL_DASHBOARD } from './FinancialDashboardActions'

const INITIAL_STATE = {
  totalReceitas: 0,
  totalDespesas: 0,
  balance: 0,
  receitasToday: 0,
  despesasToday: 0,
  balanceToday: 0,
  currentMonth: '',
  refreshing: false,
  filters: {},
  banksBalance: [],
  monthBalance: [],
  banksTotal: 0,

  payments: 0,
  paymentsDue: 0,
  paymentsLate: 0,
  paymentsTotal: 0,

  // laundryDue: 0,
  // laundryTotal: 0,
  // laundryLate: 0,
  // clientLate: 0,
  // clientDue: 0,
  // clientTotal: 0,

  receipts: 0,
  receiptsLate: 0,
  receiptsDue: 0,
  receiptsTotal: 0,
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_FINANCIAL_DASHBOARD.SUCCESS:
      return {
        ...state,
        totalReceitas: action.payload.totalReceitas,
        totalDespesas: action.payload.totalDespesas,
        currentMonth: action.payload.currentMonth,
        receitasToday: action.payload.receitasToday,
        despesasToday: action.payload.despesasToday,
        balanceToday: action.payload.balanceToday,
        monthBalance: action.payload.monthBalance,

        balance: action.payload.balance,
        banksBalance: action.payload.banksBalance,
        banksTotal: action.payload.banksTotal,

        payments: action.payload.payments,
        paymentsDue: action.payload.paymentsDue,
        paymentsLate: action.payload.paymentsLate,
        paymentsTotal: action.payload.paymentsTotal,

        // laundryDue: action.payload.laundryDue,
        // laundryLate: action.payload.laundryLate,
        // laundryTotal: action.payload.laundryTotal,
        // clientLate: action.payload.clientLate,
        // clientDue: action.payload.clientDue,
        // clientTotal: action.payload.clientTotal,

        receipts: action.payload.receipts,
        receiptsLate: action.payload.receiptsLate,
        receiptsDue: action.payload.receiptsDue,
        receiptsTotal: action.payload.receiptsTotal,

        refreshing: false,
      }
    case FETCH_FINANCIAL_DASHBOARD.ACTION:
      return {
        ...state,
        refreshing: true,
      }
    case FETCH_FINANCIAL_DASHBOARD.FAILURE:
      return {
        ...state,
        refreshing: false,
      }
    default:
      return state
  }
}
