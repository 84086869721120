/* eslint-disable no-shadow */
import { Grid } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import NoteAddIcon from '@material-ui/icons/NoteAdd'

import styles from '../../../resources/theme/global'
import {
  fetchBirthRelatoryInit,
  makeRelatory,
  clearFilter,
} from '../BirthRelatoryActions'
import { getBirthRelatoryListData } from '../BirthRelatoryReducer'

import Filter from './Filter'
import List from './List'
import BaseRelatoryPage from '../../../components/hooks/BaseRelatoryPage'

class BirtdayRelatory extends Component {
  componentWillUnmount() {
    const { clearFilter } = this.props
    clearFilter()
  }

  makeReport = filters => {
    makeRelatory(filters)
  }

  render() {
    const { classes, makeRelatory, filters } = this.props

    return (
      <BaseRelatoryPage title="Aniversariantes">
        <Grid
          container
          className={classes.pageHead}
          spacing={1}
          alignItems="center"
        >
          <Grid item xs={12}>
            <Filter classes={classes} />
          </Grid>

          <Grid item xs={12}>
            <List {...this.props} />
          </Grid>
        </Grid>
      </BaseRelatoryPage>
    )
  }
}

const mapStateToProps = state => ({
  ...getBirthRelatoryListData(state),
  permissions: state.auth.permissions || [],
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchBirthRelatoryInit,
      makeRelatory,
      clearFilter,
    },
    dispatch
  )
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(BirtdayRelatory))
