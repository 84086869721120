import React from 'react'
import FormRoot from './Root'
import FormIdentification from './Identification'
import FormContact from './Contact'
import FormAddress from './Address'
import FormResponsible from './Responsible'
import FormDocuments from './Documents'
import FormLogo from './Logo'

export const Forms = {
  root: FormRoot,
  identification: FormIdentification,
  contact: FormContact,
  address: FormAddress,
  responsible: FormResponsible,
  documents: FormDocuments,
  logo: FormLogo,
}
