/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-syntax */
/* eslint-disable import/order */
import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  Container,
  Grid,
  makeStyles,
  withStyles,
} from '@material-ui/core'
import FormGroupLaundry from './FormGroupLaundry'
import FormGroupUser from './FormGroupUser'
import { Link as RouteLink } from 'react-router-dom'
import axios from 'axios'
import config from '../../../../config'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { Alert } from '@material-ui/lab'
import Snackbar from '@material-ui/core/Snackbar'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'
import styles from '../../../../resources/theme/auth'
import colors from '../../../../assets/colors'
import { SNACKBAR } from '../../../main/MainActions'
import { useDispatch } from 'react-redux'

const useStyles = makeStyles({
  container: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#fafafa',
  },
  wrapper: {
    width: '100%',
    height: '70%',

    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#fafafa',
    borderRadius: '20px',
    boxShadow: '0px 10px 15px 0px #00000040',
  },
  box: {
    width: '100%',
    height: '100%',
    padding: '1rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '2rem',
    overflowY: 'auto',
    borderRadius: '20px',
    backgroundColor: '#FFF',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
  link: {
    backgroundColor: colors.primary,
    color: '#FFF',
    padding: '.5rem 1rem',
    transition: 'all .6s',
    '&:hover': {
      backgroundColor: colors.primary,
      opacity: '.8',
    },
  },
  routeLink: {
    color: colors.primary,
    textDecoration: 'none',
    padding: '.5rem',
    borderRadius: '.5rem',
    transition: 'all .6s',
    '&:hover': {
      color: colors.primary,
      opacity: '.8',
    },
  },
})

const createLaundry = props => {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()

  const [open, setOpen] = useState(false)
  const [sucess, setSuccess] = useState(false)

  const { classes: styles } = props

  const handleFormSubmit = async e => {
    e.preventDefault()

    const form = e.currentTarget
    const data = new FormData(form)

    try {
      const resp = await axios.post(`${config.API_URL}/new-laundry`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })

      if (resp?.data?.message) {
        setSuccess(true)
        dispatch({
          type: SNACKBAR.SOFTSUCCESS,
          success: {
            message:
              'Sucesso! Seu teste foi iniciado, aguarde e será redirecionado.',
          },
        })
      }

      setTimeout(() => {
        history.push({
          pathname: `/`,
          state: {
            email: data?.get('gerente_email'),
            password: data?.get('gerente_password'),
          },
        })
      }, 6000)
    } catch (e) {
      if (e.response?.data?.errors) {
        Object.values(e.response?.data?.errors).map(errors =>
          errors.map(e =>
            dispatch({
              type: SNACKBAR.HARDFAIL,
              error: {
                message: e,
              },
            })
          )
        )
      } else if (e.response?.data?.message) {
        dispatch({
          type: SNACKBAR.HARDFAIL,
          error: {
            message: e.response?.data?.message,
          },
        })
      }
    }
  }

  return (
    <Container className={classes.container}>
      <Box className={classes.wrapper}>
        <Box className={styles.wrraperLogo} style={{ height: '100%' }}>
          <img className={styles.logo} src="/img/logo.png" alt="Logo Tapetex" />
        </Box>
        {!sucess && (
          <Box className={classes.box}>
            <form className={classes.form} onSubmit={handleFormSubmit}>
              <FormGroupLaundry />

              <FormGroupUser />

              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={3}
              >
                <Grid item>
                  <RouteLink to="/" className={classes?.routeLink}>
                    Voltar para tela de login
                  </RouteLink>
                </Grid>

                <Grid item>
                  <Button type="submit" className={classes?.link}>
                    Enviar
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        )}
      </Box>
    </Container>
  )
}

export default withStyles(styles)(createLaundry)
