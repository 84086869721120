/* eslint-disable eqeqeq */
/* eslint-disable react/forbid-prop-types */
import {
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  withStyles,
} from '@material-ui/core'
import PropTypes from 'prop-types'
// eslint-disable-next-line import/order
import React from 'react'
import colors from '../../assets/colors'
import { StyledTableCellHeader } from '../../components/hooks/BaseTableComponents'

// import { Container } from './styles';

function EnhancedTableHead(props) {
  const {
    classes,
    order,
    orderBy,
    onRequestSort,
    headCells,
    renderFirstTH = true,
    hideID,
  } = props
  const createSortHandler = property => event => {
    onRequestSort(event, property)
  }
  return (
    <TableHead>
      <TableRow>
        {renderFirstTH && <StyledTableCellHeader padding="checkbox" />}
        {headCells.map(headCell =>
          headCell.id == 'id' && hideID ? (
            ''
          ) : (
            <StyledTableCellHeader
              key={headCell.id}
              sortDirection={orderBy === headCell.id ? order : false}
              align={headCell?.align || 'start'}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={order}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            </StyledTableCellHeader>
          )
        )}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
}

export default EnhancedTableHead
