import { Grid, IconButton } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import PropTypes from 'prop-types'
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Route } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import NoteAddIcon from '@material-ui/icons/NoteAdd'

import styles from '../../../resources/theme/global'
import { renderRoute } from '../../../util/utils'
import { fetchUsers, makeReport } from '../UserActions'
import { fetchAuthenticatedUser } from '../../auth/AuthActions'
import { getUserListData } from '../UserReducer'

import EditUser from './Edit'
import Filter from './Filter'
import List from './List'
import NewUser from './New'
import colors from '../../../assets/colors'
import Icon from '@mdi/react'
import { mdiMessageQuestion } from '@mdi/js'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'

class User extends Component {
  componentDidMount() {
    this.props.fetchUsers()
    this.props.fetchAuthenticatedUser()
  }

  render() {
    const { classes, items, permissions, makeReport, filters } = this.props

    return (
      <>
        <Grid
          container
          className={classes.pageHead}
          spacing={1}
          alignItems="center"
        >
          <Grid item xs={12}>
            <Typography
              variant="h4"
              style={{ fontSize: '20px' }}
              color={colors.primary}
            >
              Usuários
              <IconButton className={classes.questionBox}>
                {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                <a href="" target="_blank" rel="noreferrer">
                  <Icon
                    path={mdiMessageQuestion}
                    size={1}
                    color={colors.secondary}
                    style={{
                      position: 'absolute',
                      top: '-10px',
                      right: '-10px',
                    }}
                  />
                </a>
              </IconButton>
            </Typography>
          </Grid>

          <Grid item xs={12} container>
            <Filter classes={classes} permissions={permissions} />
          </Grid>
        </Grid>

        {renderRoute(['create-users'], permissions) && <Link to="/users/new" />}
        {renderRoute(['update-users'], permissions) && (
          <Link to="/users/:id/edit" />
        )}
        <Link to="/users/:id/see/:see" />

        <List permissions={permissions} classes={classes} items={items} />
      </>
    )
  }
}

User.propTypes = {
  classes: PropTypes.object.isRequired,
  fetchUsers: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
}

const mapStateToProps = state => ({
  ...getUserListData(state),
  permissions: state.auth.permissions || [],
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchUsers,
      makeReport,
      fetchAuthenticatedUser,
    },
    dispatch
  )
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(User))
