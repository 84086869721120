/* eslint-disable jsx-a11y/tabindex-no-positive */
/* eslint-disable eqeqeq */
/* eslint-disable import/order */
import { Grid, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import colors from '../../../assets/colors'
import Tabs from '../../../components/hooks/BaseTabs/Tabs'
import Tab from '../../../components/hooks/BaseTabs/Tab'
import { mdiCommentTextMultiple, mdiInvoiceTextEditOutline } from '@mdi/js'
import PlanDetails from './PlanDetails'
import PaymentHistory from './PaymentHistory'
import { useDispatch, useSelector } from 'react-redux'
import { fetchPlanDetails } from '../SubscriptionActions'
import { renderRoute } from '../../../util/utils'
import { Route } from 'react-router-dom'
import CreditCardForm from './CreditCardForm'

const Subscription = () => {
  const permissions = useSelector(state => state.auth.permissions)
  const [value, setValue] = useState(0)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchPlanDetails())
  }, [])

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12}>
        <Typography
          variant="h4"
          style={{ fontSize: '20px' }}
          color={colors.primary}
        >
          Minha Assinatura
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Tabs>
          <Tab
            label="Assinatura"
            icon={mdiInvoiceTextEditOutline}
            value={value}
            setValue={setValue}
            tabIndex={0}
            removeNavigation={false}
          />
          <Tab
            label="Histórico de pagamento"
            icon={mdiCommentTextMultiple}
            value={value}
            setValue={setValue}
            tabIndex={1}
            removeNavigation={false}
          />
        </Tabs>
      </Grid>

      {value == 0 && (
        <Grid item xs={12}>
          <PlanDetails />
        </Grid>
      )}

      {value == 1 && (
        <Grid item xs={12}>
          <PaymentHistory />
        </Grid>
      )}

      {renderRoute(['gerency-update-laundry-client'], permissions) && (
        <Route
          path="/subscription/credit-card/new"
          component={CreditCardForm}
        />
      )}

      {renderRoute(['gerency-update-laundry-client'], permissions) && (
        <Route
          path="/subscription/credit-card/:id"
          component={CreditCardForm}
        />
      )}
    </Grid>
  )
}

export default Subscription
