/* eslint-disable no-shadow */
/* eslint-disable simple-import-sort/sort */
import { Grid, IconButton, Tooltip } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link, Route } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { FaFileCsv } from 'react-icons/fa'

import styles from '../../../resources/theme/global'
import { renderRoute } from '../../../util/utils'
import { downloadImportTemplate, fetchClients } from '../ClientActions'
import { getClientListData } from '../ClientReducer'

import Filter from './Filter'
import List from './List'
import colors from '../../../assets/colors'
import { mdiMessageQuestion } from '@mdi/js'
import Icon from '@mdi/react'
import ImportClients from './ImportClients'

class Clients extends Component {
  componentDidMount() {
    // const { fetchClients } = this.props
    // fetchClients()
  }

  render() {
    const { classes, permissions, downloadImportTemplate } = this.props

    return (
      <>
        <Grid
          container
          className={classes.pageHead}
          spacing={1}
          alignItems="center"
        >
          <Grid item xs={12}>
            <Typography
              variant="h4"
              style={{ fontSize: '20px' }}
              color={colors.primary}
            >
              Clientes
              <IconButton className={classes.questionBox}>
                {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                <a href="" target="_blank" rel="noreferrer">
                  <Icon
                    path={mdiMessageQuestion}
                    size={1}
                    color={colors.secondary}
                    style={{
                      position: 'absolute',
                      top: '-10px',
                      right: '-10px',
                    }}
                  />
                </a>
              </IconButton>
            </Typography>
          </Grid>
        </Grid>

        <Grid>
          <Filter classes={classes} permissions={permissions} />
        </Grid>

        {renderRoute(['create-client'], permissions) && (
          <>
            <Link to="/clients/new" />
            <Route path="/clients/import" component={ImportClients} />
          </>
        )}
        {renderRoute(['update-client'], permissions) && (
          <Link to="/clients/:id/edit" />
        )}

        <List {...this.props} classes={classes} />
      </>
    )
  }
}

const mapStateToProps = state => ({
  ...getClientListData(state),
  permissions: state.auth.permissions || [],
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchClients,
      downloadImportTemplate,
    },
    dispatch
  )
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Clients))
