/* eslint-disable object-shorthand */
/* eslint-disable jsx-a11y/tabindex-no-positive */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable import/order */
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import FormControl from '@material-ui/core/FormControl'
import IconButton from '@material-ui/core/IconButton'
import Input from '@material-ui/core/Input'
import InputAdornment from '@material-ui/core/InputAdornment'
import InputLabel from '@material-ui/core/InputLabel'
import Link from '@material-ui/core/Link'
import Paper from '@material-ui/core/Paper'
import withStyles from '@material-ui/core/styles/withStyles'
import Tooltip from '@material-ui/core/Tooltip'
import EmailCheck from '@material-ui/icons/Check'
import Email from '@material-ui/icons/Email'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import queryString from 'query-string'

import styles from '../../../resources/theme/auth'
import EnhancedComponent from '../../components/EnhancedComponent'
import * as AuthActions from '../AuthActions'
import Reset from './Reset'
import {
  Box,
  Container,
  Grid,
  Snackbar,
  TextField,
  Typography,
} from '@material-ui/core'
import { Link as RouteLink } from 'react-router-dom'
import { Alert } from '@material-ui/lab'
import colors from '../../../assets/colors'

class Login extends EnhancedComponent {
  state = {
    fields: {
      email: '',
      password: '',
      showPassword: false,
      showForgot: false,
    },
    failLogin: false,
    initialAnimation: true,
    notFindLogin: false,
    errorOnLogin: '',
    notFindPassword: false,
    loading: false,
  }

  componentDidMount() {
    this.verifyIfTeste()
  }

  onSubmit = async event => {
    event.preventDefault()

    this.setState({
      ...this.state,
      loading: true,
    })

    const resp = await this.props.login(this.state.fields)

    if (
      resp?.response?.status === 404 &&
      resp?.response?.data?.type === 'usernotfound'
    ) {
      this.setState({
        ...this.state,
        errorOnLogin: 'Usuário não encontrado',
        notFindLogin: true,
      })
    }

    if (
      resp?.response?.status === 400 &&
      resp?.response?.data?.type === 'userinactive'
    ) {
      this.setState({
        ...this.state,
        errorOnLogin: 'Usuário inátivo',
        notFindLogin: true,
      })
    }

    if (
      resp?.response?.status === 400 &&
      resp?.response?.data?.type === 'emailnotconfirmed'
    ) {
      this.setState({
        ...this.state,
        errorOnLogin: 'Visite seu email para confirmar a sua conta',
        notFindLogin: true,
      })
    }

    if (
      resp?.response?.status === 400 &&
      resp?.response?.data?.type === 'inactivelaundry'
    ) {
      this.setState({
        ...this.state,
        errorOnLogin: 'Lavanderia inátiva',
        notFindLogin: true,
      })
    }

    if (
      resp?.response?.status === 401 &&
      resp?.response?.data?.type === 'incorrectpassword'
    ) {
      this.setState({
        ...this.state,
        notFindPassword: true,
      })
    }

    this.setState({
      ...this.state,
      loading: false,
    })
  }

  handleMouseDownPassword = event => {
    event.preventDefault()
  }

  handleClickShowPassword = () => {
    this.setState({
      fields: {
        ...this.state.fields,
        showPassword: !this.state.fields.showPassword,
      },
    })
  }

  clickForgot = () => {
    this.setState({
      fields: {
        ...this.state.fields,
        showForgot: !this.state.fields.showForgot,
      },
    })
  }

  verifyEmail = () => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(this.state.fields.email).toLowerCase())
  }

  verifyIfTeste = () => {
    if (this.props.location.state) {
      const { email, password } = this?.props?.location?.state

      this.setState({
        fields: {
          ...this.state.fields,
          email: email,
          password: password,
        },
      })
    }
  }

  onSubmitReset = async event => {
    event.preventDefault()

    this.setState({
      ...this.state,
      loading: true,
    })

    const resp = await this.props.forgotPassword(this.state.fields)

    this.setState({
      ...this.state,
      loading: false,
    })

    if (resp) {
      this.clickForgot()
    }
  }

  render() {
    const { classes } = this.props

    return (
      <>
        <Box
          className={classNames({
            [classes.main]: true,
          })}
        >
          {/* <form id="login-form" onSubmit={this.onSubmit}> */}
          <Container
            className={classNames({
              [classes.animationNono]: this.state.failLogin,
              [classes.animationFade]: this.state.initialAnimation,
              [classes.container]: true,
            })}
          >
            <Box className={classes.wrraperLogo}>
              <img
                className={classes.logo}
                src="/img/logo.png"
                alt="Logo Tapetex"
              />
            </Box>

            {!this.state.fields.showForgot && (
              <Box
                className={classNames({
                  [classes.animationNono]: this.state.failLogin,
                  [classes.animationFade]: this.state.initialAnimation,
                  [classes.paper]: true,
                })}
              >
                <Box
                  textAlign="center"
                  fontWeight="700"
                  fontSize="16px"
                  lineHeight="26px"
                  color={colors.primary}
                >
                  ÁREA DE MEMBRO
                </Box>

                <form onSubmit={this.onSubmit}>
                  <FormControl className={classes.fieldMargin}>
                    <TextField
                      label="E-mail"
                      required
                      id="adornment-email"
                      type="email"
                      tabIndex="1"
                      value={this.state.fields.email}
                      onChange={this.onChange('email')}
                      helperText={this.state.errorOnLogin || ''}
                      error={this.state.notFindLogin}
                    />
                  </FormControl>
                  <FormControl className={classes.fieldMargin}>
                    <TextField
                      label="Senha"
                      id="adornment-password"
                      tabIndex="2"
                      required
                      type={
                        this.state.fields.showPassword ? 'text' : 'password'
                      }
                      value={this.state.fields.password}
                      onChange={this.onChange('password')}
                      helperText={
                        this.state.notFindPassword ? 'Senha inválida' : ''
                      }
                      error={this.state.notFindPassword}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Tooltip
                              title={
                                !this.state.fields.showPassword
                                  ? 'Revelar senha'
                                  : 'Esconder Senha'
                              }
                              aria-label="password"
                            >
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={this.handleClickShowPassword}
                                onMouseDown={this.handleMouseDownPassword}
                              >
                                {this.state.fields.showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </Tooltip>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                  <Box className={classes.wrraperButtons}>
                    <Button type="submit" className={classes.submit}>
                      {this.state.loading ? (
                        <CircularProgress
                          size={22}
                          className={classes.circular}
                        />
                      ) : (
                        'Entrar'
                      )}
                    </Button>
                    <Link
                      // href="#"
                      align="left"
                      onClick={() => this.clickForgot()}
                      className={classes.link}
                    >
                      Esqueci minha senha
                    </Link>
                  </Box>
                </form>
              </Box>
            )}

            {this.state.fields.showForgot && (
              <Box
                className={classNames({
                  [classes.animationNono]: this.state.failLogin,
                  [classes.animationFade]: this.state.initialAnimation,
                  [classes.paper]: true,
                })}
              >
                <Box
                  textAlign="center"
                  fontWeight="700"
                  fontSize="16px"
                  lineHeight="26px"
                  color={colors.primary}
                >
                  REDEFINIÇÃO DE SENHA
                </Box>

                <form onSubmit={this.onSubmitReset} style={{ width: '100%' }}>
                  <FormControl className={classes.fieldMargin}>
                    <InputLabel htmlFor="adornment-email">
                      Digite o seu e-mail
                    </InputLabel>
                    <Input
                      required
                      id="adornment-email"
                      type="email"
                      value={this.state.fields.email}
                      onChange={this.onChange('email')}
                      fullWidth
                      helperText={this.state.errorOnLogin || ''}
                      error={this.state.notFindLogin}
                    />
                  </FormControl>

                  <Box className={classes.wrraperButtons}>
                    <Button type="submit" className={classes.submit}>
                      {this.state.loading ? (
                        <CircularProgress
                          size={22}
                          className={classes.circular}
                        />
                      ) : (
                        'Entrar'
                      )}
                    </Button>
                    <Link
                      // href=""
                      align="left"
                      onClick={() => {
                        this.clickForgot()
                      }}
                      className={classes.link}
                    >
                      Retornar para a pagina anterior
                    </Link>
                  </Box>
                </form>
              </Box>
            )}
          </Container>

          <Container
            className={classNames({
              [classes.animationNono]: this.state.failLogin,
              [classes.animationFade]: this.state.initialAnimation,
              [classes.wrraperFooter]: true,
            })}
          >
            <Box textAlign="center" fontSize="14px" color={colors.textPrimary}>
              Ainda não tem uma conta?
            </Box>
            <Box fontWeight="700" textAlign="center" fontSize="14px">
              <Link href="/" className={classes.link}>
                Você pode se cadastrar!
              </Link>
            </Box>
          </Container>
        </Box>
      </>
    )
  }
}

Login.propTypes = {
  login: PropTypes.func.isRequired,
}

// const mapStateToProps = () => ({})
const mapStateToProps = state => ({ loading: state.auth.loading })

const mapDispatchToProps = dispatch => {
  return bindActionCreators(AuthActions, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Login))
