import { EDIT_SERVICE_TYPE, FETCH_SERVICE_TYPE } from './ServiceTypeActions'

const INITIAL_STATE = {
  items: [],
  filters: {},
  editingServiceType: null,
  measureType: [],
  attendanceType: [],
  serviceCategory: [],
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_SERVICE_TYPE.SUCCESS:
      return {
        ...state,
        items: action.payload.data.data,
        measureType: action.payload.data.measureType,
        attendanceType: action.payload.data.attendanceType,
        serviceCategory: action.payload.data.serviceCategory,
        filters: action.payload.filters,
      }
    case EDIT_SERVICE_TYPE.SUCCESS:
      return {
        ...state,
        editingServiceType: action.payload,
      }
    default:
      return state
  }
}

export const getServiceTypeListData = state => {
  return {
    items: state.serviceType.items,
  }
}
