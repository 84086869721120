/* eslint-disable import/order */
/* eslint-disable camelcase */
import React, { useEffect } from 'react'
import BaseFilterModal from '../../../../components/hooks/BaseFilterModal'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { Grid } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import Form from './Form'
import { fetchProduct, updateProduct } from '../../StockActions'

const EditProduct = props => {
  const history = useHistory()
  const dispatch = useDispatch()
  const product = useSelector(state => state.stock.product)

  const { id } = props?.match?.params

  useEffect(() => {
    if (id) {
      dispatch(fetchProduct(id))
    }
  }, [id])

  const handleOpen = () => {
    history.push('/stock')
  }

  const onSubmit = async form => {
    const resp = await dispatch(updateProduct(id, form))

    if (resp) {
      handleOpen()
    }
  }

  return (
    <BaseFilterModal
      open
      handleOpen={handleOpen}
      title="Editar Produto"
      hiddenHelpButton
    >
      <Grid item xs={12} style={{ width: '220px' }}>
        <Form onSubmit={onSubmit} edit product={product} />
      </Grid>
    </BaseFilterModal>
  )
}

export default EditProduct
