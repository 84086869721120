/* eslint-disable react/prop-types */
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Popover from '@material-ui/core/Popover'
import Tooltip from '@material-ui/core/Tooltip'
import FilterListIcon from '@material-ui/icons/FilterList'
import SearchIcon from '@material-ui/icons/Search'
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state'
import React, { useState } from 'react'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { ValidatorForm } from 'react-material-ui-form-validator'
import { useDispatch, useSelector } from 'react-redux'
import { format } from 'date-fns'
import { InputAdornment, TextField } from '@material-ui/core'

import BadgeGlobal from '../../components/Badge'
import { ready } from '../HomeActions'
import BaseFilter from '../../../components/hooks/BaseFilter'
import Icon from '@mdi/react'
import { mdiMagnifyExpand } from '@mdi/js'
import colors from '../../../assets/colors'
import formComponentStyles from '../../../assets/formComponentsStyles'

export default function ReadyItemsFilter(props) {
  const items = useSelector(state => state.dashboard.readyItems)
  const formClasses = formComponentStyles()

  const dispatch = useDispatch()
  const { classes } = props
  const [form, setForm] = useState({
    collected_at: null,
    deadline_date: null,
    client: '',
  })

  const onSubmit = e => {
    e.preventDefault()
    const data = {
      ...form,
      collected_at: form.collected_at
        ? format(form.collected_at, 'yyyy-MM-dd HH:mm:ss')
        : null,
      deadline_date: form.deadline_date
        ? format(form.deadline_date, 'yyyy-MM-dd HH:mm:ss')
        : null,
    }

    dispatch(ready(data))
  }

  return (
    <BaseFilter>
      <form onSubmit={onSubmit} style={{ width: '100%' }}>
        <Grid container item xs={12} sm={10} alignItems="flex-end" spacing={1}>
          <Grid item xs={12} sm={3}>
            <TextField
              onChange={e => setForm({ ...form, client: e.target.value })}
              label="Nome do Cliente"
              className={classes.inputFilter}
              fullWidth
              value={form.client}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={onSubmit}>
                      <Icon path={mdiMagnifyExpand} size={1} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <KeyboardDatePicker
              clearable
              value={form.collected_at}
              label="Data da Coleta"
              fullWidth
              views={['date']}
              clearLabel="Limpar"
              invalidDateMessage="Data inválida"
              autoOk
              onChange={date => setForm({ ...form, collected_at: date })}
              format="dd/MM/yyyy"
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <KeyboardDatePicker
              clearable
              value={form.deadline_date}
              label="Data da Entrega"
              fullWidth
              clearLabel="Limpar"
              invalidDateMessage="Data inválida"
              autoOk
              margin="dense"
              onChange={date => setForm({ ...form, deadline_date: date })}
              format="dd/MM/yyyy"
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Button
              type="submit"
              color={colors.primary}
              className={formClasses.btnSubmmit}
            >
              <SearchIcon />
              {` Pesquisar`}
            </Button>
          </Grid>
        </Grid>
      </form>
    </BaseFilter>
    // <Tooltip title="Filter list">
    //   <PopupState variant="popover" popupId="demo-popup-popover">
    //     {popupState => (
    //       <div>
    //         <IconButton aria-label="filter list" {...bindTrigger(popupState)}>
    //           <BadgeGlobal form={form} items={items}>
    //             <FilterListIcon />
    //           </BadgeGlobal>
    //         </IconButton>
    //         <Popover
    //           {...bindPopover(popupState)}
    //           anchorOrigin={{
    //             vertical: 'bottom',
    //             horizontal: 'center',
    //           }}
    //           transformOrigin={{
    //             vertical: 'top',
    //             horizontal: 'center',
    //           }}
    //         >
    //           <Box p={2} className={classes.popover}>
    //             <div className={classes.root}>
    //               <ValidatorForm className={classes.form} onSubmit={onSubmit}>
    //                 <Grid
    //                   container
    //                   className={classes.gridContainer}
    //                   spacing={1}
    //                   justify="center"
    //                   alignItems="center"
    //                 >
    //                   <Grid item xs>
    //                     <KeyboardDatePicker
    //                       clearable
    //                       value={form.collected_at}
    //                       label="Data da Coleta"
    //                       fullWidth
    //                       clearLabel="Limpar"
    //                       invalidDateMessage="Data inválida"
    //                       autoOk
    //                       margin="dense"
    //                       inputVariant="outlined"
    //                       onChange={date =>
    //                         setForm({ ...form, collected_at: date })
    //                       }
    //                       format="dd/MM/yyyy"
    //                     />
    //                   </Grid>

    //                   <Grid item xs>
    //                     <KeyboardDatePicker
    //                       clearable
    //                       value={form.deadline_date}
    //                       label="Data da Entrega"
    //                       fullWidth
    //                       clearLabel="Limpar"
    //                       invalidDateMessage="Data inválida"
    //                       autoOk
    //                       margin="dense"
    //                       inputVariant="outlined"
    //                       onChange={date =>
    //                         setForm({ ...form, deadline_date: date })
    //                       }
    //                       format="dd/MM/yyyy"
    //                     />
    //                   </Grid>
    //                 </Grid>
    //                 <Grid container spacing={1}>
    //                   <Grid item xs={12}>
    //                     <TextField
    //                       onChange={e =>
    //                         setForm({ ...form, client: e.target.value })
    //                       }
    //                       label="Nome do Cliente"
    //                       margin="dense"
    //                       className={classes.inputFilter}
    //                       variant="outlined"
    //                       fullWidth
    //                       value={form.client}
    //                     />
    //                   </Grid>

    //                   <Grid item xs>
    //                     <Button
    //                       type="submit"
    //                       color="primary"
    //                       fullWidth
    //                       variant="contained"
    //                       className={classes.submit}
    //                       onClick={popupState.close}
    //                     >
    //                       <SearchIcon />
    //                       {` Filtrar`}
    //                     </Button>
    //                   </Grid>
    //                 </Grid>
    //               </ValidatorForm>
    //             </div>
    //           </Box>
    //         </Popover>
    //       </div>
    //     )}
    //   </PopupState>
    // </Tooltip>
  )
}
