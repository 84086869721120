import { EDIT_LAUNDRY_CLIENT_BILLING, FETCH_LAUNDRY_CLIENT_BILLING } from './LaundryClientBillingsActions'

const INITIAL_STATE = {
  items: [],
  totalSize: 0,
  page: 1,
  sizePerPage: 10,
  filters: [],
  editingClient: null
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_LAUNDRY_CLIENT_BILLING.SUCCESS:
      return {
        ...state,
        items: action.payload.data,
        filters: action.payload.data.filters,
        totalSize: action.payload.meta.total,
        page: action.payload.meta.current_page,
        sizePerPage: action.payload.meta.per_page,
        plans: action.payload.plans,
      }
    case EDIT_LAUNDRY_CLIENT_BILLING.SUCCESS:
      return {
        ...state,
        editingClient: action.payload,
      }
    default:
      return state
  }
}

export const getLaundryClientListData = state => {
  return {
    items: state.LaundryClientBillings.items,
    page: state.LaundryClientBillings.page,
    sizePerPage: state.LaundryClientBillings.sizePerPage,
    totalSize: state.LaundryClientBillings.totalSize,
    plans: state.LaundryClientBillings.plans,
  }
}

export const getEditingLaundryClient = state => {
  return state.LaundryClientBillings.editingClient
}
