import { Divider, Grid, Typography, makeStyles } from '@material-ui/core'
import React from 'react'
import colors from '../../../assets/colors'
import CountUp from 'react-countup'
import Icon from '@mdi/react'

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  aditionalBox: {
    padding: '8px',
    color: colors.primary,
    fontSize: '12px',
    lineHeight: '12px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '12px',
  },
  title: {
    color: colors.primary,
    fontSize: '17px',
    display: 'flex',
    textAlign: 'start',
    alignItems: 'center',
    fontWeight: 700,
    lineHeight: '17px',
  },
})

const AditionalBox = ({ top, title, value, icon, isDecimal = false }) => {
  const classes = useStyles()

  const contUp = money => {
    return (
      <CountUp
        end={money}
        duration={0.9}
        decimals={2}
        decimal=","
        // prefix="R$"
        separator="."
      />
    )
  }

  return (
    <Grid container className={[classes.root, classes.aditionalBox]}>
      <Grid item>
        <Icon path={icon} color={colors.graylight} size={2.5} />
      </Grid>
      <Grid item className={classes.title} xs={3}>
        {title}
      </Grid>

      <Divider orientation="vertical" flexItem />

      <Grid container item xs justifyContent="flex-start">
        <Grid item xs={12}>
          {top}
        </Grid>
        <Typography
          variant="h3"
          style={{
            color: colors.primary,
            fontSize: '40px',
            fontWeight: '700',
          }}
          color={colors.primary}
        >
          {isDecimal ? contUp(value) : value}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default AditionalBox
