import { Grid } from '@material-ui/core'
import React from 'react'

const FormRoot = ({ children }) => {
  return (
    <Grid container spacing={4}>
      {children}
    </Grid>
  )
}

export default FormRoot
