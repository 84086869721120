/* eslint-disable object-shorthand */
import axios from 'axios/index'

import config from '../../config'
import action, { actionFailed } from '../../util/action'
import createAsyncConst from '../../util/createAsyncConst'

export const FETCH_STOCK = createAsyncConst('FETCH_STOCK')
export const STORE_MOVEMENT = createAsyncConst('STORE_MOVEMENT')
export const FETCH_PRODUCT = createAsyncConst('FETCH_PRODUCT')
export const STORE_PRODUCT = createAsyncConst('STORE_PRODUCT')
export const DELETE_PRODUCT = createAsyncConst('DELETE_PRODUCT')

export function fetchStock(filters = {}) {
  return dispatch => {
    dispatch(action(FETCH_STOCK.ACTION))
    return axios
      .get(`${config.API_URL}/product`, {
        params: filters,
      })
      .then(resp => {
        dispatch(
          action(FETCH_STOCK.SUCCESS, {
            data: resp.data,
          })
        )
        return resp
      })
      .catch(e => dispatch(actionFailed(FETCH_STOCK, e)))
  }
}

export function fetchProduct(id) {
  return dispatch => {
    dispatch(action(FETCH_PRODUCT.ACTION))
    return axios
      .get(`${config.API_URL}/product/${id}`)
      .then(resp => {
        dispatch(action(FETCH_PRODUCT.SUCCESS, resp?.data))
        dispatch(fetchStock())
        return true
      })
      .catch(e => {
        dispatch(actionFailed(FETCH_PRODUCT, e))
        return false
      })
  }
}

export function storeProduct(form) {
  return dispatch => {
    dispatch(action(STORE_PRODUCT.ACTION))
    return axios
      .post(`${config.API_URL}/product`, form)
      .then(resp => {
        dispatch(action(STORE_PRODUCT.SUCCESS, resp?.data))
        dispatch(fetchStock())
        return true
      })
      .catch(e => {
        dispatch(actionFailed(STORE_PRODUCT, e))
        return false
      })
  }
}

export function updateProduct(id, form) {
  return dispatch => {
    dispatch(action(STORE_PRODUCT.ACTION))
    return axios
      .put(`${config.API_URL}/product/${id}`, form)
      .then(resp => {
        dispatch(action(STORE_PRODUCT.SUCCESS, resp?.data))
        dispatch(fetchStock())
        return true
      })
      .catch(e => {
        dispatch(actionFailed(STORE_PRODUCT, e))
        return false
      })
  }
}

export function storeMovement(form, type) {
  return dispatch => {
    dispatch(action(STORE_MOVEMENT.ACTION))
    return axios
      .post(`${config.API_URL}/movement/${type}`, form)
      .then(resp => {
        dispatch(action(STORE_MOVEMENT.SUCCESS, resp?.data))
        dispatch(fetchStock())
        return true
      })
      .catch(e => {
        dispatch(actionFailed(STORE_MOVEMENT, e))
        return false
      })
  }
}

export function deleteProduct(id) {
  return dispatch => {
    dispatch(action(DELETE_PRODUCT.ACTION))
    return axios
      .delete(`${config.API_URL}/product/${id}`)
      .then(resp => {
        dispatch(action(DELETE_PRODUCT.SUCCESS, resp))
        dispatch(fetchStock())
        return true
      })
      .catch(e => {
        dispatch(actionFailed(DELETE_PRODUCT, e))
        return false
      })
  }
}

// const submit = (values, method) => {
//   const id = values.id ? `/${values.id}` : ''

//   return async (dispatch, getState) => {
//     dispatch(action(SUBMIT_ATTENDANCE.ACTION))

//     try {
//       const resp = await axios[method](
//         `${config.API_URL}/attendance${id}`,
//         values
//       )

//       dispatch(action(SUBMIT_ATTENDANCE.SUCCESS, resp?.data))
//       const rowsPerPage = getState().attendance.sizePerPage
//       dispatch(fetchAttendanceInit(null, rowsPerPage))

//       return resp?.data
//     } catch (e) {
//       dispatch(actionFailed(SUBMIT_ATTENDANCE, e))
//     }
//   }
// }
