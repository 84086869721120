/* eslint-disable react/prop-types */
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Popover from '@material-ui/core/Popover'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import FilterListIcon from '@material-ui/icons/FilterList'
import SearchIcon from '@material-ui/icons/Search'
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state'
import React, { useEffect, useState } from 'react'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { ValidatorForm } from 'react-material-ui-form-validator'
import { useDispatch, useSelector } from 'react-redux'

import BadgeGlobal from '../../components/Badge'
import { fetchBirthRelatoryInit, makeRelatory } from '../BirthRelatoryActions'
import BaseFilter from '../../../components/hooks/BaseFilter'
import Icon from '@mdi/react'
import { mdiFilterMultiple, mdiMagnifyExpand, mdiMicrosoftExcel } from '@mdi/js'
import filterStylesComponents from '../../../assets/filterComponentsStyles'
import colors from '../../../assets/colors'
import { InputAdornment } from '@material-ui/core'

export default function Filter(props) {
  const items = useSelector(state => state.birth_relatory.items)

  const styles = filterStylesComponents()

  const dispatch = useDispatch()
  const { classes } = props
  const [form, setForm] = useState({
    name: '',
    birthday_start: null,
    birthday_end: null,
  })

  const onSubmit = e => {
    e.preventDefault()
    dispatch(fetchBirthRelatoryInit(null, form, null))
  }

  const handleChangeDate = e => {
    e.preventDefault()

    if (form.birthday_end && form.birthday_start) {
      dispatch(fetchBirthRelatoryInit(null, form, null))
    }
  }

  const handleExportExcel = () => {
    dispatch(makeRelatory(form))
  }

  useEffect(() => {
    if (!form.name) {
      dispatch(fetchBirthRelatoryInit(null, form, null))
    }
  }, [form.name])

  return (
    <BaseFilter>
      <Grid container item xs={12} sm={10} alignItems="flex-end" spacing={1}>
        <Grid item xs={12} sm={4}>
          <TextField
            onChange={e => setForm({ ...form, name: e.target.value })}
            label="Filtre por nome"
            className={classes.inputFilter}
            fullWidth
            value={form.name}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={onSubmit}>
                    <Icon path={mdiMagnifyExpand} size={1} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs={12} sm={3}>
          <KeyboardDatePicker
            clearable
            value={form.birthday_start}
            label="Aniversário Inicial"
            fullWidth
            views={['date']}
            clearLabel="Limpar"
            invalidDateMessage="Data inválida"
            autoOk
            onBlur={handleChangeDate}
            onChange={date => setForm({ ...form, birthday_start: date })}
            format="dd/MM"
          />
        </Grid>

        <Grid item xs={12} sm={3}>
          <KeyboardDatePicker
            clearable
            value={form.birthday_end}
            label="Aniversário Final"
            fullWidth
            views={['date']}
            clearLabel="Limpar"
            invalidDateMessage="Data inválida"
            autoOk
            onBlur={handleChangeDate}
            onChange={date => setForm({ ...form, birthday_end: date })}
            format="dd/MM"
          />
        </Grid>
      </Grid>
      <Grid item xs={12} sm={2} container className={styles.areaButtons}>
        <IconButton className={styles.btnAdd} onClick={handleExportExcel}>
          <Icon path={mdiMicrosoftExcel} size={1} />
          Download
        </IconButton>
      </Grid>
      {/* <Tooltip title="Filter list">
        <PopupState variant="popover" popupId="demo-popup-popover">
          {popupState => (
            <div>
              <IconButton aria-label="filter list" {...bindTrigger(popupState)}>
                <BadgeGlobal form={form} items={items}>
                  <FilterListIcon />
                </BadgeGlobal>
              </IconButton>
              <Popover
                {...bindPopover(popupState)}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <Box p={2} className={classes.popover}>
                  <div className={classes.root}>
                    <ValidatorForm className={classes.form} onSubmit={onSubmit}>
                      <Grid
                        container
                        className={classes.gridContainer}
                        spacing={1}
                        justify="center"
                        alignItems="center"
                      >

                        <Grid item xs>
                          <Button
                            type="submit"
                            color="primary"
                            fullWidth
                            variant="contained"
                            className={classes.submit}
                            onClick={popupState.close}
                          >
                            <SearchIcon />
                            {` Filtrar`}
                          </Button>
                        </Grid>
                      </Grid>
                    </ValidatorForm>
                  </div>
                </Box>
              </Popover>
            </div>
          )}
        </PopupState>
      </Tooltip> */}
    </BaseFilter>
  )
}
