import {
  DialogActions,
  FormControlLabel,
  TextField,
} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import DialogContent from '@material-ui/core/DialogContent'
import Grid from '@material-ui/core/Grid'
import withStyles from '@material-ui/core/styles/withStyles'
import ThumbDownAltOutlinedIcon from '@material-ui/icons/ThumbDownAltOutlined'
import ThumbUpAltOutlinedIcon from '@material-ui/icons/ThumbUp'
import React, { useEffect, useState } from 'react'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'
import { useDispatch } from 'react-redux'
import { SNACKBAR } from '../../main/MainActions'
import Checkbox from '@material-ui/core/Checkbox'
import NumberFormat from '../../components/NumberFormat'
import styles from '../../../resources/theme/users'



function Form(props) {
  const { data, onClose, onSubmit, notShowPost, children } = props
  const dispatch = useDispatch()

  const [form, updateForm] = useState({
    id: '',
    name: '',
    description: '',
    max_users: '',
    is_active: 1,

  })

  const updateField = React.useCallback(name => e => {
    updateForm({
      ...form,
      [name]: e.target.value,
    })
  })

  const statusChange = (() => {
    form.is_active == 1 ? 
    updateForm({
      ...form,
      is_active: 0,
    })
    :
    updateForm({
      ...form,
      is_active: 1,
    })
  })

  const handleClose = () => onClose && onClose()

  const onSubmitForm = e => {
    e.preventDefault()
    onSubmit && onSubmit(form)
  }

  const onSubmitAndRedirect = e => {
    e.preventDefault()
    onSubmit && onSubmit(form, true)
  }


  useEffect(() => {
    updateForm({ ...form, ...data })
  }, [data])

  return (
    <ValidatorForm>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item sm={6} xs={4} lg={4}>
            <TextValidator
              validators={['required']}
              errorMessages={['Campo Obrigatório']}
              onChange={updateField('name')}
              label="Nome"
              fullWidth
              value={form.name}
            />
          </Grid>
        

          <Grid item sm={6} xs={4} lg={4}>
            <TextField
              type="number"
              onChange={updateField('max_users')}
              label="Máximo de Usuários"
              fullWidth
              value={form.max_users}
            />
          </Grid>
          

          <Grid item sm={6} xs={4} lg={4}>
            <TextField
              onChange={updateField('price')}
              label="Valor"
              fullWidth
              value={form.price}
              InputProps={{
                inputComponent: NumberFormat,
              }}
            />
          </Grid>

          <Grid item sm={6} xs={12} lg={12}>
            <TextField
              type="text"
              onChange={updateField('description')}
              label="Descrição"
              fullWidth
              value={form.description}
            />
          </Grid>
        </Grid>

        <Grid item xs={12} lg={3} sm={12} md={6}>
          <FormControlLabel
            control={
              <Checkbox
                icon={<ThumbDownAltOutlinedIcon />}
                checkedIcon={<ThumbUpAltOutlinedIcon color="secondary" />}
                checked={form.is_active}
                onChange={statusChange}
                value={form.is_active}
              />
            }
            label="Ativo?"
          />
        </Grid>

        <>{children}</>
      </DialogContent>

      <DialogActions>
        {notShowPost && (
          <Button color="primary" type="button" onClick={onSubmitAndRedirect}>
            Salvar e agendar
          </Button>
        )}
        <Button color="primary" onClick={onSubmitForm}>
          Salvar
        </Button>
        {!notShowPost && <Button onClick={handleClose}>Cancelar</Button>}
      </DialogActions>
    </ValidatorForm>
  )
}

export default withStyles(styles)(Form)
