/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/extensions */
// eslint-disable-next-line simple-import-sort/sort
import DateFnsUtils from '@date-io/date-fns'
import CssBaseline from '@material-ui/core/CssBaseline'
import withStyles from '@material-ui/core/styles/withStyles'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import axios from 'axios'
import classNames from 'classnames'
import { format } from 'date-fns'
import ptBRLocale from 'date-fns/locale/pt-BR'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import Menu from '../components/Menu/Menu.jsx'

import Routes from './routes'

const styles = theme => ({
  root: {
    display: 'flex',
    fontFamily: 'Roboto',
    maxHeight: '100vh',
    height: '100vh',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(2),
    paddingLeft: '24px',
    paddingRight: '24px',
    backgroundColor: '#FFFF',
    marginTop: '48px',
    overflowY: 'auto',
  },
  drawerHeader: {
    minHeight: 49,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
  },
})

axios.interceptors.request.use(
  async config => {
    const { headers } = config

    if (window?.Echo?.socketId()) {
      headers['X-Socket-ID'] = window.Echo.socketId()
    }

    return config
  },
  error => {
    // Do something with request error
    console.log('axios.interceptors.error')
    return Promise.reject(error)
  }
)

class App extends Component {
  constructor() {
    super()

    this.state = {
      open: false,
      rendered: false,
    }
  }

  componentDidMount() {
    this.setState(prevState => ({ ...prevState, rendered: true }))
  }

  handleDrawerOpen = () => {
    this.setState(prevState => ({ ...prevState, open: true }))
  }

  handleDrawerClose = () => {
    this.setState(prevState => ({ ...prevState, open: false }))
  }

  render() {
    const { classes, theme, toogleTheme } = this.props
    const { open, rendered } = this.state

    return (
      <>
        {rendered && (
          <div className={classes.root}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBRLocale}>
              <CssBaseline />
              <Menu
                open={open}
                toogleTheme={toogleTheme}
                theme={theme}
                handleDrawerClose={this.handleDrawerClose}
                handleDrawerOpen={this.handleDrawerOpen}
              />
              <main
                className={classNames(classes.content, {
                  [classes.contentShift]: open,
                })}
              >
                {/* <div className={classes.drawerHeader} /> */}
                <Routes />
              </main>
            </MuiPickersUtilsProvider>
          </div>
        )}
      </>
    )
  }
}

App.propTypes = {
  classes: PropTypes.object.isRequired,
  // toogleTheme: PropTypes.func.isRequired,
}

export default withRouter(connect()(withStyles(styles)(App)))
