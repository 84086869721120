import {
  Badge,
  Box,
  Button,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  makeStyles,
  withStyles,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SyncIcon from '@material-ui/icons/Sync'
import classNames from 'classnames'

import { fetchDashboard, eraseDashboardFilters } from '../HomeActions'
import styles from '../../../resources/theme/global'
import { renderRoute } from '../../../util/utils'

import Cards from './Cards'
import Filter from './Filters'
import colors from '../../../assets/colors'
import PeriodFilter from '../../../components/hooks/BaseButtonGroup'
import Chart from 'react-google-charts'
import PieChart from './PieChart'
import filterStylesComponents from '../../../assets/filterComponentsStyles'
import { Link } from 'react-router-dom/cjs/react-router-dom'

const useStyles = makeStyles({
  chartBox: {
    width: '100%',
    border: `1px solid ${colors.graylight}`,
    borderRadius: '10px',
    padding: '24px',
  },
  chartBoxHeader: {
    fontSize: '20px',
    color: colors.primary,
    lineHeight: '20px',
  },
  teste: {
    border: '1px solid red',
  },
})

function Home(props) {
  const classes = useStyles()
  const filterStyles = filterStylesComponents()
  // const { classes } = props
  const refreshing = useSelector(state => state.dashboard.refreshing)
  // const filters = useSelector(state => state.dashboard.filters)
  const readyItems = useSelector(state => state.dashboard.readyCount)
  const readyItemsLaundry = useSelector(
    state => state.dashboard.readyLaundryCount
  )
  const permissions = useSelector(state => state.auth.permissions)
  const role = useSelector(state => state.auth.role) || {}

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchDashboard())
    dispatch(eraseDashboardFilters())

    return () => {
      dispatch(eraseDashboardFilters())
    }
  }, [])

  // const refresh = () => {
  //   dispatch(fetchDashboard(filters))
  // }

  const [filters, setFilters] = useState({
    date_start: '',
    date_end: '',
  })

  useEffect(() => {
    dispatch(fetchDashboard(filters))
  }, [filters])

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Grid container justifyContent="space-between">
          <Grid item xs>
            <Typography
              variant="h4"
              style={{ fontSize: '20px' }}
              color={colors.primary}
            >
              Dashboard Itens
            </Typography>
          </Grid>

          <Grid item xs container justifyContent="flex-end" spacing={2}>
            <Grid item>
              <IconButton
                variant="contained"
                component={Link}
                className={filterStyles.btnAdd}
                to={{
                  pathname: `/details/ready`,
                  state: {
                    date_start: filters?.date_start,
                    date_end: filters?.date_end,
                  },
                }}
              >
                OSS PRONTAS PARA ENTREGA
              </IconButton>
            </Grid>
            <Grid item>
              <PeriodFilter filters={filters} setFilters={setFilters} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Cards {...props} filters={filters} />

        {/* <Grid container spacing={3}>
          <Grid item md={6} xs={12}>
            <Box className={classes.chartBox}>
              <Grid container spacing={2}>
                <Grid item xs={12} className={classes.chartBoxHeader}>
                  Itens
                </Grid>

                <Grid item xs={12}>
                  <PieChart />
                </Grid>

                <Grid item xs={12}>
                  <Cards {...props} />
                </Grid>
              </Grid>
            </Box>
          </Grid>

          <Grid item md={6} xs={12}>
            <Box className={classes.chartBox}>
              <Grid container spacing={2}>
                <Grid item xs={12} className={classes.chartBoxHeader}>
                  OS
                </Grid>

                <Grid item xs={12}>
                  <PieChart />
                </Grid>

                <Grid item xs={12}>
                  Cards
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid> */}
      </Grid>
    </Grid>
    // <>
    //   <Grid
    //     container
    //     className={classes.pageHead}
    //     spacing={1}
    //     alignItems="center"
    //     style={{ border: '1px solid red' }}
    //   >
    //     <Grid item xs style={{ border: '1px solid red' }}>
    //       <Typography variant="h4" color="secondary">
    //         Dashboard
    //       </Typography>
    //     </Grid>
    //     <Grid item style={{ border: '1px solid red' }}>
    //       <Badge
    //         badgeContent={readyItems}
    //         showZero="false"
    //         anchorOrigin={{
    //           vertical: 'top',
    //           horizontal: 'left',
    //         }}
    //         color="primary"
    //         max={9999}
    //       >
    //         <Button
    //           variant="outlined"
    //           component={Link}
    //           to="/details/ready"
    //           color="primary"
    //         >
    //           OSs prontas para entrega
    //         </Button>
    //       </Badge>
    //     </Grid>
    //     <Grid item style={{ border: '1px solid red' }}>
    //       <Filter classes={classes} />
    //     </Grid>
    //     <Grid item style={{ border: '1px solid red' }}>
    //       <Tooltip title="Atualizar">
    //         <IconButton color="secondary" onClick={refresh}>
    //           <SyncIcon
    //             className={classNames({
    //               [classes.btnRefreshing]: refreshing,
    //             })}
    //           />
    //         </IconButton>
    //       </Tooltip>
    //     </Grid>
    //   </Grid>

    //   <Cards {...props} />
    // </>
  )
}

export default withStyles(styles)(Home)
