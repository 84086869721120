/* eslint-disable prettier/prettier */
/* eslint-disable import/order */
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import BaseFilter from '../../../components/hooks/BaseFilter'
import {
  Box,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core'
import filterStylesComponents from '../../../assets/filterComponentsStyles'
import {
  mdiFilterMultiple,
  mdiMagnify,
  mdiPackageVariantClosedMinus,
  mdiPackageVariantClosedPlus,
} from '@mdi/js'
import Icon from '@mdi/react'
import { Link } from 'react-router-dom/cjs/react-router-dom'
import colors from '../../../assets/colors'
import BaseFilterModal from '../../../components/hooks/BaseFilterModal'

const Filter = ({ form, setForm }) => {
  const classes = filterStylesComponents()

  const [open, setOpen] = useState(false)
  const [currentForm, setCurrentForm] = useState({
    name: '',
    unit_measure_id: '',
  })

  const handleOpen = () => {
    setOpen(!open)
  }

  const handleChange = e => {
    e.preventDefault()

    const { name, value } = e.target

    setCurrentForm({
      ...form,
      [name]: value,
    })
  }

  const handleSubmit = () => {
    setForm({
      ...form,
      ...currentForm,
    })

    handleOpen()
  }

  return (
    <BaseFilter>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item sm={8} xs={6} className={classes.areaButtons}>
            <IconButton className={classes.btnMoreFilters} onClick={handleOpen}>
              <Icon
                path={mdiFilterMultiple}
                size={1}
                color={colors.secondary}
              />
              Filtrar
            </IconButton>

            <IconButton
              className={classes.btnAdd}
              component={Link}
              to="/stock/product/new"
            >
              Cadastrar Produto
            </IconButton>
          </Grid>
          <Grid item sm={4} xs={6} className={classes.areaButtons}>
            <IconButton
              className={classes.btnAdd}
              component={Link}
              to="/stock/movements/1"
            >
              <Icon path={mdiPackageVariantClosedPlus} size={1} />
              Informar Entrada
            </IconButton>
            <IconButton
              className={classes.btnAdd}
              component={Link}
              to="/stock/movements/2"
            >
              <Icon path={mdiPackageVariantClosedMinus} size={1} />
              Informar Saída
            </IconButton>
          </Grid>
        </Grid>
      </Grid>

      <BaseFilterModal open={open} handleOpen={handleOpen} title="FILTRO">
        <Grid item xs={12} style={{ width: '220px' }}>
          <Grid
            container
            alignItems="center"
            justifyContent="flex-start"
            spacing={1}
          >
            <Grid item xs={12}>
              <Typography
                style={{ fontSize: '16px', fontWeight: 'normal' }}
                color={colors.primary}
              >
                Utilize os campos para encontrar um produto
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Nome"
                name="name"
                value={currentForm.name}
                onChange={handleChange}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Medida"
                value={currentForm.unit_measure_id}
                onChange={handleChange}
                select
                name="unit_measure_id"
                fullWidth
              >
                <MenuItem value="">Selecione</MenuItem>
                {[]?.map(unitMeasure => (
                  <MenuItem value={unitMeasure?.id} key={unitMeasure?.id}>
                    {unitMeasure?.value}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid
              item
              xs={12}
              container
              alignItems="center"
              justifyContent="center"
              style={{ marginTop: '1em' }}
            >
              <IconButton className={classes.btnAdd} onClick={handleSubmit}>
                <Icon path={mdiMagnify} size={1} />
                Pesquisar
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </BaseFilterModal>
    </BaseFilter>
  )
}

export default Filter
