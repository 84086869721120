import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import DeleteIcon from '@material-ui/icons/Delete'
import PencilIcon from '@material-ui/icons/Edit'
import { useConfirm } from 'material-ui-confirm'
import React from 'react'
import { useDispatch } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'

import { getSorting, stableSort } from '../../util/TableUtils'
import { renderRoute } from '../../util/utils'

import EnhancedTableHead from './TableHead'
import { StyledTableCellBody } from '../../components/hooks/BaseTableComponents'
import { Box } from '@material-ui/core'
import colors from '../../assets/colors'
import Icon from '@mdi/react'
import { mdiDelete, mdiNoteEditOutline, mdiPlaylistEdit } from '@mdi/js'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  tableStickHeader: {
    maxHeight: 'calc(100vh - 270px)',
  },
  status: {
    textAlign: 'center',
    fontSize: '14p',
    borderRadius: '10px',
    padding: '4px 16px',
    maxWidth: '80px',
    backgroundColor: colors.graylight,
    color: '#000',
  },
  statusAtivo: {
    color: '#FFFF',
    backgroundColor: colors.secondary,
  },
  retangleColor: {
    width: '18px',
    height: '18px',
    borderRadius: '2px',
  },
}))

function List(props) {
  const {
    permissions,
    remove,
    headCells,
    rows,
    hiddenDefaultIcons,
    orderField,
    renderFirstTH,
  } = props
  const classes = useStyles()
  const confirm = useConfirm()
  const dispatch = useDispatch()
  const [order, setOrder] = React.useState('asc')
  const [orderBy, setOrderBy] = React.useState(orderField || 'value')
  const [selected, setSelected] = React.useState([])
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === 'desc'
    setOrder(isDesc ? 'asc' : 'desc')
    setOrderBy(property)
  }

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n.name)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const confirmDelete = id => {
    confirm({
      description: 'Após ser apagado o conteúdo não poderá ser recuperado?',
      title: 'Tem certeza?',
      confirmationText: 'Sim',
      cancellationText: 'Cancelar',
      dialogProps: {
        fullWidth: true,
      },
    }).then(() => {
      dispatch(remove(id))
    })
  }

  return (
    <>
      <TableContainer className={classes.tableStickHeader}>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size="medium"
          stickyHeader
        >
          <EnhancedTableHead
            classes={classes}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
            headCells={headCells}
            hideID
            renderFirstTH={renderFirstTH}
          />
          <TableBody>
            {stableSort(rows, getSorting(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map(row => {
                return (
                  <TableRow hover tabIndex={-1} key={row.id}>
                    {renderFirstTH && (
                      <StyledTableCellBody padding="checkbox" />
                    )}
                    {Object.entries(row).map((item, index) => {
                      const except = ['update', 'delete', 'link_update']
                      if (
                        item[0] === headCells[index]?.id &&
                        except.indexOf(item[0]) === -1
                      ) {
                        if (item[0] === 'id') {
                          return null
                        }

                        if (item[0] === 'status') {
                          return (
                            <StyledTableCellBody>
                              {' '}
                              {row.status.toLowerCase() === 'ativo' ? (
                                <Box
                                  className={[
                                    classes.status,
                                    classes.statusAtivo,
                                  ]}
                                >
                                  {row.status}
                                </Box>
                              ) : (
                                <Box className={classes.status}>
                                  {row.status}
                                </Box>
                              )}
                            </StyledTableCellBody>
                          )
                        }

                        return (
                          <StyledTableCellBody>{item[1]}</StyledTableCellBody>
                        )
                      }
                    })}
                    {!hiddenDefaultIcons && (
                      <StyledTableCellBody align="center">
                        {renderRoute([row.update], permissions) &&
                          row?.is_client_service != 1 && (
                            <IconButton
                              aria-label="edit"
                              component={Link}
                              to={row.link_update}
                            >
                              <Icon path={mdiNoteEditOutline} size={1} />
                            </IconButton>
                          )}
                        {renderRoute([row.delete], permissions) &&
                          row.is_client_service != 1 && (
                            <IconButton onClick={() => confirmDelete(row.id)}>
                              <Icon path={mdiDelete} size={1} />
                            </IconButton>
                          )}
                      </StyledTableCellBody>
                    )}
                  </TableRow>
                )
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        labelRowsPerPage="Linhas por Página"
        backIconButtonProps={{
          'aria-label': 'previous page',
        }}
        labelDisplayedRows={({ from, to, count }) =>
          `Mostrando linhas ${from}-${to} de ${count}`
        }
        nextIconButtonProps={{
          'aria-label': 'next page',
        }}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  )
}

export default withRouter(List)
