import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router'
import queryString from 'query-string'

import { remove, fetchBilling, clearFilter } from '../BillingActions'
import { exportFormatedMoney } from '../../../util/utils'

import ListTable from './ListTable'

function createData(
  id,
  billingType,
  value,
  paymentStatus,
  client,
  date,
  created,
  actualPaymentDate,
  type,
  paymentDate,
  serviceID,
  service,
  linkTo,
  deadlineCurrentMonth,
  bank
) {
  return {
    id,
    billingType,
    value,
    paymentStatus,
    client,
    date,
    created,
    actualPaymentDate,
    type,
    paymentDate,
    serviceID,
    service,
    linkTo,
    deadlineCurrentMonth,
    bank,
    update: 'update-billing',
    delete: 'delete-billing',
    link_update: `/billing/${id}/edit`,
  }
}

const headCells = [
  {
    id: 'billingType',
    numeric: true,
    disablePadding: false,
    label: 'Tipo de conta',
  },
  {
    id: 'value',
    numeric: true,
    disablePadding: false,
    label: 'Valor',
  },
  {
    id: 'date',
    numeric: true,
    disablePadding: false,
    label: 'Vencimento',
  },
  {
    id: 'created',
    numeric: true,
    disablePadding: false,
    label: 'Data de cadastro',
  },
  {
    id: 'actualPaymentDate',
    numeric: true,
    disablePadding: false,
    label: 'Data de pagamento',
  },
  {
    id: 'paymentStatus',
    numeric: true,
    disablePadding: false,
    label: 'Status',
  },
  {
    id: 'client',
    numeric: true,
    disablePadding: false,
    label: 'Contato',
  },
  {
    id: 'bank',
    numeric: true,
    disablePadding: false,
    label: 'Banco',
  },
  {
    id: 'actions',
    numeric: true,
    disablePadding: false,
    label: 'Ações',
  },
]

function List(props) {
  const { items } = props
  const rows = []
  const dispatch = useDispatch()
  const sizePerPage = useSelector(state => state.billing.sizePerPage)
  const page = useSelector(state => state.billing.page)
  const totalSize = useSelector(state => state.billing.totalSize)
  const [rowsPerPage, setRowsPerPage] = useState(sizePerPage)
  const filters = useSelector(state => state.billing.filters)

  const returnClientName = row => {
    return row.person?.name
  }

  const laundryOrClient = row => {
    if (!row.service) return null

    if (row?.person?.type === 'client')
      return `/attendance/${row.service.id}/edit`
    if (row?.person?.type === 'laundry')
      return `/laundry-os/${row.service.id}/details`

    return null
  }

  items.map(item => {
    return rows.push(
      createData(
        item.id,
        item.billing_type?.name,
        exportFormatedMoney(item.value),
        item.payment_status?.name,
        returnClientName(item),
        item.date,
        item.created_at,
        item.actual_payment_date,
        item.billing_type.type_text,
        item.payment_date,
        item.service?.id,
        item.service,
        laundryOrClient(item),
        item.deadline_current_month,
        item.bank?.name,
        item.billing_type?.type
      )
    )
  })

  const onMovePage = async (event, newPage) => {
    const values = queryString.parse(props.location.search)
    const datafilters = { ...filters, ...values }
    dispatch(fetchBilling(datafilters, rowsPerPage, newPage + 1))
  }

  useEffect(() => {
    onMovePage()
  }, [rowsPerPage])

  useEffect(() => {
    return () => dispatch(clearFilter())
  }, [])

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value)
  }

  return (
    <ListTable
      {...props}
      onMovePage={onMovePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      headCells={headCells}
      rows={rows}
      remove={remove}
      rowsPerPage={rowsPerPage}
      page={page - 1}
      totalSize={totalSize}
    />
  )
}

export default withRouter(List)
