/* eslint-disable default-case */
/* eslint-disable no-unused-vars */
import {
  FETCH_SUBSCRIPTION,
  SUBMIT_SUBSCRIPTION,
  FETCH_PAYMENT_HISTORY,
  STORE_CREDIT_CARD,
  SHOW_CREDIT_CARD,
  DESTROY_CREDIT_CARD,
} from './SubscriptionActions'

const INITIAL_STATE = {
  planDetails: [],
  creditCards: [],
  historyPayment: [],
  allPlans: [],
  paymentMethods: [],
  creditCard: [],
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_SUBSCRIPTION.SUCCESS:
      return {
        ...state,
        planDetails: action.payload.data.data,
        creditCards: action.payload.data.creditCards,
        allPlans: action.payload.data.allPlans,
        paymentMethods: action.payload.data.paymentMethods,
      }
    case FETCH_PAYMENT_HISTORY.SUCCESS:
      return {
        ...state,
        historyPayment: action.payload.data.data,
      }
    case SUBMIT_SUBSCRIPTION.SUCCESS:
      return state
    case STORE_CREDIT_CARD.SUCCESS:
      return state
    case SHOW_CREDIT_CARD.SUCCESS:
      return {
        ...state,
        creditCard: action.payload.data.data,
      }
    case DESTROY_CREDIT_CARD.SUCCESS:
      return state
    default:
      return state
  }
}
