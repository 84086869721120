import { Box, Grid, Typography, makeStyles } from '@material-ui/core'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import config from '../../../config'

import Card from './Card'
import CardService from './CardServices'
import PieChart from './PieChart'
import colors from '../../../assets/colors'

const useStyles = makeStyles({
  chartBox: {
    width: '100%',
    border: `1px solid ${colors.graylight}`,
    borderRadius: '10px',
    padding: '24px',
  },
  chartBoxHeader: {
    fontSize: '20px',
    color: colors.primary,
    lineHeight: '20px',
  },
  teste: {
    border: '1px solid red',
  },
})

function Cards(props) {
  const { classes, filters } = props
  const styles = useStyles()
  const items = useSelector(state => state.dashboard.items)
  const serviceItems = useSelector(state => state.dashboard.serviceItems)
  const role = useSelector(state => state.auth.role) || {}

  const [dashboardItems, setDashboardItems] = useState()

  useEffect(() => {
    async function getDashboardItems() {
      await axios.get(`${config.API_URL}/admin-dashboard`).then(response => {
        setDashboardItems(response.data.data)
      })
    }

    getDashboardItems()
  }, [])

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        {/* <Typography variant="h6" color="secondary">
          Status de Itens
        </Typography> */}

        {role[0] !== undefined &&
        role[0].name === 'administrador' &&
        dashboardItems ? (
          <>
            <Grid
              item
              lg={12}
              sm={12}
              xl={12}
              xs={12}
              style={{ marginTop: '10px' }}
            >
              <Card
                classes={classes}
                title="Clientes Lavanderia"
                item={dashboardItems.activeLaundryClients}
                adminCard
                redirectLink="/laundry-clients"
              />
            </Grid>

            <Grid
              item
              lg={12}
              sm={12}
              xl={12}
              xs={12}
              style={{ marginTop: '10px' }}
            >
              <Card
                classes={classes}
                title="Usuários Ativos / Logados"
                item={dashboardItems.activeUsers}
                item2={dashboardItems.loggedUsers}
                adminCard
                redirectLink="/users"
              />
            </Grid>

            <Grid
              item
              lg={12}
              sm={12}
              xl={12}
              xs={12}
              style={{ marginTop: '10px' }}
            >
              <Card
                classes={classes}
                title="Atendimentos Concluídos Hoje"
                item={dashboardItems.todayConcludedAttendances}
                noDetails
                adminCard
              />
            </Grid>

            <Grid
              item
              lg={12}
              sm={12}
              xl={12}
              xs={12}
              style={{ marginTop: '10px' }}
            >
              <Card
                classes={classes}
                title="Clientes"
                item={dashboardItems.clients}
                adminCard
                redirectLink="/clients"
              />
            </Grid>
          </>
        ) : (
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <Box className={styles.chartBox}>
                <Grid container spacing={2}>
                  <Grid item xs={12} className={styles.chartBoxHeader}>
                    Itens
                  </Grid>

                  <Grid item xs={12}>
                    <PieChart data={items} />
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container spacing={3}>
                      {items.map((item, key) => (
                        <Grid item xs={12} md={6} key={String(key)}>
                          <Card
                            classes={classes}
                            item={item}
                            filters={filters}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Grid>

            <Grid item md={6} xs={12}>
              <Box className={styles.chartBox}>
                <Grid container spacing={2}>
                  <Grid item xs={12} className={styles.chartBoxHeader}>
                    OS
                  </Grid>

                  <Grid item xs={12}>
                    <PieChart data={serviceItems} />
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container spacing={3}>
                      {serviceItems.map((item, key) => (
                        <Grid item xs={12} md={6} key={String(key)}>
                          <Card
                            classes={classes}
                            item={item}
                            os
                            filters={filters}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        )}
      </Grid>
      {/* <Grid item xs>
        <Typography variant="h6" color="secondary">
          Status de OS
        </Typography>
        {serviceItems.map((item, key) => (
          <Grid
            item
            lg={12}
            sm={12}
            xl={12}
            xs={12}
            style={{ marginTop: '10px' }}
            key={String(key)}
          >
            <CardService
              {...props}
              classes={classes}
              item={item}
              notShowToday
            />
          </Grid>
        ))}
      </Grid> */}
    </Grid>
  )
}

export default Cards
