/* eslint-disable eqeqeq */
/* eslint-disable consistent-return */
/* eslint-disable camelcase */
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
import queryString from 'query-string'
import { format } from 'date-fns'

import consts from '../../../util/consts'
import Paginate from '../../components/Pagination'
import { exportFormatedMoney } from '../../../util/utils'
import { fetchSchedulesRelatoryInit } from '../SchedulesRelatoryActions'
import {
  StyledTableCellBody,
  StyledTableCellHeader,
} from '../../../components/hooks/BaseTableComponents'
import BaseFilterModal from '../../../components/hooks/BaseFilterModal'
import colors from '../../../assets/colors'
import { Grid, Typography } from '@material-ui/core'
import StyledCheckBox from '../../../components/hooks/BaseCheckBox'

function List(props) {
  const dispatch = useDispatch()
  const filters = useSelector(state => state.schedules_relatory.filters)
  const [rowsPerPage, setRowsPerPage] = useState(consts.DEFAULT_PAGINATION)
  const { classes, totalSize, page, setOpenColumns, openSelectColumns } = props

  const items = useSelector(state => state.schedules_relatory.items)

  const [columns, setColumns] = useState({
    todos: false,
    date: {
      name: 'date',
      label: 'Data',
      show: true,
    },
    time: {
      name: 'time',
      label: 'Hora',
      show: true,
    },
    team: {
      name: 'team',
      label: 'Equipe',
      show: false,
    },
    attendance: {
      name: 'attendance',
      label: 'Atendente',
      show: false,
    },
    etiqueta: {
      name: 'etiqueta',
      label: 'Etiqueta',
      show: false,
    },
    client: {
      name: 'client',
      label: 'Cliente',
      show: true,
    },
    service_type: {
      name: 'service_type',
      label: 'Tipo de Serviço',
      show: true,
    },
    telefone: {
      name: 'telefone',
      label: 'Telefone',
      show: false,
    },
    address: {
      name: 'address',
      label: 'Endereço',
      show: true,
    },
    neighborhood: {
      name: 'neighborhood',
      label: 'Bairro',
      show: true,
    },
    city: {
      name: 'city',
      label: 'Cidade',
      show: true,
    },
    total: {
      name: 'total',
      label: 'Total',
      show: true,
    },
    totalSemDesconto: {
      name: 'totalSemDesconto',
      label: 'Total Sem Desconto',
      show: true,
    },
  })

  const onMovePage = (event, newPage) => {
    const values = queryString.parse(props.location.search)

    // eslint-disable-next-line no-unused-vars
    const filtersLeads = {
      type: 'dashboardLeads',
      status: values?.status,
      filters: {
        date_start: values?.startDate,
        date_end: values?.finalDate,
      },
    }

    if (values?.status) {
      dispatch(
        fetchSchedulesRelatoryInit(newPage + 1, filtersLeads, rowsPerPage)
      )
    } else {
      dispatch(fetchSchedulesRelatoryInit(newPage + 1, filters, rowsPerPage))
    }
  }

  useEffect(() => {
    onMovePage()
  }, [rowsPerPage])

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value)
  }

  useEffect(() => {
    if (columns.todos) {
      Object.entries(columns).map(([columnName, column]) => {
        if (!column?.name) return

        setColumns(prevState => ({
          ...prevState,
          [columnName]: {
            ...column,
            show: true,
          },
        }))
      })
    }
  }, [columns.todos])

  return (
    <>
      <TableContainer>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              {columns?.date?.show && (
                <StyledTableCellHeader>Data</StyledTableCellHeader>
              )}
              {columns?.time?.show && (
                <StyledTableCellHeader>Hora</StyledTableCellHeader>
              )}
              {columns?.team?.show && (
                <StyledTableCellHeader>Equipe</StyledTableCellHeader>
              )}
              {columns?.attendance?.show && (
                <StyledTableCellHeader>Atendente</StyledTableCellHeader>
              )}
              {columns?.service_type?.show && (
                <StyledTableCellHeader>Tipo de serviço</StyledTableCellHeader>
              )}
              {columns?.client?.show && (
                <StyledTableCellHeader>Cliente</StyledTableCellHeader>
              )}
              {columns?.etiqueta?.show && (
                <StyledTableCellHeader>Etiqueta</StyledTableCellHeader>
              )}
              {columns?.telefone?.show && (
                <StyledTableCellHeader>Telefone</StyledTableCellHeader>
              )}
              {columns?.address?.show && (
                <StyledTableCellHeader>Endereço</StyledTableCellHeader>
              )}
              {columns?.neighborhood?.show && (
                <StyledTableCellHeader>Bairro</StyledTableCellHeader>
              )}
              {columns?.city?.show && (
                <StyledTableCellHeader>Cidade</StyledTableCellHeader>
              )}
              {columns?.total?.show && (
                <StyledTableCellHeader align="center">
                  Total
                </StyledTableCellHeader>
              )}
              {columns?.totalSemDesconto?.show && (
                <StyledTableCellHeader align="center">
                  Total sem Desconto
                </StyledTableCellHeader>
              )}
              {/* <StyledTableCellHeader>Tipo</StyledTableCellHeader> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map(item => (
              <TableRow key={item.id}>
                {columns?.date?.show && (
                  <StyledTableCellBody>{item.date}</StyledTableCellBody>
                )}
                {columns?.time?.show && (
                  <StyledTableCellBody>{item.hour}</StyledTableCellBody>
                )}
                {columns?.team?.show && (
                  <StyledTableCellBody>{item.seller_name}</StyledTableCellBody>
                )}
                {columns?.attendance?.show && (
                  <StyledTableCellBody>
                    {item.attendant_name}
                  </StyledTableCellBody>
                )}
                {columns?.service_type?.show && (
                  <StyledTableCellBody>
                    {item.service_type_name}
                  </StyledTableCellBody>
                )}
                {columns?.client?.show && (
                  <StyledTableCellBody>{item.client_name}</StyledTableCellBody>
                )}
                {columns?.etiqueta?.show && (
                  <StyledTableCellBody>{item.bar_code}</StyledTableCellBody>
                )}
                {columns?.telefone?.show && (
                  <StyledTableCellBody>{item.phone}</StyledTableCellBody>
                )}
                {columns?.address?.show && (
                  <StyledTableCellBody>{item.address}</StyledTableCellBody>
                )}
                {columns?.neighborhood?.show && (
                  <StyledTableCellBody>{item.neighborhood}</StyledTableCellBody>
                )}
                {columns?.city?.show && (
                  <StyledTableCellHeader>{item.city}</StyledTableCellHeader>
                )}
                {columns?.total?.show && (
                  <StyledTableCellBody align="center">
                    {exportFormatedMoney(item.total)}
                  </StyledTableCellBody>
                )}
                {columns?.totalSemDesconto?.show && (
                  <StyledTableCellBody align="center">
                    {exportFormatedMoney(item.total_sem_desconto)}
                  </StyledTableCellBody>
                )}
                {/* <StyledTableCellBody>{item.type}</StyledTableCellBody> */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Paginate
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        rowsPerPage={rowsPerPage}
        rows={totalSize}
        page={page - 1}
        handleChangePage={onMovePage}
      />

      <BaseFilterModal
        title="Colunas da tabela"
        open={openSelectColumns}
        handleOpen={setOpenColumns}
      >
        <Grid
          container
          xs={12}
          item
          style={{ width: '400px' }}
          direction="row"
          spacing={1}
        >
          <Grid item xs={12}>
            <Typography
              style={{ fontSize: '16px', fontWeight: 'normal' }}
              color={colors.primary}
            >
              Selecione os campos que devem aparecer na tabela
            </Typography>
          </Grid>

          <Grid container>
            <Grid item xs={12}>
              <StyledCheckBox
                checked={columns.todos}
                onChange={() =>
                  setColumns({
                    ...columns,
                    todos: !columns.todos,
                  })
                }
              />
              Todos
            </Grid>

            {Object.entries(columns).map(([columnName, column]) => {
              if (!column?.name) return

              return (
                <>
                  <Grid item xs={3}>
                    <StyledCheckBox
                      checked={column?.show}
                      onChange={() =>
                        setColumns({
                          ...columns,
                          [columnName]: {
                            ...column,
                            show: !column?.show,
                          },
                        })
                      }
                    />
                    {column?.label}
                  </Grid>
                </>
              )
            })}
          </Grid>
        </Grid>
      </BaseFilterModal>
    </>
  )
}

export default List
