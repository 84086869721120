/* eslint-disable no-lone-blocks */
import React, { useState, useEffect } from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import axios from 'axios'

import config from '../../../config'
import Chart from 'react-google-charts'
import colors from '../../../assets/colors'
import { format } from 'date-fns'
import { CircularProgress } from '@material-ui/core'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

export const options = {
  // vAxis: { title: '' },
  hAxis: { title: 'Mês' },
  seriesType: 'bars',
  series: { 3: { type: 'line' } },
  chartArea: {
    width: '70%',
  },
}

const formatDate = (month, year) => {
  const date = new Date(year, month - 1, 1).toLocaleString('pt-BR', {
    month: 'short',
  })

  return `${date.toUpperCase()}/${year}`
}

const getLast12Months = () => {
  const currentDate = new Date()
  const monthsArray = []
  const currentYear = currentDate.getFullYear()
  const currentMonth = currentDate.getMonth() + 2

  for (let i = 0; i < 12; i++) {
    let previousMonth = currentMonth - 1 - i
    let previousYear = currentYear

    if (previousMonth <= 0) {
      previousMonth += 12
      previousYear -= 1
    }

    monthsArray.push(formatDate(previousMonth, previousYear))
  }

  return monthsArray.reverse()
}

const DashBar = ({ referenceYear, isYear = false, monthBalance = [] }) => {
  const [data, setData] = useState([])

  const now = new Date()

  useEffect(() => {
    if (isYear) {
      const getDashboardData = async () => {
        setData([])

        const results = await axios
          .get(`${config.API_URL}/financial-graphic`, {
            params: {
              referenceYear: referenceYear || null,
            },
          })
          .then(resp => resp?.data)

        results?.map(result => {
          setData(prevData => [
            ...prevData,
            [
              result?.month,
              Number(result?.totalReceitas),
              Number(result?.totalDespesas),
              Number(result?.balance),
              Number(result?.balance),
            ],
          ])
        })
      }

      getDashboardData()
    }
  }, [referenceYear, isYear])

  useEffect(() => {
    if (!isYear && monthBalance) {
      setData([])

      monthBalance?.map(month => {
        setData(prevData => [
          ...prevData,
          [
            month?.[0],
            Number(month?.[1]),
            Number(month?.[2]),
            Number(month?.[1]) - Number(month?.[2]),
            (Number(month?.[1]) + Number(month?.[2])) / 2,
          ],
        ])
      })
    }
  }, [isYear, monthBalance])

  return (
    <>
      {data?.length ? (
        <Chart
          width="100%"
          options={{
            ...options,
            colors: ['#539fdd', '#b71c1c', colors.graylight, colors.sucess],
          }}
          chartType="ComboChart"
          data={[['Mês', 'Receitas', 'Despesas', 'Balanço', 'Média'], ...data]}
        />
      ) : (
        <CircularProgress style={{ marginTop: '1em' }} color="secondary" />
      )}
    </>
  )
}

export default DashBar
