import React, { useEffect, useState } from 'react'
import { Button, Grid, Switch, TextField } from '@material-ui/core'

import BaseFilterModal from '../../../components/hooks/BaseFilterModal'
import colors from '../../../assets/colors'
import { ColorBox, ColorPicker } from 'material-ui-color'
import { useDispatch } from 'react-redux'
import {
  fetchServiceCategory,
  post,
  show,
  update,
} from '../ServiceCategoryActions'
import { SNACKBAR } from '../../main/MainActions'
import filterStylesComponents from '../../../assets/filterComponentsStyles'
import fontSizes from '../../../assets/fontSizes'

const ModalServiceCategory = ({ open, handleClose, isEdit, id }) => {
  const [form, setForm] = useState({
    id: id || '',
    name: '',
    color: '',
    status: 1,
  })

  const [error, setError] = useState({
    error: false,
    message: '',
  })

  const dispatch = useDispatch()

  const styles = filterStylesComponents()

  const handleChange = e => {
    e.preventDefault()

    const { name, value } = e.target

    setForm({
      ...form,
      [name]: value,
    })
  }

  const onSubmit = () => {
    if (!form.name) {
      return setError({
        error: true,
        message: 'Campo obrigatório',
      })
    }

    if (id && isEdit) {
      return dispatch(update(form)).then(data => {
        if (data && data.code === 200) {
          handleClose()
        }
      })
    }

    return dispatch(post(form)).then(data => {
      if (data && data.code === 200) {
        handleClose()
      }
    })
  }

  useEffect(() => {
    setForm({ name: '', color: '#FF0000', status: 1 })

    if (id && isEdit) {
      const getService = async () => {
        try {
          const data = await show(id)

          if (data?.data) {
            setForm({
              ...form,
              id: data?.data?.id,
              name: data?.data?.name,
              status: data?.data?.status,
              color: data?.data?.color,
            })
          }
        } catch (e) {
          dispatch({
            ...SNACKBAR.HARDFAIL,
            error: { message: 'Falha ao buscar serviço' },
          })
        }
      }

      getService()
    }
  }, [open])

  return (
    <BaseFilterModal
      open={open}
      handleOpen={handleClose}
      title={id ? 'Editar Categoria' : 'Nova Categoria'}
      link=""
    >
      <Grid item container xs={12} style={{ width: '453px' }}>
        <Grid item xs={12} style={{ fontSize: '16px' }}>
          Utilize os campos abaixo para {id ? 'editar' : 'cadastrar'}
          <br />
          uma {id ? '' : 'nova'} categoria
        </Grid>

        <Grid item xs={12} container>
          <Grid item xs={6} container>
            <Grid item xs={12} container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  name="name"
                  fullWidth
                  value={form.name}
                  onChange={handleChange}
                  error={error?.error}
                  helperText={error?.message}
                  label="Adicione um nome"
                  InputLabelProps={{ style: { color: colors.graylight } }}
                />
              </Grid>
              <Grid item xs={12}>
                <Switch
                  name="status"
                  checked={form.status === 1}
                  onChange={() => {
                    setForm({
                      ...form,
                      status: form.status ? 0 : 1,
                    })
                  }}
                />
                Ativar categoria
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={6} container justifyContent="flex-end">
            <ColorBox
              value={form.color}
              disableAlpha
              hideTextfield
              onChange={color =>
                setForm(prvState => ({
                  ...prvState,
                  color: `#${color.hex}`,
                }))
              }
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid
        item
        container
        xs={12}
        alignItems="center"
        justifyContent="center"
        style={{ marginTop: '1.5em' }}
      >
        <Button
          className={styles.btnAdd}
          onClick={onSubmit}
          style={{ fontSize: fontSizes.text, padding: '4px 16px' }}
        >
          Salvar
        </Button>
      </Grid>
    </BaseFilterModal>
  )
}

export default ModalServiceCategory
