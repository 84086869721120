import React from 'react'
import filterStylesComponents from '../../../../../assets/filterComponentsStyles'
import { useDispatch, useSelector } from 'react-redux'
import BaseFilterModal from '../../../../../components/hooks/BaseFilterModal'
import {
  Button,
  Grid,
  InputBase,
  MenuItem,
  TextField,
  TextareaAutosize,
  Typography,
} from '@material-ui/core'
import {
  closeLead,
  createDescription,
  createNewOrcament,
  transferLead,
} from '../../PanelActions'
import colors from '../../../../../assets/colors'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'

const ModalNewOrcament = ({
  open = false,
  handleOpen = () => {},
  leadId = '',
}) => {
  const filterStyles = filterStylesComponents()
  const dispatch = useDispatch()
  const history = useHistory()

  const handleSubmit = async e => {
    e.preventDefault()

    try {
      const resp = await dispatch(createNewOrcament(leadId))

      if (resp) {
        history.push('/crm/panel')
      }
    } catch (e) {
      //
    }
  }

  return (
    <BaseFilterModal
      open={open}
      title="ABRIR NOVO ORÇAMENTO"
      hiddenHelpButton
      handleOpen={handleOpen}
      centerTitle
    >
      <Grid item xs={12} style={{ width: '220px' }}>
        <form style={{ width: '100%', height: '100%' }} onSubmit={handleSubmit}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography
                style={{
                  fontSize: '18px',
                  color: colors.primary,
                  textAlign: 'center',
                  width: ' 100%',
                }}
              >
                Deseja concluir essa OS e abrir <br /> um novo atendimento?
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                spacing={2}
                alignItems="center"
                justifyContent="center"
              >
                <Button type="submit" className={filterStyles.btnAdd}>
                  SIM
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </BaseFilterModal>
  )
}

export default ModalNewOrcament
