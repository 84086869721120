/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/alt-text */
import {
  DialogActions,
  FormHelperText,
  InputBase,
  TextField,
} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import DialogContent from '@material-ui/core/DialogContent'
import Grid from '@material-ui/core/Grid'
import withStyles from '@material-ui/core/styles/withStyles'
import { KeyboardDatePicker } from '@material-ui/pickers'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import styles from '../../../resources/theme/global'
import SelectField from '../../components/SelectField'
import colors from '../../../assets/colors'
import filterStylesComponents from '../../../assets/filterComponentsStyles'

function Formulario(props) {
  const { onClose, onSubmit, item } = props
  const banks = useSelector(state => state.billing.banks)
  const styles = filterStylesComponents()

  const handleClose = () => onClose && onClose()

  const [form, setForm] = useState({
    payment_details: '',
    bank: null,
  })

  useEffect(() => {
    setForm({
      ...form,
      payment_details: item?.payment_details ? item.payment_details : '',
      bank: item?.bank,
    })
  }, [item])

  const updateField = name => e => {
    setForm({
      ...form,
      [name]: e.target.value,
    })
  }

  const updateFields = (name, value) => {
    setForm({
      ...form,
      [name]: value,
    })
  }

  const onSubmitForm = e => {
    e.preventDefault()
    onSubmit && onSubmit(form)
  }

  return (
    <form onSubmit={onSubmitForm}>
      <Grid container spacing={3}>
        <Grid item sm={6} xs={12}>
          <SelectField
            style={{ marginTop: '0px' }}
            options={banks}
            setForm={updateFields}
            data={form.bank || null}
            name="bank"
            label="Banco"
            disabled={item?.payment_status?.id !== 1}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <KeyboardDatePicker
            clearable
            value={form.actual_payment_date}
            label="Data do Pagamento"
            fullWidth
            clearLabel="Limpar"
            invalidDateMessage="Data inválida"
            autoOk
            onChange={date => setForm({ ...form, actual_payment_date: date })}
            maxDate={new Date('2300-01-01')}
            format="dd/MM/yyyy"
          />
          <FormHelperText>
            *Caso não seja fornecida, será utilizada a data atual.
          </FormHelperText>
        </Grid>

        <Grid item sm={12}>
          <InputBase
            placeholder="Descrição"
            value={form.payment_details}
            onChange={updateField('payment_details')}
            variant="outlined"
            multiline
            fullWidth
            rows={5}
            maxRows={10}
            disabled={item?.payment_status?.id !== 1}
            inputProps={{
              style: {
                borderBottom: `2px solid ${colors.graylight}`,
              },
            }}
          />
        </Grid>

        <Grid
          item
          sm={12}
          container
          alignItems="center"
          justifyContent="center"
        >
          {item?.payment_status?.id === 1 && (
            <Button color="primary" type="submit" className={styles.btnSalvar}>
              Salvar
            </Button>
          )}
        </Grid>
      </Grid>
    </form>
  )
}

export default withStyles(styles)(Formulario)
