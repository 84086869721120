/* eslint-disable consistent-return */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable object-shorthand */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
import {
  Box,
  IconButton,
  InputBase,
  MenuItem,
  TextField,
  makeStyles,
} from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import withStyles from '@material-ui/core/styles/withStyles'
import { DatePicker } from '@material-ui/pickers'
import { parseISO } from 'date-fns'
import React, { useEffect, useState } from 'react'
import InputMask from 'react-input-mask'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'
import { useDispatch } from 'react-redux'

import styles from '../../../resources/theme/users'
import SelectField from '../../components/SelectField'
import { fetchClients, findCep, findClient } from '../ClientActions'
import axios from 'axios'
import config from '../../../config'
import Icon from '@mdi/react'
import colors from '../../../assets/colors'
import { SNACKBAR } from '../../main/MainActions'
import BasePopUp from '../../../components/hooks/BasePopUp'
import NumberFormat from 'react-number-format'
import { parseValue } from '../../../util/utils'

const useStyles = makeStyles({
  footer: {
    widht: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: '2em',
  },
  submmitButton: {
    minWidth: '99px',
    minHeight: '30px',
    borderRadius: '15px',
    padding: '2px 16px',
    fontWeight: 'normal',
    textTransform: 'capitalize',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '14px',
    color: '#FFFF',
    backgroundColor: colors.primary,
    transition: 'all .4s ease-in-out',
    '&:hover': {
      backgroundColor: colors.primary,
      opacity: 0.7,
    },
  },
})

function Form(props) {
  const {
    data,
    onClose,
    onSubmit,
    children,
    btnText,
    icon,
    know_way_id,
    notShowPost,
    submitOnBlur = false,
  } = props
  const dispatch = useDispatch()

  const [knowWays, setKnowWays] = useState([])
  const [openClientModal, setOpenClientModal] = useState(false)
  const [client, setClient] = useState({})

  const handleOpenClientModal = () => {
    setOpenClientModal(!openClientModal)
  }

  const [error, setError] = useState({
    name: {
      error: false,
      message: '',
    },
    phone: {
      error: false,
      message: '',
    },
    commissionPercent: {
      error: false,
      message: '',
    },
  })

  const classes = useStyles()

  const ufs = [
    'AC',
    'AL',
    'AP',
    'AM',
    'BA',
    'CE',
    'DF',
    'ES',
    'GO',
    'MA',
    'MS',
    'MT',
    'MG',
    'PA',
    'PB',
    'PR',
    'PE',
    'PI',
    'RJ',
    'RN',
    'RS',
    'RO',
    'RR',
    'SC',
    'SP',
    'SE',
    'TO',
  ]

  const [form, updateForm] = useState({
    id: '',
    name: '',
    person_type: '',
    birthday: null,
    cep: '',
    street: '',
    complement: '',
    number: '',
    city: '',
    uf: '',
    neighborhood: '',
    phone: '',
    phone2: '',
    email: '',
    cpf_cnpj: '',
    client_since: null,
    // know_way: '',
    know_way_id: '',
    profile: '',
    commission_percent: null,
  })

  const updateField = React.useCallback(name => e => {
    if (name === 'commission_percent') {
      return updateForm({
        ...form,
        [name]: parseValue(e.target.value),
      })
    }

    updateForm({
      ...form,
      [name]: e.target.value,
    })
  })

  const updateFields = React.useCallback(
    (name, value) => {
      updateForm({
        ...form,
        [name]: value,
      })
    },
    [form]
  )

  const onSubmitForm = (advanceTab = false) => {
    if (submitOnBlur) {
      onSubmit && onSubmit(form, false, null, advanceTab)
    } else {
      onSubmit && onSubmit(form)
    }
  }

  const buscaCEP = () => {
    const cep = form.cep?.replace(/[^\d]/g, '')
    cep &&
      dispatch(findCep(cep)).then(resp => {
        if (resp && resp.code === 200) {
          const { items } = resp.data
          if (items) {
            updateForm({
              ...form,
              neighborhood: items?.bairro,
              city: items?.cidade,
              uf: items?.uf,
              street: items?.logradouro,
            })
          }
        }
      })
  }

  useEffect(() => {
    if (
      submitOnBlur &&
      // eslint-disable-next-line prettier/prettier
      form.neighborhood &&
      form.city &&
      form.uf &&
      form.street
      // eslint-disable-next-line prettier/prettier
    ) {
      onSubmitForm(false)
    }
  }, [form.neighborhood, form.city, form.uf, form.street])

  useEffect(() => {
    if (form.uf && submitOnBlur) {
      onSubmitForm(false)
    }
  }, [form.uf])

  useEffect(() => {
    updateForm({
      ...form,
      ...data,
      know_way_id: know_way_id,
      birthday: data?.birthday ? parseISO(data.birthday) : null,
    })
  }, [data])

  useEffect(() => {
    const searchKnowWays = async () => {
      const resp = await axios.get(`${config.API_URL}/knowWay`)

      if (resp) setKnowWays(resp?.data?.data)
    }

    searchKnowWays()
  }, [])

  const snackBarShow = message => {
    return dispatch({
      type: SNACKBAR.HARDFAIL,
      error: { message: [message] },
    })
  }

  const handleSubmmit = () => {
    if (!form.name) {
      return setError({
        ...error,
        name: {
          error: true,
          message: 'O campo nome é obrigatório',
        },
      })
    }

    if (!form.phone) {
      return setError({
        ...error,
        phone: {
          error: true,
          message: 'O campo telefone 1 é obrigatório',
        },
      })
    }

    if (
      !notShowPost &&
      form.commission_percent &&
      (form.commission_percent < 0.01 || form.commission_percent > 100)
    ) {
      return setError({
        ...error,
        commissionPercent: {
          error: true,
          message: 'O valor deve estar entre 0,01 até 100',
        },
      })
    }

    onSubmitForm(true)
  }

  const handleVerifyPhone = async e => {
    const value = e.target?.value?.replace(/[^\d]/g, '').trim()

    if (!value) {
      return
    }

    const resp = await findClient({ phone: value })

    if (resp && resp[0]) {
      setClient(resp[0])
      handleOpenClientModal()
    }
  }

  const handleAcceptClient = () => {
    handleOpenClientModal()
    updateForm({
      ...form,
      ...client,
    })
  }

  useEffect(() => {
    if (error.name.error) {
      setError({
        ...error,
        name: {
          error: false,
          message: '',
        },
      })
    }
  }, [form.name])

  useEffect(() => {
    if (error.phone.error) {
      setError({
        ...error,
        phone: {
          error: false,
          message: '',
        },
      })
    }
  }, [form.phone])

  useEffect(() => {
    if (error.commissionPercent.error) {
      setError({
        ...error,
        commissionPercent: {
          error: false,
          message: '',
        },
      })
    }
  }, [form.commission_percent])

  return (
    <ValidatorForm /* onSubmit={onSubmitForm} */>
      <Grid container spacing={2} style={{ marginTop: '1em' }}>
        <Grid item sm={6} xs={12} lg={3}>
          <TextField
            required
            onChange={updateField('name')}
            error={error.name.error}
            helperText={error.name.message || ''}
            label="Nome"
            fullWidth
            value={form.name}
            onBlur={submitOnBlur ? () => onSubmitForm(false) : () => {}}
          />
        </Grid>
        {/* <Grid
            item
            sm={6}
            xs={12}
            lg={4}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <FormControl component="fieldset">
              <FormLabel component="legend">Tipo do Cliente</FormLabel>
              <div>
                <RadioGroup
                  row
                  aria-label="position"
                  onChange={handleRadio}
                  value={form.person_type}
                  name="position"
                  defaultValue="top"
                >
                  <FormControlLabel
                    value="pj"
                    control={<Radio color="primary" />}
                    label="Pessoa Juridica"
                  />
                  <FormControlLabel
                    value="pf"
                    control={<Radio color="primary" />}
                    label="Pessoa Física"
                  />
                </RadioGroup>
              </div>
            </FormControl>
          </Grid> */}
        <Grid item sm={6} xs={12} lg={3}>
          <InputMask
            mask={
              form.phone?.replace(/[^\d]/g, '').length <= 10
                ? '(99) 9999-9999?'
                : '(99) 99999-9999'
            }
            formatChars={{ 9: '[0-9]', '?': '[0-9 ]' }}
            maskChar=""
            value={form.phone}
            onChange={e => {
              updateForm({
                ...form,
                phone: e.target.value.replace(/[^\d]/g, ''),
              })
            }}
            onBlur={e => {
              if (submitOnBlur) {
                handleVerifyPhone(e)
                onSubmitForm(false)
              } else {
                handleVerifyPhone(e)
              }
            }}
          >
            {inputProps => (
              <TextField
                {...inputProps}
                error={error.phone.error}
                helperText={error.phone.message || ''}
                label="Telefone 1"
                required
                fullWidth
              />
            )}
          </InputMask>
        </Grid>
        <Grid item sm={6} xs={12} lg={3}>
          <InputMask
            mask={
              form.phone2?.replace(/[^\d]/g, '').length <= 10
                ? '(99) 9999-9999?'
                : '(99) 99999-9999'
            }
            formatChars={{ 9: '[0-9]', '?': '[0-9 ]' }}
            maskChar=""
            value={form.phone2}
            onChange={e => {
              updateForm({
                ...form,
                phone2: e.target.value.replace(/[^\d]/g, ''),
              })
            }}
            onBlur={submitOnBlur ? () => onSubmitForm(false) : () => {}}
          >
            {inputProps => (
              <TextValidator
                {...inputProps}
                label="Telefone 2"
                fullWidth
                validators={[
                  'matchRegexp:^\\(?[0-9]{2,3}\\)? ?[0-9]{4,5}-?[0-9]{4}$',
                ]}
                errorMessages={['Telefone inválido']}
              />
            )}
          </InputMask>
        </Grid>
        <Grid item sm={6} xs={12} lg={3}>
          <DatePicker
            clearable
            value={form.birthday}
            label="Aniversário"
            fullWidth
            views={['date', 'month']}
            clearLabel="Limpar"
            invalidDateMessage="Data inválida"
            autoOk
            onChange={date => updateForm({ ...form, birthday: date })}
            format="d MMMM"
            onBlur={submitOnBlur ? () => onSubmitForm(false) : () => {}}
            // format="dd/MM/yyyy"
            // formatDate={date => format(new Date(date), 'MM/dd/yyyy H:i:s')}
          />
        </Grid>
        <Grid item sm={6} xs={12} lg={3}>
          <InputMask
            mask="99.999-999"
            maskChar=""
            value={form.cep}
            onChange={updateField('cep')}
            onBlur={() => {
              if (submitOnBlur) {
                buscaCEP()
                onSubmitForm(false)
              }
            }}
          >
            {inputProps => (
              <TextValidator
                {...inputProps}
                errorMessages={['Campo Obrigatório']}
                label="CEP"
                fullWidth
                value={form.cep}
              />
            )}
          </InputMask>
        </Grid>
        <Grid item sm={6} xs={12} lg={6}>
          <TextField
            onBlur={submitOnBlur ? () => onSubmitForm(false) : () => {}}
            onChange={updateField('street')}
            label="Endereço"
            fullWidth
            value={form.street}
          />
        </Grid>
        <Grid item sm={6} xs={12} lg={3}>
          <TextField
            onBlur={submitOnBlur ? () => onSubmitForm(false) : () => {}}
            onChange={updateField('number')}
            label="Nº"
            fullWidth
            value={form.number}
          />
        </Grid>
        <Grid item sm={6} xs={12} lg={3}>
          <TextField
            onBlur={submitOnBlur ? () => onSubmitForm(false) : () => {}}
            onChange={updateField('complement')}
            label="Complemento"
            fullWidth
            value={form.complement}
          />
        </Grid>
        <Grid item sm={6} xs={12} lg={3}>
          <TextField
            onBlur={submitOnBlur ? () => onSubmitForm(false) : () => {}}
            onChange={updateField('neighborhood')}
            label="Bairro"
            fullWidth
            value={form.neighborhood}
          />
        </Grid>
        <Grid item sm={6} xs={12} lg={3}>
          <TextField
            onBlur={submitOnBlur ? () => onSubmitForm(false) : () => {}}
            onChange={updateField('city')}
            label="Cidade"
            fullWidth
            value={form.city}
          />
        </Grid>
        <Grid item sm={6} xs={12} lg={3}>
          <SelectField
            options={ufs}
            setForm={updateFields}
            data={form.uf}
            name="uf"
            label="Estado"
          />
        </Grid>

        <Grid item sm={6} xs={12} lg={3}>
          <TextValidator
            validators={['isEmail']}
            errorMessages={['O campo não representa um email válido']}
            onChange={updateField('email')}
            label="E-mail"
            fullWidth
            value={form.email}
            onBlur={submitOnBlur ? () => onSubmitForm(false) : () => {}}
          />
        </Grid>
        <Grid item sm={6} xs={12} lg={3}>
          <InputMask
            mask={
              form.cpf_cnpj?.replace(/[^\d]/g, '').length <= 11
                ? '999.999.999-99?'
                : '99.999.999/9999-99'
            }
            maskChar=""
            formatChars={{ 9: '[0-9]', '?': '[0-9 ]' }}
            value={form.cpf_cnpj}
            onChange={updateField('cpf_cnpj')}
            onBlur={submitOnBlur ? () => onSubmitForm(false) : () => {}}
          >
            {inputProps => (
              <TextValidator
                {...inputProps}
                validators={[
                  'required',
                  'matchRegexp:^([0-9]{2}[.]?[0-9]{3}[.]?[0-9]{3}[/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[.]?[0-9]{3}[.]?[0-9]{3}[-]?[0-9]{2})$',
                ]}
                errorMessages={['CPF/CNPJ Inválido']}
                label="CPF/CNPJ"
                fullWidth
              />
            )}
          </InputMask>
        </Grid>
        {/* <Grid item sm={6} xs={12} lg={4}>
            <DatePicker
              clearable
              value={form.client_since}
              label="Cliente desde"
              fullWidth
              orientation="landscape"
              views={['date', 'month', 'year']}
              clearLabel="Limpar"
              invalidDateMessage="Data inválida"
              autoOk
              onChange={date => updateForm({ ...form, client_since: date })}
              format="dd/MM/yyyy"
            />
          </Grid> */}

        <Grid item sm={6} xs={12} lg={3} justifyContent="center">
          <TextField
            select
            value={form?.know_way_id}
            label="Como soube da Empresa?"
            fullWidth
            onBlur={submitOnBlur ? () => onSubmitForm(false) : () => {}}
            onChange={updateField('know_way_id')}
          >
            {knowWays?.map((knowWay, index) => (
              <MenuItem key={knowWay?.id} value={knowWay?.id}>
                {knowWay?.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        {!notShowPost && (
          <Grid item sm={6} xs={12} lg={3} justifyContent="center">
            <NumberFormat
              value={form.commission_percent}
              onChange={updateField('commission_percent')}
              endAdornment="%"
              decimalScale={2}
              label="Porcentagem de Comissão"
              decimalSeparator=","
              thousandSeparator="."
              customInput={TextField}
              displayType="input"
              InputProps={{
                endAdornment: '%',
              }}
              fullWidth
              error={error.commissionPercent.error}
              helperText={error.commissionPercent.message || ''}
            />
          </Grid>
        )}

        <Grid item xs={12} style={{ marginTop: '.5em' }}>
          <TextField
            placeholder="Perfil do cliente"
            value={form.profile}
            onChange={updateField('profile')}
            multiline
            maxRows={10}
            minRows={5}
            fullWidth
            onBlur={submitOnBlur ? () => onSubmitForm(false) : () => {}}
          />
        </Grid>

        {children}
      </Grid>

      <Box className={classes.footer}>
        <IconButton onClick={handleSubmmit} className={classes.submmitButton}>
          {btnText}
          {icon && <Icon path={icon} size={1} />}
        </IconButton>
      </Box>

      <BasePopUp
        open={openClientModal}
        type="info"
        title="Cliente já cadastrado"
        message="Este telefone já está cadastrado, deseja recuperar os dados?"
        handleOpen={handleOpenClientModal}
        onConfirm={handleAcceptClient}
      />
    </ValidatorForm>
  )
}

export default withStyles(styles)(Form)
