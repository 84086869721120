import React, { useEffect, useState } from 'react'
import BaseCreatePage from '../../../../../components/hooks/BaseCreatePage'
import { Grid } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import Filter from './Filter'
import List from './List'
import { fetchGerencyReport } from '../GerencyReportsActions'

const GerencyReport = () => {
  const dispatch = useDispatch()

  const [form, setForm] = useState({
    per_page: 10,
    page: 1,
    attendant_id: '',
    date_start: '',
    date_end: '',
  })

  useEffect(() => {
    dispatch(fetchGerencyReport(form))
  }, [form])

  return (
    <>
      <BaseCreatePage page="Relátorios" path="Gerencial" hiddenReturn>
        <Grid container spacing="3" style={{ marginTop: '1em' }}>
          <Grid item xs={12}>
            <Filter form={form} setForm={setForm} />
          </Grid>
          <Grid item xs={12}>
            <List setForm={setForm} form={form} />
          </Grid>
        </Grid>
      </BaseCreatePage>
    </>
  )
}

export default GerencyReport
