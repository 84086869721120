import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  DialogActions,
  Grid,
  Paper,
  Typography,
  makeStyles,
  withStyles,
} from '@material-ui/core'
import { useConfirm } from 'material-ui-confirm'
import { useDispatch } from 'react-redux'

import styles from '../../../resources/theme/global'
import GlobalDropzone from '../../components/Dropzone'
import { uploadDocModel, getCurrentFileUrl } from '../SettingsActions'
import colors from '../../../assets/colors'
import filterStylesComponents from '../../../assets/filterComponentsStyles'

const useStyles = makeStyles({
  box: {
    border: `1px dashed ${colors.graylight}`,
    borderRadius: '5px',
  },
})

function UploadDocModel({ classes }) {
  const confirm = useConfirm()
  const dispatch = useDispatch()
  const styles = useStyles()
  const [file, setFile] = useState([])
  const [currentFile, setCurrentFile] = useState(null)

  const handleDropFiles = files => {
    setFile(files[0])
  }

  const confirmChangeModel = () => {
    confirm({
      title: 'Alterar modelo de orçamento',
      description: 'Tem certeza que deseja alterar o modelo de orçamento?',
    }).then(() => {
      const formData = new FormData()
      formData.append('file', file)
      dispatch(uploadDocModel(formData))
    })
  }

  useEffect(() => {
    dispatch(getCurrentFileUrl()).then(data => {
      if (data && data.status === 200) {
        setCurrentFile(data.data)
      }
    })
  }, [])

  return (
    <>
      <Grid
        container
        className={classes.pageHead}
        spacing={1}
        alignItems="center"
        style={{ marginTop: 20 }}
      >
        <Grid item xs={12}>
          <Typography
            variant="h4"
            style={{ fontSize: '20px' }}
            color={colors.primary}
          >
            Editar modelo de orçamento
          </Typography>
        </Grid>
      </Grid>

      <Box className={styles.box}>
        <GlobalDropzone
          handleChangeFiles={handleDropFiles}
          classes={classes}
          text="Clique aqui ou arraste documentos para selecionar"
          mimeTypes={[
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          ]}
          limit={1}
          maxSize={5242880}
        />

        <DialogActions>
          {currentFile && (
            <Button onClick={() => window.open(currentFile, '_blank')}>
              Baixar modelo atual
            </Button>
          )}
          <Button disabled={!file} onClick={confirmChangeModel}>
            Salvar Modelo
          </Button>
        </DialogActions>
      </Box>
    </>
  )
}

export default withStyles(styles)(UploadDocModel)
