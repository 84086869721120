/* eslint-disable react/prop-types */
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Popover from '@material-ui/core/Popover'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import FilterListIcon from '@material-ui/icons/FilterList'
import SearchIcon from '@material-ui/icons/Search'
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state'
import React, { useEffect, useState } from 'react'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'
import { useDispatch, useSelector } from 'react-redux'

import BadgeGlobal from '../../components/Badge'
import SelectField from '../../components/SelectField'
import { fetchLaundryClients } from '../LaundryClientActions'
import BaseFilter from '../../../components/hooks/BaseFilter'
import { InputAdornment } from '@material-ui/core'
import Icon from '@mdi/react'
import {
  mdiMagnifyExpand,
  mdiMicrosoftExcel,
  mdiNoteEditOutline,
} from '@mdi/js'
import colors from '../../../assets/colors'
import filterStylesComponents from '../../../assets/filterComponentsStyles'
import axios from 'axios'
import config from '../../../config'
import { renderRoute } from '../../../util/utils'
import { Link } from 'react-router-dom/cjs/react-router-dom'

export default function Filter(props) {
  const items = useSelector(state => state.client.items)
  const permissions = useSelector(state => state.auth.permissions)

  const dispatch = useDispatch()
  const { classes, plans } = props
  const [form, setForm] = useState({
    name: '',
    plans: '',
  })

  const filterStyles = filterStylesComponents()

  const onSubmit = e => {
    e.preventDefault()
    dispatch(fetchLaundryClients(null, form))
  }

  const updateFields = (name, value) => {
    setForm({
      ...form,
      [name]: value,
    })
  }

  async function getReport() {
    return axios({
      url: `${config.API_URL}/laundry-client-report`,
      method: 'POST',
      responseType: 'blob',
      headers: { Accept: 'application/vnd.ms-excel' },
      params: {
        items: JSON.stringify(items),
      },
    }).then(resp => {
      const url = window.URL.createObjectURL(new Blob([resp.data]))
      const link = document.createElement('a')

      link.href = url
      link.setAttribute('download', 'relatorio_clientes_lavanderia.xlsx')
      document.body.appendChild(link)
      link.click()
      return resp
    })
  }

  useEffect(() => {
    dispatch(fetchLaundryClients(null, form))
  }, [form.plans])

  useEffect(() => {
    if (!form.name) {
      dispatch(fetchLaundryClients(null, form))
    }
  }, [form.name])

  return (
    <BaseFilter>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <TextField
              onChange={e => setForm({ ...form, name: e.target.value })}
              label="Nome"
              className={classes.inputFilter}
              fullWidth
              value={form.name}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={onSubmit}>
                      <Icon
                        path={mdiMagnifyExpand}
                        size={1}
                        color={colors.graylight}
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={3}>
            <SelectField
              options={plans}
              setForm={updateFields}
              data={form.plans}
              name="plans"
              label="Planos"
            />
          </Grid>

          <Grid item xs={6} className={filterStyles.areaButtons}>
            <Grid item>
              <IconButton
                color="primary"
                variant="contained"
                onClick={() => getReport()}
                className={filterStyles.btnAdd}
              >
                <Icon path={mdiMicrosoftExcel} size={1} />
                Exportar
              </IconButton>
            </Grid>
            <Grid item>
              {renderRoute(['create-client'], permissions) && (
                <IconButton
                  color="primary"
                  variant="contained"
                  component={Link}
                  to="/laundry-clients/new"
                  className={filterStyles.btnAdd}
                >
                  {/* <AddCircleOutlineIcon className={classes.btnIcon} /> Cadastrar */}
                  <Icon path={mdiNoteEditOutline} size={1} />
                  Cadastrar
                </IconButton>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </BaseFilter>
  )
}
