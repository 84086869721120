/* eslint-disable consistent-return */
/* eslint-disable eqeqeq */
/* eslint-disable camelcase */
import {
  Box,
  DialogActions,
  Grid,
  IconButton,
  InputAdornment,
  Switch,
  TextField,
} from '@material-ui/core'
import { ColorBox } from 'material-ui-color'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import DialogContent from '@material-ui/core/DialogContent'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import withStyles from '@material-ui/core/styles/withStyles'
import ThumbDownAltOutlinedIcon from '@material-ui/icons/ThumbDownAltOutlined'
import ThumbUpAltOutlinedIcon from '@material-ui/icons/ThumbUp'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { ValidatorForm } from 'react-material-ui-form-validator'
import { connect } from 'react-redux'

import styles from '../../../resources/theme/users'
import EnhancedComponent from '../../components/EnhancedComponent'
import SelectField from '../../components/SelectField'
import CustomTextField from '../../components/Textfield'
import AutoComplete from '../../components/Autocomplete'
import colors from '../../../assets/colors'
import { mdiEyeClosed, mdiEyeOutline } from '@mdi/js'
import Icon from '@mdi/react'
import NumberFormat from 'react-number-format'
import { parseValue } from '../../../util/utils'

class UserForm extends EnhancedComponent {
  constructor(props) {
    super(props)

    this.state = {
      dirty: false,
      fields: {
        id: '',
        name: '',
        email: '',
        role_id: [],
        cpf: '',
        password: '',
        confirmPassword: '',
        showConfirmPassowrd: false,
        showPassword: false,
        company_id: '',
        laundries: [],
        laundryClients: '',
        laundryClient_id: {},
        resend: false,
        color: '#93c3ea',
        status: 1,
        error: false,
        commission_percent: null,
        commissionPercentError: false,
      },
    }
  }

  handleClose = () => this.props.onClose && this.props.onClose()

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.state.dirty && nextProps.data) {
      this.setState({
        fields: {
          ...this.state.fields,
          ...nextProps.data,
          laundryClients: nextProps.data.laundryClient_id,
        },
      })
    }
  }

  showConfirmPassowrd = () => {
    this.updateFields(
      'showConfirmPassowrd',
      !this.state.fields.showConfirmPassowrd
    )
  }

  showPassword = () => {
    this.updateFields('showPassword', !this.state.fields.showPassword)
  }

  hanldeError = () => {
    this.updateFields('error', !this.state.fields.error)
  }

  handleChange = name => event => {
    this.setState(prevState => ({
      fields: {
        ...prevState.fields,
        [name]: !prevState.fields[name],
      },
    }))
  }

  updateFields = (name, value) => {
    if (name === 'commission_percent') {
      if (this.state.fields.commissionPercentError) {
        this.setState(prevState => ({
          fields: {
            ...prevState.fields,
            commissionPercentError: false,
          },
        }))
      }

      return this.setState(prevState => ({
        fields: {
          ...prevState.fields,
          [name]: parseValue(value),
        },
      }))
    }

    this.setState(prevState => ({
      fields: {
        ...prevState.fields,
        [name]: value,
      },
    }))
  }

  onSubmit = e => {
    e.preventDefault()

    if (
      this.state.fields.commission_percent &&
      (this.state.fields.commission_percent < 0.01 ||
        this.state.fields.commission_percent > 100)
    ) {
      return this.updateFields('commissionPercentError', true)
    }

    const send = {
      ...this.state.fields,
      status: this.state.fields.status || 0,
    }

    this.props.onSubmit && this.props.onSubmit(send)
  }

  updateFunction = (areas, array) => {
    this.setState(prevState => ({
      fields: {
        ...prevState.fields,
        [array]: areas.sort((a, b) => a.name.localeCompare(b.name)),
      },
    }))
  }

  hasRole = (stringRole = 'administrador') => {
    return this.props.userRoles.some(role => role.name == stringRole)
  }

  render() {
    const { roles, laundryClients } = this.props

    const {
      laundryClient_id,
      role_id,
      email,
      name,
      cpf,
      password,
      confirmPassword,
      showPassword,
      showConfirmPassowrd,
      error,
      color,
      id,
      resend,
      status,
      commission_percent,
      commissionPercentError,
    } = this.state.fields
    const readOnly = !!this.props.see

    const roleHasID = array => {
      const item = role_id.find(item =>
        array.find(arrayItem => {
          return item.sys_name?.toLowerCase().includes(arrayItem.toLowerCase())
        })
      )

      if (item) return true

      return false
    }

    const handleConfirmPassword = e => {
      e.preventDefault()

      const { value } = e.target

      if (!password) return

      if (!value && error) {
        return this.hanldeError()
      }

      if (confirmPassword != password) {
        return this.hanldeError()
      }

      if (confirmPassword == password && error) {
        return this.hanldeError()
      }
    }

    return (
      <ValidatorForm onSubmit={this.onSubmit} style={{ width: '100%' }}>
        <Grid
          container
          spacing={2}
          style={{ marginTop: '1em' }}
          justifyContent="space-between"
        >
          <Grid item xs={4}>
            <Grid
              item
              xs={12}
              container
              alignItems="flex-start"
              justifyContent="center"
            >
              <Grid
                item
                xs={12}
                style={{
                  fontSize: '14px',
                  color: colors.gray,
                  padding: '8px',
                }}
              >
                Selecione uma cor para o usuário
              </Grid>

              <ColorBox
                value={color}
                disableAlpha
                style={{ width: '100%' }}
                hideTextfield
                onChange={color =>
                  this.setState(prvState => ({
                    dirty: true,
                    fields: {
                      ...prvState.fields,
                      color: `#${color.hex}`,
                    },
                  }))
                }
              />
            </Grid>
          </Grid>

          <Grid item xs={8}>
            <Grid container style={{ marginTop: '1em' }} spacing={2}>
              {/* <Grid item xs={4}>
                {roleHasID(['operador_de_rua_lavanderia']) && (
                  <Grid
                    item
                    xs={12}
                    container
                    alignItems="flex-start"
                    justifyContent="center"
                  >
                    <Grid
                      item
                      xs={12}
                      style={{
                        fontSize: '14px',
                        color: colors.gray,
                        padding: '8px',
                      }}
                    >
                      Selecione uma cor para o usuário
                    </Grid>

                    <ColorBox
                      value={color}
                      disableAlpha
                      style={{ width: '100%' }}
                      hideTextfield
                      onChange={color =>
                        this.setState(prvState => ({
                          dirty: true,
                          fields: {
                            ...prvState.fields,
                            color: `#${color.hex}`,
                          },
                        }))
                      }
                    />
                  </Grid>
                )}
              </Grid> */}

              <Grid item xs={6}>
                <CustomTextField
                  validators={['required', 'minStringLength:5']}
                  errorMessages={[
                    'Campo Obrigatório',
                    'Tamanho Mínimo de 5 caracteres',
                  ]}
                  onChange={this.onChange('name')}
                  label="Nome"
                  fullWidth
                  value={name}
                  disabled={readOnly}
                />
              </Grid>

              <Grid item xs={12} lg={6} sm={12} md={6}>
                <CustomTextField
                  validators={['isEmail']}
                  errorMessages={['O campo não representa um email válido']}
                  onChange={this.onChange('email')}
                  required
                  label="E-mail"
                  fullWidth
                  value={email}
                  disabled={readOnly}
                />
              </Grid>

              <Grid item xs={12} lg={6} sm={12} md={6}>
                <CustomTextField
                  value={cpf}
                  onChange={this.onChange('cpf')}
                  disabled={readOnly}
                  validators={[
                    'matchRegexp:^([0-9]{2}[.]?[0-9]{3}[.]?[0-9]{3}[/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[.]?[0-9]{3}[.]?[0-9]{3}[-]?[0-9]{2})$',
                  ]}
                  errorMessages={['CPF/CNPJ Inválido']}
                  label="CPF/CNPJ"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} lg={6} sm={12} md={6}>
                <TextField
                  onChange={this.onChange('password')}
                  label="Senha"
                  type={showPassword ? 'text' : 'password'}
                  fullWidth
                  value={password}
                  disabled={readOnly}
                  autoComplete="current-password"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        onClick={this.showPassword}
                      >
                        <IconButton>
                          {showPassword ? (
                            <Icon path={mdiEyeOutline} size={1} />
                          ) : (
                            <Icon path={mdiEyeClosed} size={1} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12} lg={6} sm={12} md={6}>
                <TextField
                  onChange={this.onChange('confirmPassword')}
                  label="Confirmar senha"
                  required={password || false}
                  value={confirmPassword}
                  onBlur={handleConfirmPassword}
                  fullWidth
                  type={showConfirmPassowrd ? 'text' : 'password'}
                  disabled={readOnly}
                  error={error}
                  helperText={error ? 'As senhas não coincidem' : ''}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        onClick={this.showConfirmPassowrd}
                      >
                        <IconButton>
                          {showConfirmPassowrd ? (
                            <Icon path={mdiEyeOutline} size={1} />
                          ) : (
                            <Icon path={mdiEyeClosed} size={1} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              {this.hasRole(['gerente_lavanderia']) && (
                <Grid
                  item
                  xs={12}
                  lg={6}
                  sm={12}
                  md={6}
                  justifyContent="center"
                >
                  <NumberFormat
                    value={commission_percent}
                    onChange={e => {
                      this.updateFields('commission_percent', e.target.value)
                    }}
                    endAdornment="%"
                    decimalScale={2}
                    label="Porcentagem de Comissão"
                    decimalSeparator=","
                    thousandSeparator="."
                    customInput={TextField}
                    displayType="input"
                    InputProps={{
                      endAdornment: '%',
                    }}
                    fullWidth
                    error={commissionPercentError}
                    helperText={
                      commissionPercentError &&
                      'O valor deve estar entre 0,01 até 100'
                    }
                  />
                </Grid>
              )}

              {roleHasID(['gerente_lavanderia']) &&
                this.hasRole('administrador') && (
                  <Grid item xs={12} lg={6} sm={6} md={6}>
                    <SelectField
                      options={laundryClients}
                      data={laundryClient_id}
                      label="Cliente Lavanderia"
                      setForm={this.updateFields}
                      name="laundryClients"
                    />
                  </Grid>
                )}

              <Grid item xs={12}>
                {role_id && (
                  <AutoComplete
                    options={roles}
                    label="Tipo de Usuário"
                    data={role_id}
                    updateFunction={this.updateFunction}
                    limitTags={8}
                    name="role_id"
                  />
                )}
              </Grid>

              <Grid
                item
                container
                xs={12}
                // sm={
                //   roleHasID(['gerente_lavanderia']) &&
                //   this.hasRole('administrador')
                //     ? 12
                //     : 6
                // }
                sm={6}
                alignItems="flex-end"
                justifyContent="flex-start"
                style={{ color: colors.gray }}
              >
                {id && email && (
                  <Grid item xs={12} container alignItems="center">
                    <FormControlLabel
                      control={
                        <Checkbox
                          fullWidth
                          disabled={readOnly}
                          checked={Boolean(resend)}
                          onChange={this.handleChange('resend')}
                          value={resend}
                        />
                      }
                      label="Reenviar email de cadastro"
                    />
                  </Grid>
                )}
              </Grid>
              <Grid
                item
                xs={12}
                sm={
                  roleHasID(['gerente_lavanderia']) &&
                  this.hasRole('administrador')
                    ? 3
                    : 6
                }
                container
                justifyContent={
                  roleHasID(['gerente_lavanderia']) &&
                  this.hasRole('administrador')
                    ? 'flex-start'
                    : 'flex-end'
                }
                alignItems="center"
              >
                <Switch
                  checked={status}
                  onChange={this.handleChange('status')}
                  disabled={readOnly}
                />
                Ativar usuário
              </Grid>
            </Grid>
          </Grid>
          {!readOnly && (
            <Grid
              item
              container
              xs={12}
              style={{
                paddingTop: '2em',
                marginTop: '2em',
              }}
              justifyContent="center"
              alignItems="center"
            >
              <Button
                type="submit"
                style={{
                  width: '101px',
                  height: '30px',
                  backgroundColor: colors.primary,
                  color: '#FFF',
                  fontSize: '14px',
                  borderRadius: '15px',
                  textTransform: 'capitalize',
                  '&:hover': {
                    opactity: 0.8,
                    backgroundColor: 'inherit',
                  },
                }}
              >
                Salvar
              </Button>
            </Grid>
          )}
        </Grid>
      </ValidatorForm>
    )
  }
}

UserForm.propTypes = {
  classes: PropTypes.object.isRequired,
  roles: PropTypes.array.isRequired,
}

const mapStateToProps = state => ({
  roles: state.user.roles || [],
  companies: state.user.companies,
  laundries: state.user.laundries,
  weekdays: state.user.weekdays,
  laundryClients: state.user.laundryClients,
  userRoles: state.auth.role || [],
})

export default connect(mapStateToProps)(withStyles(styles)(UserForm))
