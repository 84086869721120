/* eslint-disable react/prop-types */
import Box from '@material-ui/core/Box'
import { MenuItem } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Popover from '@material-ui/core/Popover'
import TextField from '@material-ui/core/TextField'
import { endOfMonth, startOfMonth } from 'date-fns'
import Tooltip from '@material-ui/core/Tooltip'
import FilterListIcon from '@material-ui/icons/FilterList'
import SearchIcon from '@material-ui/icons/Search'
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state'
import React, { useEffect, useState } from 'react'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { ValidatorForm } from 'react-material-ui-form-validator'
import { useDispatch, useSelector } from 'react-redux'

import BadgeGlobal from '../../components/Badge'
import {
  fetchServiceCategoriesRelatoryInit,
  makeRelatory,
} from '../ServiceCategoriesRelatoryActions'
import BaseFilter from '../../../components/hooks/BaseFilter'
import filterStylesComponents from '../../../assets/filterComponentsStyles'
import { mdiMagnify, mdiMagnifyExpand, mdiMicrosoftExcel } from '@mdi/js'
import Icon from '@mdi/react'

export default function Filter(props) {
  const items = useSelector(state => state.service_categories_relatory.items)

  const serviceTypes = useSelector(
    state => state.service_categories_relatory.services_type
  )

  const servicesCategories = useSelector(
    state => state.service_categories_relatory.services_categories
  )

  const dispatch = useDispatch()
  const { classes } = props
  const [form, setForm] = useState({
    service_type: '',
    service_status: '',
    service_category: '',
    date_start: startOfMonth(new Date()),
    date_end: endOfMonth(new Date()),
  })

  const styles = filterStylesComponents()

  const handleSubmit = () => {
    dispatch(fetchServiceCategoriesRelatoryInit(null, form, null))
  }

  // useEffect(() => {
  //   dispatch(fetchServiceCategoriesRelatoryInit(null, form, null))
  // }, [form.service_category])

  // const hanldeDateBlur = e => {
  //   e.preventDefault()
  //   dispatch(fetchServiceCategoriesRelatoryInit(null, form, null))
  // }

  const handleRelatory = e => {
    e.preventDefault
    dispatch(makeRelatory(form))
  }

  return (
    <BaseFilter>
      <Grid container spacing={2}>
        <Grid
          item
          xs={10}
          container
          spacing={2}
          justifyContent="flex-stat"
          alignItems="center"
        >
          <Grid item xs={3}>
            <KeyboardDatePicker
              clearable
              value={form.date_start}
              label="Data Inicial"
              fullWidth
              views={['date']}
              // onBlur={hanldeDateBlur}
              clearLabel="Limpar"
              invalidDateMessage="Data inválida"
              autoOk
              onChange={date => setForm({ ...form, date_start: date })}
              format="dd/MM/yyyy"
            />
          </Grid>

          <Grid item xs={3}>
            <KeyboardDatePicker
              clearable
              value={form.date_end}
              label="Data Final"
              fullWidth
              views={['date']}
              clearLabel="Limpar"
              // onBlur={hanldeDateBlur}
              invalidDateMessage="Data inválida"
              autoOk
              onChange={dateEnd => setForm({ ...form, date_end: dateEnd })}
              format="dd/MM/yyyy"
            />
          </Grid>

          <Grid item xs={3}>
            <TextField
              onChange={e =>
                setForm({ ...form, service_category: e.target.value })
              }
              select
              label="Categoria de Serviço"
              className={classes.inputFilter}
              fullWidth
              value={form.service_category}
            >
              <MenuItem key="" value="">
                Selecione...
              </MenuItem>
              {servicesCategories?.map(service => (
                <MenuItem key={service.id} value={service.id}>
                  {service.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={3}>
            <IconButton className={styles.btnAdd} onClick={handleSubmit}>
              <Icon path={mdiMagnify} size={1} />
              Pesquisar
            </IconButton>
          </Grid>
        </Grid>

        <Grid
          item
          xs
          className={styles.areaButtons}
          container
          justifyContent="flex-end"
        >
          <Grid item>
            <IconButton className={styles.btnAdd} onClick={handleRelatory}>
              <Icon path={mdiMicrosoftExcel} size={1} />
              Download
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    </BaseFilter>
    // <Tooltip title="Filter list">
    //   <PopupState variant="popover" popupId="demo-popup-popover">
    //     {popupState => (
    //       <div>
    //         <IconButton aria-label="filter list" {...bindTrigger(popupState)}>
    //           <BadgeGlobal form={form} items={items}>
    //             <FilterListIcon />
    //           </BadgeGlobal>
    //         </IconButton>
    //         <Popover
    //           {...bindPopover(popupState)}
    //           anchorOrigin={{
    //             vertical: 'bottom',
    //             horizontal: 'center',
    //           }}
    //           transformOrigin={{
    //             vertical: 'top',
    //             horizontal: 'center',
    //           }}
    //         >
    //           <Box p={2} className={classes.popover}>
    //             <div className={classes.root}>
    //               <ValidatorForm className={classes.form} onSubmit={onSubmit}>
    //                 <Grid
    //                   container
    //                   className={classes.gridContainer}
    //                   spacing={1}
    //                   justify="center"
    //                   alignItems="center"
    //                 >
    //                   <Grid item xs={6}>
    //                     <TextField
    //                       onChange={e =>
    //                         setForm({ ...form, service_type: e.target.value })
    //                       }
    //                       select
    //                       label="Tipo de Serviço"
    //                       margin="dense"
    //                       variant="outlined"
    //                       className={classes.inputFilter}
    //                       fullWidth
    //                       value={form.service_type}
    //                     >
    //                       <MenuItem key="" value="">
    //                         Selecione...
    //                       </MenuItem>
    //                       {serviceTypes?.map(service_type => (
    //                         <MenuItem
    //                           key={service_type.id}
    //                           value={service_type.id}
    //                         >
    //                           {service_type.name}
    //                         </MenuItem>
    //                       ))}
    //                     </TextField>
    //                   </Grid>

    //                   <Grid item xs={6}>
    //                     <KeyboardDatePicker
    //                       clearable
    //                       value={form.date_start}
    //                       label="Data Inicial"
    //                       fullWidth
    //                       margin="dense"
    //                       inputVariant="outlined"
    //                       views={['date']}
    //                       clearLabel="Limpar"
    //                       invalidDateMessage="Data inválida"
    //                       autoOk
    //                       onChange={date =>
    //                         setForm({ ...form, date_start: date })
    //                       }
    //                       format="dd/MM/yyyy"
    //                     />
    //                   </Grid>

    //                   <Grid item xs={6}>
    //                     <KeyboardDatePicker
    //                       clearable
    //                       value={form.date_end}
    //                       label="Data Final"
    //                       fullWidth
    //                       margin="dense"
    //                       inputVariant="outlined"
    //                       views={['date']}
    //                       clearLabel="Limpar"
    //                       invalidDateMessage="Data inválida"
    //                       autoOk
    //                       onChange={dateEnd =>
    //                         setForm({ ...form, date_end: dateEnd })
    //                       }
    //                       format="dd/MM/yyyy"
    //                     />
    //                   </Grid>

    //                   <Grid item xs>
    //                     <Button
    //                       type="submit"
    //                       color="primary"
    //                       fullWidth
    //                       variant="contained"
    //                       className={classes.submit}
    //                       onClick={popupState.close}
    //                     >
    //                       <SearchIcon />
    //                       {` Filtrar`}
    //                     </Button>
    //                   </Grid>
    //                 </Grid>
    //               </ValidatorForm>
    //             </div>
    //           </Box>
    //         </Popover>
    //       </div>
    //     )}
    //   </PopupState>
    // </Tooltip>
  )
}
