/* eslint-disable consistent-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-return-assign */
import { sprintf } from 'sprintf-js'
import format from 'date-fns/format'

export function renderRoute(roles, permissions, options = { all: false }) {
  const result = roles?.filter(role => permissions.find(x => x.name === role))
  if (!options.all) return result.length !== 0

  return result.length === roles.length
}

export function renderRouteEmail(emails, user) {
  const result = emails?.filter(email => user?.email === email)

  return result.length !== 0
}

export function getDecimalPart(money) {
  if (!money) return '00'

  if (typeof money == 'string') {
    const moneyString = Number(money)?.toFixed(2)

    const decimalPart = moneyString.split('.')[1]

    return decimalPart || '00'
  }

  const moneyString = money?.toFixed(2)

  const decimalPart = moneyString.split('.')[1]

  return decimalPart || '00'
}

export function renderRouteRole(roles, userRoles) {
  const result = roles?.filter(role =>
    userRoles?.find(x => x.name.includes(role))
  )

  return result.length !== 0
}

export function convertBytesToMbs(Bytes) {
  return `${Bytes / 1000000} Megabytes`
}

export function dateFormated(data) {
  const date = new Date(`${data} 00:00:00`)

  return date.toLocaleDateString('en-us')
}

const formatter = new Intl.DateTimeFormat('pt-BR')

export function dateFormatedPT(data) {
  let date = null
  if (data) {
    if (data.length <= 10) {
      date = new Date(`${data} 10:00:00`)
    } else {
      date = new Date(`${data}`)
    }
    date = formatter.format(date)
  }

  return date
}

export function dateFormatedFullDate(data) {
  if (data) {
    return format(new Date(data), "dd/MM/yyyy 'às' HH:mm:ss")
  }

  return null
}

export function duplicateTime(times, multiply) {
  let allSeconds = 0
  let totalMinutes = 0

  times.forEach(time => {
    const [hour, minute, second] = time.split(':')

    allSeconds += hour * 3600
    allSeconds += minute * 60
    allSeconds += second * 1
  })

  allSeconds *= multiply

  totalMinutes = Math.floor(allSeconds / 60)
  const seconds = allSeconds % 60
  const hours = Math.floor(totalMinutes / 60)
  const minutes = totalMinutes % 60

  return sprintf('%02d:%02d:%02d', hours, minutes, seconds)
}

export function exportFormatedMoney(money) {
  if (isNaN(money)) return money
  return parseFloat(money).toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  })
}

export function applyDiscount(value, discount) {
  return value - value * (discount / 100)
}

export function cpfMask(value) {
  const remove = value.replace(/[^\d]/g, '')

  if (remove?.length > 11) {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1/$2')
      .replace(/(\d{4})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1')
  }
  return value
    .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
    .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
}

export function removeEmpty(obj) {
  return Object.entries(obj).reduce(
    (a, [k, v]) => (v ? ((a[k] = v), a) : a),
    {}
  )
}

export function returnFirstLetters(name) {
  let final = ''

  for (let i = 0; i < name.length; i++) {
    if (final.length === 2) break

    if (i === 0) {
      final += name[i]
    }
    if (name[i] === ' ') {
      final += name[i + 1]
    }
  }

  return final
}

export function toFixed(num, fixed) {
  const re = new RegExp(`^-?\\d+(?:.\\d{0,${fixed || -1}})?`)
  return num.toString().match(re)[0]
}

export function truncateNumber(number) {
  // return Math.trunc(number * 100) / 100
  return toFixed(number, 2)
}

export function convertDateToUTC(date) {
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  )
}

export function parseValue(value) {
  if (!value) return null

  return parseFloat(value?.replace(/\./g, '')?.replace(',', '.'))
}
