/* eslint-disable import/order */
import { Grid, Slide } from '@material-ui/core'
import AppBar from '@material-ui/core/AppBar'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import withStyles from '@material-ui/core/styles/withStyles'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styles from '../../../resources/theme/global'
import { edit, update } from '../BankActions'
import Form from './Form'
import BaseFilterModal from '../../../components/hooks/BaseFilterModal'
import colors from '../../../assets/colors'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />
})

function EditBank(props) {
  const { classes } = props
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(edit(props.match.params.id))
  }, [])

  const bank = useSelector(state => state.bank.editingBank)

  const onSubmit = values => {
    dispatch(update(values)).then(data => {
      if (data && data.code === 200) {
        props.history.push('/bank')
      }
    })
  }

  const onClose = () => {
    props.history.push('/bank')
  }

  return (
    <BaseFilterModal open handleOpen={onClose} title="Editar Banco">
      <Grid
        container
        alignItems="center"
        item
        xs={12}
        justifyContent="flex-start"
        style={{ width: '420px' }}
        spacing={1}
      >
        <Grid item xs={12}>
          <Typography
            style={{ fontSize: '16px', fontWeight: 'normal' }}
            color={colors.primary}
          >
            Utilize os campos para editar o banco
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Form
            classes={classes}
            data={bank}
            onClose={onClose}
            onSubmit={onSubmit}
          />
        </Grid>
      </Grid>
    </BaseFilterModal>
  )
}

export default withStyles(styles)(EditBank)
