import axios from 'axios/index'

import config from '../../config'
import action, { actionFailed } from '../../util/action'
import createAsyncConst from '../../util/createAsyncConst'

export const REMOVE_SERVICE_TYPE = createAsyncConst('REMOVE_SERVICE_TYPE')
export const SUBMIT_SERVICE_TYPE = createAsyncConst('SUBMIT_SERVICE_TYPE')
export const FETCH_SERVICE_TYPE = createAsyncConst('FETCH_SERVICE_TYPE')
export const EDIT_SERVICE_TYPE = createAsyncConst('EDIT_SERVICE_TYPE')
export const EXPORT_REPORT = createAsyncConst('EXPORT_REPORT')
export const IMPORT_SERVICE_TYPES = createAsyncConst('IMPORT_SERVICE_TYPES')
export const DOWNLOAD_SERVIEC_TYPE_IMPORT_TEMPLATE = createAsyncConst(
  'DOWNLOAD_SERVIEC_TYPE_IMPORT_TEMPLATE'
)

export function fetchServiceType(filters = {}) {
  return dispatch => {
    dispatch(action(FETCH_SERVICE_TYPE.ACTION))
    return axios
      .get(`${config.API_URL}/service-type`, {
        params: {
          filters: JSON.stringify(filters),
        },
      })
      .then(resp => {
        dispatch(
          action(FETCH_SERVICE_TYPE.SUCCESS, { data: resp.data, filters })
        )
        return resp
      })
      .catch(e => dispatch(actionFailed(FETCH_SERVICE_TYPE, e)))
  }
}

export function edit(id) {
  return dispatch => {
    dispatch(action(EDIT_SERVICE_TYPE.ACTION, { id }))
    return axios
      .get(`${config.API_URL}/service-type/${id}`)
      .then(resp => {
        return resp.data.data
      })
      .then(data => dispatch(action(EDIT_SERVICE_TYPE.SUCCESS, data)))
      .catch(e => dispatch(actionFailed(EDIT_SERVICE_TYPE, e)))
  }
}

function submit(values, method) {
  const id = values.id ? `/${values.id}` : ''

  return dispatch => {
    dispatch(action(SUBMIT_SERVICE_TYPE.ACTION))
    return axios[method](`${config.API_URL}/service-type${id}`, values)
      .then(resp => resp.data)
      .then(data => {
        dispatch(action(SUBMIT_SERVICE_TYPE.SUCCESS, data))
        dispatch(fetchServiceType())
        return data
      })
      .catch(e => dispatch(actionFailed(SUBMIT_SERVICE_TYPE, e)))
  }
}

export function post(values) {
  return submit(values, 'post')
}

export function update(values) {
  return submit(values, 'put')
}

export function remove(id) {
  return dispatch => {
    dispatch(action(REMOVE_SERVICE_TYPE.ACTION))
    return axios
      .delete(`${config.API_URL}/service-type/${id}`)
      .then(resp => resp.data)
      .then(data => {
        dispatch(action(REMOVE_SERVICE_TYPE.SUCCESS, data))
        dispatch(fetchServiceType())
      })
      .catch(e => dispatch(actionFailed(REMOVE_SERVICE_TYPE, e)))
  }
}

export function makeRelatory(data) {
  return dispatch => {
    return axios({
      url: `${config.API_URL}/service-type/relatory`,
      method: 'POST',
      responseType: 'blob',
      headers: { Accept: 'application/vnd.ms-excel' },
      params: {
        filters: JSON.stringify(data),
      },
    })
      .then(resp => {
        const url = window.URL.createObjectURL(new Blob([resp.data]))
        const link = document.createElement('a')

        link.href = url
        link.setAttribute('download', 'relatótio-tipos-serviço.xlsx')
        document.body.appendChild(link)
        link.click()
        return resp
      })
      .catch(e => dispatch(actionFailed(EXPORT_REPORT, e)))
  }
}

export function importServiceTypes(file) {
  return dispatch => {
    dispatch(action(IMPORT_SERVICE_TYPES.ACTION))
    return axios
      .post(`${config.API_URL}/service-type/import`, file)
      .then(resp => {
        dispatch(action(IMPORT_SERVICE_TYPES.SUCCESS, resp.data))
        return resp.data
      })
      .catch(e => dispatch(actionFailed(IMPORT_SERVICE_TYPES, e)))
  }
}

export function downloadImportTemplate() {
  return dispatch => {
    dispatch(action(DOWNLOAD_SERVIEC_TYPE_IMPORT_TEMPLATE.ACTION))
    return axios
      .get(`${config.API_URL}/service-type/import-template`, {
        responseType: 'blob',
        validateStatus: status => status <= 500,
      })
      .then(async response => {
        if (response.status !== 200) {
          const error = JSON.parse(await response.data.text())
          dispatch(
            actionFailed(DOWNLOAD_SERVIEC_TYPE_IMPORT_TEMPLATE, {
              response: {
                data: error,
              },
            })
          )
        } else {
          dispatch(action(DOWNLOAD_SERVIEC_TYPE_IMPORT_TEMPLATE.SUCCESS, {}))
          const blob = new Blob([response.data], { type: response.data.type })
          const url = window.URL.createObjectURL(blob)
          const link = document.createElement('a')
          link.href = url
          const contentDisposition = response.headers['content-disposition']
          let fileName = `unknown.${response.data.type}`
          if (contentDisposition) {
            const fileNameMatch = contentDisposition.match(/filename=(.+)/)
            if (fileNameMatch.length === 2) {
              fileName = fileNameMatch[1].replaceAll('"', '')
            }
          }
          link.setAttribute('download', fileName)
          document.body.appendChild(link)
          link.click()
          link.remove()
          window.URL.revokeObjectURL(url)
        }
      })
  }
}
