import React, { useRef, useState } from 'react'
import { Forms } from '..'
import { Box, Grid, IconButton, MenuItem, TextField } from '@material-ui/core'
import ReactInputMask from 'react-input-mask'
import colors from '../../../../../assets/colors'
import { mdiProgressUpload } from '@mdi/js'
import Icon from '@mdi/react'

const FormLogo = ({ form, setForm = () => {}, classes }) => {
  const [logo, setLogo] = useState()

  const inputRef = useRef()

  const handleChooseFile = () => {
    inputRef.current.click()
  }

  const handleChangeLogo = event => {
    if (event.target.files[0] == null) {
      return
    }

    const image = event.target.files[0]
    const load = new FileReader()

    load.onload = event => {
      setLogo(event.target.result)
    }

    if (image) {
      load.readAsDataURL(image)
    }

    setForm({
      ...form,
      arquivo_logo_base64: event.target.files[0],
    })
  }

  return (
    <Forms.root>
      <Grid item xs={6}>
        <Grid container alignItems="flex-start" justifyContent="flex-start">
          <Box
            className={classes.uploadLogo}
            style={{
              width: '200px',
              height: '200px',
              objectFit: 'contain',
              padding: '4px',
            }}
          >
            {form?.caminho_logo && !logo && (
              <img
                alt="Logo"
                src={`https://api.focusnfe.com.br/${form?.caminho_logo}`}
                style={{
                  width: '190px',
                  height: '180px',
                  objectFit: 'scale-down',
                }}
              />
            )}

            {logo && (
              <img
                alt="Logo"
                src={logo}
                style={{
                  width: '190px',
                  height: '180px',
                  objectFit: 'scale-down',
                }}
              />
            )}
          </Box>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Grid container justifyContent="flex-end">
          <Grid item>
            <input
              ref={inputRef}
              name="logo"
              type="file"
              accept=".png,.jpg"
              onChange={handleChangeLogo}
              style={{ display: 'none' }}
            />
            <IconButton
              onClick={handleChooseFile}
              className={classes.uploadLogo}
              style={{ width: '200px' }}
            >
              <Grid container spacing={1}>
                <Grid
                  item
                  xs={12}
                  container
                  alignItems="center"
                  justifyContent="center"
                >
                  <Icon path={mdiProgressUpload} size={1} />
                  Anexar Logo
                  <br /> (200x200)
                </Grid>
              </Grid>
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    </Forms.root>
  )
}

export default FormLogo
