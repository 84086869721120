/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable react/prop-types */
import { InputAdornment, MenuItem } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Popover from '@material-ui/core/Popover'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import FilterListIcon from '@material-ui/icons/FilterList'
import SearchIcon from '@material-ui/icons/Search'
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state'
import React, { useEffect, useState } from 'react'
import { ValidatorForm } from 'react-material-ui-form-validator'
import { useDispatch, useSelector } from 'react-redux'

import BadgeGlobal from '../../components/Badge'
// import { fetchServiceCategory } from '../ServiceCategoryActions'
import BaseFilter from '../../../components/hooks/BaseFilter'
import colors from '../../../assets/colors'
import { renderRoute } from '../../../util/utils'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import Icon from '@mdi/react'
import { mdiMagnifyExpand, mdiNoteEditOutline } from '@mdi/js'
import filterStylesComponents from '../../../assets/filterComponentsStyles'
import { fetchKnowWay } from '../KnowWayActions'
// import ModalServiceCategory from './Modal'

export default function Filter(props) {
  const items = useSelector(state => state.serviceCategory.items)

  const dispatch = useDispatch()
  const { classes, permissions } = props
  const filterClasses = filterStylesComponents()

  const [open, setOpen] = useState(false)

  const handleClose = () => {
    setOpen(!open)
  }

  const [form, setForm] = useState({
    name: '',
    status: '',
  })

  const onSubmit = e => {
    e.preventDefault()

    if (form?.name) {
      dispatch(fetchKnowWay(form))
    }
  }

  useEffect(() => {
    dispatch(fetchKnowWay(form))
  }, [form?.status])

  useEffect(() => {
    if (!form?.name) {
      dispatch(fetchKnowWay(form))
    }
  }, [form?.name])

  return (
    <BaseFilter>
      <Grid container item xs={6} alignItems="center">
        <Grid item xs={5} style={{ marginRight: '8px' }}>
          <TextField
            onChange={e => setForm({ ...form, name: e.target.value })}
            label="Filtrar por nome"
            className={classes.inputFilter}
            fullWidth
            value={form.name}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton>
                    <Icon path={mdiMagnifyExpand} size={1} onClick={onSubmit} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs={5}>
          <TextField
            onChange={e => setForm({ ...form, status: e.target.value })}
            select
            label="Filtrar por status"
            className={classes.inputFilter}
            fullWidth
            value={form.status}
          >
            <MenuItem key="" value="">
              Selecione...
            </MenuItem>
            <MenuItem key={0} value={0}>
              Inativo
            </MenuItem>
            <MenuItem key={1} value={1}>
              Ativo
            </MenuItem>
          </TextField>
        </Grid>
      </Grid>

      <Grid container item xs={6} alignItems="center" justifyContent="flex-end">
        <Grid item>
          {renderRoute(['create-service-categories'], permissions) && (
            <IconButton
              component={Link}
              className={filterClasses.btnAdd}
              to="/knowWay/new"
              onClick={handleClose}
            >
              <Icon
                path={mdiNoteEditOutline}
                className={classes.btnIcon}
                size={1}
              />
              Cadastrar
            </IconButton>
          )}
        </Grid>
      </Grid>
    </BaseFilter>
  )
}
