import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import DeleteIcon from '@material-ui/icons/Delete'
import PencilIcon from '@material-ui/icons/Edit'
import { useConfirm } from 'material-ui-confirm'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'

import { getSorting, stableSort } from '../../../util/TableUtils'
import { renderRoute } from '../../../util/utils'
import EnhancedTableHead from '../../components/TableHead'
import { remove } from '../ServiceCategoryActions'
import { StyledTableCellBody } from '../../../components/hooks/BaseTableComponents'
import { Box, Grid } from '@material-ui/core'
import colors from '../../../assets/colors'
import Icon from '@mdi/react'
import {
  mdiDelete,
  mdiFileEdit,
  mdiFileEditOutline,
  mdiNoteEditOutline,
} from '@mdi/js'
import ModalServiceCategory from './Modal'

function createData(id, name, status, color) {
  return { id, name, status, color }
}

const headCells = [
  // {
  //   id: 'id',
  //   numeric: false,
  //   disablePadding: true,
  //   label: 'ID',
  // },
  {
    id: 'name',
    numeric: true,
    disablePadding: false,
    label: 'Nome',
  },
  {
    id: 'status',
    numeric: true,
    disablePadding: false,
    label: 'Status',
  },
  {
    id: 'status',
    numeric: true,
    disablePadding: false,
    label: 'Cor',
  },
  {
    id: 'actions',
    numeric: true,
    disablePadding: false,
    label: 'Ações',
    align: 'center',
  },
]

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  tableStickHeader: {
    maxHeight: 'calc(100vh - 200px)',
    marginTop: '1.5em',
  },
  status: {
    textAlign: 'center',
    fontSize: '14p',
    borderRadius: '10px',
    padding: '4px 16px',
    maxWidth: '80px',
    backgroundColor: colors.graylight,
    color: '#000',
  },
  statusAtivo: {
    color: '#FFFF',
    backgroundColor: colors.secondary,
  },
  retangleColor: {
    width: '18px',
    height: '18px',
    borderRadius: '2px',
  },
}))

function List(props) {
  const { permissions } = props
  const classes = useStyles()
  const confirm = useConfirm()
  const dispatch = useDispatch()
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('value')
  const [selected, setSelected] = useState([])
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [open, setOpen] = useState(false)
  const [editId, setEditId] = useState()

  const handleClose = () => {
    setOpen(!open)
  }

  const rows = []

  props.items.map(item => {
    rows.push(createData(item.id, item.name, item.status_text, item.color))
  })

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === 'desc'
    setOrder(isDesc ? 'asc' : 'desc')
    setOrderBy(property)
  }

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n.name)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const confirmDelete = id => {
    confirm({
      description: 'Após ser apagado o conteúdo não poderá ser recuperado?',
      title: 'Tem certeza?',
      confirmationText: 'Sim',
      cancellationText: 'Cancelar',
      dialogProps: {
        fullWidth: true,
      },
    }).then(() => {
      dispatch(remove(id))
    })
  }

  useEffect(() => {
    if (!open) {
      setEditId()
    }
  }, [open, editId])

  return (
    <>
      <TableContainer className={classes.tableStickHeader}>
        <Table
          className={classes.table}
          aria-labelledby="Tabela de Categorias de Serviços"
        >
          <EnhancedTableHead
            classes={classes}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
            headCells={headCells}
            renderFirstTH={false}
          />
          <TableBody>
            {stableSort(rows, getSorting(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map(row => {
                return (
                  <TableRow hover tabIndex={-1} key={row.id}>
                    {/* <StyledTableCellBody padding="checkbox" /> */}
                    <StyledTableCellBody>{row.name}</StyledTableCellBody>
                    <StyledTableCellBody>
                      {row.status.toLowerCase() === 'ativo' ? (
                        <Box className={[classes.status, classes.statusAtivo]}>
                          {row.status}
                        </Box>
                      ) : (
                        <Box className={classes.status}>{row.status}</Box>
                      )}
                    </StyledTableCellBody>
                    <StyledTableCellBody>
                      <Grid container direction="row" spacing={1}>
                        <Grid item>
                          <Box
                            className={classes.retangleColor}
                            style={{ backgroundColor: `${row.color}` }}
                          />
                        </Grid>

                        <Grid item>{row.color}</Grid>
                      </Grid>
                    </StyledTableCellBody>
                    <StyledTableCellBody align="center">
                      {renderRoute(
                        ['update-service-categories'],
                        permissions
                      ) && (
                        <IconButton
                          aria-label="edit"
                          onClick={() => {
                            handleClose()

                            setEditId(row.id)
                          }}
                        >
                          <Icon path={mdiNoteEditOutline} size={1} />
                        </IconButton>
                      )}
                      {renderRoute(
                        ['delete-service-categories'],
                        permissions
                      ) && (
                        <IconButton onClick={() => confirmDelete(row.id)}>
                          <Icon path={mdiDelete} size={1} />
                        </IconButton>
                      )}
                    </StyledTableCellBody>
                  </TableRow>
                )
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        labelRowsPerPage="Linhas por Página"
        backIconButtonProps={{
          'aria-label': 'previous page',
        }}
        labelDisplayedRows={({ from, to, count }) =>
          `Mostrando linhas ${from}-${to} de ${count}`
        }
        nextIconButtonProps={{
          'aria-label': 'next page',
        }}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />

      <ModalServiceCategory
        isEdit
        id={editId}
        open={open}
        handleClose={handleClose}
      />
    </>
  )
}

export default withRouter(List)
