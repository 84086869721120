import React from 'react'
import BaseFilterModal from '../../../components/hooks/BaseFilterModal'
import {
  Button,
  Grid,
  InputBase,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'
import filterStylesComponents from '../../../assets/filterComponentsStyles'
import { archive, fetchAttendanceInit, remove } from '../AttendanceActions'
import colors from '../../../assets/colors'

const DeleteService = props => {
  const history = useHistory()
  const dispatch = useDispatch()
  const classes = filterStylesComponents()

  const sizePerPage = useSelector(state => state.attendance.sizePerPage)
  const filters = useSelector(state => state.attendance.filters)

  const { id } = props.match.params

  const handleClose = () => {
    history.push('/attendance')
  }

  const handleSubmit = async e => {
    e.preventDefault()

    const formData = e.currentTarget
    const data = new FormData(formData)
    const entries = data.entries()
    const values = Object.fromEntries(entries)

    try {
      const resp = await dispatch(remove(values?.loss_reason, id))

      if (resp) {
        dispatch(fetchAttendanceInit(filters, sizePerPage))
        handleClose()
      }
    } catch (e) {
      //
    }
  }
  return (
    <BaseFilterModal
      open
      title="Deletar serviço"
      hiddenHelpButton
      handleOpen={handleClose}
    >
      <Grid item xs={12} style={{ width: '260px' }}>
        <form style={{ width: '100%', height: '100%' }} onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography style={{ color: colors.graylight }}>
                Após ser apagado o conteúdo não poderá ser recuperado
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="loss_reason"
                variant="standard"
                fullWidth
                label="Motivo"
                required
              />
            </Grid>

            <Grid
              item
              xs={12}
              container
              alignItems="center"
              justifyContent="flex-end"
            >
              <Button
                type="button"
                onClick={handleClose}
                className={classes.btnMoreFilters}
              >
                Cancelar
              </Button>

              <Button type="submit" className={classes.btnAdd}>
                Confirmar
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </BaseFilterModal>
  )
}

export default DeleteService
