import React, { useEffect, useState } from 'react'
import BaseFilterModal from '../../../../../components/hooks/BaseFilterModal'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'
import { fetchLead, updateLead } from '../../PanelActions'
import { useDispatch, useSelector } from 'react-redux'
import {
  Box,
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core'
import colors from '../../../../../assets/colors'
import ServiceItemsTable from './ServiceItemsTable'
import ObservationsTable from './ObservationsTable'
import Icon from '@mdi/react'
import { mdiPlus } from '@mdi/js'
import filterStylesComponents from '../../../../../assets/filterComponentsStyles'
import ModalCreateDescription from './ModalCreateDescription'
import ModalTransferOs from './ModalTransferOs'
import ModalCloseLead from './ModalCloseLead'
import ModalNewOrcament from './ModalNewOrcament'

const useStyles = makeStyles({
  '@keyframes fadeIn': {
    '0%': {
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    animation: '$fadeIn .4s ease-in-out',
  },
  box: {
    padding: '10px 0px 20px 10px',
    width: '100%',
    borderBottom: `1px dashed ${colors.graylight}`,
    color: colors.gray,
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
  },
  cardSubTitles: {
    color: colors.primary,
    fontSize: '16px',
    lineHeight: '16px',
    fontWeight: 'bold',
    margin: '10px 0',
  },
  footer: {
    marginTop: 20,
  },
})

const LeadForm = props => {
  const lead = useSelector(state => state.crmPanel.editingLead)

  const history = useHistory()
  const classes = useStyles()
  const filterStyles = filterStylesComponents()
  const dispatch = useDispatch()

  const [openObservations, setOpenObservations] = useState(false)
  const [openTransferOs, setOpenTransferOs] = useState(false)
  const [openCloseLead, setOpenCloseLead] = useState(false)
  const [openNewOrcament, setOpenNewOrcament] = useState(false)

  const handleOpenObservations = () => {
    setOpenObservations(!openObservations)
  }

  const handleOpenTransferOs = () => {
    setOpenTransferOs(!openTransferOs)
  }

  const handleOpenCloseLead = () => {
    setOpenCloseLead(!openCloseLead)
  }

  const handleOpenNewOrcament = () => {
    setOpenNewOrcament(!openNewOrcament)
  }

  const { id } = props.match.params

  const handleClose = () => {
    history.push('/crm/panel')
  }

  const handleSubmit = async e => {
    e.preventDefault()

    const formData = e.currentTarget
    const data = new FormData(formData)
    const entries = data.entries()
    const values = Object.fromEntries(entries)

    try {
      const resp = await dispatch(updateLead(id, values))

      if (resp) {
        handleClose()
      }
    } catch (e) {
      //
    }
  }

  useEffect(() => {
    dispatch(fetchLead(id))
  }, [id])

  return (
    <BaseFilterModal
      open
      hiddenHelpButton
      handleOpen={handleClose}
      title={`OS: ${lead?.os} | ${
        lead?.trigger_type == 20 ? 'RECORRÊNCIA' : 'OS EM ABERTO'
      }`}
    >
      <Grid item xs={12} style={{ width: '900px' }}>
        <form clasName={classes.container} onSubmit={handleSubmit}>
          <Box className={classes.box}>
            <Grid container alignItems="center">
              <Grid item xs={3}>
                <Grid container spacing={1}>
                  <Grid item>
                    <Typography style={{ fontWeight: 'bold' }}>
                      Capturado por:
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography>{lead?.user_capture}</Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={3}>
                <Grid container spacing={1}>
                  <Grid item>
                    <Typography style={{ fontWeight: 'bold' }}>
                      Estado Atual:
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography>{lead?.state}</Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={4}>
                <Grid container alignItems="center" spacing={1}>
                  <Grid item>
                    <Typography style={{ fontWeight: 'bold' }}>
                      Data de agendamento:
                    </Typography>
                  </Grid>
                  <Grid item>
                    <TextField
                      type="date"
                      defaultValue={lead?.scheduling_date}
                      name="scheduling_date_crm"
                      key={`scheduling_date_crm-${lead?.scheduling_date}`}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={2}>
                <Grid container spacing={1} justifyContent="flex-end">
                  <Grid item>
                    <Button
                      className={filterStyles.btnAdd}
                      style={{ textTransform: 'none' }}
                      onClick={handleOpenTransferOs}
                    >
                      Transferir OS
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Box className={classes.box}>
            <Typography className={classes.cardSubTitles}>
              Informações do cliente:
            </Typography>

            <Grid container spacing={4}>
              <Grid item xs={3}>
                <TextField
                  label="Nome"
                  fullWidth
                  disabled
                  value={lead?.client?.name}
                  key={`input-name-${lead?.client?.name}`}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Rua"
                  fullWidth
                  disabled
                  value={lead?.client?.street}
                  key={`input-street-${lead?.client?.street}`}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Bairro"
                  fullWidth
                  disabled
                  value={lead?.client?.neighborhood}
                  key={`input-neighborhood-${lead?.client?.neighborhood}`}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Cidade"
                  fullWidth
                  disabled
                  value={lead?.client?.city}
                  key={`input-city-${lead?.client?.city}`}
                />
              </Grid>
            </Grid>
          </Box>
          <Box className={classes.box}>
            <Typography className={classes.cardSubTitles}>
              Itens da OS:
            </Typography>

            <ServiceItemsTable items={lead?.service_items} />
          </Box>
          <Box className={classes.box}>
            <Typography className={classes.cardSubTitles}>
              Observações:
            </Typography>

            <ObservationsTable items={lead?.observations} />

            <Grid container justifyContent="flex-end">
              <Grid item>
                <IconButton
                  style={{
                    borderRadius: '5px',
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: '14px',
                    fontWeight: 'bold',
                    padding: '4px 12px',
                    marginTop: '4px',
                  }}
                  type="button"
                  onClick={handleOpenObservations}
                >
                  <Icon
                    path={mdiPlus}
                    size={1}
                    style={{
                      backgroundColor: colors.primary,
                      color: '#FFF',
                      borderRadius: '50%',
                      marginRight: '4px',
                    }}
                  />
                  Adicionar observação
                </IconButton>
              </Grid>
            </Grid>
          </Box>

          <Box className={classes.footer}>
            <Grid container>
              <Grid item xs={6}>
                <Grid container spacing={2} justifyContent="flex-start">
                  <Grid item>
                    <Button
                      className={filterStyles.btnAdd}
                      style={{
                        backgroundColor: colors.error,
                        textTransform: 'none',
                      }}
                      type="button"
                      onClick={handleOpenCloseLead}
                    >
                      Bloquear cliente
                    </Button>
                  </Grid>

                  <Grid item>
                    <Button
                      className={filterStyles.btnAdd}
                      style={{ textTransform: 'none' }}
                      type="button"
                      onClick={handleOpenNewOrcament}
                    >
                      Abrir novo orçamento
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container justifyContent="flex-end">
                  <Button
                    className={filterStyles.btnAdd}
                    style={{ textTransform: 'none' }}
                    type="submit"
                  >
                    Salvar
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Grid>

      <ModalCreateDescription
        open={openObservations}
        handleOpen={handleOpenObservations}
        leadId={id}
      />

      <ModalTransferOs
        open={openTransferOs}
        handleOpen={handleOpenTransferOs}
        leadId={id}
      />

      <ModalCloseLead
        open={openCloseLead}
        handleOpen={handleOpenCloseLead}
        leadId={id}
      />

      <ModalNewOrcament
        open={openNewOrcament}
        handleOpen={handleOpenNewOrcament}
        leadId={id}
      />
    </BaseFilterModal>
  )
}

export default LeadForm
