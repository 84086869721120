/* eslint-disable no-unused-vars */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable import/order */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/prop-types */
import '../../../resources/less/style.less'

import AppBar from '@material-ui/core/AppBar'
import Avatar from '@material-ui/core/Avatar'
import Divider from '@material-ui/core/Divider'
import Drawer from '@material-ui/core/Drawer'
import IconButton from '@material-ui/core/IconButton'
import Menus from '@material-ui/core/Menu'
import withStyles from '@material-ui/core/styles/withStyles'
import Toolbar from '@material-ui/core/Toolbar'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import MenuIcon from '@material-ui/icons/Menu'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'

import { logout } from '../../auth/AuthActions'
import Icons from '../Icons'
import config from '../../../config'
import axios from 'axios'
import {
  Box,
  Button,
  ClickAwayListener,
  Grow,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@material-ui/core'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import ShortLogo from '../../../../public/img/higsystem-short-logo.svg'
import FullLogo from '../../../../public/img/higsystem-full-logo.svg'
import colors from '../../../assets/colors'
import { ChevronRight } from '@material-ui/icons'
import { FaChevronDown } from 'react-icons/fa'
import { anchorRef } from 'material-ui-popup-state'
import MenulistComposition from './MenuList'
import fontSizes from '../../../assets/fontSizes'
import { Link } from 'react-router-dom/cjs/react-router-dom'

const drawerWidth = 250

const styles = theme => ({
  root: {
    display: 'flex',
  },
  grow: {
    flexGrow: 1,
  },
  hide: {
    display: 'none',
  },
  appBar: {
    width: 'calc(100% - 68px)',
    height: '47px',
    backgroundColor: '#D9D9D9',
    boxShadow: 'none',
    zIndex: theme.zIndex.drawer + 1,
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  btnAvatar: {
    fontSize: '18px',
    marginLeft: theme.spacing(1),
    display: 'flex',
    gap: '4px',
    padding: '5px',
    borderRadius: '0',
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '8px',
    cursor: 'pointer',
  },
  menuButton: {
    position: 'absolute',
    left: '-10px',
    padding: '0',
    backgroundColor: '#FFFF',
    transition: 'all .4s',
    '&:hover': {
      backgroundColor: '#FFFF',
      opacity: 0.8,
    },
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    backgroundColor: theme.gray2Color,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    backgroundColor: theme.gray2Color,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 4,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(8) + 4,
    },
  },
  drawerHeader: {
    height: '47px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '0 16px',
    backgroundColor: colors.primary,
  },
  listDrawer: {
    paddingTop: 0,
  },
  popover: {
    maxHeight: '280px',
    maxWidth: '400px',
  },
  starButton: {
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.sharp,
    }),
    '&:hover': {
      transform: 'scale(1.3)',
      cursor: 'pointer',
    },
  },
  iconRI: {
    color: colors.primary,
  },
  imgContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logoutButton: {
    fontSize: '18px',
    transition: 'all .4s ease-in-out',
    '&:hover': {
      backgroundColor: 'inherit',
      color: colors.primary,
    },
  },
})

class Menu extends Component {
  constructor(props) {
    super(props)

    this.handleMenu = this.handleMenu.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.handleClickRelatory = this.handleClickRelatory.bind(this)
    this.handleClickDashboard = this.handleClickDashboard.bind(this)
    this.state = {
      open: false,
      anchorEl: false,
      openRelatory: false,
      openDashboard: false,
      openFinance: false,
      openCrm: false,
      laundryClient: [],
    }
  }

  componentDidMount() {
    this.getLaundryClientByUserID()
  }

  handleClick = () => {
    this.setState(state => ({ open: !state.open }))
  }

  handleClickDashboard = () => {
    this.setState(state => ({ openDashboard: !state.openDashboard }))
  }

  handleClickRelatory = () => {
    this.setState(state => ({ openRelatory: !state.openRelatory }))
  }

  handleClickFinance = () => {
    this.setState(state => ({ openFinance: !state.openFinance }))
  }

  handleClickCrm = () => {
    this.setState(state => ({ openCrm: !state.openCrm }))
  }

  handleMenu(event) {
    this.setState({ ...this.state, anchorEl: event.currentTarget })
  }

  handleClose() {
    this.setState({ ...this.state, anchorEl: null })
  }

  async getLaundryClientByUserID() {
    const userID = await axios.get(`${config.API_URL}/user`).then(response => {
      return response.data.id
    })

    await axios
      .get(`${config.API_URL}/laundry-client-by-user-id/${userID}`)
      .then(resp => {
        this.setState({ ...this.state, laundryClient: resp.data })
      })
      .catch(e => console.log(e.message))
  }

  render() {
    const {
      classes,
      open,
      handleDrawerOpen,
      handleDrawerClose,
      loading,
      user,
      logout,
    } = this.props

    const active = loading || ''

    const { openFinance, openRelatory, openDashboard, openCrm } = this.state

    // const anchorRef = useState(false)

    return (
      <div className={classes.root}>
        <div className={`progress ${active}`}>
          <div className={classes.imgContainer}>
            <div>
              <img
                className={classes.logo}
                src="/img/loading.gif"
                alt="Loading"
              />
            </div>
          </div>
        </div>
        <AppBar
          color="inherit"
          position="fixed"
          className={classNames(classes.appBar, {
            [classes.appBarShift]: open,
          })}
        >
          <Toolbar variant="dense">
            <IconButton
              aria-label="Open drawer"
              onClick={() => {
                open ? handleDrawerClose() : handleDrawerOpen()
              }}
              className={classes.menuButton}
            >
              {open ? <ChevronLeftIcon /> : <ChevronRight />}
            </IconButton>

            <div className={classes.grow} />
            <Box className={classes.buttons}>
              <MenulistComposition userName={user?.name} />
            </Box>

            <Divider
              orientation="vertical"
              variant="middle"
              style={{ height: '30px', background: '#FFF' }}
            />

            <IconButton onClick={logout} className={classes.logoutButton}>
              Sair
              <ExitToAppIcon style={{ marginLeft: '4px' }} />
            </IconButton>
          </Toolbar>
        </AppBar>

        <Drawer
          variant="permanent"
          className={classNames(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: classNames({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
        >
          <Box className={classes.drawerHeader} component={Link} to="/">
            {open ? (
              <img src={FullLogo} alt="Logo higsystem" />
            ) : (
              <img src={ShortLogo} alt="Logo higsystem" />
            )}
          </Box>
          <Divider />
          <Icons
            classes={classes}
            open={this.state.open}
            openRelatory={openRelatory}
            openFinance={openFinance}
            openCrm={openCrm}
            handleClickCrm={this.handleClickCrm}
            handleDrawerClose={handleDrawerClose}
            handleClick={this.handleClick}
            handleClickRelatory={this.handleClickRelatory}
            handleClickFinance={this.handleClickFinance}
            drawerOpen={open}
            handleClickDashboard={this.handleClickDashboard}
            openDashboard={openDashboard}
            handleDrawerOpen={handleDrawerOpen}
            openDrawer={open}
          />
        </Drawer>
      </div>
    )
  }
}

Menu.propTypes = {
  classes: PropTypes.object.isRequired,
  anchorEl: PropTypes.object,
}

const mapStateToProps = state => ({
  user: state.auth.user || [],
  loading: state.main.loadingCircle,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      logout,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(styles)(Menu)))
