import React, { useEffect, useState } from 'react'
import { Grid, IconButton, MenuItem, makeStyles } from '@material-ui/core'
import { mdiReceiptTextOutline } from '@mdi/js'
import { Link } from 'react-router-dom/cjs/react-router-dom'
import colors from '../../../assets/colors'
import Icon from '@mdi/react'
import axios from 'axios'
import config from '../../../config'

const useStyles = makeStyles({
  menuItem: {
    padding: '0 2px',
    height: 'auto',
    lineHeight: '1',
    transition: 'all .4s ease-in-out',
    '&:hover': {
      backgroundColor: 'inherit',
      color: colors.primary,
    },
  },
  menuItemLi: {
    padding: 0,
    margin: 0,
    borderRadius: 0,
    transition: 'all .4s ease-in-out',
    '&:hover': {
      backgroundColor: 'inherit',
      color: colors.primary,
    },
    fontSize: '14px',
  },
  menuItemLiIcon: {
    marginRight: '12px',
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    color: 'inherit',
    textDecoration: 'none',
  },
})

const DonwloadNFSe = ({ idLaundryClient }) => {
  const classes = useStyles()

  const [nfseLink, setNfseLink] = useState()

  const nfseLinkSearch = async () => {
    try {
      const resp = await axios.get(
        `${config.API_URL}/eletronic-invoice/nfse/${idLaundryClient}`
      )

      setNfseLink(resp?.data?.data)
    } catch (e) {
      //
    }
  }

  useEffect(() => {
    if (idLaundryClient) {
      nfseLinkSearch()
    }
  }, [])

  return (
    <>
      <MenuItem className={classes.menuItem}>
        <IconButton
          aria-label="eletronic-invoice"
          className={classes.menuItemLi}
          disabled={!nfseLink}
        >
          <a
            href={nfseLink?.url_danfse}
            target="_blank"
            className={classes.link}
          >
            <Icon
              path={mdiReceiptTextOutline}
              size={1}
              className={classes.menuItemLiIcon}
            />
            Visualizar Nota
          </a>
        </IconButton>
      </MenuItem>
    </>
  )
}

export default DonwloadNFSe
