import React from 'react'
import { Forms } from '..'
import { Grid, MenuItem, TextField } from '@material-ui/core'
import ReactInputMask from 'react-input-mask'

const FormContact = ({ form, validatedForm, handleChange = () => {} }) => {
  return (
    <Forms.root>
      <Grid item xs={12} sm={6} md={3}>
        <TextField
          label="E-mail"
          name="email"
          type="email"
          fullWidth
          required
          value={form.email}
          onChange={handleChange}
          error={validatedForm.email}
          helperText={validatedForm.email}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <ReactInputMask
          mask="(99) 99999-9999"
          name="telefone"
          maskChar=""
          value={form?.telefone}
          onChange={handleChange}
        >
          {inputProps => (
            <TextField
              {...inputProps}
              fullWidth
              required
              label="Telefone"
              error={validatedForm.telefone}
              helperText={validatedForm.telefone}
            />
          )}
        </ReactInputMask>
      </Grid>
    </Forms.root>
  )
}

export default FormContact
