import React from 'react'
import BaseFilterModal from '../../../components/hooks/BaseFilterModal'
import { Button, Grid, MenuItem, TextField } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'
import filterStylesComponents from '../../../assets/filterComponentsStyles'
import { archive, fetchAttendanceInit } from '../AttendanceActions'

const ArchiveOrcament = props => {
  const history = useHistory()
  const dispatch = useDispatch()
  const classes = filterStylesComponents()
  const archiveReason = useSelector(state => state.attendance.archiveReason)
  const sizePerPage = useSelector(state => state.attendance.sizePerPage)
  const filters = useSelector(state => state.attendance.filters)

  const { id } = props.match.params

  const handleClose = () => {
    history.push('/attendance')
  }

  const handleSubmit = async e => {
    e.preventDefault()

    const formData = e.currentTarget
    const data = new FormData(formData)
    const entries = data.entries()
    const values = Object.fromEntries(entries)

    try {
      const resp = await dispatch(archive(values, id))

      if (resp) {
        dispatch(fetchAttendanceInit(filters, sizePerPage))
        handleClose()
      }
    } catch (e) {
      //
    }
  }
  return (
    <BaseFilterModal
      open
      title="Arquivar OS"
      hiddenHelpButton
      handleOpen={handleClose}
    >
      <Grid item xs={12} style={{ width: '260px' }}>
        <form style={{ width: '100%', height: '100%' }} onSubmit={handleSubmit}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <TextField
                select
                name="archive_reason"
                variant="standard"
                fullWidth
                label="Motivo"
                required
              >
                <MenuItem value="">Selecione...</MenuItem>

                {archiveReason?.map((trigger, index) => (
                  <MenuItem value={trigger?.id} key={index}>
                    {trigger?.reason}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid
              item
              xs={12}
              container
              alignItems="center"
              justifyContent="flex-end"
            >
              <Button
                type="button"
                onClick={handleClose}
                className={classes.btnMoreFilters}
              >
                Cancelar
              </Button>

              <Button type="submit" className={classes.btnAdd}>
                Salvar
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </BaseFilterModal>
  )
}

export default ArchiveOrcament
