/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-return-assign */
/* eslint-disable prettier/prettier */
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import PencilIcon from '@material-ui/icons/Edit'
import VpnKeyIcon from '@material-ui/icons/VpnKey'
import EyeIcon from '@material-ui/icons/Visibility'
import PropTypes from 'prop-types'
import React, { useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { renderRoute } from '../../../util/utils'
import { loginUsingId } from '../../auth/AuthActions'
import {
  permissionsKey,
  roleKey,
  tokenKey,
  userKey,
} from '../../auth/AuthReducer'
import {
  StyledTableCellBody,
  StyledTableCellHeader,
} from '../../../components/hooks/BaseTableComponents'
import Icon from '@mdi/react'
import {
  mdiAccountKey,
  mdiCircleMedium,
  mdiDotsVertical,
  mdiEye,
  mdiNoteEditOutline,
} from '@mdi/js'
import statusColors from '../../../assets/statusColors'
import colors from '../../../assets/colors'
import {
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Popper,
} from '@material-ui/core'

function createData(
  id,
  name,
  email,
  role,
  laundry_client_name,
  online,
  status
) {
  return { id, name, email, role, laundry_client_name, online, status }
}

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map(el => el[0])
}

function getSorting(order, orderBy) {
  return order === 'desc'
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy)
}

const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Nome',
  },
  {
    id: 'E-mail',
    numeric: true,
    disablePadding: false,
    label: 'Email',
  },
  {
    id: 'laundry_client_name',
    numeric: true,
    disablePadding: false,
    label: 'Cliente Lavanderia',
  },
  {
    id: 'role',
    numeric: true,
    disablePadding: false,
    label: 'Tipo',
  },
  {
    id: 'actions',
    numeric: true,
    disablePadding: false,
    label: 'Ações',
  },
]

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props
  const createSortHandler = property => event => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        <StyledTableCellHeader padding="checkbox" />
        {headCells.map(headCell => (
          <StyledTableCellHeader
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={order}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </StyledTableCellHeader>
        ))}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  tableStickHeader: {
    maxHeight: 'calc(100vh - 200px)',
  },

  tableRow: {
    '&.Mui-selected, &.Mui-selected:hover': {
      backgroundColor: 'rgba(152, 251, 152, 0.5)',
    },
  },
  ref: {
    width: '34px',
    height: '34px',
    position: 'absolute',
    backgroundColor: '#FFFF',
    transform: 'rotate(45deg)',
    right: '7px',
    '&:hover': {
      backgroundColor: '#FFFF',
    },
  },
  menuList: {
    padding: '10px',
  },
  menuItem: {
    padding: '0 2px',
    height: 'auto',
    lineHeight: '1',
    transition: 'all .4s ease-in-out',
    '&:hover': {
      backgroundColor: 'inherit',
      color: colors.primary,
    },
  },
  menuItemLi: {
    padding: 0,
    margin: 0,
    borderRadius: 0,
    transition: 'all .4s ease-in-out',
    '&:hover': {
      backgroundColor: 'inherit',
      color: colors.primary,
    },
    fontSize: '14px',
  },
  menuItemLiIcon: {
    marginRight: '12px',
  },
}))

export default function List(props) {
  const { permissions, items } = props
  const role = useSelector(state => state.auth.role) || {}

  const classes = useStyles()
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('value')
  const [selected, setSelected] = useState([])
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const [open, setOpen] = useState({
    id: '',
  })

  const anchorRefs = useRef([])

  const handleToggle = id => {
    if (open?.id === id) {
      return setOpen({
        id: '',
      })
    }

    setOpen({
      ...open,
      id: id,
    })
  }

  const handleClose = (event, index) => {
    if (
      anchorRefs.current[index] &&
      anchorRefs.current[index].contains(event.target)
    ) {
      return
    }

    setOpen({
      id: '',
    })
  }

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen({
        id: '',
      })
    }
  }

  const rows = []

  items.forEach(item => {
    rows.push(
      createData(
        item.id,
        item.name,
        item.email,
        item.role,
        item.laundry_client_name,
        item.logged,
        item.status
      )
    )
  })

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === 'desc'
    setOrder(isDesc ? 'asc' : 'desc')
    setOrderBy(property)
  }

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n.name)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }
  const handleLoginUsingId = async id => {
    const response = await loginUsingId(id)

    localStorage.setItem(tokenKey, JSON.stringify(response.userKey))
    localStorage.setItem(userKey, JSON.stringify(response.data.item))
    localStorage.setItem(
      permissionsKey,
      JSON.stringify(response.data.permissions)
    )
    localStorage.setItem(roleKey, JSON.stringify(response.data.userRole))

    window.location.reload()
  }

  const isSelected = name => selected.indexOf(name) !== -1

  return (
    <>
      <TableContainer className={classes.tableStickHeader}>
        <Table
          className={classes.table}
          aria-labelledby="Tabela de Usuários"
          size="medium"
          stickyHeader
        >
          <EnhancedTableHead
            classes={classes}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
          />
          <TableBody>
            {stableSort(rows, getSorting(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const isItemSelected = isSelected(row.name)
                const labelId = `enhanced-table-checkbox-${index}`

                return (
                  <TableRow
                    hover
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={String(row.id)}
                    selected={row.online}
                    className={classes.tableRow}
                  >
                    {/* <TableCell padding="checkbox" /> */}
                    <StyledTableCellBody>
                      {row.status ? (
                        <Icon
                          path={mdiCircleMedium}
                          size={1}
                          color={colors.sucess}
                        />
                      ) : (
                        <Icon
                          path={mdiCircleMedium}
                          size={1}
                          color={colors.error}
                        />
                      )}
                    </StyledTableCellBody>
                    <StyledTableCellBody
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                    >
                      {row.name}
                    </StyledTableCellBody>
                    <StyledTableCellBody>{row.email}</StyledTableCellBody>
                    <StyledTableCellBody>
                      {row.laundry_client_name || ' - '}
                    </StyledTableCellBody>
                    <StyledTableCellBody>{row.role}</StyledTableCellBody>
                    <StyledTableCellBody>
                      <IconButton
                        ref={el => (anchorRefs.current[row?.id] = el)}
                        key={row.id}
                        id={row?.id}
                        aria-controls={
                          open?.id ? `menu-list-grow-${row.id}` : undefined
                        }
                        aria-haspopup="true"
                        onClick={() => {
                          handleToggle(row.id)
                        }}
                      >
                        <Icon path={mdiDotsVertical} size={1} />
                      </IconButton>

                      <Popper
                        open={open?.id === row?.id}
                        role={undefined}
                        anchorEl={anchorRefs.current[row?.id]}
                        transition
                        disablePortal
                        style={{
                          zIndex: 99999999,
                        }}
                        placement="bottom-end"
                      >
                        {({ TransitionProps, placement }) => (
                          <Grow
                            {...TransitionProps}
                            style={{
                              transformOrigin:
                                placement == 'bottom' ? 'left-end' : 'top-end',
                            }}
                          >
                            <Paper className={classes.paper}>
                              <ClickAwayListener onClickAway={handleClose}>
                                <MenuList
                                  autoFocusItem={open?.id}
                                  id={`menu-list-grow-${row.id}`}
                                  onKeyDown={handleListKeyDown}
                                  className={classes.menuList}
                                >
                                  <MenuItem
                                    className={classes.ref}
                                    style={
                                      placement === 'bottom-end'
                                        ? { top: '-2px' }
                                        : { bottom: '-2px' }
                                    }
                                  />

                                  {renderRoute(
                                    ['update-users'],
                                    permissions
                                  ) && (
                                    <MenuItem
                                      onClick={handleClose}
                                      className={classes.menuItem}
                                    >
                                      <IconButton
                                        aria-label="edit"
                                        component={Link}
                                        to={`/users/${row.id}/edit`}
                                        className={classes.menuItemLi}
                                      >
                                        <Icon
                                          path={mdiNoteEditOutline}
                                          className={classes.menuItemLiIcon}
                                          size={1}
                                        />
                                        Editar
                                      </IconButton>
                                    </MenuItem>
                                  )}

                                  {renderRoute(['read-users'], permissions) && (
                                    <MenuItem
                                      onClick={handleClose}
                                      className={classes.menuItem}
                                    >
                                      <IconButton
                                        aria-label="edit"
                                        component={Link}
                                        to={`/users/${row.id}/see/see`}
                                        className={classes.menuItemLi}
                                      >
                                        <Icon
                                          path={mdiEye}
                                          className={classes.menuItemLiIcon}
                                          size={1}
                                        />
                                        Visuallzar
                                      </IconButton>
                                    </MenuItem>
                                  )}

                                  {role[0].name &&
                                    role[0].name === 'administrador' && (
                                      <MenuItem
                                        onClick={handleClose}
                                        className={classes.menuItem}
                                      >
                                        <IconButton
                                          aria-label="key"
                                          component={Link}
                                          to="/"
                                          onClick={() =>
                                            handleLoginUsingId(row.id)
                                          }
                                          className={classes.menuItemLi}
                                        >
                                          <Icon
                                            path={mdiAccountKey}
                                            className={classes.menuItemLiIcon}
                                            size={1}
                                          />
                                          Login
                                        </IconButton>
                                      </MenuItem>
                                    )}
                                </MenuList>
                              </ClickAwayListener>
                            </Paper>
                          </Grow>
                        )}
                      </Popper>
                      {/* 

*/}
                    </StyledTableCellBody>
                  </TableRow>
                )
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        labelRowsPerPage="Linhas por Página"
        backIconButtonProps={{
          'aria-label': 'previous page',
        }}
        labelDisplayedRows={({ from, to, count }) =>
          `Mostrando linhas ${from}-${to} de ${count}`
        }
        nextIconButtonProps={{
          'aria-label': 'next page',
        }}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  )
}
