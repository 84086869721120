/* eslint-disable import/order */
import React, { useEffect, useState } from 'react'
import { Grid, Typography } from '@material-ui/core'
import colors from '../../../assets/colors'
import { useDispatch, useSelector } from 'react-redux'
import { fetchStock } from '../StockActions'
import Filter from './Filter'
import { Link, Route } from 'react-router-dom/cjs/react-router-dom.min'
import { renderRoute } from '../../../util/utils'
import List from './List'
import NewProduct from './product/New'
import EditProduct from './product/Edit'
import Movements from './movements'

const Stock = () => {
  const dispatch = useDispatch()
  const products = useSelector(state => state.stock.products)
  const permissions = useSelector(state => state.auth.permissions)

  useEffect(() => {
    dispatch(fetchStock())
  }, [])

  const [form, setForm] = useState({
    name: '',
    unit_measure_id: '',
    page: 0,
    per_page: 10,
  })

  useEffect(() => {
    dispatch(fetchStock(form))
  }, [form])

  return (
    <>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12}>
          <Typography
            variant="h4"
            style={{ fontSize: '20px' }}
            color={colors.primary}
          >
            Estoque
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Filter form={form} setForm={setForm} />
        </Grid>

        <Grid item xs={12}>
          <List items={products} form={form} setForm={setForm} />
        </Grid>
      </Grid>

      {renderRoute(['create-attendances'], permissions) && (
        <Route path="/stock/product/new" component={NewProduct} />
      )}

      {renderRoute(['create-attendances'], permissions) && (
        <Route path="/stock/product/edit/:id" component={EditProduct} />
      )}

      {renderRoute(['update-attendances'], permissions) && (
        <Route path="/stock/movements/:type" component={Movements} />
      )}

      {renderRoute(['create-billing-client'], permissions) && (
        <Route path="/stock/movements/:type" component={Movements} />
      )}
    </>
  )
}

export default Stock
