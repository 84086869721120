/* eslint-disable array-callback-return */
/* eslint-disable camelcase */
/* eslint-disable import/order */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import config from '../../config'
import {
  Button,
  Grid,
  Snackbar,
  Typography,
  makeStyles,
} from '@material-ui/core'
import Form from './form'
import { Alert } from '@material-ui/lab'
import { ToastContainer, toast } from 'react-toastify'
import BaseCreatePage from '../../components/hooks/BaseCreatePage'
import colors from '../../assets/colors'
import { useDispatch } from 'react-redux'
import { SNACKBAR } from '../main/MainActions'

const useStyles = makeStyles({
  root: {
    padding: '16px 12px',
    width: '100%',
  },
  form: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  subTitle: {
    padding: '8px 0',
    borderTop: '1px dashed',
    borderColor: colors.textSecondary,
    borderBottom: '1px dashed',
    borderBottomColor: colors.textSecondary,
    color: colors.textPrimary,
    fontSize: '14px',
  },
  btnSave: {
    borderRadius: '20px',
    color: '#FFF',
    backgroundColor: colors.primary,
    fontSize: '12px',
    textTransform: 'capitalize',
    width: '101px',
    padding: '4px',
    transition: 'all .4s ease-in-out',
    '&:hover': {
      backgroundColor: colors.primary,
      opacity: '.7',
    },
  },
})

const EditLaundry = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const [dataLaundry, setDataLaundry] = useState({
    id: 0,
    name: '',
    email: '',
    phone: 0,
    cpf_cnpj: 0,
    logo: '',
    description: '',
    cep: 0,
    neighborhood: '',
    street: '',
    number: '',
    complement: '',
    city: '',
    uf: '',
  })

  useEffect(() => {
    if (!dataLaundry.name) {
      const getDataLaundry = async () => {
        const resp = await axios
          .get(`${config.API_URL}/laundry-client-edit`)
          .then(resp => {
            const data = resp?.data?.data || ''

            setDataLaundry({
              ...dataLaundry,
              id: data?.id,
              name: data?.name,
              email: data?.email,
              phone: data?.phone,
              cell_phone: data?.cell_phone,
              cpf_cnpj: data?.cpf_cnpj,
              logo: data?.logo,
              description: data?.description,
              cep: data?.cep,
              neighborhood: data?.neighborhood,
              street: data?.street,
              number: data?.number,
              complement: data?.complement,
              city: data?.city,
              uf: data?.uf,
            })
          })
      }
      getDataLaundry()
    }
  }, [])

  const handleSubmit = async e => {
    e.preventDefault()

    const form = e.currentTarget
    const data = new FormData(form)
    const entries = data.entries()
    const values = Object.fromEntries(entries)

    try {
      const resp = await axios.post(
        `${config.API_URL}/laundry-client-edit`,
        data,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      )

      dispatch({
        type: SNACKBAR.SOFTSUCCESS,
        success: { message: 'Atualizado com sucesso!' },
      })
    } catch (e) {
      if (e.response?.data?.errors) {
        Object.values(e.response?.data?.errors).map(errors =>
          errors.map(e => {
            dispatch({
              type: SNACKBAR.HARDFAIL,
              error: { message: e },
            })
          })
        )
      } else if (e.response?.data?.message) {
        dispatch({
          type: SNACKBAR.HARDFAIL,
          error: { message: e.response?.data?.message },
        })
      }
    }
  }

  return (
    <>
      <BaseCreatePage page="Lavanderia" path="Editar" pageLink="">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid
              container
              alignContent="center"
              justifyContent="flex-start"
              className={classes.subTitle}
            >
              <Grid
                item
                xs={12}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                Utilize os campos abaixo para editar a lavanderia
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <form onSubmit={handleSubmit} className={classes.form}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Form data={dataLaundry} />
                </Grid>

                <Grid item xs={12}>
                  <Grid container alignItems="center" justifyContent="center">
                    <Button type="submit" className={classes.btnSave}>
                      Salvar
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </BaseCreatePage>
    </>
  )
}

export default EditLaundry
