/* eslint-disable import/order */
import AppBar from '@material-ui/core/AppBar'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import Slide from '@material-ui/core/Slide'
import withStyles from '@material-ui/core/styles/withStyles'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import styles from '../../../resources/theme/users'
import { removeEmpty } from '../../../util/utils'
import EnhancedComponent from '../../components/EnhancedComponent'
import { edit, fetchUsers, update } from '../UserActions'
import Form from './Form'
import BaseCreatePage from '../../../components/hooks/BaseCreatePage'
import { Grid } from '@material-ui/core'
import colors from '../../../assets/colors'

function Transition(props) {
  return <Slide direction="down" {...props} />
}

class EditUser extends EnhancedComponent {
  componentDidMount() {
    this.props.edit(this.props.match.params.id)
  }

  onSubmit = values => {
    this.props.update(values).then(data => {
      if (data && data.code === 200) {
        this.props.history.push('/users')
      }
    })
  }

  onClose = () => {
    this.props.history.push('/users')
  }

  render() {
    const { classes, user } = this.props
    const { see } = this.props.match.params

    return (
      <BaseCreatePage
        page="Usuários"
        path="Editar"
        onClose={this.onClose}
        pageLink="/users"
      >
        <Grid
          container
          xs={12}
          alignContent="center"
          justifyContent="flex-start"
          style={{
            padding: '8px 0',
            borderTop: '1px dashed',
            borderColor: colors.textSecondary,
            borderBottom: '1px dashed',
            borderBottomColor: colors.textSecondary,
            color: colors.textPrimary,
            fontSize: '14px',
          }}
        >
          <Grid
            item
            xs={12}
            style={{
              display: 'flex',
              alignItems: 'center',
              height: 'auto',
              fontWeight: 'normal',
            }}
          >
            Utilize os campos abaixo para editar um usuário
          </Grid>
        </Grid>

        <Form
          onClose={this.onClose}
          onSubmit={this.onSubmit}
          btnText="Salvar"
          data={user}
          see={see}
        />
      </BaseCreatePage>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user.editingUser || [],
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      update,
      edit,
      fetchUsers,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(EditUser))
