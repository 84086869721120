/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Popover from '@material-ui/core/Popover'
import Tooltip from '@material-ui/core/Tooltip'
import FilterListIcon from '@material-ui/icons/FilterList'
import SearchIcon from '@material-ui/icons/Search'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { ValidatorForm } from 'react-material-ui-form-validator'
import { format, set } from 'date-fns'
import ClearIcon from '@material-ui/icons/Clear'

export const Filter = ({
  filters,
  setFilters,
  getFirstDayOfPeriod,
  getLastDayOfPeriod,
  date,
}) => {
  // eslint-disable-next-line no-unused-vars

  const [currentFilters, setCurrentFilters] = useState(null)

  const [anchorEl, setAnchorEl] = useState(null)

  const [open, setOpen] = useState(false)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
    setOpen(true)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setOpen(false)
  }

  useEffect(() => {
    if (!currentFilters) {
      setCurrentFilters(filters)
    }
  }, [filters, currentFilters])

  const handleDataInitialChange = data => {
    if (data == 'Invalid Date' || !data) return

    const date = format(data, 'yyyy-MM-dd ')

    setCurrentFilters({
      ...currentFilters,
      initialDate: date,
    })
  }

  const handleDataFinalChange = data => {
    if (data == 'Invalid Date' || !data) return

    const date = format(data, 'yyyy-MM-dd ')

    setCurrentFilters({
      ...currentFilters,
      finalDate: date,
    })
  }

  const handleSubmit = e => {
    e.preventDefault()
    setFilters({ ...currentFilters })
    setOpen(false)
  }

  const handleClearFilter = e => {
    e.preventDefault()
    setCurrentFilters({
      initialDate: format(getFirstDayOfPeriod(date), 'yyyy-MM-dd '),
      finalDate: format(getLastDayOfPeriod(date), 'yyyy-MM-dd '),
    })
    setFilters({
      initialDate: format(getFirstDayOfPeriod(date), 'yyyy-MM-dd '),
      finalDate: format(getLastDayOfPeriod(date), 'yyyy-MM-dd '),
    })
    setOpen(false)
  }

  const id = open ? 'simple-popover' : undefined

  return (
    <>
      <Tooltip title="Filtro">
        <IconButton
          aria-label="filter list"
          aria-controls="filter-popover"
          aria-haspopup="true"
          onClick={handleClick}
          aria-describedby={id}
          variant="contained"
        >
          <FilterListIcon />
        </IconButton>
      </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box p={2}>
          <ValidatorForm>
            <Grid container spacing={2} justify="center" alignItems="center">
              <Grid item xs={12} sm={6}>
                <KeyboardDatePicker
                  clearable
                  value={currentFilters?.initialDate}
                  label="Data inicial"
                  fullWidth
                  clearLabel="Limpar"
                  invalidDateMessage="Data inválida"
                  autoOk
                  margin="dense"
                  inputVariant="outlined"
                  format="dd/MM/yyyy"
                  onChange={data => handleDataInitialChange(data)}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <KeyboardDatePicker
                  clearable
                  value={currentFilters?.finalDate}
                  label="Data final"
                  fullWidth
                  clearLabel="Limpar"
                  invalidDateMessage="Data inválida"
                  autoOk
                  margin="dense"
                  inputVariant="outlined"
                  format="dd/MM/yyyy"
                  onChange={data => handleDataFinalChange(data)}
                />
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Button
                    type="button"
                    color="primary"
                    fullWidth
                    variant="contained"
                    onClick={e => handleSubmit(e)}
                  >
                    <SearchIcon />
                    {` Filtrar`}
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    type="button"
                    color="primary"
                    fullWidth
                    variant="contained"
                    onClick={e => handleClearFilter(e)}
                  >
                    <ClearIcon />
                    {` Limpar `}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </ValidatorForm>
        </Box>
      </Popover>
    </>
  )
}
