// import IconButton from '@material-ui/core/IconButton'
// import Paper from '@material-ui/core/Paper'
// import Table from '@material-ui/core/Table'
// import TableBody from '@material-ui/core/TableBody'
// import TableCell from '@material-ui/core/TableCell'
// import TableHead from '@material-ui/core/TableHead'
// import TableRow from '@material-ui/core/TableRow'
// import PencilIcon from '@material-ui/icons/Edit'
// import React, { useState, useEffect } from 'react'
// import { Link } from 'react-router-dom'
// import { useDispatch } from 'react-redux'
// import { fetchClients } from '../ClientActions'

// import consts from '../../../contst'
// import Paginate from '../../components/Pagination'

// function List(props) {

//   const { classes, items } = props
//   const dispatch = useDispatch()
//   const [rowsPerPage, setRowsPerPage] = useState(consts.DEFAULT_PAGINATION)

//   const handleChangeRowsPerPage = event => {
//     setRowsPerPage(event.target.value)
//   }

//   useEffect(() => {
//     onMovePage(null, null, 0);
//   }, [rowsPerPage])

//   const onMovePage = (event, newPage) => {
//     dispatch(fetchClients(newPage + 1, props.filters, rowsPerPage))
//   }

//   return (
//     <Paper className={classes.content} elevation={1}>
//       <Table>
//         <TableHead>
//           <TableRow>
//             <TableCell>Id</TableCell>
//             <TableCell>Nome</TableCell>
//             <TableCell>Email</TableCell>
//             <TableCell>Ações</TableCell>
//           </TableRow>
//         </TableHead>
//         <TableBody>
//           {items.map(item => (
//             <TableRow key={item.id}>
//               <TableCell>{item.id}</TableCell>
//               <TableCell>{item.name}</TableCell>
//               <TableCell>{item.email}</TableCell>
//               <TableCell>
//                 <IconButton
//                   component={Link}
//                   to={`/clients/${item.id}/edit`}
//                 >
//                   <PencilIcon fontSize="small" />
//                 </IconButton>
//               </TableCell>
//             </TableRow>
//           ))}
//         </TableBody>
//       </Table>
//       <Paginate
//         handleChangeRowsPerPage={handleChangeRowsPerPage}
//         rowsPerPage={rowsPerPage}
//         rows={pr.total}
//         page={items.current_page - 1}
//         handleChangePage={onMovePage} />
//     </Paper>
//   )
// }

// export default List

// import IconButton from '@material-ui/core/IconButton'
// import Paper from '@material-ui/core/Paper'
// import Table from '@material-ui/core/Table'
// import TableBody from '@material-ui/core/TableBody'
// import TableCell from '@material-ui/core/TableCell'
// import TableHead from '@material-ui/core/TableHead'
// import TableRow from '@material-ui/core/TableRow'
// import PencilIcon from '@material-ui/icons/Edit'
// import React, { useState, useEffect } from 'react'
// import { Link } from 'react-router-dom'
// import { useDispatch } from 'react-redux'
// import { fetchClients } from '../ClientActions'

// import consts from '../../../contst'
// import Paginate from '../../components/Pagination'

// function List(props) {

//   const { classes, items } = props
//   const dispatch = useDispatch()
//   const [rowsPerPage, setRowsPerPage] = useState(consts.DEFAULT_PAGINATION)

//   const handleChangeRowsPerPage = event => {
//     setRowsPerPage(event.target.value)
//   }

//   useEffect(() => {
//     onMovePage(null, null, 0);
//   }, [rowsPerPage])

//   const onMovePage = (event, newPage) => {
//     dispatch(fetchClients(newPage + 1, props.filters, rowsPerPage))
//   }

//   return (
//     <Paper className={classes.content} elevation={1}>
//       <Table>
//         <TableHead>
//           <TableRow>
//             <TableCell>Id</TableCell>
//             <TableCell>Nome</TableCell>
//             <TableCell>Email</TableCell>
//             <TableCell>Ações</TableCell>
//           </TableRow>
//         </TableHead>
//         <TableBody>
//           {items.map(item => (
//             <TableRow key={item.id}>
//               <TableCell>{item.id}</TableCell>
//               <TableCell>{item.name}</TableCell>
//               <TableCell>{item.email}</TableCell>
//               <TableCell>
//                 <IconButton
//                   component={Link}
//                   to={`/clients/${item.id}/edit`}
//                 >
//                   <PencilIcon fontSize="small" />
//                 </IconButton>
//               </TableCell>
//             </TableRow>
//           ))}
//         </TableBody>
//       </Table>
//       <Paginate
//         handleChangeRowsPerPage={handleChangeRowsPerPage}
//         rowsPerPage={rowsPerPage}
//         rows={pr.total}
//         page={items.current_page - 1}
//         handleChangePage={onMovePage} />
//     </Paper>
//   )
// }

// export default List

import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import PencilIcon from '@material-ui/icons/Edit'
import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import consts from '../../../util/consts'
import Paginate from '../../components/Pagination'
import { fetchClients } from '../ClientActions'
import {
  StyledTableCellBody,
  StyledTableCellHeader,
} from '../../../components/hooks/BaseTableComponents'
import Icon from '@mdi/react'
import { mdiAccountBoxEditOutline, mdiNoteEditOutline, mdiPlaylistEdit } from '@mdi/js'

function List(props) {
  const dispatch = useDispatch()
  const filters = useSelector(state => state.client.filters)
  const [rowsPerPage, setRowsPerPage] = useState(consts.DEFAULT_PAGINATION)
  const { classes, items, totalSize, page } = props

  const onMovePage = (event, newPage) => {
    dispatch(fetchClients(newPage + 1, filters, rowsPerPage))
  }

  useEffect(() => {
    onMovePage()
  }, [rowsPerPage])

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value)
  }

  return (
    <>
      <TableContainer
        className={classNames({
          [classes.tableContainer]: true,
          [classes.tableStickHeader]: true,
        })}
        style={{ marginTop: '1.5em' }}
      >
        <Table stickyHeader size="medium" className={classes.tableStyle}>
          <TableHead>
            <TableRow>
              <StyledTableCellHeader>Cliente</StyledTableCellHeader>
              <StyledTableCellHeader>Telefone</StyledTableCellHeader>
              <StyledTableCellHeader>Bairro</StyledTableCellHeader>
              <StyledTableCellHeader>Email</StyledTableCellHeader>
              <StyledTableCellHeader>Aniversário</StyledTableCellHeader>
              <StyledTableCellHeader>Ações</StyledTableCellHeader>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map(item => (
              <TableRow key={item.id}>
                <StyledTableCellBody>{item.name}</StyledTableCellBody>
                <StyledTableCellBody>{item.phone}</StyledTableCellBody>
                <StyledTableCellBody>{item.neighborhood}</StyledTableCellBody>
                <StyledTableCellBody>{item.email}</StyledTableCellBody>
                <StyledTableCellBody>{item.birthdate}</StyledTableCellBody>
                <StyledTableCellBody>
                  <IconButton component={Link} to={`/clients/${item.id}/edit`}>
                    <Icon path={mdiNoteEditOutline} size={1} />
                  </IconButton>
                </StyledTableCellBody>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Paginate
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        rowsPerPage={rowsPerPage}
        rows={totalSize}
        page={page - 1}
        handleChangePage={onMovePage}
      />
    </>
  )
}

export default List
