/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'
import IconButton from '@material-ui/core/IconButton'
import RestoreIcon from '@material-ui/icons/Restore'
import Tooltip from '@material-ui/core/Tooltip'
import {
  Box,
  DialogContentText,
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core'
import { Link } from 'react-router-dom'
import { format } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import Icon from '@mdi/react'
import { mdiCalendarAccount, mdiCalendarClock, mdiClose } from '@mdi/js'
import colors from '../../../assets/colors'

// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="up" ref={ref} {...props} />
// })

const useStyles = makeStyles({
  '@keyframes fadeIn': {
    '0%': {
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    },
  },
  box: {
    position: 'absolute',
    width: '226px',
    // height: '120px',
    borderRadius: '0 5px 5px 5px',
    boxShadow: '0px 4px 5px 0px #00000080',
    animation: '$fadeIn .4s ease-in-out',
    backgroundColor: '#FFF',
    zIndex: 9999999999999,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '8px',
    padding: '8px 20px',
    position: 'relative',
    width: '100%',
    backgroundColor: colors.graylight,
    borderRadius: '5px 5px 0 0',
    '&:before': {
      content: "''",
      width: '12px',
      height: '12px',
      padding: '4px',
      transform: 'rotate(45deg)',
      borderRadius: '8px 0 0 0',
      backgroundColor: colors.graylight,
      position: 'absolute',
      left: '-2px',
      top: '-1px',
    },
  },
  headerTypography: {
    color: colors.gray,
    fontSize: '10px',
  },
  btnClose: {
    borderRadius: 'none',
    backgroundColor: 'inherit',
    position: 'absolute',
    padding: 0,
    margin: 0,
    top: '4px',
    right: '4px',
    '&:hover': {
      backgroundColor: 'inherit',
    },
  },
  body: {
    padding: '0 20px 8px 20px',
    color: colors.gray,
    fontSize: '10px',
    lineHeight: '12px',
  },
  btnDesmarcar: {
    color: colors.gray,
    fontSize: '10px',
    borderRadius: '5px',
    padding: '4px 8px',
    margin: 0,
    backgroundColor: colors.graylight,
    textAlign: 'center',
  },
})

const Modal = ({
  open,
  handleClose,
  elementPosition,
  attendants,
  currentAttendant,
  currentEvent,
  changeAttendant,
  handleCancel,
}) => {
  const classes = useStyles()

  const disabled = useMemo(() => {
    if (!currentEvent?.copy) return currentEvent?.lastStatus?.status_id > 3
    return currentEvent?.lastStatus?.status_id > 8
  }, currentEvent)

  const renderOsButton = () => {
    return (
      <IconButton
        component={Link}
        color="inherit"
        to={
          currentEvent.isLaundry
            ? `/laundry-os/${currentEvent.serviceId}/details`
            : `/attendance/${currentEvent.serviceId}/edit`
        }
        className={classes.btnDesmarcar}
      >
        OS: {currentEvent.service_id_laundry_client}
      </IconButton>
    )
  }

  const getEventDate = () => {
    if (currentEvent?.start) {
      const currentDate = new Date(currentEvent?.start)

      return format(currentDate, 'dd/MM/yyyy', { locale: ptBR })
    }

    return ''
  }

  const getEventTime = () => {
    if (currentEvent?.start && currentEvent?.end) {
      const eventStart = new Date(currentEvent?.start)
      const eventEnd = new Date(currentEvent?.end)

      const formattedTimeStart = format(eventStart, "HH'h'mm", { locale: ptBR })
      const formattedTimeEnd = format(eventEnd, "HH'h'mm", { locale: ptBR })

      return `De ${formattedTimeStart} às ${formattedTimeEnd}`
    }

    return ''
  }

  return (
    <>
      {open && (
        <Box
          className={classes.box}
          style={{
            left: elementPosition.x + 10,
            top: elementPosition.y,
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Box className={classes.header}>
                <Icon
                  path={mdiCalendarClock}
                  size={1}
                  color={colors.secondary}
                />

                <Typography className={classes.headerTypography}>
                  {getEventDate()}
                </Typography>

                <Typography className={classes.headerTypography}>
                  {getEventTime()}
                </Typography>

                <IconButton onClick={handleClose} className={classes.btnClose}>
                  <Icon path={mdiClose} size={0.8} color={colors.primary} />
                </IconButton>
              </Box>
            </Grid>
            {currentEvent?.isTechnicalAbsence ? (
              <>
                <Grid item xs={12}>
                  <Grid container className={classes.body} spacing={1}>
                    <Grid item xs={12}>
                      <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Grid item>
                          <Icon
                            path={mdiCalendarAccount}
                            size={1}
                            color={colors.secondary}
                          />
                        </Grid>
                        <Grid item>Ausente no período</Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      Técnico: {currentEvent.attendant?.name}
                    </Grid>
                    <Grid item xs={12}>
                      Motivo: {currentEvent?.absenceReason}
                    </Grid>
                  </Grid>
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={12}>
                  <Grid container className={classes.body}>
                    <Grid item xs={12}>
                      Bairro: {currentEvent.title_json?.bairro}
                    </Grid>
                    <Grid item xs={12}>
                      Item: {currentEvent.title_json?.item}
                    </Grid>
                    <Grid item xs={12}>
                      Cliente: {currentEvent.title_json?.client}
                    </Grid>
                    <Grid item xs={12}>
                      Vendedor: {currentEvent.title_json?.vendedor}
                    </Grid>
                    <Grid item xs={12}>
                      Técnico: {currentEvent.attendant?.name}
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Grid
                    container
                    spacing={1}
                    alignItems="center"
                    justifyContent="center"
                    style={{ padding: '4px 20px' }}
                  >
                    <Grid item xs>
                      {currentEvent?.service_id_laundry_client &&
                        renderOsButton()}
                    </Grid>

                    <Grid item xs>
                      <IconButton
                        color="inherit"
                        onClick={() => {
                          handleClose()
                          handleCancel(
                            currentEvent.id,
                            currentEvent.copy || null
                          )
                        }}
                        disabled={disabled}
                        className={classes.btnDesmarcar}
                      >
                        Desmarcar
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </Box>
      )}
    </>
  )
}

export default React.memo(Modal, (prevProps, nextProps) => {
  return (
    prevProps.currentEvent === nextProps.currentEvent &&
    prevProps.open === nextProps.open
  )
})
