import React, { useEffect, useState } from 'react'
import colors from '../../../assets/colors'
import Chart from 'react-google-charts'

const PieChart = ({ data = [] }) => {
  const [graphicData, setGraphicData] = useState([])

  useEffect(() => {
    if (data) {
      setGraphicData([])

      data?.map(element => {
        setGraphicData(prevState => [
          ...prevState,
          [element?.name, element?.qtd],
        ])
      })
    }
  }, [data])

  return (
    <Chart
      data={[['Tipo', 'Quantidade'], ...graphicData]}
      options={{
        is3D: true,
        chartArea: {
          left: 5,
          top: 0,
          width: '100%',
          height: '100%',
        },
        legend: {
          alignment: 'center',
          textStyle: {
            color: colors.primary,
            fontName: 'Roboto',
            fontSize: 14,
          },
        },
      }}
      height="230px"
      width="100%"
      chartType="PieChart"
    />
  )
}

export default PieChart
