/* eslint-disable import/order */
/* eslint-disable no-unused-vars */
import React from 'react'
import { Box, Button, makeStyles } from '@material-ui/core'

import Icon from '@mdi/react'
import { mdiAccountEdit } from '@mdi/js'

import colors from '../../../assets/colors'

const useStyles = makeStyles({
  root: {
    border: `1px solid ${colors.graylight}`,
    borderTop: `5px solid ${colors.secondary}`,
    borderRadius: '0',
    fontWeight: '700',
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
    textTransform: 'capitalize',
    color: colors.primary,
    flexDirection: 'row',
    gap: '8px',
    position: 'relative',
    zIndex: 9999999,
    animation: '$fadeIn 1s ease-in-out',
    padding: '8px 18px',
    '&::before': {
      content: '""',
      position: 'absolute',
      bottom: '-4px',
      left: 0,
      width: '100%',
      height: '4px',
      backgroundColor: '#FFF',
    },
    '& .MuiButton-label': {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
    },
  },
  btnDisabled: {
    border: `1px solid transparent`,
    borderTop: `5px solid transparent`,
    borderRadius: '0',
    fontWeight: '400',
    fontSize: '14px',
    display: 'flex',
    opacity: '.5',
    alignItems: 'center',
    color: colors.grayMedium,
    flexDirection: 'row',
    gap: '8px',
    padding: '8px 18px',
    position: 'relative',
    zIndex: 9999999,
    '& .MuiButton-label': {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
    },
  },

  '@keyframes fadeIn': {
    '0%': {
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    },
  },
})

const Tab = ({
  label,
  icon,
  value,
  setValue,
  tabIndex = 0,
  iconColor = null,
  removeNavigation = true,
  children,
}) => {
  const classes = useStyles()

  const handleClick = e => {
    e.preventDefault()

    setValue(tabIndex)
  }

  return (
    <Button
      key={tabIndex}
      onClick={handleClick}
      className={tabIndex === value ? classes.root : classes.btnDisabled}
      style={
        iconColor
          ? {
              border: `1px solid ${colors.graylight}`,
              borderTop: `5px solid ${iconColor}`,
            }
          : {}
      }
      variant="text"
      disabled={removeNavigation ? value < tabIndex : false}
    >
      {icon && <Icon path={icon} size={1} color={iconColor} />}
      {label}
    </Button>
  )
}

export default Tab
