/* eslint-disable eqeqeq */
/* eslint-disable import/order */
/* eslint-disable camelcase */
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import {
  StyledTableCellBody,
  StyledTableCellHeader,
} from '../../../components/hooks/BaseTableComponents'
import { useSelector } from 'react-redux'
import { addDays, format } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { exportFormatedMoney } from '../../../util/utils'
import Pagination from '../../components/Pagination'

const List = ({ form, setForm }) => {
  const services = useSelector(state => state.commissionRelatory.services)
  const meta = useSelector(state => state.commissionRelatory.meta)

  const [paginate, setPaginate] = useState({
    page: 0,
    rows: 10,
  })

  const handleChangeRowsPerPage = e => {
    const { value } = e.target

    setPaginate({
      ...paginate,
      rows: value,
    })
  }

  const handleChangePage = (event, newPage) => {
    if (paginate.page > newPage) {
      return setPaginate({
        ...paginate,
        page: newPage - 1,
      })
    }

    setPaginate({
      ...paginate,
      page: newPage + 1,
    })
  }

  useEffect(() => {
    setForm({
      ...form,
      ...paginate,
    })
  }, [paginate])

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCellHeader>Serviço</StyledTableCellHeader>
              <StyledTableCellHeader>Comissionado</StyledTableCellHeader>
              <StyledTableCellHeader align="center">OS</StyledTableCellHeader>
              <StyledTableCellHeader align="center">
                Valor
              </StyledTableCellHeader>
              <StyledTableCellHeader align="center">
                % Comissão
              </StyledTableCellHeader>
              <StyledTableCellHeader align="center">
                Comissão
              </StyledTableCellHeader>
              <StyledTableCellHeader align="center">Data</StyledTableCellHeader>
            </TableRow>
          </TableHead>
          <TableBody>
            {services?.map(item => (
              <TableRow key={item.id}>
                <StyledTableCellBody>{item?.service}</StyledTableCellBody>
                <StyledTableCellBody>{item?.comissioned}</StyledTableCellBody>
                <StyledTableCellBody align="center">
                  {item?.os}
                </StyledTableCellBody>
                <StyledTableCellBody align="center">
                  {exportFormatedMoney(item?.value)}
                </StyledTableCellBody>
                <StyledTableCellBody align="center">
                  {item?.commission_percent}%
                </StyledTableCellBody>
                <StyledTableCellBody align="center">
                  {exportFormatedMoney(item?.commission)}
                </StyledTableCellBody>
                <StyledTableCellBody align="center">
                  {item?.date}
                </StyledTableCellBody>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        rowsPerPage={paginate?.rows}
        rows={meta?.total}
        page={paginate?.page}
        handleChangePage={handleChangePage}
      />
    </>
  )
}

export default List
