/* eslint-disable import/order */
import config from '../../config'
import {
  AUTHENTICATE,
  AUTHENTICATED_USER,
  CHECK_AUTH,
  LOGOUT,
} from './AuthActions'

export const userKey = `${config.storagePrefix}_user`
export const tokenKey = `${config.storagePrefix}_token`
export const permissionsKey = `${config.storagePrefix}_permissions`
export const roleKey = `${config.storagePrefix}_role`

function returnTheme() {
  // const date = new Date()

  // if (date.getHours() <= 8 || date.getHours() >= 18) {
  //   return 'themeDark'
  // }
  return 'theme'
}

const INITIAL_STATE = {
  forgotPassword: false,
  loading: false,
  modal: false,
  user: null,
  token: '',
  permissions: [],
  role: [],
  theme: returnTheme(),
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AUTHENTICATE.ACTION:
      return { ...state, loading: true }
    case AUTHENTICATE.SUCCESS:
      localStorage.setItem(tokenKey, JSON.stringify(action.payload))
      return { ...state, token: action.payload, loading: false }
    case AUTHENTICATE.FAILURE:
      return { ...state, loading: false }
    case AUTHENTICATED_USER.SUCCESS:
      localStorage.setItem(userKey, JSON.stringify(action.payload.data.item))
      localStorage.setItem(
        permissionsKey,
        JSON.stringify(action.payload.data.permissions)
      )
      localStorage.setItem(
        roleKey,
        JSON.stringify(action.payload.data.userRole)
      )
      return {
        ...state,
        user: action.payload.data.item,
        permissions: action.payload.data.permissions,
        role: action.payload.data.userRole,
      }
    case CHECK_AUTH:
      if (typeof localStorage !== 'undefined') {
        const newState = { ...state }
        const user = localStorage.getItem(userKey)
        const permissions = localStorage.getItem(permissionsKey)
        const role = localStorage.getItem(roleKey)

        if (user) {
          newState.user = JSON.parse(user)
          newState.permissions = JSON.parse(permissions)
          newState.role = JSON.parse(role)
        }
        const token = localStorage.getItem(tokenKey)
        if (token) {
          newState.token = JSON.parse(token)
        }
        return newState
      }

      return { ...state }
    case LOGOUT.SUCCESS:
      localStorage.removeItem(userKey)
      localStorage.removeItem(tokenKey)
      localStorage.removeItem(permissionsKey)
      localStorage.removeItem(roleKey)
      return { ...state, user: null, token: null }
    case LOGOUT.FAILURE:
      localStorage.removeItem(userKey)
      localStorage.removeItem(tokenKey)
      localStorage.removeItem(permissionsKey)
      localStorage.removeItem(roleKey)
      return { ...state, user: null, token: null }
    default:
      return state
  }
}
