import React from 'react'
import {
  Button,
  Grid,
  IconButton,
  Typography,
  withStyles,
} from '@material-ui/core'
import { useDispatch } from 'react-redux'
import AndroidIcon from '@material-ui/icons/Android'
import { useConfirm } from 'material-ui-confirm'

import styles from '../../../resources/theme/global'
import { downloadAndroidApk } from '../SettingsActions'
import colors from '../../../assets/colors'
import filterStylesComponents from '../../../assets/filterComponentsStyles'

function DownloadAPK({ classes }) {
  const dispatch = useDispatch()
  const confirm = useConfirm()

  const styles = filterStylesComponents()

  const confirmDownload = () => {
    confirm({
      title: 'Baixar APK',
      description: 'Tem certeza que deseja baixar o APK do aplicativo?',
    }).then(() => {
      dispatch(downloadAndroidApk())
    })
  }

  return (
    <>
      <Grid
        container
        className={classes.pageHead}
        spacing={1}
        alignItems="center"
        style={{ marginTop: 20 }}
      >
        <Grid item xs>
          <Typography
            variant="h4"
            style={{ fontSize: '20px' }}
            color={colors.primary}
          >
            Baixar APK do aplicativo
          </Typography>
        </Grid>
        <Grid item xs={4} className={styles.areaButtons}>
          <IconButton
            className={styles.btnAdd}
            onClick={() => confirmDownload()}
            fullWidth
          >
            <AndroidIcon className={classes.btnIcon} />
            Baixar
          </IconButton>
        </Grid>
      </Grid>
    </>
  )
}

export default withStyles(styles)(DownloadAPK)
