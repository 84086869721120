import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import PencilIcon from '@material-ui/icons/Edit'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import consts from '../../../util/consts'
import Paginate from '../../components/Pagination'
import { fetchClientPlan } from '../ClientPlanActions';
import { exportFormatedMoney } from '../../../util/utils';

function List(props) {
  const dispatch = useDispatch()
  const filters = useSelector(state => state.client.filters)
  const [rowsPerPage, setRowsPerPage] = useState(consts.DEFAULT_PAGINATION)
  const { classes, items, totalSize, page } = props

  const onMovePage = (event, newPage) => {
    dispatch(fetchClientPlan(newPage + 1, filters, rowsPerPage))
  }

  useEffect(() => {
    onMovePage()
  }, [rowsPerPage])

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value)
  }

  return (
    <Paper>
      <TableContainer className={classes.tableContainer}>
        <Table size="small" className={classes.tableStyle}>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Nome</TableCell>
              <TableCell>Descrição</TableCell>
              <TableCell>Mãximo de Usuários</TableCell>
              <TableCell>Valor</TableCell>
              <TableCell>Ativo</TableCell>
              <TableCell>Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map(item => (
              <TableRow key={item.id}>
                <TableCell>{item.id}</TableCell>
                <TableCell>{item.name}</TableCell>
                <TableCell>{item.description}</TableCell>
                <TableCell>{item.max_users}</TableCell>
                <TableCell>{exportFormatedMoney(item.price)}</TableCell>
                <TableCell>{item.is_active == 1? 'Sim': 'Não'}</TableCell>
                <TableCell>
                  <IconButton component={Link} to={`/client-plan/${item.id}/edit`}>
                    <PencilIcon fontSize="small" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Paginate
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        rowsPerPage={rowsPerPage}
        rows={totalSize}
        page={page - 1}
        handleChangePage={onMovePage}
      />
    </Paper>
  )
}

export default List
