/* eslint-disable no-shadow */
import { Grid } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import NoteAddIcon from '@material-ui/icons/NoteAdd'

import styles from '../../../resources/theme/global'
import {
  fetchServiceCategoriesRelatoryInit,
  fetchServiceCategoriesRelatoryDetailsInit,
  makeRelatory,
  makeDetailsRelatory,
  clearFilter,
} from '../ServiceCategoriesRelatoryActions'
import { getServiceCategoriesRelatoryListData } from '../ServiceCategoriesRelatoryReducer'

import Filter from './Filter'
import List from './List'
import BaseRelatoryPage from '../../../components/hooks/BaseRelatoryPage'

class ServiceCategoriesRelatory extends Component {
  componentWillUnmount() {
    const { clearFilter } = this.props
    clearFilter()
  }

  makeReport = filters => {
    makeRelatory(filters)
  }

  render() {
    const { classes, makeRelatory, filters } = this.props

    return (
      <BaseRelatoryPage title="Categoria de Serviços">
        <Grid
          item
          xs="12"
          container
          className={classes.pageHead}
          spacing={1}
          alignItems="center"
          style={{ marginTop: '1em' }}
        >
          <Grid item xs={12}>
            <Filter classes={classes} />
          </Grid>
        </Grid>

        <Grid
          item
          container
          justifyContent="flex-end"
          alignItems="center"
          style={{ marginTop: '1em' }}
          xs={12}
        >
          <List {...this.props} />
        </Grid>
      </BaseRelatoryPage>
      // <Grid
      //   container
      //   className={classes.pageHead}
      //   spacing={1}
      //   alignItems="center"
      // >
      //   <Grid item xs>
      //     <Typography variant="h4" color="secondary">
      //       Relatório de Categoria de Serviços
      //     </Typography>
      //   </Grid>
      //   <Grid item>
      //   </Grid>
      //   <Grid item>
      //     <Button
      //       color="primary"
      //       type="button"
      //       onClick={() => makeRelatory(filters)}
      //     >
      //       <NoteAddIcon className={classes.btnIcon} /> Relatório
      //     </Button>
      //   </Grid>
      // </Grid>

      // <List {...this.props} />
    )
  }
}

const mapStateToProps = state => ({
  ...getServiceCategoriesRelatoryListData(state),
  permissions: state.auth.permissions || [],
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchServiceCategoriesRelatoryInit,
      fetchServiceCategoriesRelatoryDetailsInit,
      makeDetailsRelatory,
      makeRelatory,
      clearFilter,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ServiceCategoriesRelatory))
