/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/prop-types */
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Popover from '@material-ui/core/Popover'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import FilterListIcon from '@material-ui/icons/FilterList'
import SearchIcon from '@material-ui/icons/Search'
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state'
import React, { useEffect, useState } from 'react'
import InputMask from 'react-input-mask'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'
import { useDispatch, useSelector } from 'react-redux'

import BadgeGlobal from '../../components/Badge'
import { downloadImportTemplate, fetchClients } from '../ClientActions'
import BaseCreatePage from '../../../components/hooks/BaseCreatePage'
import BaseFilter from '../../../components/hooks/BaseFilter'
import { InputAdornment, Typography } from '@material-ui/core'
import {
  mdiFilterMultiple,
  mdiImport,
  mdiMagnifyExpand,
  mdiMicrosoftExcel,
  mdiNoteEditOutline,
  mdiSwapVertical,
} from '@mdi/js'
import Icon from '@mdi/react'
import { renderRoute } from '../../../util/utils'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import filterStylesComponents from '../../../assets/filterComponentsStyles'
import colors from '../../../assets/colors'
import BaseFilterModal from '../../../components/hooks/BaseFilterModal'
import { DatePicker, KeyboardDatePicker } from '@material-ui/pickers'

export default function Filter(props) {
  const items = useSelector(state => state.client.items)

  const dispatch = useDispatch()
  const { classes, permissions } = props
  const [form, setForm] = useState({
    name: '',
    email: '',
    phone: '',
    birthdate: null,
    neighborhood: '',
  })

  const [open, setOpen] = useState(false)

  const styles = filterStylesComponents()

  const handleDownloadTemplate = () => {
    dispatch(downloadImportTemplate())
  }

  const onSubmit = e => {
    e.preventDefault()
    dispatch(fetchClients(null, form))
  }

  const handleOpen = () => {
    setOpen(!open)
  }

  const handleChange = e => {
    e.preventDefault()

    const { name, value } = e.target

    setForm({
      ...form,
      [name]: value,
    })
  }

  const handleSubmit = e => {
    e.preventDefault()
    dispatch(fetchClients(null, form))
    handleOpen()
  }

  useEffect(() => {
    if (!form?.name) {
      dispatch(fetchClients(null, form))
    }
  }, [form?.name])

  useEffect(() => {
    if (!form?.phone) {
      dispatch(fetchClients(null, form))
    }
  }, [form?.phone])

  return (
    <BaseFilter>
      <Grid item xs={3}>
        <TextField
          onChange={e => setForm({ ...form, name: e.target.value })}
          label="Filtrar por nome"
          className={classes.inputFilter}
          fullWidth
          value={form.name}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton>
                  <Icon path={mdiMagnifyExpand} size={1} onClick={onSubmit} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item container xs={9} className={styles.areaButtons}>
        <Grid
          item
          container
          alignItems="center"
          direction="row"
          xs
          justifyContent="flex-end"
        >
          <Grid item>
            <IconButton onClick={handleOpen} className={styles.btnMoreFilters}>
              <Icon
                path={mdiFilterMultiple}
                className={styles.btnIcon}
                size={1}
                color={colors.secondary}
              />
              Outros filtros
            </IconButton>
          </Grid>

          <Grid item>
            <IconButton
              component={Link}
              to="/clients/import"
              className={styles.btnMoreFilters}
            >
              <Icon path={mdiSwapVertical} size={1} color={colors.secondary} />
              Importar Clientes
            </IconButton>
          </Grid>

          <Grid item>
            <Button
              onClick={handleDownloadTemplate}
              className={styles.downloadTemplate}
            >
              (baixar modelo)
            </Button>
          </Grid>
        </Grid>

        <Grid item>
          {renderRoute(['create-client'], permissions) && (
            <IconButton
              component={Link}
              to="/clients/new"
              className={styles.btnAdd}
            >
              <Icon
                path={mdiNoteEditOutline}
                className={classes.btnIcon}
                size={1}
              />
              Cadastrar
            </IconButton>
          )}
        </Grid>
      </Grid>

      <BaseFilterModal open={open} handleOpen={handleOpen} title="FILTRO">
        <Grid container xs={12} item direction="row" style={{ width: '367px' }}>
          <Grid item xs={12}>
            <Typography
              style={{ fontSize: '16px', fontWeight: 'normal' }}
              color={colors.primary}
            >
              Utilize os campos abaixo para encontrar <br /> um cliente
            </Typography>
          </Grid>

          <Grid item xs={12} container className={classes.form}>
            <Grid
              item
              xs={12}
              container
              display="flex"
              alignItems="center"
              justifyContent="center"
              spacing={2}
            >
              <Grid item xs={12}>
                <TextField
                  onChange={handleChange}
                  name="name"
                  label="Nome"
                  variant="standard"
                  fullWidth
                  value={form.name}
                />
              </Grid>

              <Grid item xs={6}>
                <InputMask
                  mask={
                    form.phone?.replace(/[^\d]/g, '').length <= 10
                      ? '(99) 9999-9999?'
                      : '(99) 99999-9999'
                  }
                  formatChars={{ 9: '[0-9]', '?': '[0-9 ]' }}
                  maskChar=""
                  value={form.phone}
                  onChange={e => {
                    setForm({
                      ...form,
                      phone: e.target.value.replace(/[^\d]/g, ''),
                    })
                  }}
                >
                  {inputProps => (
                    <TextField
                      {...inputProps}
                      label="Filtrar por telefone"
                      fullWidth
                    />
                  )}
                </InputMask>
              </Grid>

              <Grid item xs={6}>
                <TextField
                  onChange={handleChange}
                  name="email"
                  label="Email"
                  variant="standard"
                  fullWidth
                  value={form.email}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  onChange={handleChange}
                  name="neighborhood"
                  label="Bairro"
                  variant="standard"
                  fullWidth
                  value={form.neighborhood}
                />
              </Grid>

              <Grid item xs={6}>
                <DatePicker
                  clearable
                  value={form.birthdate}
                  label="Aniversário"
                  fullWidth
                  views={['date', 'month']}
                  clearLabel="Limpar"
                  invalidDateMessage="Data inválida"
                  autoOk
                  onChange={date => setForm({ ...form, birthdate: date })}
                  format="d MMMM"
                  // format="dd/MM/yyyy"
                  // formatDate={date => format(new Date(date), 'MM/dd/yyyy H:i:s')}
                />
              </Grid>

              <Grid
                item
                xs={12}
                container
                alignItems="center"
                justifyContent="center"
                style={{ marginTop: '1em' }}
              >
                <Button
                  color={colors.primary}
                  variant="contained"
                  className={styles.btnAdd}
                  onClick={handleSubmit}
                >
                  <SearchIcon />
                  {` Pesquisar`}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </BaseFilterModal>
    </BaseFilter>
    // <Tooltip title="Filter list">
    //   <PopupState variant="popover" popupId="demo-popup-popover">
    //     {popupState => (
    //       <div>
    //         <IconButton aria-label="filter list" {...bindTrigger(popupState)}>
    //           <BadgeGlobal form={form} items={items}>
    //             <FilterListIcon />
    //           </BadgeGlobal>
    //         </IconButton>
    //         <Popover
    //           {...bindPopover(popupState)}
    //           anchorOrigin={{
    //             vertical: 'bottom',
    //             horizontal: 'center',
    //           }}
    //           transformOrigin={{
    //             vertical: 'top',
    //             horizontal: 'center',
    //           }}
    //         >
    //           <Box p={2} className={classes.popover}>
    //             <div className={classes.root}>
    //               <ValidatorForm className={classes.form} onSubmit={onSubmit}>
    //                 <Grid
    //                   container
    //                   className={classes.gridContainer}
    //                   spacing={1}
    //                   justify="center"
    //                   alignItems="center"
    //                 >
    //                   <Grid item xs={6}>
    //                     <TextField
    //                       onChange={e =>
    //                         setForm({ ...form, name: e.target.value })
    //                       }
    //                       label="Nome"
    //                       margin="dense"
    //                       className={classes.inputFilter}
    //                       variant="outlined"
    //                       fullWidth
    //                       value={form.name}
    //                     />
    //                   </Grid>

    //                   <Grid item xs={6}>
    //                     <TextField
    //                       onChange={e =>
    //                         setForm({ ...form, email: e.target.value })
    //                       }
    //                       label="Email"
    //                       margin="dense"
    //                       className={classes.inputFilter}
    //                       variant="outlined"
    //                       fullWidth
    //                       value={form.email}
    //                     />
    //                   </Grid>

    //                   <Grid item xs>
    //                     <InputMask
    //                       mask={
    //                         form.phone?.replace(/[^\d]/g, '').length <= 10
    //                           ? '(99) 9999-9999?'
    //                           : '(99) 99999-9999'
    //                       }
    //                       formatChars={{ 9: '[0-9]', '?': '[0-9 ]' }}
    //                       maskChar=""
    //                       margin="dense"
    //                       value={form.phone}
    //                       onChange={e => {
    //                         setForm({
    //                           ...form,
    //                           phone: e.target.value.replace(/[^\d]/g, ''),
    //                         })
    //                       }}
    //                     >
    //                       {inputProps => (
    //                         <TextValidator
    //                           {...inputProps}
    //                           label="Telefone"
    //                           fullWidth
    //                           variant="outlined"
    //                           validators={[
    //                             'matchRegexp:^\\(?[0-9]{2,3}\\)? ?[0-9]{4,5}-?[0-9]{4}$',
    //                           ]}
    //                           errorMessages={['Telefone inválida']}
    //                         />
    //                       )}
    //                     </InputMask>
    //                   </Grid>

    //                   <Grid item xs>
    //                     <Button
    //                       type="submit"
    //                       color="primary"
    //                       fullWidth
    //                       variant="contained"
    //                       className={classes.submit}
    //                       onClick={popupState.close}
    //                     >
    //                       <SearchIcon />
    //                       {` Filtrar`}
    //                     </Button>
    //                   </Grid>
    //                 </Grid>
    //               </ValidatorForm>
    //             </div>
    //           </Box>
    //         </Popover>
    //       </div>
    //     )}
    //   </PopupState>
    // </Tooltip>
  )
}
