/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
/* eslint-disable import/order */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
import CssBaseline from '@material-ui/core/CssBaseline'
import { MuiThemeProvider } from '@material-ui/core/styles'
import axios from 'axios'
import { ConfirmProvider } from 'material-ui-confirm'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

// import { theme } from '../../resources/theme'
import { checkAuth, logout } from '../auth/AuthActions'
import OpenRoutes from '../auth/pages/OpenRoutes'
import OpenRoutes2 from '../auth/pages/OpenRoutes2'
import Snackbars from '../components/Snackbars'
import App from './App'
import Echo from 'laravel-echo'
import config from '../../config'

window.Pusher = require('pusher-js')

class AuthOrApp extends Component {
  componentDidMount() {
    this.props.checkAuth()
  }

  render() {
    const { token } = this.props.auth || false
    if (token) {
      axios.defaults.headers.common.Authorization = `${token.token_type} ${token.access_token}`

      window.Echo = new Echo({
        broadcaster: 'pusher',
        key: `${config.PUSHER_KEY}`,
        authEndpoint: `${config.API_URL}/broadcasting/auth`,
        wsHost: `${config.WS_HOST}`,
        wsPort: `${config.WS_PORT}`,
        forceTLS: String(config.FORCE_TLS) === 'true',
        disableStats: true,
        auth: {
          headers: {
            Authorization: `${token.token_type} ${token.access_token}`,
            Accept: 'application/json',
          },
        },
      })
    }

    const { theme } = this.props

    return (
      <MuiThemeProvider theme={theme}>
        <ConfirmProvider>
          <CssBaseline />
          <Snackbars />
          {token === '' ? (
            <OpenRoutes2 {...this.props} />
          ) : token ? (
            <App {...this.props}>{this.props.children}</App>
          ) : (
            <OpenRoutes {...this.props} />
          )}
        </ConfirmProvider>
      </MuiThemeProvider>
    )
  }
}

AuthOrApp.propTypes = {
  auth: PropTypes.object.isRequired,
  children: PropTypes.any,
  checkAuth: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({ auth: state.auth })
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      logout,
      checkAuth,
    },
    dispatch
  )
export default connect(mapStateToProps, mapDispatchToProps)(AuthOrApp)
